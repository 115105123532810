:root {
  --clr-black: #000;
  --clr-white: #fff;
  --clr-purple: #37006e;
  --clr-yellow: #f6c471;
  --clr-magenta: #87006e;
  --clr-dark-gray: #656565;
  --clr-light-gray: #e0e1e2;
  --clr-green: #407371;
  --clr-red: #cc5a5b;
  --clr-light-blue: #94b1c7;
  --clr-blue: #2b6c97;
  --ff-primary: "Montserrat";
  --ff-secondary: "Rufina";
}

::selection {
  background-color: var(--clr-yellow);
}

body {
  background-image: linear-gradient(var(--clr-black), var(--clr-black) 100%);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 200px;
  font-size: 16px;
}

a {
  transition: all .35s;
}

a:hover, a:focus, a:active {
  color: var(--clr-magenta);
  outline: none !important;
}

a:hover {
  cursor: pointer;
}

img {
  vertical-align: top;
  max-width: 100%;
  height: auto;
  border: 0;
}

.modal, .modal-open {
  padding-right: 0 !important;
}

h4, .h4 {
  line-height: 1.4;
}

.cet-montserrat {
  font-family: var(--ff-primary) !important;
}

ul {
  margin: 0;
  padding-left: 30px;
}

ul ul {
  padding-top: 14px;
  padding-left: 20px;
}

ul > li {
  padding-bottom: 14px;
  line-height: 24px;
  list-style: none;
}

ul li:last-of-type {
  padding-bottom: 0;
}

.content-main ul > li:before {
  content: "•";
  max-width: 0;
  max-height: 0;
  font-size: 22px;
  display: block;
  position: relative;
  top: -1px;
  left: -16px;
}

@media (width <= 767.98px) {
  .cet-flex-column-reverse .row {
    flex-direction: column-reverse !important;
  }
}

@media (width <= 575px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (width >= 576px) and (width <= 767px) {
  .hidden-sm {
    display: none !important;
  }
}

html, body {
  overflow-x: hidden;
}

body .body-page {
  background-color: var(--clr-white);
}

#page-main-content {
  min-height: 150px;
}

#footer {
  background-color: var(--clr-black);
  font-size: 15px;
}

#footer a {
  color: var(--clr-white) !important;
}

#footer a:hover, #footer a:focus {
  color: var(--clr-white);
  text-decoration: underline;
}

#footer .cet-disclaimer a:hover {
  color: var(--clr-yellow) !important;
}

#footer .footer-center {
  padding-top: 15px;
  padding-bottom: 40px;
}

@media (width <= 991.98px) {
  .cet-disclaimer-container {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  #footer .footer-center {
    padding-bottom: 0;
  }

  .cet-disclaimer {
    padding-top: 40px;
    font-size: 11px !important;
    line-height: 22px !important;
  }
}

#footer .footer-center .block {
  margin-bottom: 0;
  padding-bottom: 0;
}

.footer-center .row {
  border-bottom: 1px solid var(--clr-dark-gray);
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 40px;
}

.content-full > .container {
  padding: 80px 15px;
}

@media (width <= 991.98px) {
  .content-full > .container {
    padding: 60px 15px;
  }
}

.path-node .field--name-body h1, .path-node .field--name-body h2, .path-node .field--name-body h3, .path-node .field--name-body h4, .path-node .field--name-body .h1, .path-node .field--name-body .h2, .path-node .field--name-body .h3, .path-node .field--name-body .h4 {
  margin-top: 15px;
}

.path-node .field--name-body ul {
  margin: 0 0 15px;
}

.box-margin-small {
  margin-bottom: 30px;
}

@media (width <= 767.98px) {
  .box-margin-small {
    margin-bottom: 20px;
  }
}

.fweight-600 {
  font-weight: 600 !important;
}

.text-margin-bottom-banner-p {
  max-width: 950px;
  margin: 0 auto 46px;
}

@media (width <= 767.98px) {
  .text-margin-bottom-banner-p {
    margin: 0 auto 27px;
  }
}

.text-black {
  color: var(--clr-black) !important;
}

.text-white {
  color: var(--clr-white) !important;
}

.text-purple {
  color: var(--clr-purple) !important;
}

.text-body {
  color: var(--clr-black) !important;
}

.button {
  color: var(--clr-white);
  z-index: 1;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-box-shadow: 0 10px 30px #ff82574d;
  box-shadow: none;
  background: #85216c;
  border: none;
  border-radius: 5px;
  padding: 16px 50px;
  font-size: 16px;
  font-weight: 600;
  transition: all .3s;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.button:hover, .button:focus, .button:active {
  background: var(--clr-black);
  opacity: .3;
  color: var(--clr-white);
}

.block {
  background: none;
  margin-bottom: 30px;
}

.block .block {
  margin-bottom: 0 !important;
}

.block.block-system-main-block {
  margin-bottom: 0;
}

.block.no-title {
  border: none;
  margin-top: 0;
}

.block.no-title:before {
  content: none;
}

.block.no-title {
  border: none;
}

.help {
  z-index: 1;
  width: 100%;
  display: none;
  position: relative;
}

.gbb-row-wrapper, .gbb-row-wrapper .bb-container {
  position: relative;
}

.container .container {
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (width <= 767.98px) {
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
}

.gbb-row .bb-inner {
  padding-top: 65px;
  padding-bottom: 0;
}

@media (width <= 991.98px) {
  .gbb-row .bb-inner {
    padding-top: 50px;
    padding-bottom: 0;
  }
}

.gbb-row .bb-inner.padding-small {
  padding-top: 30px;
  padding-bottom: 30px;
}

.gbb-row .bb-inner.padding-medium {
  padding-top: 45px;
  padding-bottom: 45px;
}

.gbb-row .bb-inner.padding-top-medium {
  padding-top: 45px;
}

.gbb-row .bb-inner.padding-bottom-small {
  padding-bottom: 30px;
}

.gbb-row .bb-inner.padding-top-removed-bottom-small {
  padding-top: 0;
  padding-bottom: 30px;
}

.gbb-row .bb-inner.padding-top-removed-bottom-medium {
  padding-top: 0;
  padding-bottom: 45px;
}

@media (width <= 991.98px) {
  .gbb-row .bb-inner.padding-small {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .gbb-row .bb-inner.padding-medium {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .gbb-row .bb-inner.padding-top-medium {
    padding-top: 30px;
  }

  .gbb-row .bb-inner.padding-bottom-small {
    padding-bottom: 15px;
  }

  .gbb-row .bb-inner.padding-top-removed-bottom-small {
    padding-top: 0;
    padding-bottom: 15px;
  }

  .gbb-row .bb-inner.padding-top-removed-bottom-medium {
    padding-top: 0;
    padding-bottom: 30px;
  }
}

@media (width <= 767.98px) {
  .gbb-row .bb-inner.padding-small {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .gbb-row .bb-inner.padding-medium {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .gbb-row .bb-inner.padding-top-medium {
    padding-top: 25px;
  }

  .gbb-row .bb-inner.padding-bottom-small {
    padding-bottom: 10px;
  }

  .gbb-row .bb-inner.padding-top-removed-bottom-small {
    padding-top: 0;
    padding-bottom: 10px;
  }

  .gbb-row .bb-inner.padding-top-removed-bottom-medium {
    padding-top: 0;
    padding-bottom: 25px;
  }
}

.gbb-row .bb-inner.remove_padding_top_bottom {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.gsc-icon-box {
  transition: all .35s;
  position: relative;
}

.gsc-icon-box:before, .gsc-icon-box:after {
  content: " ";
  display: table;
}

.gsc-icon-box:after {
  clear: both;
}

.gsc-icon-box .icon-container.fa-2x {
  width: 64px;
  height: 64px;
  display: block;
}

.gsc-icon-box .icon-container.fa-2x .icon {
  margin-top: 15px;
}

.gsc-icon-box .highlight_content {
  margin-top: 5px;
}

.gsc-icon-box .desc {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.gsc-icon-box .desc p {
  margin-bottom: 0;
}

.gsc-icon-box .icon-container {
  text-align: center;
  color: #2e3d62;
  line-height: 1;
  position: relative;
}

.gsc-icon-box .icon-container .icon {
  z-index: 11;
  display: inline-block;
  position: relative;
}

.gsc-icon-box .icon-container.text-white[class*="border-"] {
  border-color: #fff3;
}

.gsc-icon-box .icon-container.text-black {
  color: var(--clr-black);
}

.gsc-icon-box .icon-container.text-black[class*="border-"] {
  border-color: #0000001a;
}

.gsc-icon-box.top-center {
  text-align: center;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}

.gsc-icon-box.top-center .highlight-icon {
  text-align: center;
  padding-bottom: 10px;
  padding-right: 0 !important;
}

.gsc-icon-box.top-center .highlight-icon .icon-container {
  display: inline-block;
}

.gsc-icon-box.top-center .title {
  padding: 15px 0;
  font-size: 22px;
  position: relative;
}

.gsc-icon-box.top-center p.title {
  font-size: 18px;
  line-height: 28px;
  font-family: var(--ff-primary);
  padding: 0;
  position: relative;
}

.gsc-icon-box.top-center .highlight_content {
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
}

.gsc-icon-box.top-center .desc {
  text-align: center;
  max-width: 246px;
  margin: 0 auto;
  font-weight: 400;
}

@media (width <= 575.98px) {
  #footer {
    border-image-source: linear-gradient(117.03deg, #210036 11.9%, #320148 34.01%, #22004c 83.11%, #000 96.39%);
  }
}

.gsc-heading {
  width: 100%;
  padding: 0 0 65px;
  display: block;
}

.gsc-heading:before, .gsc-heading:after {
  content: " ";
  display: table;
}

.gsc-heading:after {
  clear: both;
}

@media (width <= 991.98px) {
  .gsc-heading {
    padding: 0 0 40px;
  }
}

.gsc-heading .title {
  max-width: 950px;
  margin: 0 auto;
  position: relative;
}

@media (width <= 991.98px) {
  h1, .h1 {
    font-size: 2.5rem;
    line-height: 50px;
  }

  h2, .h2 {
    font-size: 1.75rem;
    line-height: 38px;
  }

  h3, .h3 {
    font-size: 1.5rem;
    line-height: 34px;
  }
}

.gsc-heading .sub-title {
  padding-bottom: 5px;
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
}

.gsc-heading .title-desc {
  clear: both;
  max-width: 950px;
  margin: 20px auto 0;
  font-size: 18px;
  line-height: 36px;
}

.gsc-heading .title-desc p {
  margin-bottom: 0;
}

.gsc-heading.align-center {
  text-align: center;
}

.gsc-heading.align-left {
  text-align: left;
}

.gsc-heading.align-left .title-desc {
  float: left;
}

.gsc-heading.style-1 {
  padding-bottom: 35px;
}

.gsc-heading.style-1 .title {
  margin-top: 10px;
  padding-bottom: 0;
  position: relative;
}

.gsc-button {
  text-transform: uppercase;
  letter-spacing: 2px;
  clear: both;
  cursor: pointer;
  border: 2px solid #0000;
  margin-bottom: 20px;
  font-weight: 600;
  transition: all .35s;
  display: inline-block;
  color: var(--clr-black) !important;
  font-family: var(--ff-primary) !important;
}

.gsc-button.large {
  background: var(--clr-yellow);
  min-width: 142px;
  text-align: center;
  letter-spacing: 0;
  text-transform: none;
  min-height: 40px;
  border-radius: 12px;
  padding: 7px 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;
  border: 2px solid var(--clr-yellow) !important;
}

.gsc-button.large:hover {
  background-color: #0000;
  border-color: var(--clr-yellow) !important;
}

.cet-hero-container .gsc-button.large:hover {
  color: var(--clr-white) !important;
}

.gsc-image {
  margin-bottom: 5px;
}

@media (width <= 991.98px) {
  .gsc-heading .sub-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@media (width <= 480px) {
  .col-xs-6 {
    width: 100% !important;
  }
}

.cet-hero-container {
  width: 100%;
  min-height: 660px;
  background-size: cover;
  justify-content: center;
  align-items: center;
  padding-top: 110px;
  padding-bottom: 160px;
  display: flex;
  position: relative;
}

.cet-hero-container:before {
  content: "";
  background-color: #0000008c;
  position: absolute;
  inset: 0;
}

.cet-hero-container h1, .cet-hero-container .title-desc {
  max-width: 950px;
  padding-left: 30px;
  padding-right: 30px;
}

.cet-hero-container .title-desc p {
  max-width: 600px;
  font-size: 26px;
  line-height: 40px;
}

.cet-hero-container .gsc-button {
  margin-bottom: 0;
}

.cet-external-link-icon:after {
  content: url("external-link-icon.600e88c4.svg");
  padding-right: 10px;
  position: relative;
  top: 2px;
  left: 10px;
}

@media (width <= 767.98px) {
  .gsc-heading.style-1 {
    padding-bottom: 25px;
  }
}

.cet-disclaimer-container {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.cet-disclaimer {
  color: var(--clr-white);
  max-width: 900px;
  margin-bottom: 50px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
}

.cet-disclaimer a {
  font-weight: 600;
  text-decoration: underline;
}

.cet-teal-grd-bg-container .gsc-button:hover {
  color: var(--clr-white) !important;
}

.cet-teal-grd-bg .bb-inner {
  padding: 20px;
}

.cet-teal-grd-bg .column-inner {
  min-height: 330px;
  flex-flow: column wrap;
  place-content: center;
  align-items: center;
  display: flex;
}

.cet-teal-grd-bg .sub-title {
  padding-bottom: 25px;
}

.cet-teal-grd-bg .gsc-heading.style-1 {
  margin-top: 0;
  padding-bottom: 0;
}

.cet-flex-vertical-left-aligned {
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.icon-box-classic .gsc-icon-box.top-center .title {
  max-width: 240px;
  margin: 0 auto;
  font-size: 40px;
  font-weight: 700;
}

.icon-box-classic .gsc-icon-box.top-center .desc p {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: var(--clr-dark-gray) !important;
}

.icon-box-classic .bb-inner {
  padding-top: 0 !important;
}

.icon-box-classic a {
  color: var(--clr-dark-gray);
  font-size: 22px;
  font-family: var(--ff-primary);
}

.icon-box-classic a:hover {
  color: var(--clr-magenta);
}

.cet-footnote p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  font-family: var(--ff-primary);
  color: var(--clr-black);
  max-width: 750px;
  margin: 0 auto;
}

.field--type-text-with-summary h3[class*="cet-montserrat"] {
  margin-top: 0;
  font-weight: 600;
}

.field--type-text-with-summary strong {
  font-weight: 600;
}

.cet-breadcrumbs {
  color: var(--clr-magenta);
  font-weight: 600;
  line-height: 22px;
}

.cet-breadcrumbs a {
  color: var(--clr-dark-gray);
  text-decoration: none;
}

.cet-breadcrumbs a:hover {
  text-decoration: underline;
}

.cet-breadcrumbs a:after {
  content: url("breadcrumbs-carrot.cbfebbb7.svg");
  padding-left: 10px;
  padding-right: 4px;
  position: relative;
  top: 1px;
}

@media (width <= 767.98px) {
  .cet-breadcrumbs, .cet-breadcrumbs a {
    font-size: 12px;
    line-height: 18px;
  }

  .cet-breadcrumbs {
    padding-top: 5px;
  }
}

@-moz-document url-prefix() {
  .stars {
    height: 33px !important;
    width: 229px !important;
  }

  .stars img {
    position: relative !important;
    top: -1px !important;
  }
}

@media (width <= 767.98px) {
  h3, .h3 {
    font-size: 1.25rem;
    line-height: 30px;
  }

  .icon-box-classic .gsc-icon-box.top-center .title {
    padding-bottom: 5px;
  }

  .icon-box-classic .gsc-icon-box.top-center .desc p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}

@media (width <= 991.98px) {
  .cet-hero-container {
    min-height: 525px;
    padding-top: 40px;
    padding-bottom: 60px;
  }

  .icon-box-classic .gsc-icon-box.top-center .title {
    font-size: 1.75rem;
  }
}

@media (width >= 768px) and (width <= 991.98px) {
  .cet-hero-container h1 {
    max-width: 640px !important;
  }
}

.cet-modal-bio .modal-body::-webkit-scrollbar {
  width: 7px;
}

.cet-modal-bio .modal-body::-webkit-scrollbar-track {
  background-color: #0000000d;
}

.cet-modal-bio .modal-body::-webkit-scrollbar-thumb {
  background-color: var(--clr-purple);
}

.cet-modal-bio img {
  width: 260px;
  height: 100%;
  border-radius: 0 6px 6px 0;
}

.modal-content__right {
  margin-right: 2rem;
}

.modal-content__right .modal-header {
  border: none;
  padding: 0 0 15px 30px;
}

.modal-header__name-title-wrapper a {
  outline: none;
}

.cet-modal-bio .modal-body p, .cet-modal-bio .modal-body ul {
  margin-bottom: 25px;
}

.cet-modal-bio .modal-body p:last-child {
  margin-bottom: 0;
}

.cet-modal-bio .modal-body p a {
  color: var(--clr-purple);
}

.cet-modal-bio .modal-body p a:hover {
  color: var(--clr-magenta);
  text-decoration: none;
}

.cet-modal-bio .modal-body p strong {
  font-weight: 600;
}

.cet-modal-bio .modal-body li {
  padding-bottom: 5px;
  font-size: 18px;
  line-height: 28px;
}

.modal-body__quote-wrapper {
  border: 1px solid var(--clr-light-gray);
  -webkit-flex: 0;
  flex-shrink: 0;
  margin-bottom: 1.5rem;
  padding: 39px 25px 39px 20px;
  display: flex;
}

.modal-body__quote-symbol {
  font-family: var(--ff-secondary);
  color: var(--clr-yellow);
  margin-right: 24px;
  font-size: 75px;
  font-style: normal;
  font-weight: 500;
  line-height: 70px;
  margin-bottom: 0 !important;
}

.modal-body__quote {
  font-family: var(--ff-primary);
  color: var(--clr-dark-gray);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.cet-404 hr {
  border-top: 1px solid var(--clr-light-gray);
  margin: 30px 0;
}

@media (width <= 767px) {
  .modal-header .close, .modal-header svg {
    outline: none;
  }

  .cet-modal-bio__wrapper {
    border: none;
    flex-direction: column;
    padding: 1.5rem 0;
  }

  .cet-modal-bio__wrapper > .modal-header {
    border: none;
    padding: 0 1rem 1rem;
  }

  .cet-modal-bio img {
    width: 300px;
    height: 100%;
    border-radius: 6px;
    align-self: center;
    margin-bottom: 1.5rem;
  }

  .modal-content__right {
    margin-right: 1rem;
  }

  .modal-content__right .modal-body {
    flex-direction: column;
    padding: 1rem 1rem 1.5rem;
    display: flex;
  }

  .cet-modal-bio .modal-body li {
    font-size: 16px;
    line-height: 26px;
  }

  .cet-breadcrumbs a:after {
    top: 2px;
  }
}

@media (width >= 768px) {
  .cet-modal-bio {
    max-width: 75vw;
  }
}

@media (width <= 575.98px) {
  .cet-modal-bio img {
    width: 100%;
    max-width: 300px;
  }

  .modal-header__name-title-wrapper div:first-child {
    font-size: 20px;
    line-height: 26px;
  }

  .modal-header__name-title-wrapper div:last-child {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (width >= 930px) {
  .cet-modal-bio img {
    width: 360px;
    height: 577px;
  }
}

@media (width <= 991.98px) {
  .cet-hero-container .title-desc p {
    max-width: 600px;
    font-size: 22px;
    line-height: 30px;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  h2.title, .gsc-icon-box.top-center .desc h2 {
    letter-spacing: -2px !important;
  }
}

@media screen and (-webkit-device-pixel-ratio >= 0) {
  .stars {
    height: 33px !important;
    width: 229px !important;
  }

  .stars img {
    position: relative !important;
    top: -1px !important;
  }
}

@media (width <= 767.98px) {
  .d-components-tab-grid, .d-components-tab-width-image {
    display: none;
  }
}

.gsc-button.large {
  max-width: 100%;
}

.close:hover, .close:focus {
  border: none;
  outline: none;
}

.cet-hero-container:before {
  opacity: .9;
  mix-blend-mode: multiply;
  background: linear-gradient(#707070 0%, #121c22f2 82.81%);
}

.cet-teal-grd-bg-header {
  background: linear-gradient(123.79deg, #060e1e 5.01%, #101e3d 22.31%, #051c28 37.16%, #000 88.9%);
}

.cet-teal-grd-bg-header .container {
  max-width: 100%;
}

.cet-teal-grd-bg-container, .cet-teal-grd-bg {
  background: radial-gradient(35.35% 84.06% at 98.43% 95.08%, #290e448a 22.52%, #00213400 73.07%, #0000 100%), radial-gradient(89.49% 178.99% at 0 19.88%, #1a1d59 0%, #00213400 64.06%, #0000 100%), radial-gradient(38.91% 92.52% at 24.34% 14.96%, #05273a 21.14%, #00213400 64.06%, #0000 100%), linear-gradient(126.91deg, #060e1e 5.2%, #101e3d 25.09%, #0c2636 38.47%, #000 92.02%);
}

.cet-teal-grd-bg-container .bb-inner {
  margin-top: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.cet-teal-grd-bg-container .title {
  color: var(--clr-white);
  font-weight: 500;
}

.cet-teal-grd-bg-container p {
  color: var(--clr-white);
}

.cet-teal-grd-bg-header .gsc-image {
  margin-bottom: 0;
}

.cet-teal-grd-bg-header img {
  vertical-align: middle;
  padding-left: 100px;
}

@media (width >= 576px) and (width <= 767.98px) {
  .cet-teal-grd-bg-header img {
    padding-left: 70px;
  }
}

@media (width <= 575.98px) {
  .cet-teal-grd-bg-header img {
    padding-left: 0;
  }
}

@media (width <= 767px) {
  .cet-teal-grd-bg-header.gbb-row .bb-inner.padding-small {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .CH-img-padding-left, .CH-img-padding-right {
    padding: 20px 0;
  }
}

.cethold-bd-container {
  margin-bottom: 40px;
  padding: 35px 20px;
  box-shadow: 0 4px 18.84px #00000040;
}

.cethold-bd {
  color: #1a1d59;
  min-height: 70px;
  max-width: 300px;
  font-family: Rufina;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}

.cethold-bdsub {
  min-height: 75px;
  padding: 20px 0 0;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.cethold-bdbtn a {
  margin-bottom: 0;
}

.gsc-icon-box.top-center .desc {
  max-width: 240px;
}

@media (width >= 768px) {
  .padding-20-left {
    padding-left: 20px;
  }

  .CH-img-padding-left {
    padding: 25px 0 25px 5px;
  }

  .CH-img-padding-right {
    padding: 25px 5px 25px 0;
  }
}

@media (width <= 1199.98px) {
  .CH-content-padding {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.cet-modal-bio__wrapper {
  border-radius: 9px;
  flex-direction: row;
  padding: 40px 0;
}

.modal-header__name-title-wrapper div:first-child {
  font-family: var(--ff-primary);
  color: var(--clr-purple);
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
}

.modal-header__name-title-wrapper div:last-child {
  font-family: var(--ff-primary);
  color: var(--clr-dark-gray);
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

@media (width >= 768px) {
  .cet-modal-bio {
    max-width: 75vw;
  }
}

.cet-modal-bio .modal-body {
  max-height: 491px;
  padding: 10px 35px 0 30px;
  overflow-y: auto;
}

.cet-modal-bio .modal-body li {
  list-style: disc !important;
}

@media (width <= 767px) {
  .cet-modal-bio__wrapper {
    border: none;
    flex-direction: column;
    padding: 1.5rem 0;
  }
}

/*# sourceMappingURL=index.81b47dfa.css.map */

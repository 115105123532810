/****/
:root {
  /* colors */
  --clr-black: #000;
  --clr-white: #FFF;
  --clr-purple: #37006E;
  --clr-yellow: #F6C471;
  --clr-magenta: #87006E;
  --clr-dark-gray: #656565;
  --clr-light-gray: #E0E1E2;
  --clr-green: #407371;
  --clr-red: #CC5A5B;
  --clr-light-blue: #94B1C7;
  --clr-blue: #2B6C97;

  /* font-families */
  --ff-primary: 'Montserrat';
  --ff-secondary: 'Rufina';
}
::selection {
  background-color: var(--clr-yellow);
}
body {
  font-size: 16px;
  background-image: linear-gradient(var(--clr-black), var(--clr-black) 100%);
  background-size: 100% 200px;
  background-repeat: no-repeat;
  background-position: center bottom;
}
.taxo-cont .field.field--name-name.field--type-string.field--label-hidden.field__item {
  font-size: 14px;
  font-weight: normal;
}

body.footer-nomargintop #footer {
  margin-top: 0;
}
a {
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

a:hover, a:focus, a:active {
  outline: none !important;
  color: var(--clr-magenta);
}

input:hover, input:focus, input:active, select:hover, select:focus, select:active, textarea:hover, textarea:focus, textarea:active {
  outline: none !important;
}

input[type="button"]:hover, input[type="submit"]:hover, a:hover {
  cursor: pointer;
}

img {
  border: 0;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

.modal, .modal-open {
  padding-right: 0px !important;
}

.video-responsive {
  height: 0;
  padding-top: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.video-responsive embed, .video-responsive iframe, .video-responsive object, .video-responsive video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

h4, .h4, h5, h6, .h5, .h6 {
  line-height: 1.4;
}

.body-page input[type="text"],
.body-page input[type="tel"],
.body-page input[type="password"],
.body-page input[type="email"],
.body-page textarea, .body-page select {
  background-color: var(--clr-white);
  border: 1px solid var(--clr-dark-gray);
  padding: 5px 10px;
}

.body-page input[type="text"]:focus, .body-page input[type="text"]:active, .body-page input[type="text"]:hover,
.body-page input[type="tel"]:focus,
.body-page input[type="tel"]:active,
.body-page input[type="tel"]:hover,
.body-page input[type="password"]:focus,
.body-page input[type="password"]:active,
.body-page input[type="password"]:hover,
.body-page input[type="email"]:focus,
.body-page input[type="email"]:active,
.body-page input[type="email"]:hover,
.body-page textarea:focus,
.body-page textarea:active,
.body-page textarea:hover, .body-page select:focus, .body-page select:active, .body-page select:hover {
  outline: none;
}

.label {
  color: var(--clr-black);
}
.cet-fullwidth .title {
  max-width: 100% !important;
}
.cet-montserrat {
  font-family: var(--ff-primary) !important;
}

.cet-rufina {
  font-family: var(--ff-secondary) !important;
}

.list-menu ul li {
  list-style: none;
  padding: 15px 2px;
  font-size: 0.875rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.list-menu ul li:last-child {
  border-bottom: none;
}

ul {
  margin: 0;
  padding-left: 30px;
}

ul ul, ul ol, ol ol, ol ul {
  padding-left: 20px;
  padding-top: 14px;
}

ul > li {
  list-style: none;
  line-height: 24px;
  padding-bottom: 14px;
}

ul li:last-of-type, ol li:last-of-type {
  padding-bottom: 0;
}
ol li {
  line-height: 24px;
  padding-bottom: 14px;
}
.content-main ul > li::before {
  content: '\2022';
  display: block;
  position: relative;
  max-width: 0;
  max-height: 0;
  left: -16px;
  top: -1px;
  font-size: 22px;
}
ul.drupal-tabs li::before {
  content: '';
  display: block;
  position: relative;
  max-width: 0;
  max-height: 0;
  left: -16px;
  top: -1px;
  font-size: 22px;
}

ul li i {
  margin-right: 3px;
  font-size: 16px;
  min-width: 20px;
  text-align: center;
}

.item-list ul li {
  margin-left: 0;
}

ul.menu > li {
  position: relative;
  padding: 8px 0;
}

ul.menu > li > a {
  padding-left: 0;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  position: relative;
}

ul.menu > li > a:hover, ul.menu > li > a:active, ul.menu > li > a:focus {
  color: #ff8257;
}

ul.feature-list {
  margin: 10px 0;
  padding-left: 0;
}

ul.feature-list > li {
  padding-left: 25px;
  position: relative;
  list-style: none;
  margin-bottom: 8px;
}

ul.feature-list > li:after {
  content: "\f14a";
  font-family: "FontAwesome";
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 1;
  color: #ff8257;
  font-weight: 900;
  font-size: 14px;
}

ul.inline > li {
  float: left;
  margin-left: 5px;
}

table {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid var(--clr-dark-gray);
}

table > thead > tr > th,
table > thead > tr > td,
table > tbody > tr > th,
table > tbody > tr > td,
table > tfoot > tr > th,
table > tfoot > tr > td {
  border-color: var(--clr-dark-gray);
  border-width: 1px 1px 1px 0;
  border-style: solid;
  padding: 5px 10px;
}

table > thead > tr > th,
table > thead > tr > td {
  border-bottom-width: 2px;
}

.no-padding {
  padding: 0 !important;
}

a.disable {
  pointer-events: none;
  cursor: default;
}

.form-text {
  margin-top: 0;
}

/************************* Pagination ************************/
.pager {
  text-align: center;
}

.pager ul.pager__items {
  display: inline-block;
  margin: 20px 0 0;
  padding: 0;
}

.pager ul.pager__items > li {
  list-style-type: none;
  float: left;
  margin-right: 10px;
}

.pager ul.pager__items > li a {
  border: 1px solid #eaeaea;
  font-weight: 600;
  display: inline-block;
  line-height: 34px;
  margin: 0 1px;
  text-align: center;
  margin-right: 10px;
  padding: 0 15px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  text-transform: capitalize;
  font-size: 14px;
}

.pager ul.pager__items > li a:last-child {
  margin-right: 0;
}

.pager ul.pager__items > li a:hover {
  background: #ff8257;
  color: var(--clr-white);
  border-color: #ff8257;
}

.pager ul.pager__items > li.pager__item--next {
  font-size: 14px;
}

.pager ul.pager__items > li.pager__item--last {
  font-size: 14px;
}

.pager ul.pager__items > li.is-active a {
  background: #ff8257;
  color: var(--clr-white);
  border-color: #ff8257;
}

.breadcrumb-content-inner {
  margin-bottom: 30px;
  text-align: center;
}

.gavias-pagebuilder .breadcrumb-content-inner {
  margin-bottom: 0;
}

.breadcrumb-content-inner .breadcrumb-content-main {
  position: relative;
  z-index: 9;
  max-width: 700px;
  margin: 0 auto;
}

.breadcrumb-content-inner .gva-breadcrumb-content .page-title {
  font-size: 46px;
  line-height: 55px;
  font-weight: 700;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .breadcrumb-content-inner .gva-breadcrumb-content .page-title {
    font-size: 30px;
    line-height: 45px;
  }
}

.breadcrumb-content-inner .gva-breadcrumb-content .page-title span {
  -webkit-box-shadow: 0 -8px 0 0 rgba(255, 255, 255, 0.1) inset;
  box-shadow: 0 -8px 0 0 rgba(255, 255, 255, 0.1) inset;
}

.breadcrumb-content-inner .gva-breadcrumb-content .gva-block-breadcrumb .breadcrumb-style {
  padding: 120px 0 120px;
  margin-bottom: 45px;
  position: relative;
}

@media (max-width: 767.98px) {
  .breadcrumb-content-inner .gva-breadcrumb-content .gva-block-breadcrumb .breadcrumb-style {
    padding: 60px 0;
  }
  .cet-flex-column-reverse .row {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
}

.gavias-pagebuilder .breadcrumb-content-inner .gva-breadcrumb-content .gva-block-breadcrumb .breadcrumb-style {
  margin-bottom: 0;
}

.breadcrumb-content-inner .gva-breadcrumb-content .gva-block-breadcrumb.text-white *, .breadcrumb-content-inner .gva-breadcrumb-content .gva-block-breadcrumb.text-white .page-title, .breadcrumb-content-inner .gva-breadcrumb-content .gva-block-breadcrumb.text-light *, .breadcrumb-content-inner .gva-breadcrumb-content .gva-block-breadcrumb.text-light .page-title {
  color: var(--clr-white);
}

.breadcrumb-content-inner .gva-breadcrumb-content .gva-block-breadcrumb.text-white *:after, .breadcrumb-content-inner .gva-breadcrumb-content .gva-block-breadcrumb.text-white .page-title:after, .breadcrumb-content-inner .gva-breadcrumb-content .gva-block-breadcrumb.text-light *:after, .breadcrumb-content-inner .gva-breadcrumb-content .gva-block-breadcrumb.text-light .page-title:after {
  background: var(--clr-white);
}

.breadcrumb-content-inner .gva-breadcrumb-content .gva-block-breadcrumb.text-white .breadcrumb-links li, .breadcrumb-content-inner .gva-breadcrumb-content .gva-block-breadcrumb.text-light .breadcrumb-links li {
  color: #fcfbfe;
  font-size: 18px;
}

.breadcrumb-content-inner .gva-breadcrumb-content .gva-block-breadcrumb.text-white .breadcrumb-links li a, .breadcrumb-content-inner .gva-breadcrumb-content .gva-block-breadcrumb.text-light .breadcrumb-links li a {
  color: #fcfbfe;
}

.breadcrumb-content-inner .gva-breadcrumb-content .breadcrumb-links nav.breadcrumb {
  display: inline-block;
  -webkit-box-shadow: 0 -8px 0 0 rgba(255, 255, 255, 0.1) inset;
  box-shadow: 0 -8px 0 0 rgba(255, 255, 255, 0.1) inset;
}

.breadcrumb-content-inner .gva-breadcrumb-content .breadcrumb-links nav.breadcrumb li {
  font-size: 14px;
  font-weight: 400;
  color: #bbbbbb;
  text-transform: capitalize;
}

.breadcrumb-content-inner .gva-breadcrumb-content .breadcrumb-links nav.breadcrumb li a {
  color: #2e3d62;
}

.breadcrumb-content-inner .gva-breadcrumb-content .breadcrumb-links nav.breadcrumb li a:hover {
  color: #ff8257;
}

.breadcrumb-content-inner .gva-parallax-background .gva-parallax-inner {
  background-position: center center;
  background-size: cover;
}

pre {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  margin: 30px 0;
}

.text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6 {
  color: var(--clr-white);
}

.hidden {
  display: none !important;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

@media (max-width: 575px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl {
    display: none !important;
  }
}

html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
}

body.boxed {
  max-width: 1440px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.05);
  background-color: #EAF1F7;
}

body.boxed div.body-page {
  background: var(--clr-white);
}

body .body-page {
  background-color: var(--clr-white);
}

.topbar {
  background: #2e3d62;
  color: #b8b8d3;
  padding: 15px 0;
  font-size: 13px;
  font-weight: 500;
}

.topbar a {
  text-decoration: underline;
  color: var(--clr-white);
}

.topbar a:hover {
  color: #ff8257;
}

.topbar .block {
  margin: 0 !important;
}

.topbar .topbar-content-inner {
  padding-right: 35px;
}

.topbar .topbar-content-inner .gva-search-region .icon {
  color: var(--clr-white);
}

.topbar .topbar-text .icon {
  font-size: 12px;
  margin-right: 8px;
  color: #ff8257;
}

.language-box .links > li {
  margin-right: 10px;
  opacity: 0.6;
  filter: alpha(opacity=60);
  line-height: 1;
  margin-top: 5px;
  float: right;
}

.language-box .links > li.is-active {
  opacity: 1;
  filter: alpha(opacity=100);
}

.gv-sticky-menu {
  position: static;
  top: -75px;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
}

.stuck {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 99 !important;
  width: 100%;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.area.slideshow_content .block {
  margin-bottom: 0;
}

.area-panel {
  padding: 30px 0;
}

.header-right .block {
  margin-bottom: 0;
}

.promotion {
  padding: 0;
  margin-bottom: 0;
}

#page-main-content {
  min-height: 150px;
}

.main-menu .area-main-menu .area-inner .block {
  margin-bottom: 0;
}

.sidebar {
  position: relative;
}

#footer {
  font-size: 15px;
  background-color: var(--clr-black);
}

#footer a {
  color: var(--clr-white) !important;
}

#footer a:hover, #footer a:focus {
  color: var(--clr-white);
  text-decoration: underline;
}

#footer .socials-2 a:hover, #footer .cet-disclaimer a:hover {
  color: var(--clr-yellow) !important;
}

#footer .footer-top {
  padding: 120px 0;
  text-align: center;
}

#footer ul.gva_menu {
  padding-left: 0;
}

#footer .footer-center {
  padding-bottom: 40px;
  padding-top: 15px;
}

@media (max-width: 991.98px) {
  #footer .footer-top {
    padding: 60px 0;
  }
  .cet-disclaimer-container {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .cet-pp-logo-container {
    justify-content: flex-start !important;
  }
  #footer .footer-center {
    padding-bottom: 0;
  }
  .cet-disclaimer {
    font-size: 11px !important;
    line-height: 22px !important;
    padding-top: 40px;
  }
  #footer .copyright {
    font-size: 11px !important;
    line-height: 22px;
  }
  #footer .navigation .gva_menu > li > a {
    font-size: 14px !important;
  }
}

#footer .footer-center .block {
  padding-bottom: 0;
  margin-bottom: 0;
}
#footer .footer-center .block .block-title {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 20px;
  margin-bottom: 15px;
  top: 5px;
}
#footer .footer-center .block .block-title .svg-inline--fa {
  display:none;
}
#footer .navigation .gva_menu > li {
  display: block;
  padding: 6px 0px;
  text-align: left;
}
#footer .navigation .gva_menu > li > a {
  font-size: 16px;
  font-family: var(--ff-primary);
  text-decoration: none;
  font-weight: 400 !important;
  line-height: 20px;
}
#footer .navigation .gva_menu > li > a:hover {
    text-decoration: underline;
}
#footer .block .block-title span {
  font-family: var(--ff-primary);
}
.footer-center .row {
  border-bottom: 1px solid var(--clr-dark-gray);
  padding-bottom: 40px;
  margin-left: 0;
  margin-right: 0;
}

#footer .footer-first {
  padding-left: 5px;
}

#footer ul.menu > li {
  padding: 6px 0;
  margin: 0;
}

#footer ul.menu a {
  padding-left: 0;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
}

#footer ul.menu a:hover {
  padding-left: 8px;
}
#footer .socials-2 a {
  color: var(--clr-white);
  background: transparent;
  font-size: 27px;
  width: auto;
}

#footer .socials-2 a:hover {
  color: var(--clr-yellow) !important;
  background: transparent;
}
#footer .copyright {
  background: var(--clr-black);
  color: var(--clr-white);
  padding: 30px 0 0;
  font-size: 14px;
}
#footer .copyright a {
  color: var(--clr-white);
}
#footer .copyright a:hover, #footer .copyright a:focus {
  color: var(--clr-yellow) !important;
}

#footer .copyright .block {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  #footer .column + .column {
    margin-top: 30px;
  }
  #footer .navigation .gva_menu > li {
    padding: 4px 0px;
  }
}

.quick-side-icon {
  position: absolute;
  right: 54px;
  top: 50%;
  margin-top: -10px;
  z-index: 1;
}

.quick-side-icon .icon {
  font-size: 20px;
  line-height: 1;
}

.gva-quick-side {
  position: fixed;
  width: 350px;
  top: 0;
  right: -355px;
  z-index: 99;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  background: var(--clr-white);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  padding: 50px 30px 20px;
  overflow-x: hidden;
  height: 100%;
}

@media (max-width: 355px) {
  .gva-quick-side {
    width: 100%;
  }
}

.gva-quick-side .quick-side-close {
  margin-bottom: 30px;
  font-size: 16px;
  display: inline-block;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #F3F5FA;
  line-height: 42px;
  text-align: center;
}

.gva-quick-side .quick-side-close:hover {
  background: #ff8257;
  color: var(--clr-white);
}

.gva-quick-side .content-inner .block {
  text-align: left;
}

.gva-quick-side .content-inner .block .block-title {
  text-align: left;
  font-weight: 600;
}

.gva-quick-side .content-inner ul {
  width: 100%;
}

.gva-quick-side .content-inner ul > li {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.gva-quick-side .content-inner ul > li:last-child {
  border-bottom: none;
}

.gva-quick-side .content-inner ul > li a {
  padding: 0;
  font-size: 14px;
}

.gva-quick-side .content-inner ul > li a:hover, .gva-quick-side .content-inner ul > li a:active, .gva-quick-side .content-inner ul > li a:focus {
  color: #ff8257 !important;
}

.gva-quick-side .block {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 30px;
  padding-bottom: 30px;
  float: left;
  width: 100%;
}

.gva-quick-side .block:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.gva-quick-side.open {
  right: 0;
  width: 352px;
}

header .site-branding-logo {
  padding-top: 35px;
  display: inline-block;
  max-height: 95px;
  height: auto;
}

@media (max-width: 991.98px) {
  header .site-branding-logo {
    padding-top: 20px;
  }
}

@media (max-width: 767.98px) {
  header .site-branding {
    text-align: left;
    padding-bottom: 10px;
  }
  header .header-right-inner, header .header-right-inner .block {
    text-align: center !important;
  }
}

header .header-main {
  padding: 0;
  background: none;
}

@media (max-width: 991.98px) {
  header .main-menu-inner {
    width: 100%;
  }
}

header .area-main-menu .navigation {
  position: static;
}

header .area-main-menu .navigation ul.gva_menu {
  position: static;
}

header .block {
  overflow: visible;
}

@media (max-width: 575.98px) {
  header .header-right {
    display: none;
  }
  .cet-home-logos-banner img {
    max-width: 150px;
  }
}

header .header-information {
  display: inline-block;
  text-align: left;
  margin-left: 10px;
  float: right;
}

header .header-information.box-red .icon {
  border-color: #ef4059;
  color: #ef4059;
}

header .header-information.box-green .icon {
  border-color: #5dd8d3;
  color: #5dd8d3;
}

header .header-information.box-orange .icon {
  border-color: #FDA400;
  color: #FDA400;
}

header .header-information .icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid #eaeaea;
  color: #ff8257;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

header .header-information .content {
  padding-top: 10px;
}

header .header-information .content .label {
  display: block;
  color: #2e3d62;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

header .header-information .content .text {
  display: block;
  font-size: 14px;
  color: #666e82;
  line-height: 24px;
}

header .header-information.icon-left .icon {
  float: left;
}

header .header-information.icon-left .content {
  padding-left: 75px;
}

header .header-information.icon-right .icon {
  float: right;
}

@media (max-width: 991.98px) {
  header .header-information.icon-right .icon {
    float: left;
  }
}

header .header-information.icon-right .content {
  padding-right: 75px;
}

@media (max-width: 991.98px) {
  header .header-information.icon-right .content {
    padding-right: 0;
    padding-left: 75px;
  }
}

.gva-search-region {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -6px;
  z-index: 9;
  line-height: 1;
}

@media (max-width: 991.98px) {
  .gva-search-region {
    right: 75px;
  }
}

.gva-search-region .icon {
  font-size: 16px;
  color: #2e3d62;
}

.gva-search-region .icon:hover {
  cursor: pointer;
}

.gva-search-region .search-content {
  display: none;
  width: 310px;
  position: absolute;
  top: 43px;
  right: 0;
  margin-top: -1px;
  z-index: 99;
  background: var(--clr-white);
  padding: 20px;
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid #eaeaea;
}

.gva-search-region .search-content form.search-block-form {
  position: relative;
}

.gva-search-region .search-content form.search-block-form .js-form-item {
  display: block;
}

.gva-search-region .search-content form.search-block-form input#edit-keys {
  height: 45px;
  padding: 0 20px;
  border: 1px solid #eaeaea;
  background: none;
  width: 100%;
  padding-right: 95px;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
}

.gva-search-region .search-content form.search-block-form input[id*="edit-submit"] {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  height: 35px;
  line-height: 35px;
  padding: 0 5px;
  background: #ff8257;
  color: var(--clr-white);
  border: none;
  font-size: 12px;
  width: 90px;
}

.gva-search-region .search-content form.search-block-form input[id*="edit-submit"]:hover {
  cursor: pointer;
}

.gva-search-region .search-content #edit-actions {
  margin: 0 !important;
  padding: 0 !important;
}

.gva-search-region.show .search-content {
  display: block;
}

header.header-default {
  position: relative;
  background: var(--clr-white);
  z-index: 99;
}

header.header-default .gva-search-region .icon {
  color: #2e3d62;
}

header.header-default .header-main-inner .branding {
  width: 180px;
  float: left;
}

header.header-default .header-main-inner .logo-white {
  display: none;
}

header.header-default .header-main-inner .header-inner {
  padding-left: 180px;
  padding-right: 65px;
}

@media (max-width: 767.98px) {
  header.header-default .header-main-inner .header-inner {
    padding-right: 0;
  }
}

header.header-default .header-main-inner .quick-side-icon {
  margin-top: -14px;
}

header.header-default .header-main-inner .gva-search-region {
  margin-top: -10px;
}

@media (max-width: 991.98px) {
  header.header-default .header-main-inner .gva-search-region {
    margin-top: -3px;
  }
}

header.header-default .header-main-inner .gva-offcanvas-mobile {
  float: right;
}

header.header-default .stuck {
  background: var(--clr-white);
}

header.header-default .main-menu-inner {
  float: left;
}

header.header-1 .header-main {
  padding: 35px 0;
  background: var(--clr-white);
}

@media (max-width: 575.98px) {
  header.header-1 .header-main {
    padding: 0;
  }
}

header.header-1 .header-main .site-branding-logo {
  padding: 25px 0 0;
}

@media (max-width: 575.98px) {
  header.header-1 .header-main .site-branding-logo {
    padding-bottom: 20px;
  }
}

header.header-1 .header-main .site-branding-logo .logo-white {
  display: none;
}

header.header-1 .header-main .header-info .header-icon {
  color: #ff8257;
}

header.header-1 .header-main .header-info .title {
  color: #2e3d62;
}

header.header-1 .block {
  margin-bottom: 0;
}

header.header-1 .main-menu {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

header.header-1 .main-menu .area-inner {
  padding: 0;
}

header.header-1 .main-menu ul.gva_menu_main {
  float: left;
}

@media (max-width: 575px) {
  header.header-1 .branding {
    width: 100%;
    text-align: center;
  }
  header.header-1 .right-header {
    width: 100%;
    text-align: center;
  }
  header.header-1 .right-header .header-info {
    display: inline-block;
  }
  header.header-1 .right-header .header-info .content-inner {
    text-align: left;
  }
}

header.header-1 .stuck {
  background: var(--clr-white);
}

header.header-v2 {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background: none;
  margin-top: 10px;
}

header.header-v2 .branding {
  width: 190px;
  float: left;
}

@media (max-width: 991.98px) {
  header.header-v2 .branding {
    width: 170px;
  }
}

header.header-v2 .site-branding-logo img.logo-white {
  display: none;
}

header.header-v2 .header-inner {
  padding-left: 200px;
  padding-right: 68px;
}

@media (max-width: 991.98px) {
  header.header-v2 .header-inner {
    padding-left: 180px;
  }
}

@media (max-width: 767.98px) {
  header.header-v2 .header-inner {
    padding-right: 0;
  }
}

header.header-v2 .gva-offcanvas-mobile {
  float: right;
}

header.header-v2 .main-menu-inner {
  float: left;
}

header.header-v2 .gva-search-region .icon {
  color: #666e82;
}

header.header-v2 .quick-side-icon a {
  color: #666e82;
}

header.header-v2 .stuck {
  background: var(--clr-white);
}

header.header-v3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: transparent;
  margin-top: 2px;
}

@media (max-width: 767.98px) {
  header.header-v3 {
    position: static;
    margin-top: 0;
  }
}

header.header-v3 .gva-search-region .icon {
  color: #2e3d62;
}

header.header-v3 .header-main-inner {
  background: var(--clr-white);
  margin-top: 30px;
  padding: 0 30px;
}

@media (max-width: 767.98px) {
  header.header-v3 .header-main-inner {
    margin-top: 0;
  }
}

header.header-v3 .header-main-inner .branding {
  width: 190px;
  float: left;
}

header.header-v3 .header-main-inner .header-inner {
  padding-left: 200px;
  padding-right: 60px;
}

@media (max-width: 767.98px) {
  header.header-v3 .header-main-inner .header-inner {
    padding-right: 0;
  }
}

header.header-v3 .header-main-inner .gva-offcanvas-mobile {
  float: right;
}

header.header-v3 .stuck {
  background: var(--clr-white);
}

header.header-v3 .stuck .header-main-inner {
  margin-top: 0;
}

header.header-v3 .main-menu-inner {
  float: left;
}

body.hidden-title .node--view-mode-full .header-title {
  display: none !important;
}

/* ----- Maintenance page ----- */
body.maintenance-page {
  background-color: var(--clr-white);
  color: var(--clr-black);
}

.maintenance-page #page-wrapper {
  background: var(--clr-white);
  margin-left: auto;
  margin-right: auto;
  min-width: 0;
  min-height: 0;
  border: 1px solid #ddd;
  margin-top: 40px;
}

.maintenance-page #page {
  margin: 10px;
}

.maintenance-page #main-wrapper {
  min-height: inherit;
}

.maintenance-page #header {
  width: auto;
}

.maintenance-page #main {
  margin: 0;
}

.maintenance-page .content .section {
  padding: 0 0 0 10px;
}

[dir="rtl"] .maintenance-page .content .section {
  padding-left: 0;
  padding-right: 10px;
}

.maintenance-page #header {
  background-color: var(--clr-white);
  background-image: none;
}

.maintenance-page .site-branding-text {
  margin-bottom: 50px;
  margin-left: 0;
  padding-top: 20px;
  font-size: 90%;
}

[dir="rtl"] .maintenance-page .site-branding-text {
  margin-right: 0;
}

.maintenance-page .site-branding-text,
.maintenance-page .site-branding-text a,
.maintenance-page .site-branding-text a:hover,
.maintenance-page .site-branding-text a:focus {
  color: #777;
}

.maintenance-page .page-title {
  line-height: 1em;
  margin-top: 0;
}

@media all and (min-width: 800px) {
  .maintenance-page #page-wrapper {
    width: 800px;
  }
  .maintenance-page #header div.section,
  .maintenance-page #main {
    width: 700px;
  }
}

@media all and (min-width: 600px) {
  .maintenance-page #page {
    margin: 20px 40px 40px;
  }
}

.page-notfound {
  text-align: center;
  padding: 150px 0;
  background: #f6f6f6;
}

@media (max-width: 767.98px) {
  .page-notfound {
    padding: 50px 0;
  }
}

.page-notfound header {
  display: none !important;
}

.page-notfound .big-title {
  font-size: 200px;
  font-weight: 700;
  text-transform: uppercase;
  color: #2e3d62;
  line-height: 1;
}

@media (max-width: 767.98px) {
  .page-notfound .big-title {
    font-size: 90px;
  }
}

.page-notfound .sub-title {
  color: #999999;
  padding: 30px 0;
}

.page-notfound .content {
  color: #666666;
  font-size: 16px;
  max-width: 450px;
  margin: 0 auto;
  line-height: 30px;
}

@media (max-width: 767.98px) {
  .page-notfound .content {
    padding: 0 20px;
  }
}

.page-notfound .action {
  text-align: center;
  margin-top: 30px;
}

.page-notfound .action a {
  font-size: 16px;
  color: #ff8257;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.page-notfound .action a:hover {
  color: #2e3d62;
}

.content-full > .container {
  padding: 80px 15px;
}

@media (max-width: 991.98px) {
  .content-full > .container {
    padding: 60px 15px;
  }
}

.portfolio-single {
  padding-bottom: 30px;
}

.portfolio-single .portfolio-images .owl-nav .owl-prev {
  left: 20px !important;
}

.portfolio-single .portfolio-images .owl-nav .owl-next {
  right: 20px !important;
}

.portfolio-single .portfolio-images .owl-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
}

.portfolio-single.post-block .post-title {
  font-size: 24px;
  padding: 0;
  margin: 0 0 10px;
}

.portfolio-single .post-meta .line {
  width: 10px;
  height: 2px;
  background: #ff8257;
  margin: 0 10px 0 4px;
  display: inline-block;
  position: relative;
  top: -3px;
}

.portfolio-single .portfolio-informations {
  margin-bottom: 15px;
}

.portfolio-single .portfolio-informations .item-information {
  border-bottom: 1px solid #eaeaea;
  padding: 10px 0;
}

.portfolio-single .portfolio-informations .item-information span:first-child {
  font-weight: 600;
  min-width: 120px;
  display: inline-block;
  color: #2e3d62;
}

.portfolio-single .post-content {
  margin-top: 30px;
}

.portfolio-single .post-content ul {
  padding-left: 20px;
  margin-top: 15px;
}

.team-single-page .team-name {
  margin-bottom: 30px;
}

.team-single-page .team-name .name {
  font-size: 30px;
  font-weight: 600;
  color: #2e3d62;
  line-height: 42px;
}

.team-single-page .team-name .job {
  font-size: 12px;
  color: #999999;
}

.team-single-page .team-name .line {
  text-align: center;
  float: left;
}

.team-single-page .team-name .line span {
  width: 30px;
  height: 5px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: inline-block;
  margin: 0 5px;
}

.team-single-page .team-name .line span.one {
  background: #5EB4E7;
  margin-left: 0;
}

.team-single-page .team-name .line span.second {
  background: #F8C44F;
}

.team-single-page .team-name .line span.three {
  background: #F49626;
  margin-right: 0;
}

.team-single-page .socials {
  margin-top: 10px;
}

.team-single-page .heading {
  font-size: 18px;
  font-weight: 600;
  color: #2e3d62;
}

.team-single-page .team-contact {
  margin-bottom: 20px;
}

.team-single-page .team-skills {
  margin-top: 15px;
}

.team-single-page .team-skills .team-skill {
  margin-bottom: 15px;
}

.team-single-page .team-skills .team-skill .progress-label {
  font-size: 14px;
}

.team-single-page .team-contact .content-inner, .team-single-page .team-education .content-inner {
  font-size: 14px;
  line-height: 28px;
}

.team-single-page .team-education .field__item {
  padding-left: 20px;
  position: relative;
}

.team-single-page .team-education .field__item:after {
  content: "";
  width: 6px;
  height: 6px;
  background: #ff8257;
  position: absolute;
  top: 8px;
  left: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 1;
}

.team-single-page .team-info {
  margin-bottom: 30px !important;
}

.team-single-page .team-quote {
  font-size: 16px;
  color: #999999;
  margin-top: 20px;
  padding-left: 50px;
  position: relative;
}

.team-single-page .team-quote:after {
  font-family: "FontAwesome";
  content: "\f10d";
  left: 0;
  top: 0;
  position: absolute;
  z-index: 1;
  color: #ff8257;
  font-size: 24px;
  text-align: center;
  line-height: 40px;
  font-weight: 700;
}

.page-user-login, .page-user-register, .page-user-pass {
  position: relative;
}

.page-user-login .bg, .page-user-register .bg, .page-user-pass .bg {
  background: #152C49;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
}

.page-user-login input, .page-user-register input, .page-user-pass input {
  max-width: 100%;
}

.page-user-login #page-main-content, .page-user-register #page-main-content, .page-user-pass #page-main-content {
  min-height: 50px;
}

.page-user-login .main-page, .page-user-register .main-page, .page-user-pass .main-page {
  padding: 30px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  max-width: 550px;
  background: var(--clr-white);
  border: 8px solid rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 50px auto 0;
  position: relative;
  z-index: 99;
}

@media (max-width: 767.98px) {
  .page-user-login .main-page, .page-user-register .main-page, .page-user-pass .main-page {
    margin-top: 35px;
  }
}

.page-user-login .branding, .page-user-register .branding, .page-user-pass .branding {
  margin-bottom: 20px;
}

.page-user-login .container, .page-user-register .container, .page-user-pass .container {
  padding: 0;
}

.page-user-login .nav-tabs, .page-user-register .nav-tabs, .page-user-pass .nav-tabs {
  margin-bottom: 20px;
}

.page-user-login .nav-tabs > li > a, .page-user-register .nav-tabs > li > a, .page-user-pass .nav-tabs > li > a {
  padding: 8px 20px 0 0 !important;
}

.path-node .field--name-body h1, .path-node .field--name-body h2, .path-node .field--name-body h3, .path-node .field--name-body h4, .path-node .field--name-body h5, .path-node .field--name-body h6, .path-node .field--name-body .h1, .path-node .field--name-body .h2, .path-node .field--name-body .h3, .path-node .field--name-body .h4, .path-node .field--name-body .h5, .path-node .field--name-body .h6 {
  margin-top: 15px;
}

.path-node .field--name-body ul {
  margin: 0 0 15px;
}

.post-block .post-image {
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
}

.post-block .post-image a {
  position: relative;
  display: block;
}

.post-block .post-image img {
  width: 100%;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.post-block .post-image:hover a:before, .post-block .post-image:hover a:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.post-block .post-thumbnail {
  position: relative;
}

.post-block .post-created {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 11;
  background: #ff8257;
  color: var(--clr-white);
  padding: 5px 20px;
  border-radius: 5px 0 5px 0;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.post-block .post-created > span {
  display: block;
}

.post-block img {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.post-block .post-content:before, .post-block .post-content:after {
  content: " ";
  display: table;
}

.post-block .post-content:after {
  clear: both;
}

.post-block .post-title {
  padding: 0;
  display: block;
  line-height: 34px;
  font-weight: 600;
  font-size: 20px;
  word-wrap: break-word;
}

.post-block .post-meta {
  font-size: 12px;
  font-weight: 600;
  color: #ff8257;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  margin: 0;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.post-block .post-categories {
  position: relative;
  margin-bottom: 0;
  display: inline-block;
}

.post-block .post-categories a {
  color: #ff8257;
  padding: 0;
  display: inline-block;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  line-height: 1.5;
}

.post-block .post-categories a:hover {
  color: #2e3d62;
}

.post-block .post-readmore {
  margin-top: 25px;
}

.post-block .post-readmore a {
  font-size: 30px;
  line-height: 30px;
  display: block;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  color: #bcbfc7;
  position: relative;
  z-index: 11;
}

.post-block .post-readmore a:hover {
  color: #ff8257;
}

.post-block:hover .post-image {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.post-block:hover .post-image img {
  -webkit-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1);
}

.post-block:hover .post-image .post-created {
  background: #ff8257;
  color: var(--clr-white);
}

.post-block-1 {
  -webkit-box-shadow: 0px 5px 20px 0 rgba(46, 61, 98, 0.1);
  box-shadow: 0px 5px 20px 0 rgba(46, 61, 98, 0.1);
  background: var(--clr-white);
  padding: 60px 35px 50px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

@media (max-width: 1199.98px) {
  .post-block-1 {
    padding: 30px;
    padding-bottom: 20px;
  }
}

.post-block-1:after, .post-block-1:before {
  position: absolute;
  content: "";
  width: 190px;
  height: 190px;
  border-radius: 190px;
  -webkit-border-radius: 190px;
  -moz-border-radius: 190px;
  -ms-border-radius: 190px;
  -o-border-radius: 190px;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  background: rgba(255, 255, 255, 0.1);
}

.post-block-1:after {
  bottom: -95px;
  left: -95px;
}

.post-block-1:before {
  top: -95px;
  right: -95px;
}

.post-block-1:hover {
  background: #ff8257;
}

.post-block-1:hover:after, .post-block-1:hover:before {
  opacity: 1;
  filter: alpha(opacity=100);
}

.post-block-1:hover .post-meta, .post-block-1:hover .post-categories a, .post-block-1:hover .post-body, .post-block-1:hover .post-title a, .post-block-1:hover .post-readmore a {
  color: var(--clr-white);
}

.post-block-1:hover .post-created {
  background: var(--clr-white);
  color: #2e3d62;
}

.post-block-1 .post-meta {
  margin-top: 30px;
}

@media (max-width: 1199.98px) {
  .post-block-1 .post-meta {
    margin-top: 15px;
  }
}

.post-block-1 .post-created {
  position: absolute;
  top: 0;
  left: 0;
  background: #ff8257;
  color: var(--clr-white);
  padding: 5px 20px;
  border-radius: 5px 0 5px 0;
  font-size: 12px;
  font-weight: 600;
}

.post-block-1 .post-title {
  margin-bottom: 25px;
}

.post-block-1 .post-body {
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.post-footer {
  margin-top: 25px;
}

.post-footer .item-list ul li {
  margin-bottom: 15px !important;
}

.post-footer .item-list ul li:last-child {
  border: none;
  margin-bottom: 0 !important;
}

.post-footer .item-list ul li .post-block {
  background: transparent !important;
}

.post-footer .item-list ul li .post-block .post-title {
  font-size: 14px;
}

.post-footer .item-list ul li .post-block .post-title a {
  color: var(--clr-white);
  font-weight: 400;
}

.post-footer .item-list ul li .post-block .post-title a:hover {
  color: #ff8257;
}

.post-footer .item-list ul li .post-block .post-meta {
  color: rgba(255, 255, 255, 0.8);
}

.post-footer .item-list ul li .post-block .post-meta .post-categories {
  font-size: 12px;
}

.post-footer .item-list ul li .post-block .post-meta .post-categories a {
  font-size: 10px;
}

.post-style-grid .post-block {
  margin-bottom: 30px;
}

.post-style-grid .owl-item.active:nth-child(1) .post-block-2 {
  background: red;
}

.gallery-post {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.gallery-post:after {
  content: "";
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  top: 0;
  left: 0;
  position: absolute;
  background: #ff8257;
  z-index: 9;
  opacity: 0;
  filter: alpha(opacity=0);
}

.gallery-post .post-title {
  position: absolute;
  top: 50px;
  left: 50px;
  z-index: 11;
  color: var(--clr-white);
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  opacity: 0;
  filter: alpha(opacity=0);
}

.gallery-post .images {
  overflow: hidden;
  position: relative;
}

.gallery-post .images img {
  -webkit-transition: all 5s;
  transition: all 5s;
  -moz-transition: all 5s;
  -ms-transition: all 5s;
}

.gallery-post a.zoomGallery {
  position: absolute;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  line-height: 38px;
  text-align: center;
  background: var(--clr-white);
  right: 50px;
  bottom: 50px;
  z-index: 99;
  color: #2e3d62;
  font-size: 18px;
  display: inline-block;
  opacity: 0;
  filter: alpha(opacity=0);
}

@media (max-width: 680px) {
  .gallery-post a.zoomGallery {
    opacity: 1;
  }
}

.gallery-post a.zoomGallery:hover {
  background: #2e3d62;
  color: var(--clr-white);
}

.gallery-post:hover:after {
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.gallery-post:hover .post-title {
  opacity: 1;
  filter: alpha(opacity=100);
}

.gallery-post:hover a.zoomGallery {
  opacity: 1;
  filter: alpha(opacity=100);
}

.style-dark .post-block {
  background: transparent;
}

.style-dark .post-block .post-title {
  background: transparent;
}

.style-dark .post-block .post-title a {
  color: var(--clr-white);
}

.style-dark .post-block .post-title a:hover {
  color: #ff8257;
}

.post-style-horizontal .post-block:before, .post-style-horizontal .post-block:after {
  content: " ";
  display: table;
}

.post-style-horizontal .post-block:after {
  clear: both;
}

.node--view-mode-full iframe {
  min-height: 400px;
}

.post-single {
  padding-bottom: 35px;
}

.post-single iframe {
  width: 100%;
  display: block;
}

.post-single .post-thumbnail {
  position: relative;
  margin-bottom: 20px;
}

.post-single .post-thumbnail.post-gallery {
  overflow: hidden;
}

.post-single .post-thumbnail.post-gallery .owl-nav {
  opacity: 1;
  filter: alpha(opacity=100);
}

.post-single .post-thumbnail.post-gallery .owl-nav .owl-prev {
  margin-left: 100px;
}

.post-single .post-thumbnail.post-gallery .owl-nav .owl-next {
  margin-right: 100px;
}

.post-single .post-title {
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 34px;
}

.post-single .field--name-field-tags {
  margin-top: 20px;
}

.post-single .field--name-field-tags .field__item {
  float: left;
  margin-right: 10px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
}

.post-single .field--name-field-tags .field__item a {
  background: #F3F5FA;
  padding: 2px 15px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.post-single .field--name-field-tags .field__item a:hover, .post-single .field--name-field-tags .field__item a:focus, .post-single .field--name-field-tags .field__item a:active {
  background: #ff8257;
  color: var(--clr-white);
}

.categories-view-content.layout-grid .post-block, .categories-view-content.layout-masonry .post-block {
  margin-bottom: 30px;
  text-align: left;
}

.categories-view-content.layout-grid .post-block .post-categories, .categories-view-content.layout-masonry .post-block .post-categories {
  margin: 0;
}

.categories-view-content.layout-grid .post-block .post-categories h2, .categories-view-content.layout-grid .post-block .post-categories h3, .categories-view-content.layout-grid .post-block .post-categories h4, .categories-view-content.layout-masonry .post-block .post-categories h2, .categories-view-content.layout-masonry .post-block .post-categories h3, .categories-view-content.layout-masonry .post-block .post-categories h4 {
  margin: 0;
  line-height: 1;
}

.categories-view-content.layout-grid .post-block .post-title, .categories-view-content.layout-masonry .post-block .post-title {
  padding: 0;
}

.categories-view-content.layout-grid .post-block .post-meta, .categories-view-content.layout-masonry .post-block .post-meta {
  margin-top: 0;
}

.categories-view-content.layout-grid .post-block .post-body, .categories-view-content.layout-masonry .post-block .post-body {
  padding: 10px 0 15px;
}

.categories-view-content.layout-grid .post-block .post-content, .categories-view-content.layout-masonry .post-block .post-content {
  padding: 15px;
  border: 1px solid #eaeaea;
  border-top: 0;
}

.categories-view-content.layout-list .post-block {
  margin-bottom: 0;
}

.categories-view-content.layout-list .post-block:before, .categories-view-content.layout-list .post-block:after {
  content: " ";
  display: table;
}

.categories-view-content.layout-list .post-block:after {
  clear: both;
}

.categories-view-content.layout-list .post-block .post-thumbnail {
  width: 45%;
  float: left;
}

@media (max-width: 575.98px) {
  .categories-view-content.layout-list .post-block .post-thumbnail {
    width: 100%;
  }
}

.categories-view-content.layout-list .post-block .post-title {
  padding: 0;
  margin: 0;
}

.categories-view-content.layout-list .post-block .post-content {
  width: 55%;
  float: left;
  padding-left: 30px;
}

@media (max-width: 575.98px) {
  .categories-view-content.layout-list .post-block .post-content {
    width: 100%;
  }
}

.categories-view-content.layout-list .post-block .post-content .post-categories {
  line-height: 1;
  padding-bottom: 0;
  margin-bottom: 0;
}

.categories-view-content.layout-list .post-block .post-content .post-categories h2 {
  margin: 0;
}

.post-style-list ul > li {
  margin-bottom: 30px !important;
}

.post-style-list .post-block {
  clear: both;
}

.post-style-list .post-block .post-image {
  width: 38%;
  float: left;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .post-style-list .post-block .post-image {
    width: 100%;
  }
}

.post-style-list .post-block .post-image img {
  height: auto;
  width: 100%;
}

.post-style-list .post-block .post-meta {
  margin-top: 2px;
}

.post-style-list .post-block .post-content {
  padding-top: 5px;
  width: 62%;
  padding-left: 30px;
  float: left;
}

@media (max-width: 767.98px) {
  .post-style-list .post-block .post-content {
    width: 100%;
    padding-left: 0;
  }
}

.post-style-list .post-block .post-title {
  padding-top: 0;
}

.post-style-list .post-block.no-image .post-content {
  width: 100%;
  padding-left: 0;
}

.post-list-small {
  background: var(--clr-white);
  border: 1px solid #eaeaea;
  padding: 20px;
}

.post-list-small .item-list ul li {
  margin-bottom: 20px !important;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
}

.post-list-small .item-list ul li:last-child {
  margin-bottom: 0 !important;
  border-bottom: 0;
  border: 0;
}

.post-list-small .post-block {
  clear: both;
  background: none;
}

.post-list-small-2 .item-list {
  margin-top: 30px;
}

.post-list-small-2 .item-list ul li {
  margin-bottom: 20px !important;
}

.post-list-small-2 .item-list ul li:last-child {
  margin-bottom: 0 !important;
}

.post-list-small-2 .post-block {
  clear: both;
  background: none;
}

.post-list-small-2 .post-block .post-image {
  width: 80px;
  float: left;
}

.post-list-small-2 .post-block .post-image img {
  height: auto;
  width: 100%;
  border-radius: 4px;
}

.post-list-small-2 .post-block .post-content {
  padding-left: 95px;
  padding-top: 0;
}

.post-list-small-2 .post-block .post-title {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.post-list-small-2 .post-block .post-meta {
  position: relative;
}

.post-list-small-3 .item-list ul li {
  margin-bottom: 10px !important;
}

.post-list-small-3 .item-list ul li:last-child {
  margin-bottom: 0 !important;
}

.post-list-small-3 .post-block {
  clear: both;
  background: none;
}

.post-list-small-3 .post-block .post-image {
  float: left;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
}

.post-list-small-3 .post-block .post-image img {
  width: 62px;
  height: 62px;
}

.post-list-small-3 .post-block .post-content {
  padding-left: 78px;
  padding-top: 0;
}

.post-list-small-3 .post-block .post-content .post-title {
  padding: 0;
  margin: 0;
}

.post-list-small-3 .post-block .post-content .post-title a {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #666e82;
  display: inline-block;
}

.post-list-small-3 .post-block .post-content .post-title a:hover {
  color: #2e3d62;
  text-decoration: underline;
}

.feed-icon {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 30px;
  background: #ff8257;
  color: var(--clr-white);
  -webkit-box-shadow: 0px 10px 30px 0 rgba(255, 130, 87, 0.3);
  box-shadow: 0px 10px 30px 0 rgba(255, 130, 87, 0.3);
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  display: inline-block;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.feed-icon:hover, .feed-icon:focus {
  background: #ff5c24;
  color: var(--clr-white);
}

.post-slider.post-block {
  position: relative;
}

.post-slider.post-block .post-inner {
  position: relative;
}

.post-slider.post-block .post-inner:after {
  content: '';
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
}

.post-slider.post-block .post-meta-wrap {
  width: 100%;
  padding: 30px 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

@media (max-width: 767.98px) {
  .post-slider.post-block .post-meta-wrap {
    padding: 20px;
  }
}

.post-slider.post-block .post-meta-wrap .post-title {
  padding: 0 0 2px;
}

.post-slider.post-block .post-meta-wrap .post-title a {
  font-size: 16px;
  color: var(--clr-white);
  font-weight: 500;
  line-height: 24px;
}

@media (max-width: 767.98px) {
  .post-slider.post-block .post-meta-wrap .post-title a {
    font-size: 15px;
  }
}

.post-slider.post-block .post-meta-wrap .post-title a:hover {
  color: #ff8257 !important;
}

.post-slider.post-block .post-body {
  margin-top: 0;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.85);
}

@media (max-width: 767.98px) {
  .post-slider.post-block .post-body {
    display: none;
  }
}

.post-slider.post-block .post-meta {
  color: var(--clr-white);
}

.post-slider.post-block .post-categories {
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.post-slider.post-block .post-categories a {
  font-size: 12px;
  color: var(--clr-white);
  font-weight: 400;
  background: #ff8257;
  padding: 2px 10px;
}

.box-margin-0 {
  margin-bottom: 0;
}

.box-margin-small {
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .box-margin-small {
    margin-bottom: 20px;
  }
}

.box-margin-medium {
  margin-bottom: 50px;
}

@media (max-width: 767.98px) {
  .box-margin-medium {
    margin-bottom: 30px;
  }
}

@media (max-width: 575.98px) {
  .box-margin-medium {
    margin-top: 10px;
  }
}

.box-margin-large {
  margin-bottom: 80px;
}

@media (max-width: 991.98px) {
  .box-margin-large {
    margin-bottom: 50px;
  }
}

@media (max-width: 767.98px) {
  .box-margin-large {
    margin-bottom: 30px;
  }
}

.no-margin {
  margin: 0 !important;
}

.margin-1 {
  margin-top: -75px;
}

@media (max-width: 767.98px) {
  .margin-1 {
    margin-top: 50px;
  }
}

.margin-2 {
  margin-top: -90px;
  padding-top: 60px;
  background: var(--clr-white);
}

@media (max-width: 991.98px) {
  .margin-2 {
    margin-top: 0;
    padding-top: 100px;
  }
}

.margin-2 > .bb-inner {
  background: var(--clr-white);
}

.margin-3 {
  margin-top: 80px;
}

@media (max-width: 767.98px) {
  .margin-3 {
    margin-top: 50px;
  }
}

.margin-4 {
  margin-top: 30px;
}

@media (max-width: 575.98px) {
  .margin-4 {
    margin-top: 0;
  }
}

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-bottom-100 {
  margin-bottom: 100px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-30 {
  padding: 30px !important;
}

.padding-40 {
  padding: 40px !important;
}

.padding-50 {
  padding: 50px !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-bottom-25 {
  padding-bottom: 25px !important;
}

.padding-bottom-30 {
  padding-bottom: 30px !important;
}

.padding-bottom-35 {
  padding-bottom: 35px !important;
}

.padding-bottom-40 {
  padding-bottom: 40px !important;
}

.padding-bottom-45 {
  padding-bottom: 45px !important;
}

.padding-bottom-50 {
  padding-bottom: 50px !important;
}

.padding-bottom-100 {
  padding-bottom: 100px !important;
}

@media (max-width: 767.98px) {
  .padding-bottom-100 {
    padding-bottom: 30px !important;
  }
  .padding-top-0-mobile .bb-inner {
    padding-top: 0 !important;
  }
  .padding-bottom-0-mobile .bb-inner {
    padding-bottom: 0 !important;
  }
  .margin-top-0-mobile {
    margin-top: 0 !important;
  }
  .margin-bottom-0-mobile {
    margin-top: 0 !important;
  }
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-top-25 {
  padding-top: 25px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-top-35 {
  padding-top: 35px !important;
}

.padding-top-40 {
  padding-top: 40px !important;
}
.padding-top-45 {
  padding-top: 45px !important;
}

.padding-top-50 {
  padding-top: 50px !important;
}

.width-100 {
  max-width: 100px !important;
  margin: 0 auto;
}

.width-200 {
  max-width: 200px !important;
  margin: 0 auto;
}

.width-300 {
  max-width: 300px !important;
  margin: 0 auto;
}

.width-400 {
  max-width: 400px !important;
  margin: 0 auto;
}

.width-500 {
  max-width: 500px !important;
  margin: 0 auto;
}

.width-600 {
  max-width: 600px !important;
  margin: 0 auto;
}

.width-700 {
  max-width: 700px !important;
  margin: 0 auto;
}

.width-750 {
  max-width: 750px !important;
  margin: 0 auto;
}

.width-800 {
  max-width: 800px !important;
  margin: 0 auto;
}

.width-900 {
  max-width: 900px !important;
  margin: 0 auto;
}

.width-950 {
  max-width: 950px !important;
  margin: 0 auto;
}

.width-1000 {
  max-width: 1000px !important;
  margin: 0 auto;
}

.radius-1x {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.radius-2x {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.radius-5x {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.border-1 {
  border: 1px solid #eaeaea;
}

.border-2 {
  border: 2px solid #eaeaea;
}

.border-3 {
  border: 3px solid #eaeaea;
}

.border-4 {
  border: 4px solid #eaeaea;
}

.border-5 {
  border: 5px solid #eaeaea;
}

.cet-fsize-18 {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.fsize-14 {
  font-size: 14px !important;
}

.fsize-15 {
  font-size: 15px !important;
}

.fsize-16 {
  font-size: 16px !important;
}

.fsize-17 {
  font-size: 17px !important;
}

.fsize-18 {
  font-size: 18px !important;
}

.fsize-19 {
  font-size: 19px !important;
}

.fsize-20 {
  font-size: 20px !important;
}

.fsize-21 {
  font-size: 21px !important;
}

.fsize-22 {
  font-size: 22px !important;
}

.fsize-23 {
  font-size: 23px !important;
}

.fsize-24 {
  font-size: 24px !important;
}

.fsize-25 {
  font-size: 25px !important;
}

.fsize-26 {
  font-size: 26px !important;
}

.fsize-27 {
  font-size: 27px !important;
}

.fsize-28 {
  font-size: 28px !important;
}

.fsize-29 {
  font-size: 29px !important;
}

.fsize-30 {
  font-size: 30px !important;
}

.fsize-31 {
  font-size: 31px !important;
}

.fsize-32 {
  font-size: 32px !important;
}

.fsize-33 {
  font-size: 33px !important;
}

.fsize-34 {
  font-size: 34px !important;
}

.fsize-35 {
  font-size: 35px !important;
}

.fsize-36 {
  font-size: 36px !important;
}

.fsize-37 {
  font-size: 37px !important;
}

.fsize-38 {
  font-size: 38px !important;
}

.fsize-39 {
  font-size: 39px !important;
}

.fsize-40 {
  font-size: 40px !important;
}

.fsize-41 {
  font-size: 41px !important;
}

.fsize-42 {
  font-size: 42px !important;
}

.fsize-44 {
  font-size: 44px !important;
}

.fsize-46 {
  font-size: 46px !important;
}

@media (max-width: 991.98px) {
  .fsize-46 {
    font-size: 38px !important;
  }
}

.fsize-48 {
  font-size: 48px !important;
}

@media (max-width: 991.98px) {
  .fsize-48 {
    font-size: 38px !important;
  }
}

.fsize-50 {
  font-size: 50px !important;
}

@media (max-width: 991.98px) {
  .fsize-50 {
    font-size: 38px !important;
  }
}

.fsize-52 {
  font-size: 52px !important;
}

@media (max-width: 991.98px) {
  .fsize-52 {
    font-size: 38px !important;
  }
}

.fsize-54 {
  font-size: 54px !important;
}

.fsize-56 {
  font-size: 56px !important;
}

@media (max-width: 991.98px) {
  .fsize-56 {
    font-size: 38px !important;
  }
}

.fsize-58 {
  font-size: 58px !important;
}

@media (max-width: 991.98px) {
  .fsize-58 {
    font-size: 38px !important;
  }
}

.fsize-60 {
  font-size: 60px !important;
}

@media (max-width: 991.98px) {
  .fsize-60 {
    font-size: 40px !important;
  }
}

.fsize-70 {
  font-size: 70px !important;
}

@media (max-width: 991.98px) {
  .fsize-70 {
    font-size: 50px !important;
  }
}

.fsize-80 {
  font-size: 80px !important;
}

@media (max-width: 991.98px) {
  .fsize-80 {
    font-size: 50px !important;
  }
}

.fsize-90 {
  font-size: 90px !important;
}

@media (max-width: 991.98px) {
  .fsize-90 {
    font-size: 50px !important;
  }
}

.fsize-100 {
  font-size: 100px !important;
}

@media (max-width: 991.98px) {
  .fsize-100 {
    font-size: 60px !important;
  }
}

.lheight-18 {
  line-height: 18px !important;
}

.lheight-19 {
  line-height: 19px !important;
}

.lheight-20 {
  line-height: 20px !important;
}

.lheight-21 {
  line-height: 21px !important;
}

.lheight-22 {
  line-height: 22px !important;
}

.lheight-23 {
  line-height: 23px !important;
}

.lheight-24 {
  line-height: 24px !important;
}

.lheight-25 {
  line-height: 25px !important;
}

.lheight-26 {
  line-height: 26px !important;
}

.lheight-27 {
  line-height: 27px !important;
}

.lheight-28 {
  line-height: 28px !important;
}

.lheight-29 {
  line-height: 29px !important;
}

.lheight-30 {
  line-height: 30px !important;
}

.lheight-31 {
  line-height: 31px !important;
}

.lheight-32 {
  line-height: 32px !important;
}

.lheight-33 {
  line-height: 33px !important;
}

.lheight-34 {
  line-height: 34px !important;
}

.lheight-35 {
  line-height: 35px !important;
}

.lheight-36 {
  line-height: 36px !important;
}

.lheight-37 {
  line-height: 37px !important;
}

.lheight-38 {
  line-height: 38px !important;
}

.lheight-39 {
  line-height: 39px !important;
}

.lheight-40 {
  line-height: 40px !important;
}

.lheight-41 {
  line-height: 41px !important;
}

.lheight-42 {
  line-height: 42px !important;
}

.lheight-43 {
  line-height: 43px !important;
}

.lheight-44 {
  line-height: 44px !important;
}

.lheight-46 {
  line-height: 46px !important;
}

@media (max-width: 991.98px) {
  .lheight-46 {
    line-height: 38px !important;
  }
}

.lheight-48 {
  line-height: 48px !important;
}

@media (max-width: 991.98px) {
  .lheight-48 {
    line-height: 38px !important;
  }
}

.lheight-50 {
  line-height: 50px !important;
}

@media (max-width: 991.98px) {
  .lheight-50 {
    line-height: 38px !important;
  }
}

.lheight-52 {
  line-height: 52px !important;
}

@media (max-width: 991.98px) {
  .lheight-52 {
    line-height: 38px !important;
  }
}

.lheight-54 {
  line-height: 54px !important;
}

@media (max-width: 991.98px) {
  .lheight-54 {
    line-height: 38px !important;
  }
}

.lheight-56 {
  line-height: 56px !important;
}

@media (max-width: 991.98px) {
  .lheight-56 {
    line-height: 38px !important;
  }
}

.lheight-58 {
  line-height: 58px !important;
}

@media (max-width: 991.98px) {
  .lheight-58 {
    line-height: 38px !important;
  }
}

.lheight-60 {
  line-height: 60px !important;
}

@media (max-width: 991.98px) {
  .lheight-60 {
    line-height: 40px !important;
  }
}

.lheight-70 {
  line-height: 70px !important;
}

@media (max-width: 991.98px) {
  .lheight-70 {
    line-height: 60px !important;
  }
}

.lheight-80 {
  line-height: 80px !important;
}

@media (max-width: 991.98px) {
  .lheight-80 {
    line-height: 70px !important;
  }
}

.lheight-90 {
  line-height: 90px !important;
}

@media (max-width: 991.98px) {
  .lheight-90 {
    line-height: 70px !important;
  }
}

.lheight-100 {
  line-height: 100px !important;
}

@media (max-width: 991.98px) {
  .lheight-100 {
    line-height: 80px !important;
  }
}

.fweight-400 {
  font-weight: 400 !important;
}

.fweight-500 {
  font-weight: 500 !important;
}

.fweight-600 {
  font-weight: 600 !important;
}

.fweight-700 {
  font-weight: 700 !important;
}

.fweight-800 {
  font-weight: 800 !important;
}

.fweight-900 {
  font-weight: 900 !important;
}

.text-light .border-1, .text-light .border-2, .text-light .border-3, .text-light .border-4, .text-light .border-5 {
  border-color: rgba(255, 255, 255, 0.6) !important;
}

.padding-responsive {
  padding: 30px 120px;
}

@media (max-width: 767.98px) {
  .padding-responsive {
    padding: 30px;
  }
}

.special-margin-top {
  margin-top: -125px;
}

@media (max-width: 767.98px) {
  .special-margin-top {
    margin-top: 30px;
  }
}

.special-margin-top-2 {
  margin-top: -120px;
}

@media (max-width: 767.98px) {
  .special-margin-top-2 {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

.margin-top-contact {
  margin-top: -100px;
  padding-bottom: 80px;
}

@media (max-width: 767.98px) {
  .margin-top-contact {
    margin-top: 30px;
    padding-bottom: 45px;
  }
}

.padding-lr-15p {
  padding-left: 15%;
  padding-right: 15%;
}

@media (max-width: 767.98px) {
  .padding-lr-15p {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.border {
  border: 1px solid #eaeaea;
}

.overflow-visible {
  overflow: visible !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
  margin-right: 1px !important;
}

.no-bg {
  background-color: transparent !important;
}

.col-no-padding div[class*="col-"] {
  padding: 0 !important;
}

.p-static {
  position: static !important;
}

.p-relative {
  position: relative !important;
}

.bg-theme {
  background: #ff8257;
}

.bg-theme-second {
  background: #9364d4;
}

.bg-white {
  background: var(--clr-white);
}

.bg-black {
  background: #26263e;
}

.bg-gray {
  background: #F6F7FB;
}

.bg-fixed, .gva-fixed-background {
  background-attachment: fixed;
}

@media (max-width: 991.98px) {
  .bg-fixed, .gva-fixed-background {
    background-attachment: scroll;
  }
}

.youtube-bg {
  position: relative;
  z-index: 9;
}

.youtube-bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 9;
}

.youtube-bg .bb-inner {
  position: relative;
  z-index: 99;
}

.ui-widget-content {
  color: #666e82;
}

.gva-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 9;
  display: none;
}

.text-normal {
  font-size: 15px;
}

.text-medium {
  font-size: 20px;
}

.text-size-big {
  font-size: 42px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 48px;
  letter-spacing: 1px;
}
.text-margin-bottom-banner-p {
  max-width: 950px;
  margin: 0 auto 46px;
}
.text-margin-top-bottom-banner-p {
  margin-top: 35px;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .text-size-big {
    font-size: 30px;
    line-height: 38px;
  }
  .text-margin-bottom-banner-p {
    margin: 0 auto 27px;
  }
  .text-margin-top-bottom-banner-p {
    margin-top: 25px;
  }
}

.text-size-big {
  font-size: 36px;
}

@media (max-width: 767.98px) {
  .text-size-big {
    font-size: 20px;
  }
}
.text-black {
  color: var(--clr-black) !important;
}

.text-white {
  color: var(--clr-white) !important;
}

.text-theme {
  color: #ff8257 !important;
}

.text-blue {
  color: #36266D !important;
}
.text-purple {
  color: var(--clr-purple) !important;
}
.text-dark-grey {
  color: var(--clr-dark-gray) !important;
}
.text-light-grey {
  color: var(--clr-light-gray) !important;
}
.text-yellow {
  color: var(--clr-yellow) !important;
}
.text-gray {
  color: #787887 !important;
}

.text-light {
  color: var(--clr-white) !important;
}

.text-theme-2, .text-theme-second {
  color: #9364d4 !important;
}

.color-theme {
  color: #ff8257 !important;
}

.color-text, .text-body {
  color: var(--clr-black) !important;
}

.color-white {
  color: var(--clr-white) !important;
}

.color-dark {
  color: #f1f1f1 !important;
}
.color-black {
  color: var(--clr-black) !important;
}

.gavias-parallax {
  background-attachment: fixed !important;
}

@media (max-width: 575.98px) {
  .gavias-parallax {
    background-attachment: scroll !important;
  }
}

.gavias-parallax.fp-section {
  background-attachment: scroll !important;
}

.socials {
  display: block;
  text-align: left;
  width: 100%;
}

.socials a {
  color: #666e82;
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  margin-right: 10px;
  font-size: 14px;
}

.socials a:last-child {
  margin-right: 0;
}

.socials a:hover, .socials a:focus, .socials a:active {
  opacity: 0.7;
  filter: alpha(opacity=70);
  color: var(--clr-white);
}

.socials a i {
  color: var(--clr-white) !important;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  background: #ff8257;
}

.socials a i[class*="facebook"] {
  background: #3b5998;
}

.socials a i[class*="twitter"] {
  background: #4099ff;
}

.socials a i[class*="skype"] {
  background: #12a5f4;
}

.socials a i[class*="dribbble"] {
  background: #ea4c89;
}

.socials a i[class*="linkedin"] {
  background: #0e76a8;
}

.socials a i[class*="apple"] {
  background: #f2f2f2;
}

.socials a i[class*="pinterest"] {
  background: #c92228;
}

.socials a i[class*="google"] {
  background: #d34836;
}

.socials a i[class*="youtube"] {
  background: #c4302b;
}

.socials a i[class*="vimeo"] {
  background: #4ebbff;
}

.socials a i[class*="tumblr"] {
  background: #35465c;
}

.socials a i[class*="xing"] {
  background: #126567;
}

.socials a i[class*="instagram"] {
  background: #e8e2d9;
}

.socials a i[class*="flickr"] {
  background: #FF0084;
}

.socials a i[class*="behance"] {
  background: #0057FF;
}

.socials-2, .socials-3 {
  margin-top: 30px;
  margin-left: -20px;
}

.socials-2 a, .socials-3 a {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: var(--clr-white);
  color: #2e3d62 !important;
  font-size: 16px;
  text-align: center;
  line-height: 42px;
  display: inline-block;
}

.socials-2 a + a, .socials-3 a + a {
  margin-left: 23px;
}

.socials-2 a:hover, .socials-3 a:hover {
  background: #ff8257;
  color: var(--clr-white) !important;
}

.socials-3 a {
  background: #F3F5FA;
}

.socials-topbar {
  float: right;
}

.socials-topbar a {
  font-size: 16px;
  text-align: center;
  line-height: 42px;
  display: inline-block;
  margin-right: 15px;
  line-height: 1;
}

.socials-topbar a:last-child {
  margin-right: 0;
}

.socials-topbar a:hover {
  color: var(--clr-white);
}

.post-masonry-style > div, .isotope-items > div {
  margin-left: -1px;
  -webkit-transform: translateX(1px);
  transform: translateX(1px);
}

.nav-tabs > li > a {
  font-weight: 700;
  color: #2e3d62;
  padding: 8px 18px;
  border: none;
  font-size: 13px;
  text-transform: uppercase;
  background: none;
}

.nav-tabs > li > a i {
  margin-right: 5px;
}

.nav-tabs > li > a:hover, .nav-tabs > li > a:focus, .nav-tabs > li > a:active {
  color: #ff8257;
}

.nav-tabs > li.active > a, .nav-tabs > li > a.active {
  color: #ff8257;
}

.box-quick-link ul {
  padding: 0;
  margin: 0;
}

.box-quick-link ul > li {
  width: 46%;
  height: 70px;
  border: 1px solid #eaeaea;
  float: left;
  list-style: none;
  text-align: center;
  margin-bottom: 8%;
}

.box-quick-link ul > li:nth-child(2n) {
  margin-left: 4%;
}

.box-quick-link ul > li:nth-child(2n+1) {
  margin-right: 4%;
}

.box-quick-link ul > li a {
  font-weight: 700;
  font-size: 13px;
  display: block;
  line-height: 1.8;
  padding-top: 12px;
}

.box-quick-link ul > li a i {
  display: block;
  font-size: 23px;
  color: #777777;
}

.box-quick-link ul > li a:hover {
  color: var(--clr-white);
}

.box-quick-link ul > li a:hover i {
  color: var(--clr-white);
}

.box-quick-link ul > li:hover {
  background-color: #555555;
}

.box-quick-link ul > li:hover * {
  color: var(--clr-white);
}

.init-carousel-owl {
  margin-left: -15px;
  margin-right: -15px;
  width: auto;
}

.init-carousel-owl .owl-item > .item {
  padding-left: 15px;
  padding-right: 15px;
}

.init-carousel-owl.carousel-no-padding, .init-carousel-owl.no-padding {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.init-carousel-owl.carousel-no-padding .owl-item > .item, .init-carousel-owl.no-padding .owl-item > .item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.init-carousel-owl.small-padding {
  margin-left: -3px !important;
  margin-right: -3px !important;
}

.init-carousel-owl.small-padding .owl-item > .item {
  padding: 6px 3px !important;
}

.init-carousel-owl[data-items*="1"] {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.init-carousel-owl[data-items*="1"] .owl-item > .item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

div.error {
  background-repeat: no-repeat !important;
  background-image: none !important;
}

.owl-carousel {
  background: none !important;
  position: relative;
  max-height: 200px;
}

.owl-carousel .item {
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
  -moz-transition: opacity 0.35s;
  -ms-transition: opacity 0.35s;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
}

.owl-carousel .owl-stage-outer .item {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
}

.owl-carousel.owl-loaded {
  max-height: none;
}

.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

.owl-carousel .owl-controls {
  margin: 0;
}

.owl-carousel .owl-controls .owl-pagination {
  position: relative;
  bottom: 10px;
  z-index: 9;
  display: inline-block;
  width: 100%;
  left: 0;
}

.owl-carousel .owl-controls .owl-pagination .owl-page span {
  background: #d6d6d6;
  width: 10px;
  height: 10px;
}

.owl-carousel .owl-controls .owl-pagination .owl-page.active span {
  background: #869791;
}

.owl-carousel .owl-dots {
  margin-top: 20px;
  text-align: center;
  width: 100%;
  display: block;
  clear: bottom;
}

.owl-carousel .owl-dots .owl-dot {
  width: 16px;
  height: 6px;
  background: #ADB2B5;
  -webkit-transition: background 0.35s;
  transition: background 0.35s;
  -moz-transition: background 0.35s;
  -ms-transition: background 0.35s;
  display: inline-block;
  margin: 0 5px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border: none;
}

.owl-carousel .owl-dots .owl-dot.active {
  background: #ff8257;
}

.owl-carousel .owl-nav {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
}

.owl-carousel .owl-nav > div {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  color: #666e82 !important;
  opacity: 1;
  filter: alpha(opacity=100);
  background: none;
  line-height: 42px;
  text-align: center;
  font-size: 16px;
  padding-top: 0;
  padding-bottom: 0;
  background: #F6F6F6;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.owl-carousel .owl-nav > div:hover {
  background: #ff8257;
  border-color: #ff8257;
}

.owl-carousel .owl-nav > div:hover i {
  color: var(--clr-white);
}

.owl-carousel .owl-nav > div.owl-prev {
  right: auto !important;
  left: 0 !important;
}

.owl-carousel .owl-nav > div.owl-next {
  left: auto !important;
  right: 0 !important;
}

.owl-carousel:hover .owl-nav {
  opacity: 1;
  filter: alpha(opacity=100);
  background: var(--clr-white);
}

.owl-carousel:hover .owl-nav .owl-next {
  margin-left: 30px;
}

.owl-carousel:hover .owl-nav .owl-prev {
  margin-right: 30px;
}

.owl-carousel.carousel-thumbnail.text.top {
  padding-top: 60px;
}

.owl-carousel.carousel-thumbnail.text.top .owl-dots {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  z-index: 99;
  margin-top: 0;
}

.owl-carousel.carousel-thumbnail.text.top .owl-dots .owl-dot {
  width: auto;
  height: auto;
  background: none;
  text-transform: uppercase;
  font-size: 13px;
  margin: 0 10px;
  color: #2e3d62;
  font-weight: 700;
}

.owl-carousel.carousel-thumbnail.text.top .owl-dots .owl-dot.active {
  color: #ff8257;
}

.owl-carousel.carousel-thumbnail.text.vertical {
  padding-left: 25%;
}

.owl-carousel.carousel-thumbnail.text.vertical .owl-nav {
  display: none;
}

.owl-carousel.carousel-thumbnail.text.vertical .owl-dots {
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  z-index: 99;
  margin-top: 0;
  padding-right: 30px;
}

@media (max-width: 767.98px) {
  .owl-carousel.carousel-thumbnail.text.vertical .owl-dots {
    width: 100%;
  }
}

.owl-carousel.carousel-thumbnail.text.vertical .owl-dots .owl-dot {
  width: 100%;
  display: block;
  text-align: left;
  background: none;
  text-transform: uppercase;
  font-size: 13px;
  color: #2e3d62;
  font-weight: 700;
  background: var(--clr-white);
  padding: 25px 20px;
  border-bottom: 1px solid #eaeaea;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  height: auto;
  line-height: 1;
  position: relative;
}

.owl-carousel.carousel-thumbnail.text.vertical .owl-dots .owl-dot:after {
  content: "";
  position: absolute;
  right: 0;
  top: 15px;
  bottom: 15px;
  width: 3px;
  background: #ff8257;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.owl-carousel.carousel-thumbnail.text.vertical .owl-dots .owl-dot:last-child {
  border-bottom: 0;
}

@media (max-width: 767.98px) {
  .owl-carousel.carousel-thumbnail.text.vertical .owl-dots .owl-dot {
    display: inline-block;
    width: auto;
    text-align: center;
  }
}

.owl-carousel.carousel-thumbnail.text.vertical .owl-dots .owl-dot.active {
  color: #ff8257;
}

.owl-carousel.carousel-thumbnail.text.vertical .owl-dots .owl-dot.active:after {
  opacity: 1;
  filter: alpha(opacity=100);
}

.owl-carousel.pagination-right .owl-dots {
  position: absolute;
  right: -30px;
  top: 50%;
  margin-top: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 10px;
  height: auto;
}

.owl-carousel.pagination-right .owl-dots .owl-dot {
  display: block;
  margin: 10px 0;
}

.owl-carousel.pagination-left .owl-dots {
  float: left;
  width: auto;
  margin-top: 15px;
  text-align: left;
}

.owl-carousel.white .owl-dots .owl-dot {
  background: #AAABAC;
}

.owl-carousel.white .owl-dots .owl-dot.active {
  background: var(--clr-white);
}

.button, .btn, .btn-white, .btn-theme, .btn-black, .btn-theme-second, .more-link a, .btn-theme-submit {
  background: #85216C;
  color: var(--clr-white);
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 50px;
  position: relative;
  display: inline-block;
  z-index: 1;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-box-shadow: 0px 10px 30px 0 rgba(255, 130, 87, 0.3);
  box-shadow: none;
  border: none;
}

.button:hover, .button:focus, .button:active, .button.active, .btn:hover, .btn:focus, .btn:active, .btn.active, .btn-white:hover, .btn-white:focus, .btn-white:active, .btn-white.active, .btn-theme:hover, .btn-theme:focus, .btn-theme:active, .btn-theme.active, .btn-black:hover, .btn-black:focus, .btn-black:active, .btn-black.active, .btn-theme-second:hover, .btn-theme-second:focus, .btn-theme-second:active, .btn-theme-second.active, .more-link a:hover, .more-link a:focus, .more-link a:active, .more-link a.active, .btn-theme-submit:hover, .btn-theme-submit:focus, .btn-theme-submit:active, .btn-theme-submit.active {
  background: var(--clr-black);
  opacity: 0.3;
  color: var(--clr-white);
}

.more-link {
  text-align: center;
  margin-top: 30px;
}

.btn-theme-second {
  background: #9364d4;
}

.btn-theme-second:hover, .btn-theme-second:focus, .btn-theme-second:active {
  background: #773cc9;
}

.btn-white {
  background: #F3F5FA;
  border: 1px solid #F3F5FA;
  color: #2e3d62;
}

.btn-white:hover, .btn-white:focus, .btn-white:active {
  border-color: #ff8257;
  color: var(--clr-white);
  background: #ff8257;
}

.btn-black {
  background: #2e3d62;
  color: var(--clr-white);
}

.btn-black:hover, .btn-black:focus, .btn-black:active, .btn-black.active {
  background: #465d96;
}

.btn-inline {
  text-transform: capitalize;
  color: #ff8257;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  font-weight: 500;
}

.btn-inline:hover {
  text-decoration: underline;
  color: #ff490b;
}

.btn-outline {
  border: solid 1px #eaeaea;
  background: transparent;
  padding: 10px 40px;
  font-size: 16px;
  display: inline-block;
  font-weight: 600;
  color: #666e82;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.btn-outline:hover, .btn-outline:focus, .btn-outline:active, .btn-outline.active {
  background: #ff8257;
  border-color: #ff8257;
  color: var(--clr-white);
}

/*************** Easy PieChart ***************/
.easyPieChart {
  font-size: 24px;
  font-weight: 400;
  position: relative;
  text-align: center;
  display: inline-block;
}

.easyPieChart:last-child {
  margin-right: 0;
}

.easyPieChart span {
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.easyPieChart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

/*************** Progress Bars ***************/
.progress-label {
  font-size: 16px;
  color: #2e3d62;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 8px;
  text-align: left;
}

.progress {
  height: 3px !important;
  background-color: #F3F5FA !important;
  position: relative;
  overflow: visible !important;
}

.progress .progress-bar {
  -webkit-transition: width 2s ease-in-out;
  transition: width 2s ease-in-out;
  -moz-transition: width 2s ease-in-out;
  -ms-transition: width 2s ease-in-out;
  text-align: right;
  line-height: 16px !important;
  background: #9364d4;
  position: relative;
}

.progress .percentage {
  font-size: 14px !important;
  text-align: center;
  position: absolute;
  right: 1px;
  top: -30px;
  z-index: 9;
  color: #666e82;
  font-weight: 500;
}

.panel {
  border: none !important;
  margin-top: 0 !important;
  -webkit-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
  background: var(--clr-white);
  padding: 15px 0;
}

.panel:first-child {
  padding-top: 0;
}

.panel .panel-heading {
  background: none !important;
  padding: 0 !important;
  margin-bottom: 0;
}

.panel .panel-heading .panel-title {
  font-size: 16px;
  padding-right: 20px;
  margin-bottom: 0;
  font-family: var(--ff-primary) !important;
}

.panel .panel-heading .panel-title > a {
  display: block;
  letter-spacing: 0;
  position: relative;
  background: var(--clr-white);
  line-height: 30px;
  font-weight: 600;
  color: var(--clr-black);
  font-size: 18px;
  text-decoration: none;
  padding-right: 23px;
}

.panel .panel-heading .panel-title > a:after {
  content: "\f068";
  font-family: "FontAwesome";
  position: absolute;
  font-size: 10px;
  top: 0;
  right: -20px;
  z-index: 1;
  font-weight: 700;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  background: var(--clr-yellow);
  color: var(--clr-white);
}

.gsc-accordion .panel-group.skin-white-border .panel .panel-heading .panel-title > a {
  display: block;
  letter-spacing: 0;
  position: relative;
  background: var(--clr-white);
  line-height: 34px;
  font-weight: 700;
  color: var(--clr-black);
  font-size: 24px;
  text-decoration: none;
  padding-right: 23px;
  font-family: 'Rufina';
}

.gsc-accordion .panel-group.skin-white-border .panel:first-child {
  padding-top: 4px !important;
}

.panel .panel-heading .panel-title > a.collapsed {
  color: var(--clr-black);
}

.panel .panel-heading .panel-title > a.collapsed:after {
  content: "\f067";
  background: var(--clr-light-gray);
  color: var(--clr-dark-gray);
}

.panel .panel-body {
  border: none !important;
  line-height: 28px;
  padding: 10px 30px 0;
  font-size: 14px;
}

.gsc-accordion .panel-group.skin-white-border .panel .panel-body ul, .gsc-accordion .panel-group.skin-white-border .panel .panel-body ol {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 0;
}

.gsc-accordion .panel-group.skin-white-border .panel .panel-body ul > li, .gsc-accordion .panel-group.skin-white-border .panel .panel-body ol > li {
  font-size: 18px;
  padding-bottom: 8px;
  line-height: 28px;
}

.dropcap {
  display: block;
  float: left;
  font-size: 40px;
  line-height: 34px;
  color: #444;
  margin: 6px 8px 0 0;
}

.dropcap.border-dropcap {
  border: 2px solid #444;
  width: 46px;
  height: 46px;
  font-size: 28px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  margin: 8px 12px 0 0;
}

.dropcap.bg-dropcap {
  background-color: #444;
  color: var(--clr-white);
  width: 48px;
  height: 48px;
  font-size: 28px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  margin: 8px 12px 0 0;
}

.dropcap.circle-dropcap {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 2px solid #444;
  width: 46px;
  height: 46px;
  font-size: 28px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  margin: 8px 12px 0 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.info-contact > div {
  float: left;
  margin-right: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 991.98px) {
  .info-contact > div {
    margin-right: 50px;
  }
}

@media (max-width: 767.98px) {
  .info-contact > div {
    margin-right: 30px;
  }
  .cet-accordion-img {
    text-align: center !important;
    margin-bottom: 25px !important;
  }
  .panel:first-child {
    padding-top: 20px;
}
  .panel {
    padding: 15px 0;
  }
  .gsc-accordion .panel-group.skin-white-border .panel .panel-heading .panel-title > a {
    line-height: 30px;
    font-size: 20px;
    padding-right: 0;
  }
  .gsc-accordion .panel-group.skin-white-border .panel .panel-body ul, .gsc-accordion .panel-group.skin-white-border .panel .panel-body ol {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .gsc-accordion .panel-group.skin-white-border .panel .panel-body ul > li, .gsc-accordion .panel-group.skin-white-border .panel .panel-body ol > li {
    font-size: 16px;
    padding-bottom: 6px;
    line-height: 26px;
  }
}

.info-contact > div:last-child {
  margin-right: 0;
}

.info-contact > div .icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  float: left;
  margin-right: 10px;
  line-height: 40px;
  color: var(--clr-white);
  background: #ff8257;
  text-align: center;
  margin-top: -7px;
}

.info-contact > div.phone .icon {
  background: #9364d4;
}

.info-contact > div.address .icon {
  background: #9364d4;
}

.menu-style li {
  position: relative;
  font-size: 18px;
  line-height: 30px;
}

.menu-style li + li {
  padding-top: 10px;
}

.menu-style li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 14px;
  margin-right: 10px;
  color: #ff8257;
}

.scroll-to-top {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border: 2px solid #ff8257;
  color: #ff8257 !important;
  background: transparent;
  position: fixed;
  bottom: 80px;
  right: 40px;
  z-index: 99;
  text-align: center;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  display: none;
  border-radius: 50%;
}

.scroll-to-top:hover, .scroll-to-top:focus {
  background: #ff8257;
  color: var(--clr-white) !important;
}

.links .comment-add {
  display: none;
}

#node-single-comment {
  margin-top: 30px;
}

#node-single-comment h2 {
  margin-top: 60px;
  margin-bottom: 40px;
  font-size: 30px;
}

#node-single-comment #comment-form .form-item {
  margin-bottom: 20px;
}

#node-single-comment #comment-form .form-item.js-form-type-textarea {
  margin: 0;
}

#node-single-comment #comment-form .form-item label {
  display: none;
}

#node-single-comment #comment-form ul > li {
  list-style-type: none;
}

#node-single-comment input[type*='text'], #node-single-comment input[type="email"], #node-single-comment textarea {
  width: 100%;
  border-radius: 30px;
  min-height: 65px;
  padding-left: 20px;
}

#node-single-comment .js-filter-wrapper {
  background: #f1f0f0;
  padding: 20px;
  border-radius: 30px;
  font-style: italic;
  margin-bottom: 30px;
  font-size: 12px;
}

.comment-wrapper h2.comment-form__title {
  margin-bottom: 1em;
}

.field-node--comment {
  font-size: 0.934em;
}

.comment {
  margin-bottom: 30px;
  vertical-align: top;
  display: table;
}

[dir="rtl"] .comment {
  direction: rtl;
}

.comment__meta {
  padding: 0 30px 0 0;
  font-size: 1.071em;
  width: 115px;
}

@media (max-width: 767.98px) {
  .comment__meta {
    width: 90px;
  }
}

[dir="rtl"] .comment__meta {
  padding: 0 0 0 30px;
}

.comment__attribution img {
  border: 1px solid #d3d7d9;
}
.comment .field--name-user-picture img {
  margin: 0;
}

.comment__author .username {
  white-space: nowrap;
  text-transform: uppercase;
  color: #2e3d62;
  font-weight: 700;
  font-size: 12px;
}

.comment__author {
  margin: 4px 0;
  line-height: 1.2;
  text-transform: uppercase;
  color: #2e3d62;
  font-weight: 700;
  font-size: 12px;
}

.comment__time {
  margin-bottom: 4px;
  color: #ff8257;
  font-size: 0.733em;
  line-height: 1.2;
}

.comment__permalink {
  font-size: 0.733em;
  line-height: 1.2;
}

.comment__content {
  position: relative;
  display: table-cell;
  padding: 20px 25px 30px;
  vertical-align: top;
  width: 100%;
  border: 1px solid #eaeaea;
  font-size: 0.929em;
  line-height: 1.6;
  word-break: break-all;
}

.comment__content h3 a {
  font-size: 20px;
  font-weight: 600;
}

.comment__content .links.inline {
  margin-top: 10px;
}

.comment__content .links.inline > li {
  float: left;
}

.comment__content .links.inline > li a {
  font-weight: 600;
  font-size: 14px;
  background: #ff8257;
  padding: 8px 30px;
  display: inline-block;
  color: var(--clr-white);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.comment__content:before {
  content: '';
  position: absolute;
  right: 100%;
  top: 20px;
  border-top: 20px solid transparent;
  border-right: 20px solid #eaeaea;
  border-bottom: 20px solid transparent;
}

[dir="rtl"] .comment__content:before {
  right: auto;
  left: 100%;
  border-right: none;
  border-left: 20px solid #eaeaea;
}

.comment__content:after {
  content: '';
  position: absolute;
  right: 100%;
  top: 20px;
  border-top: 20px solid transparent;
  border-right: 20px solid var(--clr-white);
  border-bottom: 20px solid transparent;
  margin-right: -1px;
}

[dir="rtl"] .comment__content:after {
  right: auto;
  left: 100%;
  border-right: none;
  border-left: 20px solid var(--clr-white);
  margin-right: 0;
  margin-left: -1px;
}

.comment__content h3 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 20px;
}

.comment__content nav {
  position: absolute;
  top: 0;
  right: 0;
}

.indented {
  margin-left: 40px;
}

[dir="rtl"] .indented {
  margin-right: 40px;
  margin-left: 0;
}

.comment .links {
  padding: 0;
  list-style: none;
}

.comment .links li {
  padding: 0 0.5em 0 0;
  font-size: 1.08em;
}

[dir="rtl"] .comment .links li {
  padding: 0 0 0 0.5em;
}

.comment--unpublished {
  margin-right: 5px;
  padding: 5px 2px 5px 5px;
  background: #fff4f4;
}

[dir="rtl"] .comment--unpublished {
  margin-left: 5px;
  margin-right: 0;
  padding: 5px 5px 5px 2px;
}
.unpublished .comment-text .comment-arrow {
  border-left: 1px solid #fff4f4;
  border-right: 1px solid #fff4f4;
}

.unpublished {
  padding: 20px 15px 0;
}

.comment-footer {
  display: table-row;
}

.comment--unpublished .comment__text:after,
.node--unpublished .comment__text:after {
  border-right-color: #fff4f4;
}

[dir="rtl"] .comment--unpublished .comment__content:after,
[dir="rtl"] .node--unpublished .comment__content:after {
  border-left-color: #fff4f4;
}

.mailchimp-signup-subscribe-form {
  max-width: 760px;
  margin: 0 auto;
  position: relative;
}

.mailchimp-signup-subscribe-form.block {
  margin-bottom: 0;
}

.mailchimp-signup-subscribe-form.block .block-title {
  font-size: 50px;
  line-height: 60px;
  font-weight: bold;
  margin-bottom: 60px;
}

.mailchimp-signup-subscribe-form.block .block-title span {
  -webkit-box-shadow: 0 -8px 0 0 rgba(255, 130, 87, 0.1) inset;
  box-shadow: 0 -8px 0 0 rgba(255, 130, 87, 0.1) inset;
}

@media (max-width: 991.98px) {
  .mailchimp-signup-subscribe-form.block .block-title {
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 40px;
  }
}

.mailchimp-signup-subscribe-form form {
  position: relative;
}

.mailchimp-signup-subscribe-form .mailchimp-newsletter-mergefields label {
  display: none;
}

.mailchimp-signup-subscribe-form .mailchimp-newsletter-mergefields input {
  width: 100%;
}

.mailchimp-signup-subscribe-form input[type*='email'] {
  border: 1px solid #eaeaea;
  height: 85px;
  padding-left: 30px;
  padding-right: 250px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

@media (max-width: 575.98px) {
  .mailchimp-signup-subscribe-form input[type*='email'] {
    padding-right: 15px;
    padding-left: 15px;
    height: 70px;
  }
}

.mailchimp-signup-subscribe-form .form-actions {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  width: 210px;
}

@media (max-width: 575.98px) {
  .mailchimp-signup-subscribe-form .form-actions {
    width: 100%;
    position: initial;
    margin-top: 10px;
  }
}

.mailchimp-signup-subscribe-form .form-actions input[type*='submit'] {
  height: 65px;
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 45px;
}

form summary {
  background: #ff8257;
  color: var(--clr-white);
  padding: 4px 15px;
  font-weight: 500;
  margin-bottom: 15px;
}

form summary a {
  color: var(--clr-white) !important;
}

form summary a:hover, form summary a:focus {
  color: var(--clr-white) !important;
}

form .form-item label {
  width: 100%;
  font-weight: 600;
  text-transform: capitalize;
  color: #2e3d62;
  margin-bottom: 0;
  margin-top: 6px;
}

.form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.user-login-form .form-item, .user-register-form .form-item, .user-form .form-item, .user-pass .form-item {
  margin-bottom: 15px;
}

#user-login-form .form-item input[type="text"],
#user-login-form .form-item input[type="tel"],
#user-login-form .form-item input[type="password"],
#user-login-form .form-item input[type="email"], .user-register-form .form-item input[type="text"],
.user-register-form .form-item input[type="tel"],
.user-register-form .form-item input[type="password"],
.user-register-form .form-item input[type="email"], .user-pass .form-item input[type="text"],
.user-pass .form-item input[type="tel"],
.user-pass .form-item input[type="password"],
.user-pass .form-item input[type="email"] {
  width: 100%;
}

#user-login-form ul, .user-register-form ul, .user-pass ul {
  width: 100%;
  padding: 10px 0 30px;
}

#user-login-form ul > li, .user-register-form ul > li, .user-pass ul > li {
  width: 49%;
  float: left;
  text-align: center;
}

#user-login-form ul > li:first-child, .user-register-form ul > li:first-child, .user-pass ul > li:first-child {
  margin-right: 2%;
}

#user-login-form ul > li a, .user-register-form ul > li a, .user-pass ul > li a {
  width: 100%;
  display: inline-block;
  float: none;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 5px;
}

#user-login ul {
  margin: 0 0 5px;
}

#user-login ul li {
  margin: 0;
}

#user-login li.openid-link {
  background: none;
}

#search-form .panel {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#search-form .panel-default > .panel-heading {
  border-bottom: none;
}

.hide-label label {
  background: none;
}

.exposed-filters .form-item {
  margin-bottom: 10px;
  margin-top: 0;
}

#article-admin-filter ul {
  padding: .5em 0;
}

#article-admin-filter ul li {
  list-style: none;
}

#edit-operation-wrapper select {
  margin: .5em;
}

div.resizable-textarea textarea {
  margin-top: 0;
  border: 1px solid #eaeaea;
}

.search-advanced .criterion {
  float: none;
  margin: 0 5px;
}

#edit-keywords-fieldset .form-item:first-child {
  margin-top: 5px;
}

#user-admin-filter ul li {
  list-style: none;
}

.field-type-text-long fieldset {
  padding: 10px 0;
  border: #eaeaea;
}

.field-type-text-long fieldset .form-item {
  margin: 0;
  padding: 0 0 0 15px;
}

#comment-form label {
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 5px;
}

#contact-site-form .form-checkbox {
  margin-left: 0;
}

#comment-form-wrapper .form-item label {
  min-width: 120px;
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
}

.contact-message-form {
  margin: 0 auto;
}

.contact-message-form div[id*="edit-name"], .contact-message-form div[id*="edit-mail"], .contact-message-form .js-form-item-mail {
  height: 45px;
  line-height: 45px;
  padding: 5px;
  border-bottom: 2px solid #CCCCCC;
}

.contact-message-form .form-item {
  margin-bottom: 30px;
  color: #666e82;
}

.contact-message-form .form-item label {
  font-size: 12px;
  display: block;
  text-transform: uppercase;
}

.contact-message-form .form-item #edit-copy {
  float: left;
  margin-right: 5px;
}

.contact-message-form .form-item input[type='text'], .contact-message-form .form-item input[type='email'], .contact-message-form .form-item textarea {
  width: 100%;
  border: none;
  border-bottom: 2px solid #CCCCCC;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  height: 45px;
  -webkit-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
  padding-left: 5px;
}

.contact-message-form .form-item input[type='text']::-webkit-input-placeholder, .contact-message-form .form-item input[type='email']::-webkit-input-placeholder, .contact-message-form .form-item textarea::-webkit-input-placeholder {
  color: #666e82;
  text-transform: capitalize;
}

.contact-message-form .form-item input[type='text']:-moz-placeholder, .contact-message-form .form-item input[type='email']:-moz-placeholder, .contact-message-form .form-item textarea:-moz-placeholder {
  color: #666e82;
  text-transform: capitalize;
  opacity: 1;
  filter: alpha(opacity=100);
}

.contact-message-form .form-item input[type='text']::-moz-placeholder, .contact-message-form .form-item input[type='email']::-moz-placeholder, .contact-message-form .form-item textarea::-moz-placeholder {
  color: #666e82;
  text-transform: capitalize;
  opacity: 1;
  filter: alpha(opacity=100);
}

.contact-message-form .form-item input[type='text']:-ms-input-placeholder, .contact-message-form .form-item input[type='email']:-ms-input-placeholder, .contact-message-form .form-item textarea:-ms-input-placeholder {
  color: #666e82;
  text-transform: capitalize;
}

.contact-message-form .form-item textarea {
  height: 200px;
  padding-top: 15px;
}

@media (max-width: 767.98px) {
  .contact-message-form .form-item textarea {
    height: 120px;
  }
}

.contact-message-form .form-item-copy {
  margin-bottom: 10px !important;
}

.contact-message-form #edit-actions {
  width: 100%;
  text-align: center;
}

.contact-message-form #edit-actions:before, .contact-message-form #edit-actions:after {
  content: " ";
  display: table;
}

.contact-message-form #edit-actions:after {
  clear: both;
}

.contact-message-form #edit-preview {
  display: none;
}

.text-light .contact-message-form #edit-name, .text-light .contact-message-form #edit-mail, .text-light .contact-message-form input[type='text'], .text-light .contact-message-form input[type='email'], .text-light .contact-message-form textarea {
  background: none;
  border: 2px solid rgba(255, 255, 255, 0.2);
  color: var(--clr-white);
}

.text-light .contact-message-form #edit-name::-webkit-input-placeholder, .text-light .contact-message-form #edit-mail::-webkit-input-placeholder, .text-light .contact-message-form input[type='text']::-webkit-input-placeholder, .text-light .contact-message-form input[type='email']::-webkit-input-placeholder, .text-light .contact-message-form textarea::-webkit-input-placeholder {
  color: var(--clr-white);
  text-transform: capitalize;
}

.text-light .contact-message-form #edit-name:-moz-placeholder, .text-light .contact-message-form #edit-mail:-moz-placeholder, .text-light .contact-message-form input[type='text']:-moz-placeholder, .text-light .contact-message-form input[type='email']:-moz-placeholder, .text-light .contact-message-form textarea:-moz-placeholder {
  color: var(--clr-white);
  text-transform: capitalize;
  opacity: 1;
  filter: alpha(opacity=100);
}

.text-light .contact-message-form #edit-name::-moz-placeholder, .text-light .contact-message-form #edit-mail::-moz-placeholder, .text-light .contact-message-form input[type='text']::-moz-placeholder, .text-light .contact-message-form input[type='email']::-moz-placeholder, .text-light .contact-message-form textarea::-moz-placeholder {
  color: var(--clr-white);
  text-transform: capitalize;
  opacity: 1;
  filter: alpha(opacity=100);
}

.text-light .contact-message-form #edit-name:-ms-input-placeholder, .text-light .contact-message-form #edit-mail:-ms-input-placeholder, .text-light .contact-message-form input[type='text']:-ms-input-placeholder, .text-light .contact-message-form input[type='email']:-ms-input-placeholder, .text-light .contact-message-form textarea:-ms-input-placeholder {
  color: var(--clr-white);
  text-transform: capitalize;
}

.webform-submission-form .form-content-inner {
  max-width: 900px;
  margin: 0 -15px;
  padding: 0;
}

.webform-submission-form .form-item {
  margin-bottom: 20px;
  position: relative;
}

.webform-submission-form .form-item:before, .webform-submission-form .form-item:after {
  content: " ";
  display: table;
}

.webform-submission-form .form-item:after {
  clear: both;
}

.webform-submission-form .form-item label {
  font-size: 14px;
  font-weight: 600;
  color: #2e3d62;
  text-transform: capitalize;
  margin-bottom: 6px;
}

.webform-submission-form .form-item input, .webform-submission-form .form-item textarea, .webform-submission-form .form-item select {
  width: 100%;
  background: none;
  border: 1px solid #eaeaea;
  font-size: 16px;
  padding-left: 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  background: transparent;
  font-weight: 600;
}

.webform-submission-form .form-item input::-webkit-input-placeholder, .webform-submission-form .form-item textarea::-webkit-input-placeholder, .webform-submission-form .form-item select::-webkit-input-placeholder {
  color: #666e82;
  text-transform: capitalize;
  font-weight: 400;
}

.webform-submission-form .form-item input:-moz-placeholder, .webform-submission-form .form-item textarea:-moz-placeholder, .webform-submission-form .form-item select:-moz-placeholder {
  color: #666e82;
  font-weight: 400;
  text-transform: capitalize;
  opacity: 1;
  filter: alpha(opacity=100);
}

.webform-submission-form .form-item input::-moz-placeholder, .webform-submission-form .form-item textarea::-moz-placeholder, .webform-submission-form .form-item select::-moz-placeholder {
  color: #666e82;
  font-weight: 400;
  text-transform: capitalize;
  opacity: 1;
  filter: alpha(opacity=100);
}

.webform-submission-form .form-item input:-ms-input-placeholder, .webform-submission-form .form-item textarea:-ms-input-placeholder, .webform-submission-form .form-item select:-ms-input-placeholder {
  color: #666e82;
  font-weight: 400;
  text-transform: capitalize;
}

.webform-submission-form .form-item input:focus, .webform-submission-form .form-item textarea:focus, .webform-submission-form .form-item select:focus {
  border-color: #ff8257;
}

.webform-submission-form .form-item input, .webform-submission-form .form-item select {
  line-height: 50px;
}

.webform-submission-form .form-item input {
  padding: 5px 15px;
}

.webform-submission-form .form-item select {
  height: 60px;
}

.webform-submission-form .form-item textarea {
  resize: none;
}

.webform-submission-form .form-actions {
  padding: 0 15px;
  text-align: center;
  width: 100%;
  float: left;
  text-align: left;
}

.text-light .webform-submission-form .form-item input, .text-light .webform-submission-form .form-item textarea, .text-light .webform-submission-form .form-item select {
  background: rgba(0, 0, 0, 0.1);
  border: none;
  color: rgba(255, 255, 255, 0.8);
}

.text-light .webform-submission-form .form-item input::-webkit-input-placeholder, .text-light .webform-submission-form .form-item textarea::-webkit-input-placeholder, .text-light .webform-submission-form .form-item select::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
  text-transform: capitalize;
}

.text-light .webform-submission-form .form-item input:-moz-placeholder, .text-light .webform-submission-form .form-item textarea:-moz-placeholder, .text-light .webform-submission-form .form-item select:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
  text-transform: capitalize;
  opacity: 1;
  filter: alpha(opacity=100);
}

.text-light .webform-submission-form .form-item input::-moz-placeholder, .text-light .webform-submission-form .form-item textarea::-moz-placeholder, .text-light .webform-submission-form .form-item select::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
  text-transform: capitalize;
  opacity: 1;
  filter: alpha(opacity=100);
}

.text-light .webform-submission-form .form-item input:-ms-input-placeholder, .text-light .webform-submission-form .form-item textarea:-ms-input-placeholder, .text-light .webform-submission-form .form-item select:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
  text-transform: capitalize;
}

.text-light .webform-submission-form select option {
  color: #2e3d62;
  background: var(--clr-white);
}

.text-light .webform-submission-form .form-actions input.form-submit {
  border-color: var(--clr-white);
  color: var(--clr-white);
  background: var(--clr-white);
  color: #2e3d62;
}

.text-light .webform-submission-form .form-actions input.form-submit:hover {
  background: rgba(255, 255, 255, 0.8);
}

.text-light .webform-submission-form label {
  color: var(--clr-white);
}

.webform-fullwidth .form-item {
  width: 100% !important;
  max-width: 100% !important;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.webform-fullwidth .form-actions {
  padding: 0;
}

.webform-fullwidth.no-label label {
  display: none;
}

#forum {
  width: 100%;
  position: relative;
  right: -1px;
}

#forum .name {
  font-weight: bold;
  font-size: 16px;
}

#forum table {
  width: 100%;
}

#forum tr.odd {
  background: #F3F5FA;
}

#forum tr.even td {
  border-bottom: 1px solid #eaeaea;
}

#forum tr td.container {
  padding: 1em;
}

#forum thead th {
  background: #F3F5FA;
  border-left: 1px solid #eaeaea;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px;
}

#forum td {
  border: 1px solid #eaeaea;
  padding: 1em;
}

#forum td a:hover {
  text-decoration: none;
}

#forum td.topics {
  font-weight: bold;
  text-align: center;
  width: 9%;
}

#forum td.posts {
  text-align: center;
  width: 9%;
}

#forum td.replies {
  text-align: center;
}

#forum td.forum {
  padding: 0 1em;
  width: 44%;
  padding-top: 12px;
}

#forum td.forum .name {
  background-position: left 5px;
  background-repeat: no-repeat;
  line-height: 20px;
  padding-left: 30px;
  font-size: 15px;
  font-weight: normal;
  line-height: 25px;
}

#forum td.forum .description {
  font-size: 14px;
  padding-left: 30px;
}

#forum td.title .submitted {
  font-size: 14px;
}

#forum td.icon {
  width: 5%;
}

#forum .icon .topic-status-new {
  background-position: -24px 0;
}

#forum .icon .topic-status-hot {
  background-position: -48px 0;
}

#forum .icon .topic-status-hot-new {
  background-position: -72px 0;
}

#forum .icon .topic-status-sticky {
  background-position: -96px 0;
}

#forum .icon .topic-status-closed {
  background-position: -120px 0;
}

.page-forum #page-title {
  margin-bottom: 10px;
}

.page-forum ul.action-links {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
}

.page-forum ul.action-links li {
  margin-left: 0;
}

.page-forum ul.action-links li a {
  line-height: 0;
  display: inline-block;
  border: none;
  padding: 10px;
  text-transform: uppercase;
  vertical-align: middle;
}

.block {
  background: transparent;
  margin-bottom: 30px;
}

.block .block {
  margin-bottom: 0 !important;
}

.block .block-title {
  background: none;
  margin: 0px;
  text-transform: unset;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  position: relative;
  color: #2e3d62;
  z-index: 9;
}

@media (max-width: 991.98px) {
  .block .block-title {
    font-size: 16px;
  }
}

.block.block-system-main-block {
  margin-bottom: 0;
}

.block.block-system-breadcrumb-block {
  overflow: visible;
  margin-bottom: 20px;
}

.gavias-pagebuilder .block.block-system-breadcrumb-block {
  margin-bottom: 0;
}

.block.block-none, .block.no-padding {
  background: none !important;
  padding: 0 !important;
}

.block.block-none .block-content, .block.no-padding .block-content {
  padding: 0 !important;
}

.block.no-title {
  margin-top: 0;
  border: none;
}

.block.no-title:before {
  content: none;
}

.block.style-higlight {
  background: #34495E;
  padding-left: 20px;
  padding-right: 20px;
}

.block.style-higlight .block-title {
  border: none;
}

.block.style-higlight .block-title span:before {
  content: none;
}

.block.style-higlight .block-title h2 {
  margin: 0;
  padding: 0;
  border: none;
}

.block.style-higlight .block-title h2 span {
  padding: 10px;
  background: #4E6984;
  color: var(--clr-white);
}

.block.style-higlight .post-title a {
  color: var(--clr-white);
}

.block#block-system-main {
  padding-top: 10px;
}

.block.block-local-tasks-block {
  margin-bottom: 0;
}

.block.no-title {
  border: none;
}

.block.no-title > .block-title, .block.no-title > div > .block-title {
  padding: 0;
  margin: 0;
  border: none;
}

.block.no-title > .block-title:before, .block.no-title > div > .block-title:before {
  content: none;
}

.block-v2 {
  background: #ff8257 !important;
}

.block-v3 {
  padding: 0 !important;
  border: none !important;
}

.block.site-branding {
  margin-bottom: 0;
}

.block-local-tasks-block {
  position: relative;
  z-index: 99;
}

.drupal-tabs {
  border-bottom: 1px solid #eaeaea;
}

.drupal-tabs:before, .drupal-tabs:after {
  content: " ";
  display: table;
}

.drupal-tabs:after {
  clear: both;
}

.drupal-tabs a {
  font-size: 12px;
}

.drupal-tabs .is-active a {
  color: #ff8257;
}

.help {
  position: relative;
  z-index: 1;
  display: none;
  width: 100%;
}

.help .drupal-tabs {
  padding: 10px 0 10px;
  margin-bottom: 20px;
}

.help .messages__wrapper {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #F3F5FA;
  margin-bottom: 20px;
}

.help .messages__wrapper .messages {
  margin: 5px 10px !important;
  font-weight: 500;
  color: #2e3d62;
}

.text-light .title {
  color: var(--clr-white) !important;
}

.region-main-menu .block {
  background: transparent;
}

.region-main-menu .block .block-content {
  padding: 0;
}

.sidebar .block {
  padding: 40px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  -webkit-box-shadow: 0px 10px 60px 0 rgba(46, 61, 98, 0.1);
  box-shadow: 0px 10px 60px 0 rgba(46, 61, 98, 0.1);
  background-color: var(--clr-white);
  text-align: left;
}

.sidebar .block:before, .sidebar .block:after {
  content: " ";
  display: table;
}

.sidebar .block:after {
  clear: both;
}

.sidebar .block img {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.sidebar .block-v2 {
  background: #ff8257;
  border: none;
}

.sidebar .block-v2:before, .sidebar .block-v2:after {
  content: " ";
  display: table;
}

.sidebar .block-v2:after {
  clear: both;
}

.sidebar .block-v2 .block-title {
  color: var(--clr-white);
}

.sidebar .block-v2 .block-content {
  color: var(--clr-white);
}

.sidebar .block-v2 .block-content a {
  color: var(--clr-white);
}

.sidebar .block-v2 .block-content a:after {
  color: var(--clr-white);
}

.sidebar .block-menu:before, .sidebar .block-menu:after, .sidebar .category-list:before, .sidebar .category-list:after {
  content: " ";
  display: table;
}

.sidebar .block-menu:after, .sidebar .category-list:after {
  clear: both;
}

.sidebar .block-menu ul, .sidebar .category-list ul {
  padding-left: 0 !important;
}

.sidebar .block-menu ul li, .sidebar .category-list ul li {
  margin: 0 0 5px !important;
  padding: 0 !important;
  border: none !important;
  float: left;
  width: 100%;
  list-style-type: none;
}

.sidebar .block-menu ul li a, .sidebar .category-list ul li a {
  padding: 5px 0;
  display: block;
  font-size: 16px;
  overflow: hidden;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  position: relative;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
  border: none;
  color: #666e82;
}

.sidebar .block-menu ul li a:after, .sidebar .category-list ul li a:after {
  content: '\f105';
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-family: "FontAwesome";
  border-radius: 50%;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: 0;
  left: auto;
  z-index: 1;
  font-weight: 900;
  background: var(--clr-white);
  font-size: 12px;
  opacity: 1;
}

.sidebar .block-menu ul li a:hover, .sidebar .block-menu ul li a:focus, .sidebar .block-menu ul li a:active, .sidebar .category-list ul li a:hover, .sidebar .category-list ul li a:focus, .sidebar .category-list ul li a:active {
  color: #2e3d62;
}

.sidebar .block-menu ul li a:hover:after, .sidebar .block-menu ul li a:focus:after, .sidebar .block-menu ul li a:active:after, .sidebar .category-list ul li a:hover:after, .sidebar .category-list ul li a:focus:after, .sidebar .category-list ul li a:active:after {
  background: #ff8257;
  color: var(--clr-white);
}

.sidebar .block-menu ul li.menu-item--active-trail > a, .sidebar .category-list ul li.menu-item--active-trail > a {
  color: #2e3d62 !important;
}

.sidebar .block-menu ul li.menu-item--active-trail > a:after, .sidebar .category-list ul li.menu-item--active-trail > a:after {
  background: #ff8257;
  color: var(--clr-white);
}

.recent-comment-block .views-field-picture {
  margin-right: 10px;
}

.recent-comment-block .views-field-title {
  margin-top: -4px;
}

.recent-comment-block .views-field-title a {
  font-weight: 700;
  text-transform: uppercase;
}

.recent-comment-block .views-field-name {
  color: #a4a4a4;
}

.recent-comment-block .views-field-name a {
  color: #a4a4a4;
}

.list-tags .view-list ul > li {
  float: left;
  border: 1px solid #eaeaea;
  display: inline-block;
  width: auto !important;
  padding: 0 !important;
  margin: 0 10px 10px 0 !important;
}

.list-tags .view-list ul > li a {
  padding: 6px 10px;
  display: block;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  font-size: 14px;
}

.list-tags .view-list ul > li:hover {
  background: #ff8257;
}

.list-tags .view-list ul > li:hover a {
  color: var(--clr-white);
}

nav.breadcrumb {
  margin-bottom: 0 !important;
  background: none;
  color: #999999;
}

nav.breadcrumb ol {
  list-style: none;
  margin-bottom: 0 !important;
  padding: 0;
}

nav.breadcrumb ol:before, nav.breadcrumb ol:after {
  content: " ";
  display: table;
}

nav.breadcrumb ol:after {
  clear: both;
}

nav.breadcrumb ol > li {
  float: left;
}

nav.breadcrumb ol > li a:hover {
  color: #ff8257;
}

nav.breadcrumb ol > li span {
  padding: 0 2px 0px 0px;
}

.contact-info ul, .contact-info-2 ul, .contact-info-3 ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.contact-info ul li, .contact-info-2 ul li, .contact-info-3 ul li {
  margin-bottom: 10px;
}

.contact-info ul li .icon, .contact-info-2 ul li .icon, .contact-info-3 ul li .icon {
  color: var(--clr-white);
  width: 22px;
  float: left;
  display: block;
  margin-top: 1px;
}

.contact-info ul li .icon i, .contact-info-2 ul li .icon i, .contact-info-3 ul li .icon i {
  font-size: 14px;
}

.contact-info ul li .text, .contact-info-2 ul li .text, .contact-info-3 ul li .text {
  padding-left: 28px;
  display: block;
}

ul.contact-info-2 {
  padding: 0;
  list-style-type: none;
}

ul.contact-info-2 .label {
  font-size: 20px;
  color: #666e82;
}

ul.contact-info-2 .text {
  font-size: 26px;
  font-weight: 500;
  color: #ff8257;
  line-height: 30px;
}

ul.contact-info-2 .icon {
  color: #2e3d62;
}

.contact-info-3 .icon {
  color: #2e3d62 !important;
  margin-right: 3px;
}

.social-icon a {
  background: none;
  width: auto;
  height: auto;
  line-height: 1;
  margin-right: 20px;
  font-size: 16px;
}

.box-search-team {
  padding: 30px;
  background: #ff8257;
  text-align: center;
  color: var(--clr-white);
  min-height: 330px;
}

@media (max-width: 991.98px) {
  .box-search-team {
    min-height: 260px;
  }
}

@media (max-width: 767.98px) {
  .box-search-team {
    width: 350px;
    margin: 0 auto;
  }
}

.box-search-team .icon {
  font-size: 46px;
  line-height: 1.2;
}

.box-search-team .text {
  font-size: 18px;
  font-weight: 700;
  color: var(--clr-white);
  margin-top: 15px;
  line-height: 32px;
}

.box-search-team .link {
  margin-top: 15px;
}

.box-search-team .link a {
  color: var(--clr-white);
}

.box-search-team .link a:after {
  color: var(--clr-white);
}

.box-search-team .link a:hover {
  color: #9364d4;
}

.box-search-team .link a:hover:after {
  color: #9364d4;
}

ul.list-1 {
  padding: 30px;
  background: #F3F1EE;
  margin: 0;
  list-style-type: none;
}

@media (max-width: 991.98px) {
  ul.list-1 {
    margin-bottom: 30px;
  }
}

ul.list-1 li {
  background: var(--clr-white);
  line-height: 60px;
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 500;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  position: relative;
}

ul.list-1 li:after {
  content: '\f054';
  font-family: "FontAwesome";
  width: 30px;
  height: 30px;
  background: #F3F5FA;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -15px;
  z-index: 11;
  font-weight: 900;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

ul.list-1 li a {
  padding: 0 50px 0 30px;
  display: block;
  color: #666e82;
}

ul.list-1 li:hover {
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  color: #2e3d62;
}

ul.list-1 li:hover:after {
  background: #9364d4;
  color: var(--clr-white);
}

ul.list-1 li:hover a {
  color: #2e3d62;
}

ul.list-2 {
  padding: 0;
}

ul.list-2 > li {
  list-style: none;
  background: var(--clr-white);
  padding: 25px 20px;
  font-size: 16px;
  color: #2e3d62;
  font-weight: 500;
}

ul.list-2 > li .number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ff8257;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: var(--clr-white);
  display: inline-block;
  margin-right: 12px;
}

ul.list-2 > li:nth-child(odd) {
  background: #F3F5FA;
}

ul.list-3 {
  padding: 0;
}

ul.list-3 > li {
  list-style: none;
  background: var(--clr-white);
  padding: 20px 30px 20px 55px;
  color: #2e3d62;
  position: relative;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
  border-radius: 6px;
}

ul.list-3 > li a {
  color: #ff8257;
}

ul.list-3 > li a:hover {
  color: #2e3d62;
}

ul.list-3 > li:after {
  content: '\f058';
  font-family: "FontAwesome";
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: -8px;
  font-weight: 900;
  line-height: 1;
}

ul.list-3 > li:nth-child(even) {
  background: rgba(255, 130, 87, 0.09);
}

ul.list-3 > li:nth-child(odd) {
  background: rgba(255, 130, 87, 0.3);
}

.poll .poll-item {
  margin-top: 20px;
}

.poll .poll-item .heading {
  position: relative;
}

.poll .poll-item .heading .text {
  font-weight: 700;
  color: #333333;
  padding-right: 40px;
}

.poll .poll-item .heading .percent {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  font-weight: 700;
}

.poll .poll-item .bar .foreground {
  background: #ff8257;
}

.poll .total {
  margin-top: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.poll .choices .form-item.form-item-choice input.form-radio {
  margin: 0 !important;
}

.poll .choices .form-item.form-item-choice label {
  vertical-align: text-top;
  margin-left: 5px;
}

.block-poll ul.links {
  text-align: center;
  overflow: hidden;
  padding: 15px 0 0;
}

.block-poll ul.links li {
  display: inline-block;
  margin-right: 20px;
}

.block-poll ul.links li a {
  padding-right: 10px;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.block-poll ul.links li a:after {
  content: "\f105";
  position: absolute;
  top: -1px;
  right: 0;
  font-family: "FontAwesome";
}

.block-poll ul.links li a:hover {
  padding-right: 15px;
}

.block-poll ul.links li:last-child {
  margin: 0;
}

/* perfect-scrollbar v0.6.7 */
.ps-container {
  -ms-touch-action: none;
  overflow: hidden !important;
}

.ps-container.ps-active-x > .ps-scrollbar-x-rail,
.ps-container.ps-active-y > .ps-scrollbar-y-rail {
  display: block;
}

.ps-container.ps-in-scrolling {
  pointer-events: none;
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
}

.ps-container > .ps-scrollbar-x-rail {
  display: none;
  position: absolute;
  border-radius: 4px;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  bottom: 3px;
  height: 8px;
}

.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  position: absolute;
  background-color: #aaa;
  border-radius: 4px;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
  bottom: 0;
  height: 8px;
}

.ps-container > .ps-scrollbar-y-rail {
  display: none;
  position: absolute;
  border-radius: 4px;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  right: 3px;
  width: 4px;
}

.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  position: absolute;
  background-color: #aaa;
  border-radius: 4px;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
  right: 0;
  width: 5px;
}

.ps-container:hover.ps-in-scrolling {
  pointer-events: none;
}

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
}

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
}

.ps-container:hover > .ps-scrollbar-x-rail,
.ps-container:hover > .ps-scrollbar-y-rail {
  opacity: 0.6;
  filter: alpha(opacity=60);
}

.ps-container:hover > .ps-scrollbar-x-rail:hover {
  background-color: #eee;
  opacity: 0.6;
  filter: alpha(opacity=60);
}

.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
  background-color: #999;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover {
  background-color: #eee;
  opacity: 0.6;
  filter: alpha(opacity=60);
}

.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
  background-color: #999;
}

.uk-slideset-init {
  position: relative;
}

.uk-slideset-init ul {
  list-style-type: none;
  padding: 0;
}

.uk-slideset-init .uk-slideset-nav {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 1;
  margin: 0;
  padding: 0;
}

@media (max-width: 575.98px) {
  .uk-slideset-init .uk-slideset-nav {
    width: 100%;
  }
}

.uk-slideset-init .uk-slideset-nav li {
  float: left;
  margin-right: 10px;
  list-style-type: none;
}

@media (max-width: 575.98px) {
  .uk-slideset-init .uk-slideset-nav li {
    float: none;
    display: inline-block;
  }
}

.uk-slideset-init .uk-slideset-nav li a {
  width: 22px;
  height: 10px;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  display: inline-block;
  border: 2px solid #ff8257;
}

.uk-slideset-init .uk-slideset-nav li a:hover {
  cursor: pointer;
}

.uk-slideset-init .uk-slideset-nav li.uk-active a {
  background: #ff8257;
}

.uk-slideset-init.nav-slideset-top .uk-slideset-nav {
  top: -30px;
}

.uk-slideset-init.nav-slideset-bottom .uk-slideset-nav {
  bottom: -30px;
  top: auto;
  left: 0;
  text-align: center;
}

.uk-slideset-init.nav-slideset-bottom .uk-slideset-nav li {
  display: inline-block;
  float: none;
}

.text-light .uk-slideset-init .uk-slideset-nav li a {
  border-color: var(--clr-white);
}

.text-light .uk-slideset-init .uk-slideset-nav li.uk-active a {
  background: var(--clr-white);
}

/* #Block grid
--------------------------------------------------------------- */
.item-columns {
  padding: 0 15px;
}

[class*="block-grid-"] {
  display: block;
  margin: 0 -10px;
  padding: 0;
}

[class*="block-grid-"]:before, [class*="block-grid-"]:after {
  display: table;
  content: " ";
}

[class*="block-grid-"]:after {
  clear: both;
}

[class*="block-grid-"] > div {
  display: block;
  float: left;
  height: auto;
  padding: 0 10px;
  width: 100%;
}

[class*="block-grid-"] > div:after {
  display: block;
}

@media (min-width: 640px) {
  .xs-block-grid-1 > div {
    width: 100%;
  }
  .xs-block-grid-1 > div:nth-of-type(1n) {
    clear: none;
  }
  .xs-block-grid-1 > div:nth-of-type(1n):after {
    display: block;
  }
  .xs-block-grid-1 > div:nth-of-type(1n+1) {
    clear: both;
  }
  .xs-block-grid-1 > div:nth-of-type(1n+1):after {
    display: none;
  }
  .xs-block-grid-1 > div:nth-last-child(-n+1):before {
    content: none;
  }
  .xs-block-grid-2 > div {
    width: 50%;
  }
  .xs-block-grid-2 > div:nth-of-type(1n) {
    clear: none;
  }
  .xs-block-grid-2 > div:nth-of-type(1n):after {
    display: block;
  }
  .xs-block-grid-2 > div:nth-of-type(2n+1) {
    clear: both;
  }
  .xs-block-grid-2 > div:nth-of-type(2n+1):after {
    display: none;
  }
  .xs-block-grid-2 > div:nth-last-child(-n+2):before {
    content: none;
  }
  .xs-block-grid-3 > div {
    width: 33.33333%;
  }
  .xs-block-grid-3 > div:nth-of-type(1n) {
    clear: none;
  }
  .xs-block-grid-3 > div:nth-of-type(1n):after {
    display: block;
  }
  .xs-block-grid-3 > div:nth-of-type(3n+1) {
    clear: both;
  }
  .xs-block-grid-3 > div:nth-of-type(3n+1):after {
    display: none;
  }
  .xs-block-grid-3 > div:nth-last-child(-n+3):before {
    content: none;
  }
  .xs-block-grid-4 > div {
    width: 25%;
  }
  .xs-block-grid-4 > div:nth-of-type(1n) {
    clear: none;
  }
  .xs-block-grid-4 > div:nth-of-type(1n):after {
    display: block;
  }
  .xs-block-grid-4 > div:nth-of-type(4n+1) {
    clear: both;
  }
  .xs-block-grid-4 > div:nth-of-type(4n+1):after {
    display: none;
  }
  .xs-block-grid-4 > div:nth-last-child(-n+4):before {
    content: none;
  }
  .xs-block-grid-5 > div {
    width: 20%;
  }
  .xs-block-grid-5 > div:nth-of-type(1n) {
    clear: none;
  }
  .xs-block-grid-5 > div:nth-of-type(1n):after {
    display: block;
  }
  .xs-block-grid-5 > div:nth-of-type(5n+1) {
    clear: both;
  }
  .xs-block-grid-5 > div:nth-of-type(5n+1):after {
    display: none;
  }
  .xs-block-grid-5 > div:nth-last-child(-n+5):before {
    content: none;
  }
  .xs-block-grid-6 > div {
    width: 16.66667%;
  }
  .xs-block-grid-6 > div:nth-of-type(1n) {
    clear: none;
  }
  .xs-block-grid-6 > div:nth-of-type(1n):after {
    display: block;
  }
  .xs-block-grid-6 > div:nth-of-type(6n+1) {
    clear: both;
  }
  .xs-block-grid-6 > div:nth-of-type(6n+1):after {
    display: none;
  }
  .xs-block-grid-6 > div:nth-last-child(-n+6):before {
    content: none;
  }
  .xs-block-grid-7 > div {
    width: 14.28571%;
  }
  .xs-block-grid-7 > div:nth-of-type(1n) {
    clear: none;
  }
  .xs-block-grid-7 > div:nth-of-type(1n):after {
    display: block;
  }
  .xs-block-grid-7 > div:nth-of-type(7n+1) {
    clear: both;
  }
  .xs-block-grid-7 > div:nth-of-type(7n+1):after {
    display: none;
  }
  .xs-block-grid-7 > div:nth-last-child(-n+7):before {
    content: none;
  }
  .xs-block-grid-8 > div {
    width: 12.5%;
  }
  .xs-block-grid-8 > div:nth-of-type(1n) {
    clear: none;
  }
  .xs-block-grid-8 > div:nth-of-type(1n):after {
    display: block;
  }
  .xs-block-grid-8 > div:nth-of-type(8n+1) {
    clear: both;
  }
  .xs-block-grid-8 > div:nth-of-type(8n+1):after {
    display: none;
  }
  .xs-block-grid-8 > div:nth-last-child(-n+8):before {
    content: none;
  }
  .xs-block-grid-9 > div {
    width: 11.11111%;
  }
  .xs-block-grid-9 > div:nth-of-type(1n) {
    clear: none;
  }
  .xs-block-grid-9 > div:nth-of-type(1n):after {
    display: block;
  }
  .xs-block-grid-9 > div:nth-of-type(9n+1) {
    clear: both;
  }
  .xs-block-grid-9 > div:nth-of-type(9n+1):after {
    display: none;
  }
  .xs-block-grid-9 > div:nth-last-child(-n+9):before {
    content: none;
  }
  .xs-block-grid-10 > div {
    width: 10%;
  }
  .xs-block-grid-10 > div:nth-of-type(1n) {
    clear: none;
  }
  .xs-block-grid-10 > div:nth-of-type(1n):after {
    display: block;
  }
  .xs-block-grid-10 > div:nth-of-type(10n+1) {
    clear: both;
  }
  .xs-block-grid-10 > div:nth-of-type(10n+1):after {
    display: none;
  }
  .xs-block-grid-10 > div:nth-last-child(-n+10):before {
    content: none;
  }
  .xs-block-grid-11 > div {
    width: 9.09091%;
  }
  .xs-block-grid-11 > div:nth-of-type(1n) {
    clear: none;
  }
  .xs-block-grid-11 > div:nth-of-type(1n):after {
    display: block;
  }
  .xs-block-grid-11 > div:nth-of-type(11n+1) {
    clear: both;
  }
  .xs-block-grid-11 > div:nth-of-type(11n+1):after {
    display: none;
  }
  .xs-block-grid-11 > div:nth-last-child(-n+11):before {
    content: none;
  }
  .xs-block-grid-12 > div {
    width: 8.33333%;
  }
  .xs-block-grid-12 > div:nth-of-type(1n) {
    clear: none;
  }
  .xs-block-grid-12 > div:nth-of-type(1n):after {
    display: block;
  }
  .xs-block-grid-12 > div:nth-of-type(12n+1) {
    clear: both;
  }
  .xs-block-grid-12 > div:nth-of-type(12n+1):after {
    display: none;
  }
  .xs-block-grid-12 > div:nth-last-child(-n+12):before {
    content: none;
  }
}

@media (min-width: 768px) {
  .sm-block-grid-1 > div {
    width: 100%;
  }
  .sm-block-grid-1 > div:nth-of-type(1n) {
    clear: none;
  }
  .sm-block-grid-1 > div:nth-of-type(1n):after {
    display: block;
  }
  .sm-block-grid-1 > div:nth-of-type(1n+1) {
    clear: both;
  }
  .sm-block-grid-1 > div:nth-of-type(1n+1):after {
    display: none;
  }
  .sm-block-grid-1 > div:nth-last-child(-n+1):before {
    content: none;
  }
  .sm-block-grid-2 > div {
    width: 50%;
  }
  .sm-block-grid-2 > div:nth-of-type(1n) {
    clear: none;
  }
  .sm-block-grid-2 > div:nth-of-type(1n):after {
    display: block;
  }
  .sm-block-grid-2 > div:nth-of-type(2n+1) {
    clear: both;
  }
  .sm-block-grid-2 > div:nth-of-type(2n+1):after {
    display: none;
  }
  .sm-block-grid-2 > div:nth-last-child(-n+2):before {
    content: none;
  }
  .sm-block-grid-3 > div {
    width: 33.33333%;
  }
  .sm-block-grid-3 > div:nth-of-type(1n) {
    clear: none;
  }
  .sm-block-grid-3 > div:nth-of-type(1n):after {
    display: block;
  }
  .sm-block-grid-3 > div:nth-of-type(3n+1) {
    clear: both;
  }
  .sm-block-grid-3 > div:nth-of-type(3n+1):after {
    display: none;
  }
  .sm-block-grid-3 > div:nth-last-child(-n+3):before {
    content: none;
  }
  .sm-block-grid-4 > div {
    width: 25%;
  }
  .sm-block-grid-4 > div:nth-of-type(1n) {
    clear: none;
  }
  .sm-block-grid-4 > div:nth-of-type(1n):after {
    display: block;
  }
  .sm-block-grid-4 > div:nth-of-type(4n+1) {
    clear: both;
  }
  .sm-block-grid-4 > div:nth-of-type(4n+1):after {
    display: none;
  }
  .sm-block-grid-4 > div:nth-last-child(-n+4):before {
    content: none;
  }
  .sm-block-grid-5 > div {
    width: 20%;
  }
  .sm-block-grid-5 > div:nth-of-type(1n) {
    clear: none;
  }
  .sm-block-grid-5 > div:nth-of-type(1n):after {
    display: block;
  }
  .sm-block-grid-5 > div:nth-of-type(5n+1) {
    clear: both;
  }
  .sm-block-grid-5 > div:nth-of-type(5n+1):after {
    display: none;
  }
  .sm-block-grid-5 > div:nth-last-child(-n+5):before {
    content: none;
  }
  .sm-block-grid-6 > div {
    width: 16.66667%;
  }
  .sm-block-grid-6 > div:nth-of-type(1n) {
    clear: none;
  }
  .sm-block-grid-6 > div:nth-of-type(1n):after {
    display: block;
  }
  .sm-block-grid-6 > div:nth-of-type(6n+1) {
    clear: both;
  }
  .sm-block-grid-6 > div:nth-of-type(6n+1):after {
    display: none;
  }
  .sm-block-grid-6 > div:nth-last-child(-n+6):before {
    content: none;
  }
  .sm-block-grid-7 > div {
    width: 14.28571%;
  }
  .sm-block-grid-7 > div:nth-of-type(1n) {
    clear: none;
  }
  .sm-block-grid-7 > div:nth-of-type(1n):after {
    display: block;
  }
  .sm-block-grid-7 > div:nth-of-type(7n+1) {
    clear: both;
  }
  .sm-block-grid-7 > div:nth-of-type(7n+1):after {
    display: none;
  }
  .sm-block-grid-7 > div:nth-last-child(-n+7):before {
    content: none;
  }
  .sm-block-grid-8 > div {
    width: 12.5%;
  }
  .sm-block-grid-8 > div:nth-of-type(1n) {
    clear: none;
  }
  .sm-block-grid-8 > div:nth-of-type(1n):after {
    display: block;
  }
  .sm-block-grid-8 > div:nth-of-type(8n+1) {
    clear: both;
  }
  .sm-block-grid-8 > div:nth-of-type(8n+1):after {
    display: none;
  }
  .sm-block-grid-8 > div:nth-last-child(-n+8):before {
    content: none;
  }
  .sm-block-grid-9 > div {
    width: 11.11111%;
  }
  .sm-block-grid-9 > div:nth-of-type(1n) {
    clear: none;
  }
  .sm-block-grid-9 > div:nth-of-type(1n):after {
    display: block;
  }
  .sm-block-grid-9 > div:nth-of-type(9n+1) {
    clear: both;
  }
  .sm-block-grid-9 > div:nth-of-type(9n+1):after {
    display: none;
  }
  .sm-block-grid-9 > div:nth-last-child(-n+9):before {
    content: none;
  }
  .sm-block-grid-10 > div {
    width: 10%;
  }
  .sm-block-grid-10 > div:nth-of-type(1n) {
    clear: none;
  }
  .sm-block-grid-10 > div:nth-of-type(1n):after {
    display: block;
  }
  .sm-block-grid-10 > div:nth-of-type(10n+1) {
    clear: both;
  }
  .sm-block-grid-10 > div:nth-of-type(10n+1):after {
    display: none;
  }
  .sm-block-grid-10 > div:nth-last-child(-n+10):before {
    content: none;
  }
  .sm-block-grid-11 > div {
    width: 9.09091%;
  }
  .sm-block-grid-11 > div:nth-of-type(1n) {
    clear: none;
  }
  .sm-block-grid-11 > div:nth-of-type(1n):after {
    display: block;
  }
  .sm-block-grid-11 > div:nth-of-type(11n+1) {
    clear: both;
  }
  .sm-block-grid-11 > div:nth-of-type(11n+1):after {
    display: none;
  }
  .sm-block-grid-11 > div:nth-last-child(-n+11):before {
    content: none;
  }
  .sm-block-grid-12 > div {
    width: 8.33333%;
  }
  .sm-block-grid-12 > div:nth-of-type(1n) {
    clear: none;
  }
  .sm-block-grid-12 > div:nth-of-type(1n):after {
    display: block;
  }
  .sm-block-grid-12 > div:nth-of-type(12n+1) {
    clear: both;
  }
  .sm-block-grid-12 > div:nth-of-type(12n+1):after {
    display: none;
  }
  .sm-block-grid-12 > div:nth-last-child(-n+12):before {
    content: none;
  }
}

/* Medium */
@media (min-width: 992px) {
  [class*="block-grid-"] {
    margin: 0 -10px;
  }
  [class*="block-grid-"] > div {
    padding: 0 10px;
  }
  .item-columns {
    padding: 0 10px;
  }
  .md-block-grid-1 > div {
    width: 100%;
  }
  .md-block-grid-1 > div:nth-of-type(1n) {
    clear: none;
  }
  .md-block-grid-1 > div:nth-of-type(1n):after {
    display: block;
  }
  .md-block-grid-1 > div:nth-of-type(1n+1) {
    clear: both;
  }
  .md-block-grid-1 > div:nth-of-type(1n+1):after {
    display: none;
  }
  .md-block-grid-1 > div:nth-last-child(-n+1):before {
    content: none;
  }
  .md-block-grid-2 > div {
    width: 50%;
  }
  .md-block-grid-2 > div:nth-of-type(1n) {
    clear: none;
  }
  .md-block-grid-2 > div:nth-of-type(1n):after {
    display: block;
  }
  .md-block-grid-2 > div:nth-of-type(2n+1) {
    clear: both;
  }
  .md-block-grid-2 > div:nth-of-type(2n+1):after {
    display: none;
  }
  .md-block-grid-2 > div:nth-last-child(-n+2):before {
    content: none;
  }
  .md-block-grid-3 > div {
    width: 33.33333%;
  }
  .md-block-grid-3 > div:nth-of-type(1n) {
    clear: none;
  }
  .md-block-grid-3 > div:nth-of-type(1n):after {
    display: block;
  }
  .md-block-grid-3 > div:nth-of-type(3n+1) {
    clear: both;
  }
  .md-block-grid-3 > div:nth-of-type(3n+1):after {
    display: none;
  }
  .md-block-grid-3 > div:nth-last-child(-n+3):before {
    content: none;
  }
  .md-block-grid-4 > div {
    width: 25%;
  }
  .md-block-grid-4 > div:nth-of-type(1n) {
    clear: none;
  }
  .md-block-grid-4 > div:nth-of-type(1n):after {
    display: block;
  }
  .md-block-grid-4 > div:nth-of-type(4n+1) {
    clear: both;
  }
  .md-block-grid-4 > div:nth-of-type(4n+1):after {
    display: none;
  }
  .md-block-grid-4 > div:nth-last-child(-n+4):before {
    content: none;
  }
  .md-block-grid-5 > div {
    width: 20%;
  }
  .md-block-grid-5 > div:nth-of-type(1n) {
    clear: none;
  }
  .md-block-grid-5 > div:nth-of-type(1n):after {
    display: block;
  }
  .md-block-grid-5 > div:nth-of-type(5n+1) {
    clear: both;
  }
  .md-block-grid-5 > div:nth-of-type(5n+1):after {
    display: none;
  }
  .md-block-grid-5 > div:nth-last-child(-n+5):before {
    content: none;
  }
  .md-block-grid-6 > div {
    width: 16.66667%;
  }
  .md-block-grid-6 > div:nth-of-type(1n) {
    clear: none;
  }
  .md-block-grid-6 > div:nth-of-type(1n):after {
    display: block;
  }
  .md-block-grid-6 > div:nth-of-type(6n+1) {
    clear: both;
  }
  .md-block-grid-6 > div:nth-of-type(6n+1):after {
    display: none;
  }
  .md-block-grid-6 > div:nth-last-child(-n+6):before {
    content: none;
  }
  .md-block-grid-7 > div {
    width: 14.28571%;
  }
  .md-block-grid-7 > div:nth-of-type(1n) {
    clear: none;
  }
  .md-block-grid-7 > div:nth-of-type(1n):after {
    display: block;
  }
  .md-block-grid-7 > div:nth-of-type(7n+1) {
    clear: both;
  }
  .md-block-grid-7 > div:nth-of-type(7n+1):after {
    display: none;
  }
  .md-block-grid-7 > div:nth-last-child(-n+7):before {
    content: none;
  }
  .md-block-grid-8 > div {
    width: 12.5%;
  }
  .md-block-grid-8 > div:nth-of-type(1n) {
    clear: none;
  }
  .md-block-grid-8 > div:nth-of-type(1n):after {
    display: block;
  }
  .md-block-grid-8 > div:nth-of-type(8n+1) {
    clear: both;
  }
  .md-block-grid-8 > div:nth-of-type(8n+1):after {
    display: none;
  }
  .md-block-grid-8 > div:nth-last-child(-n+8):before {
    content: none;
  }
  .md-block-grid-9 > div {
    width: 11.11111%;
  }
  .md-block-grid-9 > div:nth-of-type(1n) {
    clear: none;
  }
  .md-block-grid-9 > div:nth-of-type(1n):after {
    display: block;
  }
  .md-block-grid-9 > div:nth-of-type(9n+1) {
    clear: both;
  }
  .md-block-grid-9 > div:nth-of-type(9n+1):after {
    display: none;
  }
  .md-block-grid-9 > div:nth-last-child(-n+9):before {
    content: none;
  }
  .md-block-grid-10 > div {
    width: 10%;
  }
  .md-block-grid-10 > div:nth-of-type(1n) {
    clear: none;
  }
  .md-block-grid-10 > div:nth-of-type(1n):after {
    display: block;
  }
  .md-block-grid-10 > div:nth-of-type(10n+1) {
    clear: both;
  }
  .md-block-grid-10 > div:nth-of-type(10n+1):after {
    display: none;
  }
  .md-block-grid-10 > div:nth-last-child(-n+10):before {
    content: none;
  }
  .md-block-grid-11 > div {
    width: 9.09091%;
  }
  .md-block-grid-11 > div:nth-of-type(1n) {
    clear: none;
  }
  .md-block-grid-11 > div:nth-of-type(1n):after {
    display: block;
  }
  .md-block-grid-11 > div:nth-of-type(11n+1) {
    clear: both;
  }
  .md-block-grid-11 > div:nth-of-type(11n+1):after {
    display: none;
  }
  .md-block-grid-11 > div:nth-last-child(-n+11):before {
    content: none;
  }
  .md-block-grid-12 > div {
    width: 8.33333%;
  }
  .md-block-grid-12 > div:nth-of-type(1n) {
    clear: none;
  }
  .md-block-grid-12 > div:nth-of-type(1n):after {
    display: block;
  }
  .md-block-grid-12 > div:nth-of-type(12n+1) {
    clear: both;
  }
  .md-block-grid-12 > div:nth-of-type(12n+1):after {
    display: none;
  }
  .md-block-grid-12 > div:nth-last-child(-n+12):before {
    content: none;
  }
}

/* Large */
@media (min-width: 1200px) {
  [class*="block-grid-"] {
    margin: 0 -12px;
  }
  [class*="block-grid-"] > div {
    padding: 0 12px;
  }
  .item-columns {
    padding: 0 12px;
  }
  .lg-block-grid-1 > div {
    width: 100%;
  }
  .lg-block-grid-1 > div:nth-of-type(1n) {
    clear: none;
  }
  .lg-block-grid-1 > div:nth-of-type(1n):after {
    display: block;
  }
  .lg-block-grid-1 > div:nth-of-type(1n+1) {
    clear: both;
  }
  .lg-block-grid-1 > div:nth-of-type(1n+1):after {
    display: none;
  }
  .lg-block-grid-1 > div:nth-last-child(-n+1):before {
    content: none;
  }
  .lg-block-grid-2 > div {
    width: 50%;
  }
  .lg-block-grid-2 > div:nth-of-type(1n) {
    clear: none;
  }
  .lg-block-grid-2 > div:nth-of-type(1n):after {
    display: block;
  }
  .lg-block-grid-2 > div:nth-of-type(2n+1) {
    clear: both;
  }
  .lg-block-grid-2 > div:nth-of-type(2n+1):after {
    display: none;
  }
  .lg-block-grid-2 > div:nth-last-child(-n+2):before {
    content: none;
  }
  .lg-block-grid-3 > div {
    width: 33.33333%;
  }
  .lg-block-grid-3 > div:nth-of-type(1n) {
    clear: none;
  }
  .lg-block-grid-3 > div:nth-of-type(1n):after {
    display: block;
  }
  .lg-block-grid-3 > div:nth-of-type(3n+1) {
    clear: both;
  }
  .lg-block-grid-3 > div:nth-of-type(3n+1):after {
    display: none;
  }
  .lg-block-grid-3 > div:nth-last-child(-n+3):before {
    content: none;
  }
  .lg-block-grid-4 > div {
    width: 25%;
  }
  .lg-block-grid-4 > div:nth-of-type(1n) {
    clear: none;
  }
  .lg-block-grid-4 > div:nth-of-type(1n):after {
    display: block;
  }
  .lg-block-grid-4 > div:nth-of-type(4n+1) {
    clear: both;
  }
  .lg-block-grid-4 > div:nth-of-type(4n+1):after {
    display: none;
  }
  .lg-block-grid-4 > div:nth-last-child(-n+4):before {
    content: none;
  }
  .lg-block-grid-5 > div {
    width: 20%;
  }
  .lg-block-grid-5 > div:nth-of-type(1n) {
    clear: none;
  }
  .lg-block-grid-5 > div:nth-of-type(1n):after {
    display: block;
  }
  .lg-block-grid-5 > div:nth-of-type(5n+1) {
    clear: both;
  }
  .lg-block-grid-5 > div:nth-of-type(5n+1):after {
    display: none;
  }
  .lg-block-grid-5 > div:nth-last-child(-n+5):before {
    content: none;
  }
  .lg-block-grid-6 > div {
    width: 16.66667%;
  }
  .lg-block-grid-6 > div:nth-of-type(1n) {
    clear: none;
  }
  .lg-block-grid-6 > div:nth-of-type(1n):after {
    display: block;
  }
  .lg-block-grid-6 > div:nth-of-type(6n+1) {
    clear: both;
  }
  .lg-block-grid-6 > div:nth-of-type(6n+1):after {
    display: none;
  }
  .lg-block-grid-6 > div:nth-last-child(-n+6):before {
    content: none;
  }
  .lg-block-grid-7 > div {
    width: 14.28571%;
  }
  .lg-block-grid-7 > div:nth-of-type(1n) {
    clear: none;
  }
  .lg-block-grid-7 > div:nth-of-type(1n):after {
    display: block;
  }
  .lg-block-grid-7 > div:nth-of-type(7n+1) {
    clear: both;
  }
  .lg-block-grid-7 > div:nth-of-type(7n+1):after {
    display: none;
  }
  .lg-block-grid-7 > div:nth-last-child(-n+7):before {
    content: none;
  }
  .lg-block-grid-8 > div {
    width: 12.5%;
  }
  .lg-block-grid-8 > div:nth-of-type(1n) {
    clear: none;
  }
  .lg-block-grid-8 > div:nth-of-type(1n):after {
    display: block;
  }
  .lg-block-grid-8 > div:nth-of-type(8n+1) {
    clear: both;
  }
  .lg-block-grid-8 > div:nth-of-type(8n+1):after {
    display: none;
  }
  .lg-block-grid-8 > div:nth-last-child(-n+8):before {
    content: none;
  }
  .lg-block-grid-9 > div {
    width: 11.11111%;
  }
  .lg-block-grid-9 > div:nth-of-type(1n) {
    clear: none;
  }
  .lg-block-grid-9 > div:nth-of-type(1n):after {
    display: block;
  }
  .lg-block-grid-9 > div:nth-of-type(9n+1) {
    clear: both;
  }
  .lg-block-grid-9 > div:nth-of-type(9n+1):after {
    display: none;
  }
  .lg-block-grid-9 > div:nth-last-child(-n+9):before {
    content: none;
  }
  .lg-block-grid-10 > div {
    width: 10%;
  }
  .lg-block-grid-10 > div:nth-of-type(1n) {
    clear: none;
  }
  .lg-block-grid-10 > div:nth-of-type(1n):after {
    display: block;
  }
  .lg-block-grid-10 > div:nth-of-type(10n+1) {
    clear: both;
  }
  .lg-block-grid-10 > div:nth-of-type(10n+1):after {
    display: none;
  }
  .lg-block-grid-10 > div:nth-last-child(-n+10):before {
    content: none;
  }
  .lg-block-grid-11 > div {
    width: 9.09091%;
  }
  .lg-block-grid-11 > div:nth-of-type(1n) {
    clear: none;
  }
  .lg-block-grid-11 > div:nth-of-type(1n):after {
    display: block;
  }
  .lg-block-grid-11 > div:nth-of-type(11n+1) {
    clear: both;
  }
  .lg-block-grid-11 > div:nth-of-type(11n+1):after {
    display: none;
  }
  .lg-block-grid-11 > div:nth-last-child(-n+11):before {
    content: none;
  }
  .lg-block-grid-12 > div {
    width: 8.33333%;
  }
  .lg-block-grid-12 > div:nth-of-type(1n) {
    clear: none;
  }
  .lg-block-grid-12 > div:nth-of-type(1n):after {
    display: block;
  }
  .lg-block-grid-12 > div:nth-of-type(12n+1) {
    clear: both;
  }
  .lg-block-grid-12 > div:nth-of-type(12n+1):after {
    display: none;
  }
  .lg-block-grid-12 > div:nth-last-child(-n+12):before {
    content: none;
  }
}

.no-gutter [class*="block-grid-"] {
  margin: 0 !important;
}

.no-gutter [class*="block-grid-"] > div {
  padding: 0 !important;
}

.no-gutter .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.no-gutter [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-gutter .portfolio-v1 {
  margin-bottom: 0 !important;
}

.area-main-menu .menu-toggle {
  display: none;
}

@media (min-width: 992px) {
  .navigation {
    position: relative;
    clear: both;
    text-align: center;
  }
  .navigation ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .navigation .gva_menu {
    position: relative;
    font-size: 0;
  }
  .navigation .gva_menu li {
    position: relative;
  }
  .navigation .gva_menu li a {
    text-align: left !important;
  }
  .navigation .gva_menu li a .icaret {
    margin-left: 4px;
    border: none !important;
    font-size: 9px;
  }
  .navigation .gva_menu > li {
    display: inline-block;
    padding: 30px 15px;
  }
  .navigation .gva_menu > li.gva-mega-menu {
    position: static !important;
  }
  .navigation .gva_menu > li.gva-mega-menu > .sub-menu {
    width: 100%;
  }
}

@media (min-width: 992px) and (min-width: 576px) {
  .navigation .gva_menu > li:hover > ul.sub-menu, .navigation .gva_menu > li:hover div.sub-menu {
    display: block;
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    top: 99%;
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
  .navigation .gva_menu > li:hover > a {
    color: #ff8257;
  }
}

@media (min-width: 992px) {
  .navigation .gva_menu > li > a {
    display: block;
    position: relative;
    font-size: 18px;
    font-weight: 500;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
    -moz-transition: all 0.35s;
    -ms-transition: all 0.35s;
    text-align: center;
    color: #666e82;
  }
}

@media (min-width: 992px) and (max-width: 991.98px) {
  .navigation .gva_menu > li > a {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 992px) and (max-width: 767.98px) {
  .navigation .gva_menu > li > a {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (min-width: 992px) {
  .navigation .gva_menu > li > a.is-active {
    color: #ff8257;
  }
  .navigation .gva_menu > li > a > span {
    position: relative;
    padding-bottom: 6px;
  }
  .navigation .gva_menu > li:hover > a, .navigation .gva_menu > li:focus > a, .navigation .gva_menu > li:active > a, .navigation .gva_menu > li.menu-item--active-trail > a {
    color: #ff8257;
  }
  .navigation .gva_menu > li:hover > a:after, .navigation .gva_menu > li:focus > a:after, .navigation .gva_menu > li:active > a:after, .navigation .gva_menu > li.menu-item--active-trail > a:after {
    opacity: 1;
    filter: alpha(opacity=100);
    bottom: 0;
  }
  .navigation .gva_menu > li.megamenu.menu-columns-2 > ul.sub-menu > li {
    width: 50%;
    float: left;
  }
  .navigation .gva_menu > li.megamenu.menu-columns-3 > ul.sub-menu > li {
    width: 33.33%;
    float: left;
  }
  .navigation .gva_menu > li.megamenu.menu-columns-4 > ul.sub-menu > li {
    width: 25%;
    float: left;
  }
  .navigation .gva_menu > li.megamenu.menu-columns-5 > ul.sub-menu > li {
    width: 20%;
    float: left;
  }
  .navigation .gva_menu > li.menu-list ul.sub-menu {
    min-width: 250px;
  }
  .navigation .gva_menu > li.menu-list ul.sub-menu > li {
    border-bottom: 1px solid #eaeaea;
  }
  .navigation .gva_menu > li.menu-list ul.sub-menu > li > a {
    padding: 10px 15px;
    text-transform: uppercase;
  }
  .navigation .gva_menu .megamenu, .navigation .gva_menu .gva-mega-menu {
    position: static;
  }
  .navigation .gva_menu .megamenu > .sub-menu, .navigation .gva_menu .gva-mega-menu > .sub-menu {
    width: 100%;
    padding: 20px;
  }
  .navigation .gva_menu .megamenu > .sub-menu .sub-menu, .navigation .gva_menu .gva-mega-menu > .sub-menu .sub-menu {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
  .navigation .gva_menu .megamenu > .sub-menu > li, .navigation .gva_menu .gva-mega-menu > .sub-menu > li {
    border: none;
    position: static;
    padding: 0 15px;
  }
  .navigation .gva_menu .megamenu > .sub-menu > li > ul.sub-menu, .navigation .gva_menu .gva-mega-menu > .sub-menu > li > ul.sub-menu {
    position: static;
    border-width: 0;
    padding: 0;
    min-width: inherit;
    width: 100%;
    background-color: transparent;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

@media (min-width: 992px) and (min-width: 768px) {
  .navigation .gva_menu .megamenu > .sub-menu > li > ul.sub-menu, .navigation .gva_menu .gva-mega-menu > .sub-menu > li > ul.sub-menu {
    display: block !important;
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .navigation .gva_menu .megamenu > .sub-menu > li > ul.sub-menu li, .navigation .gva_menu .gva-mega-menu > .sub-menu > li > ul.sub-menu li {
    padding: 10px 0 0;
    margin-bottom: 0;
    border: none;
  }
  .navigation .gva_menu .megamenu > .sub-menu > li > a, .navigation .gva_menu .gva-mega-menu > .sub-menu > li > a {
    display: block;
    font-size: 14px;
    color: #666e82;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0 0 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    position: relative;
  }
  .navigation .gva_menu .megamenu > .sub-menu > li > a:after, .navigation .gva_menu .gva-mega-menu > .sub-menu > li > a:after {
    display: none;
  }
  .navigation .gva_menu .sub-menu {
    top: 100%;
    position: absolute;
    min-width: 280px;
    background-color: var(--clr-white);
    z-index: 999;
    left: 0;
    padding: 15px 20px 10px;
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -webkit-box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.2);
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    border-top: 1px solid #eaeaea;
  }
  .navigation .gva_menu .sub-menu > li {
    padding: 0 0 9px;
  }
  .navigation .gva_menu .sub-menu > li > a {
    font-size: 15px;
    display: block;
    color: var(--clr-black);
    font-weight: 400;
    text-transform: capitalize;
    position: relative;
    text-decoration: none;
  }
  .navigation .gva_menu .sub-menu > li > a:hover, .navigation .gva_menu .sub-menu > li > a:focus, .navigation .gva_menu .sub-menu > li > a:active {
    color: var(--clr-black);
  }
  .navigation .gva_menu .sub-menu > li:last-child {
    border-bottom: 0;
  }
  .navigation .gva_menu .sub-menu > li ul {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 250px;
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    display: none;
    z-index: 11;
  }
}

@media (min-width: 992px) and (min-width: 768px) {
  .navigation .gva_menu .sub-menu > li:hover > ul {
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    display: block;
    left: 100%;
  }
}

body.header-absolute .navigation ul.gva_menu > li > a {
  color: var(--clr-white) !important;
}

.gva-mega-menu .block-blocktabs {
  position: relative;
}

.gva-mega-menu .block-blocktabs .ui-widget {
  font-size: 0.875rem;
}

.gva-mega-menu .block-blocktabs .ui-tabs-nav {
  padding: 0;
  position: static;
  width: 15%;
  float: left;
}

.gva-mega-menu .block-blocktabs .ui-tabs-nav > li {
  background: none;
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  float: none;
  clear: both;
  padding-right: 20px;
}

.gva-mega-menu .block-blocktabs .ui-tabs-nav > li > a {
  font-size: 12px;
  border-bottom: 1px solid #eaeaea;
  font-weight: 600;
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.gva-mega-menu .block-blocktabs .ui-tabs-nav > li:last-child > a {
  padding-right: 0;
}

.gva-mega-menu .block-blocktabs .ui-tabs-nav > li.ui-tabs-active > a {
  color: #ff8257;
}

.gva-mega-menu .block-blocktabs .ui-tabs-panel {
  float: left;
  width: 85%;
}

.gva-view.view-header-right header {
  float: right;
}

@media (max-width: 575.98px) {
  .gva-view.view-header-right header {
    display: none;
  }
}

.item-list ul {
  padding: 0;
}

.item-list ul li.view-list-item {
  margin: 0 0 30px;
  list-style-type: none;
}

.item-list ul li.view-list-item:before, .item-list ul li.view-list-item:after {
  content: " ";
  display: table;
}

.item-list ul li.view-list-item:after {
  clear: both;
}

.item-list ul li.view-list-item .post-block {
  margin: 0;
}

.padding-small .item-list ul li.view-list-item {
  margin-bottom: 20px !important;
}

.padding-small .item-list ul li.view-list-item:last-child {
  margin-bottom: 0 !important;
}

ul[data-drupal-views-infinite-scroll-pager] {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

ul[data-drupal-views-infinite-scroll-pager].pager {
  margin: 20px 0 0;
}

.views-view-grid .views-row {
  width: auto;
  clear: none;
  float: none !important;
}

.views-view-grid .views-row:last-child {
  margin-bottom: 0;
}

.post-masonry-style > div.views-col {
  margin-bottom: 10px;
}

.no-padding .gva-portfolio-items .row {
  margin: 0 !important;
}

.no-padding .gva-portfolio-items .isotope-items > div {
  padding: 0 !important;
}

.no-padding .gva-portfolio-items .isotope-items > div .isotope-item {
  margin-bottom: 0 !important;
}

.brand-grid .views-view-grid .views-row {
  margin-bottom: 65px;
}

.brand-grid .views-view-grid .views-row .views-field-field-image {
  text-align: center;
}

@media (max-width: 575.98px) {
  .brand-grid .views-view-grid .views-row .views-field-field-image {
    margin-bottom: 20px;
  }
}

.testimonial-v1 .testimonial-item {
  text-align: center;
  background: var(--clr-white);
  padding: 60px 30px;
}

.testimonial-v1 .testimonial-item .quote {
  line-height: 28px;
  position: relative;
  padding-top: 35px;
}

.testimonial-v1 .testimonial-item .avatar {
  text-align: center;
}

.testimonial-v1 .testimonial-item .avatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.testimonial-v1 .testimonial-item .content .title {
  margin: 30px 0 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: #2e3d62;
}

.testimonial-v1 .testimonial-item .content .job {
  font-size: 12px;
  color: #bbbbbb;
}

.view-testimonial-v2 {
  position: relative;
  float: left;
  width: 100%;
}

.view-testimonial-v2 .owl-dots {
  text-align: left;
  position: relative;
  margin-top: 20px;
  float: left;
  width: 100%;
}

.view-testimonial-v2 .owl-dots .owl-dot {
  display: inline-block;
  padding: 0;
  margin: 5px;
  background: none;
  width: 45px;
  height: 45px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border: 2px solid #eaeaea;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: center;
  position: relative;
  z-index: 9;
}

.view-testimonial-v2 .owl-dots .owl-dot.active {
  border-color: #ff8257;
}

.view-testimonial-v2 .owl-nav {
  display: none;
}

.testimonial-grid .item-grid {
  margin-bottom: 45px;
}

.testimonial-grid .item-grid:before, .testimonial-grid .item-grid:after {
  content: " ";
  display: table;
}

.testimonial-grid .item-grid:after {
  clear: both;
}

.testimonial-grid .item-grid .testimonials-quote {
  font-size: 16px;
  color: #2e3d62;
  padding: 30px;
  border: 1px solid #eaeaea;
  font-weight: 300;
  font-style: italic;
}

.testimonial-grid .item-grid .testimonials-profile {
  display: inline-block;
  position: relative;
  margin-left: 30px;
  float: left;
  padding-top: 20px;
}

.testimonial-grid .item-grid .testimonials-profile:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  background: #ff8257;
  top: -1px;
  left: 0;
  z-index: 1;
}

.testimonial-grid .item-grid .testimonials-profile .right {
  padding-left: 70px;
}

.testimonial-grid .item-grid .testimonials-profile .right .name {
  font-size: 16px;
  font-weight: 700;
  color: var(--clr-black);
  margin: 0;
  text-transform: uppercase;
}

.testimonial-grid .item-grid .testimonials-profile .right .job {
  font-size: 12px;
  font-weight: 900;
  color: #ff8257;
  text-transform: uppercase;
}

.testimonial-grid .item-grid .testimonials-profile .left {
  display: inline-block;
  float: left;
}

.testimonial-grid .item-grid .testimonials-profile .left img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  width: 50px;
  height: auto;
}

.slider-gallery-v1 {
  margin-left: -3px !important;
  margin-right: -3px !important;
}

.slider-gallery-v1 .carousel-item {
  float: left;
  padding-right: 3px !important;
}

.slider-gallery-v1 .gallery-large {
  background: var(--clr-white);
}

.slider-gallery-v1 .gallery-large img {
  width: 100%;
  height: auto;
}

.slider-gallery-v1 div > .gallery-small {
  width: 50% !important;
  background: transparent !important;
  float: left;
}

.slider-gallery-v1 div > .gallery-small .gallery-small-item {
  background: var(--clr-white);
  width: 100% !important;
  height: auto !important;
  float: left;
  clear: both;
  padding-bottom: 3px;
  margin: 0;
}

.slider-gallery-v1 div > .gallery-small .gallery-small-item:last-child {
  padding-bottom: 0;
}

.slider-gallery-v1 div > .gallery-small .gallery-small-item img {
  width: 100%;
  height: auto;
}

.small-list .item-list ul > li {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 15px !important;
  padding-bottom: 15px;
}

.small-list .item-list ul > li a {
  font-size: 12px;
  text-transform: uppercase;
  color: #2e3d62;
  font-weight: 700;
}

.small-list .item-list ul > li a:hover {
  color: #ff8257;
}

.small-list .item-list ul > li:last-child {
  border: none;
  margin-bottom: 0 !important;
  padding-bottom: 0;
}

.tags-list .item-list > ul > li {
  display: inline-block;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.tags-list .item-list > ul > li a {
  display: inline-block;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  color: #666e82;
  border: 1px solid #eaeaea;
  padding: 0 15px 2px;
  font-size: 15px;
}

.tags-list .item-list > ul > li a:hover, .tags-list .item-list > ul > li a:focus, .tags-list .item-list > ul > li a:active {
  color: #ff8257;
  border-color: #ff8257;
}

.banner-carousel .init-carousel-owl {
  margin-left: 0;
  margin-right: 0;
}

.banner-carousel .init-carousel-owl .item {
  padding: 0;
  border-left: 0;
  text-align: center;
  position: relative;
}

.banner-carousel.v2 .item {
  padding: 0;
  height: auto;
  background: transparent;
}

.banner-carousel.v2 .item > div {
  padding: 15px 20px;
  text-align: center;
  position: relative;
}

.banner-carousel.v2 .item > div:last-child {
  border: none;
}

.gva-view-mlayout-1 .item-first {
  border-bottom: 0 none;
  display: block;
  float: left;
  padding-right: 15px !important;
  width: 50%;
  clear: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .gva-view-mlayout-1 .item-first {
    width: 100%;
    clear: both;
    margin-bottom: 20px;
  }
}

.gva-view-mlayout-1 .item-first .post-content {
  padding: 20px 0 0;
}

.gva-view-mlayout-1 .item-first .post-title {
  margin-bottom: 2px;
}

.gva-view-mlayout-1 .item-first .post-title a {
  font-size: 22px;
  line-height: 28px;
}

.gva-view-mlayout-1 .item-first .post-action {
  display: block !important;
}

.gva-view-mlayout-1 .items-second {
  float: left;
  width: 50%;
}

@media (max-width: 991.98px) {
  .gva-view-mlayout-1 .items-second {
    width: 100%;
  }
}

.gva-view-mlayout-1 .items-second .item-post {
  margin-bottom: 30px;
  padding-left: 15px;
}

.gva-view-mlayout-1 .items-second .item-post .post-image {
  display: inline-block;
  width: 150px;
  height: auto;
  float: left;
}

.gva-view-mlayout-1 .items-second .item-post .post-content {
  padding: 0 0 0 170px;
}

.gva-view-mlayout-1 .items-second .item-post .post-meta {
  clear: none !important;
  margin: 0 0 5px;
}

.gva-view-mlayout-1 .items-second .item-post .post-meta {
  float: left;
  width: 100%;
  padding: 0;
}

.gva-view-mlayout-1 .items-second .item-post .post-meta .post-author {
  display: none;
}

.gva-view-mlayout-1 .items-second .item-post .post-title {
  padding: 0;
  margin: 0 0 5px;
}

.gva-view-mlayout-1 .items-second .item-post .post-body {
  padding: 0;
}

.gva-view-mlayout-2 .item-first {
  border-bottom: 0 none;
  display: block;
  float: left;
  width: 100%;
  clear: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.gva-view-mlayout-2 .item-first .post-title {
  padding: 12px 0;
}

.gva-view-mlayout-2 .item-first .post-title a {
  font-size: 20px;
  line-height: 28px;
}

.gva-view-mlayout-2 .item-first .post-meta {
  margin: 0 0 10px;
}

.gva-view-mlayout-2 .item-first:after {
  content: none;
}

.gva-view-mlayout-2 .items-second {
  float: left;
  width: 100%;
  margin-top: 20px;
}

.gva-view-mlayout-2 .items-second .item-post {
  margin-bottom: 20px;
  padding-left: 15px;
}

.gva-view-mlayout-2 .items-second .item-post .post-image {
  display: inline-block;
  width: 100px;
  height: auto;
  float: left;
}

.gva-view-mlayout-2 .items-second .item-post .post-content {
  padding-left: 110px;
}

.gva-view-mlayout-2 .items-second .item-post .post-title, .gva-view-mlayout-2 .items-second .item-post .post-meta {
  clear: none !important;
}

.gva-view-mlayout-2 .items-second .item-post .post-meta {
  float: left;
  width: 100%;
  margin: 2px 0 0;
}

.gva-view-mlayout-2 .items-second .item-post .post-meta .post-author {
  display: none;
}

.gva-view-mlayout-2 .items-second .item-post .post-body {
  display: none;
}

.gva-view-mlayout-2 .items-second .item-post .post-title {
  padding: 0;
}

.gva-view-mlayout-2 .items-second .item-post .post-title a {
  display: block;
  font-size: 14px;
}

.gva-view-mlayout-3 .item-first {
  width: 100%;
  float: left;
}

.gva-view-mlayout-3 .item-first .post-block .post-image {
  width: 50%;
  float: left;
}

.gva-view-mlayout-3 .item-first .post-block .post-title a {
  font-size: 18px;
  line-height: 26px;
}

.gva-view-mlayout-3 .item-first .post-block .post-content {
  width: 50%;
  float: left;
  padding-left: 20px;
}

.gva-view-mlayout-3 .items-second {
  width: 100%;
  float: left;
  margin-top: 30px;
}

.gva-view-mlayout-3 .items-second .post-block .post-content {
  padding-top: 10px;
}

.gva-view-mlayout-3 .items-second .post-block .post-body {
  display: none;
}

.gva-view-mlayout-3 .items-second .post-block .view-node {
  display: none;
}

.gva-view-mlayout-3.v2 .post-block {
  position: relative;
}

.gva-view-mlayout-3.v2 .post-block .post-image {
  position: relative;
  width: 100%;
  float: none;
}

.gva-view-mlayout-3.v2 .post-block .post-image a:after {
  content: '';
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
}

.gva-view-mlayout-3.v2 .post-block .post-meta span {
  color: var(--clr-white);
}

.gva-view-mlayout-3.v2 .post-block .post-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px;
  width: 100%;
  z-index: 9;
}

.gva-view-mlayout-3.v2 .post-block .post-content .post-title a {
  font-size: 16px;
  color: var(--clr-white);
  font-weight: 400;
}

.gva-view-mlayout-3.v2 .post-block .post-content .post-title a:hover {
  color: #ff8257;
}

.gva-view-mlayout-3.v2 .post-block .post-content .post-body {
  color: rgba(255, 255, 255, 0.9);
}

.gva-view-mlayout-3.v2 .post-block .post-content .post-meta {
  color: #ccc;
}

.gva-view-mlayout-3.v2 .item-first .post-block .post-title a {
  font-size: 22px;
  line-height: 30px;
}

.gva-view-mlayout-4 .item-first {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
}

.gva-view-mlayout-4 .item-first .post-block .post-image {
  width: 50%;
  float: left;
}

.gva-view-mlayout-4 .item-first .post-block .post-title a {
  font-size: 22px;
  line-height: 30px;
}

.gva-view-mlayout-4 .item-first .post-block .post-content {
  width: 50%;
  float: left;
  padding-left: 20px;
}

.gva-view-mlayout-4 .item-first .post-block .view-node {
  margin-top: 15px;
}

.gva-view-mlayout-4 .items-second {
  float: left;
  width: 100%;
}

.gva-view-mlayout-4 .items-second:before, .gva-view-mlayout-4 .items-second:after {
  content: " ";
  display: table;
}

.gva-view-mlayout-4 .items-second:after {
  clear: both;
}

.gva-view-mlayout-4 .items-second .item-post .post-block {
  margin-bottom: 24px;
}

.gva-view-mlayout-4 .items-second .item-post .post-block:before, .gva-view-mlayout-4 .items-second .item-post .post-block:after {
  content: " ";
  display: table;
}

.gva-view-mlayout-4 .items-second .item-post .post-block:after {
  clear: both;
}

.gva-view-mlayout-4 .items-second .item-post .post-block .post-image {
  width: 100%;
  float: left;
}

.gva-view-mlayout-4 .items-second .item-post .post-block .post-title {
  margin: 0;
  padding-top: 0;
}

.gva-view-mlayout-4 .items-second .item-post .post-block .post-content {
  width: 100%;
  float: left;
  padding-top: 8px;
}

.gva-view-mlayout-4 .items-second .item-post .post-block .post-content .post-body {
  display: none;
}

.gva-view-mlayout-4 .items-second .item-post .post-block .post-content .view-node {
  display: none;
}

.gva-view-grid .post-block .post-content {
  padding-top: 10px;
}

.posts-stick-list .item-list > ul > li {
  display: block;
  overflow: hidden;
  width: 100%;
  clear: both;
}

.posts-stick-list .item-list > ul > li:not(:first-child) {
  margin-bottom: 20px;
}

.posts-stick-list .item-list > ul > li:not(:first-child) .post-image {
  width: 100px;
  height: auto;
  float: left;
  margin: 0;
}

.posts-stick-list .item-list > ul > li:not(:first-child) .post-content {
  padding-left: 115px;
  padding-top: 0;
}

.posts-stick-list .item-list > ul > li:not(:first-child) .post-body {
  display: none;
}

.posts-stick-list .item-list > ul > li:not(:first-child) .post-meta {
  float: left;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}

.posts-stick-list .item-list > ul > li:not(:first-child) .post-title {
  padding: 0;
  margin: 0;
}

.posts-stick-list .item-list > ul > li:not(:first-child) .post-title a {
  display: block;
  font-size: 14px;
  line-height: 20px;
}

.posts-stick-list .item-list > ul > li:first-child {
  border-bottom: 0 none;
  display: block;
  width: 100%;
  clear: none;
  padding-bottom: 0;
  margin-bottom: 20px;
}

.posts-stick-list .item-list > ul > li:first-child .post-title {
  margin: 10px 0 0;
}

.posts-stick-list .item-list > ul > li:first-child .post-title a {
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 767.98px) {
  .posts-stick-list .item-list > ul > li:first-child .post-title a {
    font-size: 16px;
  }
}

.posts-stick-list .item-list > ul > li:first-child .post-meta {
  padding-top: 0;
}

.posts-stick-list .item-list > ul > li:last-child {
  margin-bottom: 0 !important;
}

.block.block-gavias-blockbuilder {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.block.block-gavias-blockbuilder ul > li {
  list-style: none;
}

.gbb-row-wrapper {
  position: relative;
}

.gbb-row-wrapper .particles-js-canvas-el {
  position: absolute;
  top: 0;
  left: 0;
}

.gbb-row-wrapper .row-text-overlay {
  font-size: 50px;
  font-weight: 900;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.09);
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  height: 46px;
  top: 50%;
  margin-top: -23px;
  z-index: 1;
  line-height: 1;
}

@media (max-width: 767.98px) {
  .gbb-row-wrapper .row-text-overlay {
    display: none;
  }
}
.gbb-row-wrapper .bb-container {
  position: relative;
}

.gbb-row-wrapper .icon-row {
  color: #ff8257;
}

.row-box {
  padding-left: 100px;
  padding-right: 100px;
}

@media (max-width: 767.98px) {
  .row-box {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.container .container {
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bb-container.full-screen {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.bb-container.full-screen .bb-inner {
  padding: 0 !important;
}

.bb-container.full-screen > .row {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.gbb-row.polygon {
  -webkit-clip-path: polygon(100% 0, 100% 66%, 0% 100%, -200% 0%);
  clip-path: polygon(100% 0, 100% 66%, 0% 100%, -200% 0%);
}

@media (max-width: 767.98px) {
  .gbb-row.row-border > .bb-inner > .bb-container > .row-wrapper > .gsc-column, .gbb-row.row-border-white > .bb-inner > .bb-container > .row-wrapper > .gsc-column {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .gbb-row.row-border > .bb-inner > .bb-container > .row-wrapper > .gsc-column:last-child, .gbb-row.row-border-white > .bb-inner > .bb-container > .row-wrapper > .gsc-column:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.gbb-row.row-border > .bb-inner > .bb-container > .row-wrapper > .gsc-column:after, .gbb-row.row-border-white > .bb-inner > .bb-container > .row-wrapper > .gsc-column:after {
  content: '';
  width: 1px;
  height: auto;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .gbb-row.row-border > .bb-inner > .bb-container > .row-wrapper > .gsc-column:after, .gbb-row.row-border-white > .bb-inner > .bb-container > .row-wrapper > .gsc-column:after {
    display: none;
  }
}

.gbb-row.row-border > .bb-inner > .bb-container > .row-wrapper > .gsc-column .column-inner, .gbb-row.row-border-white > .bb-inner > .bb-container > .row-wrapper > .gsc-column .column-inner {
  padding-left: 15px;
  padding-right: 15px;
}

.gbb-row.row-border > .bb-inner > .bb-container > .row-wrapper > .gsc-column:last-child:after, .gbb-row.row-border-white > .bb-inner > .bb-container > .row-wrapper > .gsc-column:last-child:after {
  display: none !important;
}

.gbb-row.row-border > .bb-inner > .bb-container > .row-wrapper > .gsc-column[class*='col-lg-12']:after, .gbb-row.row-border-white > .bb-inner > .bb-container > .row-wrapper > .gsc-column[class*='col-lg-12']:after {
  display: none;
}

@media (max-width: 767.98px) {
  .gbb-row.row-border-white > .bb-inner > .bb-container > .row-wrapper > .gsc-column {
    border-bottom-color: rgba(255, 255, 255, 0.2);
  }
}

.gbb-row.row-border-white > .bb-inner > .bb-container > .row-wrapper > .gsc-column:after {
  background: rgba(255, 255, 255, 0.2);
}

.gbb-row.border-inner-row .row-wrapper {
  border-bottom: 1px solid #eaeaea;
}

.gbb-row.special-row .bb-inner {
  padding-top: 0;
}

.gbb-row.special-row .row-wrapper {
  margin-top: -220px;
  position: relative;
  z-index: 9;
  -webkit-box-shadow: 0 5px 35px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--clr-white);
}

.gbb-row.special-row .row-wrapper .column-inner {
  padding: 30px 20px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

@media (max-width: 991.98px) {
  .gbb-row.special-row .row-wrapper .column-inner {
    padding: 30px 10px;
  }
}

.gbb-row.row-background-particles-js > .bb-inner {
  position: relative;
  z-index: 9;
}

.gbb-row.row-background-particles-js > .particles-js-canvas-el {
  z-index: 8;
}

.column-style-1 > .column-inner {
  padding-left: calc((100vw - 1200px) / 2);
}
.column-banner-right-img-50 {
  padding-right: 50px;
}
.column-banner-left-img-50 {
  padding-left: 50px;
}

@media (max-width: 1199.98px) {
  .column-style-1 > .column-inner {
    padding-left: calc((100vw - 992px) / 2);
  }
}

@media (max-width: 991.98px) {
  .column-style-1 > .column-inner {
    padding-left: calc((100vw - 768px) / 2);
  }
}

@media (max-width: 767.98px) {
  .column-style-1 > .column-inner {
    padding-left: 30px;
    padding-right: 30px;
  }
  .column-banner-right-img-50 {
    padding-right: 20px;
  }
  .column-banner-left-img-50 {
    padding-left: 20px;
  }
  p, .cet-fsize-18, .cet-copy li {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
}

.column-style-2 > .column-inner {
  padding-right: calc((100vw - 1200px) / 2);
}

@media (max-width: 1199.98px) {
  .column-style-2 > .column-inner {
    padding-right: calc((100vw - 992px) / 2);
  }
}

@media (max-width: 991.98px) {
  .column-style-2 > .column-inner {
    padding-right: calc((100vw - 768px) / 2);
  }
}

@media (max-width: 767.98px) {
  .column-style-2 > .column-inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.column-style-3 {
  max-width: 480px;
  float: right;
}

.column-bg-right {
  margin-left: auto;
  max-width: 650px;
}

.column-padding-medium {
  padding: 60px;
}

@media (max-width: 991.98px) {
  .column-padding-medium {
    padding: 30px 0;
  }
}

.column-box-shadow {
  background: var(--clr-white);
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.column-box-shadow-2 {
  background: var(--clr-white);
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.column-border {
  border: 2px solid #ff8257;
  padding: 30px;
}

@media (max-width: 991.98px) {
  .column-border {
    padding: 20px;
  }
}

.row-gradient-1 {
  background-image: radial-gradient(farthest-corner at 50% -50%, #135a9f 0%, #192532 70%, #192532 100%);
  background-color: #192532;
}

.row-gradient-2 {
  position: relative;
}

.row-gradient-2 > .bb-inner {
  position: relative;
  z-index: 11;
}

.row-gradient-2:after {
  content: '';
  background-image: radial-gradient(farthest-corner at 50% -50%, #fff 0%, #dde7ff 70%, #dde7ff 100%);
  background-color: #dde7ff;
  position: absolute;
  top: 120px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .row-gradient-2:after {
    top: 0;
  }
}

.row-bg-top {
  position: relative;
}

.row-bg-top > .bb-inner {
  position: relative;
  z-index: 11;
}

.row-bg-top:after {
  content: '';
  background-image: radial-gradient(farthest-corner at 50% -50%, #fff 0%, #dde7ff 70%, #dde7ff 100%);
  background-color: #dde7ff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 500px;
  width: 100%;
}

@media (max-width: 991.98px) {
  .row-bg-top:after {
    top: 0;
  }
}

.grid-small .container {
  max-width: 900px;
  margin: 0 auto;
}

.gavias-blockbuilder-content {
  position: relative;
}

.gavias-blockbuilder-content .link-edit-blockbuider {
  border: 1px solid #eaeaea;
  position: absolute;
  top: 30px;
  left: 0px;
  z-index: 99;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
  background: #ff8257;
  color: var(--clr-white);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  display: inline-block;
  padding: 10px;
}

.gavias-blockbuilder-content .link-edit-blockbuider:hover, .gavias-blockbuilder-content .link-edit-blockbuider:focus, .gavias-blockbuilder-content .link-edit-blockbuider:active {
  color: var(--clr-white);
}

.gbb-row .bb-inner {
  padding-top: 65px;
  padding-bottom: 0;
}

@media (max-width: 991.98px) {
  .gbb-row .bb-inner {
    padding-top: 50px;
    padding-bottom: 0;
  }
}

.gbb-row .bb-inner.padding-small {
  padding-top: 30px;
  padding-bottom: 30px;
}

.gbb-row .bb-inner.padding-medium {
  padding-top: 45px;
  padding-bottom: 45px;
}

.gbb-row .bb-inner.padding-large {
  padding-top: 110px;
  padding-bottom: 110px;
}

.gbb-row .bb-inner.padding-top-small {
  padding-top: 30px;
}

.gbb-row .bb-inner.padding-top-medium {
  padding-top: 45px;
}

.gbb-row .bb-inner.padding-top-large {
  padding-top: 110px;
}

.gbb-row .bb-inner.padding-bottom-small {
  padding-bottom: 30px;
}

.gbb-row .bb-inner.padding-bottom-medium {
  padding-bottom: 45px;
}

.gbb-row .bb-inner.padding-bottom-large {
  padding-bottom: 110px;
}

.gbb-row .bb-inner.padding-top-removed-bottom-small {
  padding-top: 0;
  padding-bottom: 30px;
}

.gbb-row .bb-inner.padding-top-removed-bottom-medium {
  padding-top: 0;
  padding-bottom: 45px;
}

.gbb-row .bb-inner.padding-top-removed-bottom-large {
  padding-top: 0;
  padding-bottom: 110px;
}

@media (max-width: 991.98px) {
  .gbb-row .bb-inner.padding-small {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .gbb-row .bb-inner.padding-medium {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .gbb-row .bb-inner.padding-large {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  .gbb-row .bb-inner.padding-top-small {
    padding-top: 15px;
  }
  .gbb-row .bb-inner.padding-top-medium {
    padding-top: 30px;
  }
  .gbb-row .bb-inner.padding-top-large {
    padding-top: 75px;
  }
  .gbb-row .bb-inner.padding-bottom-small {
    padding-bottom: 15px;
  }
  .gbb-row .bb-inner.padding-bottom-medium {
    padding-bottom: 30px;
  }
  .gbb-row .bb-inner.padding-bottom-large {
    padding-bottom: 75px;
  }
  .gbb-row .bb-inner.padding-top-removed-bottom-small {
    padding-top: 0;
    padding-bottom: 15px;
  }
  .gbb-row .bb-inner.padding-top-removed-bottom-medium {
    padding-top: 0;
    padding-bottom: 30px;
  }
  .gbb-row .bb-inner.padding-top-removed-bottom-large {
    padding-top: 0;
    padding-bottom: 75px;
  }
}

@media (max-width: 767.98px) {
  .gbb-row .bb-inner.padding-small {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .gbb-row .bb-inner.padding-medium {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .gbb-row .bb-inner.padding-large {
    padding-top: 55px;
    padding-bottom: 55px;
  }
  .gbb-row .bb-inner.padding-top-small {
    padding-top: 10px;
  }
  .gbb-row .bb-inner.padding-top-medium {
    padding-top: 25px;
  }
  .gbb-row .bb-inner.padding-top-large {
    padding-top: 55px;
  }
  .gbb-row .bb-inner.padding-bottom-small {
    padding-bottom: 10px;
  }
  .gbb-row .bb-inner.padding-bottom-medium {
    padding-bottom: 25px;
  }
  .gbb-row .bb-inner.padding-bottom-large {
    padding-bottom: 55px;
  }
  .gbb-row .bb-inner.padding-top-removed-bottom-small {
    padding-top: 0;
    padding-bottom: 10px;
  }
  .gbb-row .bb-inner.padding-top-removed-bottom-medium {
    padding-top: 0;
    padding-bottom: 25px;
  }
  .gbb-row .bb-inner.padding-top-removed-bottom-large {
    padding-top: 0;
    padding-bottom: 55px;
  }
}

.gbb-row .bb-inner.remove_margin {
  margin: 0 !important;
}

.gbb-row .bb-inner.remove_padding {
  padding: 0 !important;
}

.gbb-row .bb-inner.remove_padding_top_bottom {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.gbb-row .bb-inner.remove_padding_col > .bb-container {
  padding-left: 0;
  padding-right: 0;
}

.gbb-row .bb-inner.remove_padding_col > .bb-container > .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.gbb-row .bb-inner.remove_padding_col > .bb-container > .row > .row-wrapper > div.gsc-column, .gbb-row .bb-inner.remove_padding_col > .bb-container > .row.row-wrapper > div.gsc-column {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.gbb-row .bb-inner.remove_padding_top {
  padding-top: 0 !important;
}

.gbb-row .bb-inner.remove_padding_bottom {
  padding-bottom: 0 !important;
}

.widget.gsc-progress {
  margin-bottom: 25px;
}

.widget.gsc-progress .progress {
  height: 3px !important;
}

.widget.gsc-progress .progress-bar {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.widget.gsc-progress .progress-bar span {
  top: 0;
}

.widget.gsc-progress .progress-label {
  text-transform: capitalize;
  font-size: 14px;
}

.widget.gsc-progress.text-light .progress-label {
  color: var(--clr-white);
}

.gsc-block-view.remove-margin-on {
  margin-bottom: 0 !important;
}

.column-content.text-quote {
  padding: 165px 20px;
  max-width: 820px;
  margin: 0 auto;
}

.column-content.text-quote-2 {
  padding: 80px 20px;
  max-width: 1000px;
  margin: 0 auto;
}

@media (max-width: 991.98px) {
  .column-content.text-quote {
    padding: 20px 20px;
  }
  .column-content.text-quote-2 {
    padding: 20px 20px;
  }
}

.gsc-accordion .panel-body {
  padding-left: 0;
  padding-right: 0;
}
.gsc-accordion .skin-white .panel-body p {
  margin-right: 45px;
  margin-bottom: 0;
}

.gsc-accordion .panel-group.skin-white .panel {
  margin-bottom: 2px;
}

.gsc-accordion .panel-group.skin-dark {
  background: #F3F5FA;
}

.gsc-accordion .panel-group.skin-dark .panel {
  background: #F3F5FA;
  border-bottom: 1px solid #e2e2e2 !important;
}

.gsc-accordion .panel-group.skin-dark .panel .panel-title {
  background: #F3F5FA !important;
}

.gsc-accordion .panel-group.skin-dark .panel .panel-title a {
  background: none !important;
}

.gsc-accordion .panel-group.skin-dark .panel:last-child {
  border-bottom: none !important;
}

.gsc-accordion .panel-group.skin-white-border {
  border: 1px solid var(--clr-light-gray);
  padding: 20px;
  background: var(--clr-white);
}

.gsc-accordion .panel-group.skin-white-border .panel {
  padding: 0 0 22px !important;
  border-bottom: 1px solid var(--clr-light-gray) !important;
  margin-bottom: 24px !important;
}

.gsc-accordion .panel-group.skin-white-border .panel .panel-title a {
  padding-left: 48px;
}

.gsc-accordion .panel-group.skin-white-border .panel .panel-title a:after {
  left: 0;
  top: 4px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 25px;
}

.gsc-accordion .panel-group.skin-white-border .panel .panel-body {
  padding-top: 10px;
}

.gsc-accordion .panel-group.skin-white-border .panel .panel-body p {
  margin-bottom: 0;
}

.gsc-accordion .panel-group.skin-white-border .panel .panel-body p > a {
  font-weight: 600;
}

.gsc-accordion .panel-group.skin-white-border .panel:last-child {
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.gsc-call-to-action {
  position: relative;
}

.gsc-call-to-action .title {
  margin: 0 0 25px 0;
}

.gsc-call-to-action .button-action {
  margin-top: 20px;
}

.gsc-call-to-action .button-action .quick-text {
  font-weight: 600;
  color: #2e3d62;
  font-size: 22px;
  margin-right: 15px;
}

.gsc-call-to-action .desc {
  margin-top: 25px;
}
.gsc-call-to-action.cet-center-header-txt-btn .content-inner {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}

.gsc-call-to-action.cet-center-header-txt-btn .content-inner .content {
  text-align: center;
  max-width: 950px;
}

.gsc-call-to-action.button-top-right .content, .gsc-call-to-action.button-center-right .content, .gsc-call-to-action.button-bottom-right .content {
  padding-right: 60px;
  max-width: 1000px;
}

.gsc-call-to-action.button-top-right .content-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.gsc-call-to-action.button-top-right .button-action {
  margin-top: 6px;
  text-align: right;
}
.gsc-call-to-action.button-center-right .content-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gsc-call-to-action.button-center-right .button-action, .gsc-call-to-action.button-bottom-right .button-action {
  text-align: right;
}
.gsc-call-to-action.button-bottom-right .content-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.gsc-call-to-action.button-bottom-center .button-action {
  text-align: center;
}

.gsc-call-to-action.button-left-bottom .content-inner {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
}
.gsc-call-to-action.button-center-bottom .content-inner {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}
.gsc-call-to-action.button-right-bottom .content-inner {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

.gsc-call-to-action.button-bottom-right.text-light .desc {
  color: var(--clr-white);
}

.gsc-call-to-action.button-center {
  text-align: center;
  margin: 0 auto;
}

.gsc-call-to-action.button-center .title {
  font-size: 58px;
  position: relative;
  margin-bottom: 20px;
  line-height: 1.2;
  margin-top: 0;
}

@media (max-width: 1199.98px) {
  .gsc-call-to-action.button-center .title {
    font-size: 42px;
  }
}

@media (max-width: 991.98px) {
  .gsc-call-to-action.button-center .title {
    font-size: 32px;
  }
}

.gsc-call-to-action.button-center .desc {
  font-size: 18px;
  font-weight: 500;
}

.gsc-call-to-action.button-center .button-action {
  margin-top: 35px;
  text-align: center;
}

.gsc-call-to-action.button-center .button-action .quick-text {
  position: relative;
  top: -20px;
}

.gsc-call-to-action.has-background {
  padding: 50px 30px;
  border-radius: 5px;
}

@media (max-width: 991.98px) {
  .gsc-call-to-action.has-background {
    margin-bottom: 30px;
  }
}

.gsc-call-to-action.has-background .title {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 20px;
}

.gsc-call-to-action.has-background .title:after {
  display: none;
}

.gsc-call-to-action.has-background.button-right .button-action {
  right: 30px;
}

@media (max-width: 800px) {
  .gsc-call-to-action.has-background.button-right .button-action {
    right: 0;
  }
}

.gsc-call-to-action.text-light .content {
  color: var(--clr-white);
}

.gsc-call-to-action.text-light .content .title {
  color: var(--clr-white);
}

.gsc-call-to-action.text-light .content .title span span {
  border-bottom-color: var(--clr-white);
}

.gsc-call-to-action.text-light .content .desc * {
  color: var(--clr-white);
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.gsc-call-to-action.text-light .subtitle {
  color: #d9d9d9;
}

.gsc-call-to-action.text-light .button-action .quick-text {
  color: var(--clr-white) !important;
}

.gsc-icon-box {
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.gsc-icon-box:before, .gsc-icon-box:after {
  content: " ";
  display: table;
}

.gsc-icon-box:after {
  clear: both;
}

.gsc-icon-box .icon-container.left {
  padding-right: 30px;
}

@media (max-width: 991.98px) {
  .gsc-icon-box .icon-container.left {
    padding-right: 0;
  }
}

.gsc-icon-box .icon-container.fa-1x {
  display: block;
  width: 40px;
  height: 40px;
}

.gsc-icon-box .icon-container.fa-1x .icon {
  margin-top: 12px;
}

.gsc-icon-box .icon-container.fa-2x {
  display: block;
  width: 64px;
  height: 64px;
}

.gsc-icon-box .icon-container.fa-2x .icon {
  margin-top: 15px;
}

.gsc-icon-box .icon-container.fa-3x {
  display: block;
  width: 124px;
  height: 124px;
}

.gsc-icon-box .icon-container.fa-3x .icon {
  margin-top: 30px;
}

.gsc-icon-box .icon-container.fa-4x {
  display: block;
  width: 160px;
  height: 160px;
}

.gsc-icon-box .icon-container.fa-4x .icon {
  margin-top: 40px;
}

.gsc-icon-box .highlight_content {
  margin-top: 5px;
}

.gsc-icon-box .desc {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.gsc-icon-box .desc p {
  margin-bottom: 0;
}

.gsc-icon-box .icon-container {
  text-align: center;
  line-height: 1;
  position: relative;
  color: #2e3d62;
}

.gsc-icon-box .icon-container .icon {
  position: relative;
  z-index: 11;
  display: inline-block;
}

.gsc-icon-box .icon-container.icon-shadow {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
}

.gsc-icon-box .icon-container.text-theme[class*="border-"] {
  border-color: rgba(0, 0, 0, 0.1);
}

.gsc-icon-box .icon-container.text-white[class*="border-"] {
  border-color: rgba(255, 255, 255, 0.2);
}

.gsc-icon-box .icon-container.text-black {
  color: var(--clr-black);
}

.gsc-icon-box .icon-container.text-black[class*="border-"] {
  border-color: rgba(0, 0, 0, 0.1);
}

.gsc-icon-box .icon-container.radius-1x:after {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.gsc-icon-box .icon-container.radius-2x:after {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.gsc-icon-box .icon-container.radius-5x:after {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.gsc-icon-box.icon-background .highlight-icon .icon-container {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
}

.gsc-icon-box.icon-background .highlight-icon .icon-container:after {
  top: 0px;
  left: 0px;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
}

.gsc-icon-box.icon-background .highlight-icon .icon-container.bg-black:after {
  background: #222222;
}

.gsc-icon-box.icon-background .highlight-icon .icon-container.bg-theme:after {
  background: #ff8257;
}

.gsc-icon-box.icon-background.icon-border .highlight-icon .icon-container.bg-black {
  border-color: #222222;
}

.gsc-icon-box.icon-background.icon-border .highlight-icon .icon-container.bg-theme {
  border-color: #ff8257;
}

.gsc-icon-box.icon-border:not(.icon-background) .highlight-icon .icon-container {
  color: #ff8257;
}

.gsc-icon-box.top-center {
  text-align: center;
  max-width: 550px;
  margin-right: auto;
  margin-left: auto;
}

.gsc-icon-box.top-center .highlight-icon {
  text-align: center;
  padding-right: 0 !important;
  padding-bottom: 10px;
}

.gsc-icon-box.top-center .highlight-icon .icon-container {
  display: inline-block;
}

.gsc-icon-box.top-center .highlight-icon .icon-container.fa-1x {
  font-size: 1.4em;
}

.gsc-icon-box.top-center .title {
  position: relative;
  padding: 15px 0;
  font-size: 22px;
}

.gsc-icon-box.top-center p.title {
  position: relative;
  padding: 0;
  font-size: 18px;
  line-height: 28px;
  font-family: var(--ff-primary);
}

.gsc-icon-box.top-center .highlight_content {
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
}

.gsc-icon-box.top-center .desc {
  text-align: center;
  font-weight: 400;
  max-width: 246px;
  margin: 0 auto;
}

.gsc-icon-box.top-left {
  text-align: left;
  max-width: 300px;
  clear: both;
}

.gsc-icon-box.top-left .title {
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 0;
}

.gsc-icon-box.top-right {
  text-align: right;
  max-width: 300px;
  clear: both;
  float: right;
}

.gsc-icon-box.top-right .title {
  padding: 25px 0 15px;
}

.gsc-icon-box.top-right .highlight-icon {
  width: 100%;
  text-align: right;
}

.gsc-icon-box.top-right .highlight-icon .icon-container {
  display: inline-block;
}

.gsc-icon-box.top-left-title .title-inner, .gsc-icon-box.top-right-title .title-inner {
  display: table;
  margin-bottom: 15px;
}

.gsc-icon-box.top-left-title .title-inner .highlight-icon, .gsc-icon-box.top-right-title .title-inner .highlight-icon {
  display: table-cell;
  vertical-align: top;
}

.gsc-icon-box.top-left-title .title-inner .highlight-icon .icon-image, .gsc-icon-box.top-right-title .title-inner .highlight-icon .icon-image {
  width: 36px;
  display: inline-block;
}

.gsc-icon-box.top-left-title .title-inner .title, .gsc-icon-box.top-right-title .title-inner .title {
  display: table-cell;
  vertical-align: middle;
}

.gsc-icon-box.top-left-title .highlight-icon {
  padding-right: 15px;
}

.gsc-icon-box.top-right-title {
  text-align: right;
}

.gsc-icon-box.top-right-title:before, .gsc-icon-box.top-right-title:after {
  content: " ";
  display: table;
}

.gsc-icon-box.top-right-title:after {
  clear: both;
}

.gsc-icon-box.top-right-title .title-inner {
  float: right;
}

.gsc-icon-box.top-right-title .highlight-icon {
  padding-left: 15px;
}

.gsc-icon-box.top-right-title .desc {
  float: right;
  width: 100%;
}

.gsc-icon-box.right, .gsc-icon-box.left {
  display: table;
  max-width: 600px;
}

.gsc-icon-box.right .highlight-icon, .gsc-icon-box.left .highlight-icon {
  display: table-cell;
  vertical-align: top;
  padding-top: 2px;
}

.gsc-icon-box.right .highlight-icon .icon-image, .gsc-icon-box.left .highlight-icon .icon-image {
  width: 60px;
  display: inline-block;
}

.gsc-icon-box.right .highlight_content, .gsc-icon-box.left .highlight_content {
  display: table-cell;
  vertical-align: top;
}

.gsc-icon-box.right .highlight_content .title, .gsc-icon-box.left .highlight_content .title {
  margin-bottom: 16px;
  position: relative;
  font-family: var(--ff-primary) !important;
}

.gsc-icon-box.right {
  text-align: right;
}

.gsc-icon-box.right .highlight-icon {
  padding-left: 20px;
}

.gsc-icon-box.right .highlight_content .title:after {
  left: auto;
  right: 0;
}

.gsc-icon-box.left {
  text-align: left;
}

.gsc-icon-box.left .highlight-icon {
  padding-right: 20px;
}

@media (max-width: 991.98px) {
  .gsc-icon-box.responsive-1 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    float: none;
    display: block;
  }
  .gsc-icon-box.responsive-1 .highlight-icon {
    text-align: center;
    display: block;
    margin-bottom: 15px;
    padding: 0;
  }
  .gsc-icon-box.responsive-1 .highlight_content {
    display: block;
  }
  .gsc-icon-box.responsive-1 .highlight_content .title:after {
    left: 50%;
    margin-left: -17.5px;
  }
}

.gsc-icon-box.text-light .title {
  color: var(--clr-white);
}

.gsc-icon-box.text-light .title:after {
  background: var(--clr-white);
}

.gsc-icon-box.text-light .title a {
  color: var(--clr-white);
}

.gsc-icon-box.text-light .title a:hover {
  color: #ff8257;
}

.gsc-icon-box.text-light .highlight-icon .icon-container {
  color: var(--clr-white);
}

.gsc-icon-box.text-light .desc {
  color: rgba(255, 255, 255, 0.8);
}

.gsc-icon-box.box-background {
  padding: 30px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.gsc-icon-box.box-background.active, .gsc-icon-box.box-background:hover, .gsc-icon-box.box-background:focus, .gsc-icon-box.box-background:active {
  -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.gsc-icon-box.border-s1 .icon-inner {
  padding: 6px;
  border: 2px solid #eaeaea;
  display: inline-block;
}

.gsc-icon-box.border-s1 .icon-inner .fa-stack {
  line-height: 2.3em !important;
  width: 2.2em;
  height: 2.2em;
}

.gsc-icon-box.border-s1 .icon-inner .fa-2x {
  font-size: 1.6em;
}

.gsc-icon-box.border-s1 .icon-inner .fa-1x {
  font-size: 1.2em;
}

.gsc-icon-box.border-s1 .icon-inner .fa-1x .fa-stack {
  line-height: 2.3em !important;
  width: 2.2em;
  height: 2.2em;
}

.gsc-icon-box .i-border-theme {
  border-color: #ff8257 !important;
}

.gsc-icon-box .i-border-black {
  border-color: #2e3d62 !important;
}

.gsc-icon-box .i-border-dark {
  border-color: #f1f1f1 !important;
}

.gsc-icon-box .i-border-white {
  border-color: var(--clr-white) !important;
}

.gsc-icon-box-style {
  background: var(--clr-white);
}

.gsc-icon-box-style.highlight-box {
  background: #9364d4;
  position: relative;
  overflow: hidden;
}

.gsc-icon-box-style.highlight-box:after, .gsc-icon-box-style.highlight-box:before {
  position: absolute;
  content: "";
  width: 190px;
  height: 190px;
  border-radius: 190px;
  -webkit-border-radius: 190px;
  -moz-border-radius: 190px;
  -ms-border-radius: 190px;
  -o-border-radius: 190px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  background: rgba(255, 255, 255, 0.1);
}

.gsc-icon-box-style.highlight-box:after {
  bottom: -95px;
  left: -95px;
}

.gsc-icon-box-style.highlight-box:before {
  top: -95px;
  right: -95px;
}

.gsc-icon-box-style.text-light .box-title a {
  color: var(--clr-white);
}

.gsc-icon-box-style.text-light .arrow, .gsc-icon-box-style.text-light .box-desc {
  color: var(--clr-white);
}

.gsc-icon-box-style.style-1 {
  text-align: center;
  position: relative;
  padding-top: 45px;
  padding-bottom: 45px;
  border-radius: 4px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  border: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 30px;
}

.gsc-icon-box-style.style-1 .box-title {
  font-size: 18px;
  font-weight: 500;
  margin-top: 35px;
}

.gsc-icon-box-style.style-1 .arrow {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  color: #ff8257;
  position: absolute;
  bottom: -18px;
  left: 50%;
  margin-left: -18px;
  z-index: 11;
  background: #F6F7FB;
}

.gsc-icon-box-style.style-1.highlight {
  background: #ff8257;
  -webkit-box-shadow: 0 5px 26px rgba(0, 0, 0, 0.08);
  box-shadow: 0 5px 26px rgba(0, 0, 0, 0.08);
}

.gsc-icon-box-style.style-1.highlight .box-title {
  color: var(--clr-white);
}

.gsc-icon-box-style.style-1.highlight .box-title a {
  color: var(--clr-white);
}

.gsc-icon-box-style.style-1.highlight .arrow {
  background: var(--clr-yellow);
  color: var(--clr-white);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.gsc-icon-box-style.style-1:hover {
  -webkit-box-shadow: 0 5px 26px rgba(0, 0, 0, 0.08);
  box-shadow: 0 5px 26px rgba(0, 0, 0, 0.08);
  border-color: rgba(0, 0, 0, 0.08);
}

.gsc-icon-box-style.style-1:hover .arrow {
  background: var(--clr-yellow);
  color: var(--clr-white);
}

.gsc-icon-box-style.style-2 {
  position: relative;
  padding: 50px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  border-radius: 4px;
  -webkit-box-shadow: 0px 10px 60px 0 rgba(46, 61, 98, 0.1);
  box-shadow: 0px 10px 60px 0 rgba(46, 61, 98, 0.1);
  margin-bottom: 30px;
}

@media (max-width: 991.98px) {
  .gsc-icon-box-style.style-2 {
    padding: 30px;
  }
}

.gsc-icon-box-style.style-2 .box-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 30px;
}

.gsc-icon-box-style.style-2 .arrow {
  font-size: 25px;
  line-height: 25px;
  color: #bcbfc7;
  margin-top: 20px;
  display: inline-block;
}

.gsc-icon-box-style.style-2 .arrow:hover {
  color: #ff8257;
}

.gsc-icon-box-style.style-2:hover .arrow {
  color: #ff8257;
}

.gsc-icon-box-style.style-2:hover .link {
  color: #ff8257;
}

.gsc-icon-box-style.style-3 {
  text-align: center;
  position: relative;
  padding: 50px;
  border-radius: 4px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -webkit-box-shadow: 0 5px 26px rgba(0, 0, 0, 0.08);
  box-shadow: 0 5px 26px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
}

@media (max-width: 991.98px) {
  .gsc-icon-box-style.style-3 {
    padding: 30px;
  }
}

.gsc-icon-box-style.style-3 .content-inner {
  max-width: 225px;
  margin: 0 auto;
}

.gsc-icon-box-style.style-3 .box-title {
  font-size: 20px;
  line-height: 36px;
  font-weight: 600;
  margin-top: 35px;
}

@media (max-width: 991.98px) {
  .gsc-icon-box-style.style-3 .box-title {
    font-size: 20px;
    line-height: 30px;
  }
}

.gsc-icon-box-style.style-3 .icon {
  font-size: 80px;
  color: #ff8257;
}

.gsc-icon-box-style.style-3 .arrow {
  display: none !important;
}

.gsc-icon-box-style.style-3:hover {
  -webkit-box-shadow: 0 5px 26px rgba(0, 0, 0, 0.08);
  box-shadow: 0 5px 26px rgba(0, 0, 0, 0.08);
  border-color: rgba(0, 0, 0, 0.08);
}

.milestone-block.position-icon-top {
  text-align: center;
  display: block;
  width: 100%;
  padding: 0 0 30px;
}

.milestone-block.position-icon-top .milestone-icon {
  color: #ff8257;
  display: inline-block;
  text-align: center;
  float: none;
  margin: 0;
  padding: 10px;
  margin-bottom: 5px;
  display: inline-block;
}

.milestone-block.position-icon-top .milestone-icon .icon {
  text-align: center;
  line-height: 56px;
  color: #ff8257;
  font-size: 40px;
}

.milestone-block.position-icon-top .milestone-right {
  display: block;
  text-align: center;
  float: none;
  width: 100%;
  margin-top: 10px;
}

.milestone-block.position-icon-top .milestone-number {
  font-size: 100px;
  font-weight: 300;
  line-height: 1.1;
  color: #2e3d62;
}

@media (max-width: 991.98px) {
  .milestone-block.position-icon-top .milestone-number {
    font-size: 60px;
  }
}

.milestone-block.position-icon-top .milestone-text {
  font-size: 18px;
  font-weight: 500;
  max-width: 220px;
  margin: 18px auto 0;
  line-height: 30px;
}

.milestone-block.position-icon-left {
  margin-bottom: 20px;
}

.milestone-block.position-icon-left:before, .milestone-block.position-icon-left:after {
  content: " ";
  display: table;
}

.milestone-block.position-icon-left:after {
  clear: both;
}

.milestone-block.position-icon-left .milestone-icon {
  color: #ff8257;
  display: inline-block;
  text-align: center;
  float: none;
  margin: 0;
  width: 68px;
  height: 68px;
  margin-top: 8px;
  display: inline-block;
  float: left;
  margin-right: 4px;
}

.milestone-block.position-icon-left .milestone-icon span {
  color: #ff8257;
  font-size: 38px;
  line-height: 1;
}

.milestone-block.position-icon-left .milestone-right {
  padding-left: 85px;
  text-align: left;
}

.milestone-block.position-icon-left .milestone-right .milestone-number {
  color: #2e3d62;
  text-align: left;
  font-size: 50px;
  line-height: 1;
  padding-top: 20px;
  font-weight: 700;
}

.milestone-block.position-icon-left .milestone-right .milestone-text {
  font-size: 16px;
  text-transform: capitalize;
  text-align: left;
  font-weight: 500;
  width: 100%;
  color: #2e3d62;
}

.milestone-block.position-without-icon {
  text-align: center;
  width: 192px;
  height: 192px;
  border-radius: 192px;
  -webkit-border-radius: 192px;
  -moz-border-radius: 192px;
  -ms-border-radius: 192px;
  -o-border-radius: 192px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 25px 60px 0 rgba(46, 61, 98, 0.05);
  box-shadow: 0px 25px 60px 0 rgba(46, 61, 98, 0.05);
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
}

@media (max-width: 991.98px) {
  .milestone-block.position-without-icon {
    margin: 0 auto 60px !important;
  }
}

.milestone-block.position-without-icon:hover .milestone-text {
  letter-spacing: 0;
}

.milestone-block.position-without-icon .milestone-content {
  width: 192px;
  height: 192px;
  border-radius: 192px;
  -webkit-border-radius: 192px;
  -moz-border-radius: 192px;
  -ms-border-radius: 192px;
  -o-border-radius: 192px;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 0 18px var(--clr-white);
  box-shadow: 0 0 0 18px var(--clr-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.milestone-block.position-without-icon .milestone-number, .milestone-block.position-without-icon .symbol {
  font-size: 40px;
  line-height: 30px;
  font-weight: 300;
}

.milestone-block.position-without-icon .milestone-text {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
}

.milestone-block.text-light .milestone-number {
  color: var(--clr-white) !important;
}

.milestone-block.text-light .milestone-text {
  color: #b8b8d3 !important;
}

.milestone-block.text-light .milestone-icon span {
  border-color: var(--clr-white);
  color: var(--clr-white);
}

.milestone-block.hightlight {
  background: var(--clr-white);
  border-radius: 3px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  padding: 60px 15px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.milestone-block.border-style-1 .milestone-content {
  border: 1px dashed #ef4059;
}

.milestone-block.border-style-1 .milestone-number, .milestone-block.border-style-1 .symbol {
  color: #ef4059;
}

.milestone-block.border-style-1:hover {
  -webkit-box-shadow: 0px 25px 60px 0 rgba(239, 64, 89, 0.5);
  box-shadow: 0px 25px 60px 0 rgba(239, 64, 89, 0.5);
}

.milestone-block.border-style-2 .milestone-content {
  border: 1px dashed #5dd8d3;
}

.milestone-block.border-style-2 .milestone-number, .milestone-block.border-style-2 .symbol {
  color: #5dd8d3;
}

.milestone-block.border-style-2:hover {
  -webkit-box-shadow: 0px 25px 60px 0 rgba(93, 216, 211, 0.5);
  box-shadow: 0px 25px 60px 0 rgba(93, 216, 211, 0.5);
}

.milestone-block.border-style-3 .milestone-content {
  border: 1px dashed #9364d4;
}

.milestone-block.border-style-3 .milestone-number, .milestone-block.border-style-3 .symbol {
  color: #9364d4;
}

.milestone-block.border-style-3:hover {
  -webkit-box-shadow: 0px 25px 60px 0 rgba(147, 100, 212, 0.5);
  box-shadow: 0px 25px 60px 0 rgba(147, 100, 212, 0.5);
}

.milestone-block.border-style-4 .milestone-content {
  border: 1px dashed #ff8257;
}

.milestone-block.border-style-4 .milestone-number, .milestone-block.border-style-4 .symbol {
  color: #ff8257;
}

.milestone-block.border-style-4:hover {
  -webkit-box-shadow: 0px 25px 60px 0 rgba(255, 130, 87, 0.5);
  box-shadow: 0px 25px 60px 0 rgba(255, 130, 87, 0.5);
}

.gsc-block-drupal {
  margin-bottom: 35px;
}

.gsc-block-drupal.hidden-title-off .block-inner .block-title h2 {
  display: none !important;
}

.gsc-block-drupal.title-align-center .block-title {
  text-align: center;
}

.gsc-block-drupal.title-align-left .block-title {
  text-align: left;
}

.gsc-block-drupal.title-align-right .block-title {
  text-align: right;
}

.gsc-block-drupal.text-light div {
  color: var(--clr-white);
}

.gsc-block-drupal.text-light .block-inner .block-title {
  color: var(--clr-white);
}

.gsc-block-drupal.text-light .block-inner .block-content *, .gsc-block-drupal.text-light .block-inner .block-content div {
  color: var(--clr-white);
}

.gsc-block-drupal.text-light .block-inner .block-content *:after, .gsc-block-drupal.text-light .block-inner .block-content *:before, .gsc-block-drupal.text-light .block-inner .block-content div:after, .gsc-block-drupal.text-light .block-inner .block-content div:before {
  color: rgba(255, 255, 255, 0.2);
}

.gsc-block-drupal.remove-margin-on {
  margin-bottom: 0 !important;
}

.gsc-block-drupal.remove-margin-on .block {
  margin-bottom: 0 !important;
}

.gsc-block-drupal.block-style-1 {
  padding: 40px 40px 50px;
  background: #9364d4;
}

@media (max-width: 1199.98px) {
  .gsc-block-drupal.block-style-1 {
    padding: 50px 30px 60px;
  }
}

.gsc-content-images-parallax {
  float: left;
  width: 100%;
}

.gsc-content-images-parallax:before, .gsc-content-images-parallax:after {
  content: " ";
  display: table;
}

.gsc-content-images-parallax:after {
  clear: both;
}

.gsc-content-images-parallax .images {
  width: 40%;
  position: relative;
}

@media (max-width: 767.98px) {
  .gsc-content-images-parallax .images {
    width: 100%;
    float: left;
  }
  .gsc-accordion .panel-group.skin-white-border .panel .panel-title a:after {
    top: 1px;
  }
  .gsc-accordion .panel-group.skin-white-border .panel:first-child {
    padding-top: 6px !important;
  }
}

.gsc-content-images-parallax .images img {
  border: 2px solid var(--clr-white);
}

.gsc-content-images-parallax .images .image-1 {
  -webkit-box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.2);
}

.gsc-content-images-parallax .images .image-2 {
  max-width: 50%;
  -webkit-box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.15);
}

.gsc-content-images-parallax .content {
  width: 60%;
}

@media (max-width: 767.98px) {
  .gsc-content-images-parallax .content {
    width: 100%;
    float: left;
  }
}

.gsc-content-images-parallax .action {
  margin-top: 15px;
}

.gsc-content-images-parallax.left .images {
  float: right;
}

.gsc-content-images-parallax.left .content {
  float: left;
  padding-right: 30px;
}

@media (max-width: 767.98px) {
  .gsc-content-images-parallax.left .content {
    padding-right: 0;
  }
}

.gsc-content-images-parallax.right .images {
  float: left;
}

.gsc-content-images-parallax.right .content {
  float: right;
  padding-left: 30px;
}

@media (max-width: 767.98px) {
  .gsc-content-images-parallax.right .content {
    padding-left: 0;
  }
}

.gsc-content-images-parallax.style-v1 {
  margin-bottom: 60px;
}

.gsc-content-images-parallax.style-v1 .images {
  position: relative;
  text-align: center;
}

@media (max-width: 767.98px) {
  .gsc-content-images-parallax.style-v1 .images {
    max-width: 550px;
    float: none;
    margin: 0 auto;
  }
}

.gsc-content-images-parallax.style-v1 .content {
  text-align: center;
  padding-top: 30px;
}

@media (max-width: 991.98px) {
  .gsc-content-images-parallax.style-v1 .content {
    padding-top: 20px;
  }
}

.gsc-content-images-parallax.style-v1 .content .content-inner {
  display: inline-block;
  max-width: 480px;
  text-align: left;
}

.gsc-content-images-parallax.style-v1 .content .description {
  font-size: 16px;
  margin-top: 10px;
}

.gsc-content-images-parallax.style-v1 .content .sub-title span {
  text-transform: capitalize;
  background: #ff8257;
  font-size: 11px;
  padding: 4px 10px;
  letter-spacing: 1.5px;
  color: var(--clr-white);
}

.gsc-content-images-parallax.style-v1 .content .title {
  font-size: 23px;
  position: relative;
  line-height: 42px;
  margin: 5px 0;
  color: #2e3d62;
  font-weight: 600;
  text-transform: capitalize;
}

.gsc-content-images-parallax.style-v1 .content .title span {
  position: relative;
  z-index: 9;
}

.gsc-content-images-parallax.style-v1.right .image-1 {
  position: relative;
  left: 30px;
  z-index: 1;
}

@media (max-width: 575.98px) {
  .gsc-content-images-parallax.style-v1.right .image-1 {
    left: 0;
  }
}

.gsc-content-images-parallax.style-v1.right .image-2 {
  position: absolute;
  top: 150px;
  left: 0;
  z-index: 9;
}

@media (max-width: 767.98px) {
  .gsc-content-images-parallax.style-v1.right .image-2 {
    top: -30px !important;
  }
}

.gsc-content-images-parallax.style-v1.left .image-1 {
  position: relative;
  right: 30px;
  z-index: 1;
}

@media (max-width: 575.98px) {
  .gsc-content-images-parallax.style-v1.left .image-1 {
    right: 0;
  }
}

.gsc-content-images-parallax.style-v1.left .image-2 {
  position: absolute;
  bottom: 150px;
  right: 0;
  z-index: 9;
}

@media (max-width: 767.98px) {
  .gsc-content-images-parallax.style-v1.left .image-2 {
    top: -30px !important;
  }
}

.gsc-content-images-parallax.style-v2 {
  margin-bottom: 30px;
}

.gsc-content-images-parallax.style-v2 .images {
  position: relative;
  width: 52%;
}

.gsc-content-images-parallax.style-v2 .images img {
  border: none;
}

@media (max-width: 575.98px) {
  .gsc-content-images-parallax.style-v2 .images {
    width: 100%;
  }
}

.gsc-content-images-parallax.style-v2 .content {
  text-align: center;
  padding-top: 80px;
  width: 48%;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 575.98px) {
  .gsc-content-images-parallax.style-v2 .content {
    width: 100%;
  }
}

.gsc-content-images-parallax.style-v2 .content .content-inner {
  display: inline-block;
  max-width: 480px;
  text-align: left;
}

.gsc-content-images-parallax.style-v2 .content .description {
  font-size: 16px;
  margin-top: 10px;
}

.gsc-content-images-parallax.style-v2 .content .sub-title span {
  text-transform: capitalize;
  background: #ff8257;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 5px;
  letter-spacing: 1.5px;
  color: var(--clr-white);
}

.gsc-content-images-parallax.style-v2 .content .title {
  font-size: 18px;
  position: relative;
  color: #2e3d62;
  line-height: 1.3;
  margin-bottom: 25px;
  text-transform: capitalize;
}

.gsc-content-images-parallax.style-v2 .content .title span {
  position: relative;
  z-index: 9;
}

.gsc-content-images-parallax.style-v2 .action a {
  font-size: 12px;
}

.gsc-content-images-parallax.style-v2 .action a i {
  position: relative;
  top: 1px;
}

.gsc-content-images-parallax.style-v2.right {
  padding-bottom: 60px;
}

@media (max-width: 575.98px) {
  .gsc-content-images-parallax.style-v2.right {
    padding-bottom: 0;
  }
}

.gsc-content-images-parallax.style-v2.right .image-1 {
  position: relative;
  left: 0;
  top: 85px;
  z-index: 1;
  width: 50%;
}

.gsc-content-images-parallax.style-v2.right .image-2 {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 9;
  width: 50%;
}

.gsc-content-images-parallax.style-v2.left {
  padding-top: 60px;
}

@media (max-width: 575.98px) {
  .gsc-content-images-parallax.style-v2.left {
    padding-top: 0;
  }
}

.gsc-content-images-parallax.style-v2.left .image-1 {
  position: relative;
  left: 0;
  z-index: 1;
  width: 50%;
}

.gsc-content-images-parallax.style-v2.left .image-2 {
  position: absolute;
  top: 85px;
  left: 50%;
  width: 50%;
  z-index: 9;
}

.gsc-video-box {
  position: relative;
  margin: 0 auto;
}

.gsc-video-box.style-1 {
  width: 100%;
  max-width: 924px;
}

.gsc-video-box.style-1 .video-inner {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.gsc-video-box.style-1 .video-inner .image img {
  border-radius: 9px;
}

.gsc-video-box.style-1 .video-inner .image .popup-video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
}

.gsc-video-box.style-1 .video-inner .image .popup-video .icon {
  position: absolute;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  top: 50%;
  left: 50%;
  margin-left: -45px;
  margin-top: -45px;
  z-index: 12;
  background: var(--clr-yellow);
  color: var(--clr-black);
  display: inline-block;
  text-align: center;
  line-height: 90px;
  font-size: 26px;
}

.gsc-video-box.style-1 .video-content {
  padding-bottom: 25px;
  padding-top: 25px;
}

.gsc-video-box.style-1 .video-content .video-title {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  line-height: 28px;
  padding-left: 20px;
  padding-right: 20px;
}

.gsc-video-box.style-1 .video-content .video-title a {
  color: var(--clr-white);
  text-decoration: underline;
}

.gsc-video-box.style-1 .video-content .video-title a:hover {
  color: var(--clr-magenta);
}

.gsc-video-box.style-1:hover .video-inner .image .popup-video .icon {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 1;
  filter: alpha(opacity=100);
}

.gsc-video-box.style-2 {
  margin-bottom: 30px;
}

.gsc-video-box.style-2 .image .popup-video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 19;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
}

.gsc-video-box.style-2 .image .popup-video .icon {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  z-index: 19;
  background: #ff8257;
  color: var(--clr-white);
  display: inline-block;
  text-align: center;
  line-height: 50px;
}

.gsc-video-box.style-2 .video-content .video-title {
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0 0;
  position: relative;
  float: left;
  text-transform: capitalize;
}

.gsc-video-box.style-2 .video-content .video-title a {
  color: #2e3d62;
  text-decoration: underline;
}

.gsc-video-box.style-2 .video-content .video-title a:hover {
  color: #ff8257;
}

.gsc-video-box.style-2 .video-inner {
  position: relative;
}

.gsc-links .content-inner {
  max-width: 1000px;
  margin: 0 auto 0;
  position: relative;
  top: 0;
}

.gsc-links .content-inner .box-content {
  padding: 15px 5px;
}

.gsc-links .content-inner .box-content a {
  text-align: center;
}

.gsc-links .content-inner .box-content a span {
  display: block;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 500;
  font-size: 11px;
}

.gsc-links .content-inner .box-content a span.icon {
  font-size: 24px;
}

.gsc-links .content-inner .box-content a:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  color: #2e3d62;
}

.gsc-links .content-inner .box-content a.o_active {
  color: #ff8257;
}

.gsc-links .btn-hidden-links {
  position: absolute;
  right: 15px;
  top: 30px;
  z-index: 9;
  display: none;
  font-size: 32px;
  line-height: 1;
}

.gsc-links .btn-hidden-links.hidden-menu {
  top: 26px;
}

.gsc-links .btn-hidden-links.hidden-menu span:before {
  content: "\f20e";
}

.gsc-links.stuck {
  background: var(--clr-white);
  margin-top: 0 !important;
  right: 0;
  left: auto;
}

@media (max-width: 1200px) {
  .gsc-links.stuck {
    padding-right: 70px;
  }
}

.gsc-links.stuck .btn-hidden-links {
  display: block;
}

.gsc-links.stuck.hidden-menu {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  top: -120px;
}

.gsc-links.stuck.hidden-menu .content-inner {
  opacity: 0;
  filter: alpha(opacity=0);
}

.gsc-links.stuck.hidden-menu .btn-hidden-links {
  position: fixed;
}

.gsc-links:hover .box-content a {
  opacity: 0.3;
  filter: alpha(opacity=30);
}

.gsc-tabs-content {
  background: var(--clr-white);
}

.gsc-tabs-content .nav-tabs {
  text-align: center;
  margin-bottom: 0;
  border: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.gsc-tabs-content .nav-tabs > li {
  display: inline-block;
}

@media (max-width: 767.98px) {
  .gsc-tabs-content .nav-tabs > li {
    width: 100%;
    margin-bottom: 10px;
  }
  .gsc-video-box.style-1 .video-content .video-title {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    line-height: 26px;
  }
}

.gsc-tabs-content .nav-tabs > li > a {
  font-size: 18px;
  font-weight: 500;
  padding: 35px 50px;
  display: inline-block;
  background: #F3F5FA;
}

@media (max-width: 991.98px) {
  .gsc-tabs-content .nav-tabs > li > a {
    padding: 30px 20px;
  }
}

@media (max-width: 767.98px) {
  .gsc-tabs-content .nav-tabs > li > a {
    width: 100%;
  }
}

.gsc-tabs-content .nav-tabs > li > a.active {
  background: var(--clr-white);
  color: var(--clr-white);
}

.gsc-tabs-content .tab-content {
  padding-top: 60px;
}

.gsc-tabs-content .tab-content .tab-pane .images {
  float: left;
  width: 50%;
  padding-right: 15px;
}

@media (max-width: 991.98px) {
  .gsc-tabs-content .tab-content .tab-pane .images {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
  }
}

.gsc-tabs-content .tab-content .tab-pane .images .content-inner {
  width: 100%;
  text-align: center;
}

.gsc-tabs-content .tab-content .tab-pane .images .content-inner span {
  position: relative;
  display: inline-block;
}

.gsc-tabs-content .tab-content .tab-pane .content-inner {
  float: left;
  width: 50%;
  padding-left: 15px;
}

@media (max-width: 991.98px) {
  .gsc-tabs-content .tab-content .tab-pane .content-inner {
    width: 100%;
    padding-left: 0;
  }
}

.gsc-team .social-icons a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  color: var(--clr-white);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.gsc-team .social-icons a:hover {
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
}

.gsc-team .team-name {
  font-size: 16px;
  line-height: 22px;
  color: var(--clr-black);
  position: relative;
  font-weight: 600;
  margin: 0;
  font-family: var(--ff-primary);
}

.gsc-team .team-position {
  color: var(--clr-black);
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: var(--ff-primary);
}

.gsc-team .team-info {
  margin: 15px 0;
}

.gsc-team.team-horizontal {
  margin-top: 50px;
}

.gsc-team.team-horizontal .team-header {
  position: relative;
  overflow: hidden;
}

.gsc-team.team-horizontal .team-header img {
  -webkit-transition: all 3s;
  transition: all 3s;
  -moz-transition: all 3s;
  -ms-transition: all 3s;
  display: inline-block;
}

.gsc-team.team-horizontal .team-header .box-hover {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.gsc-team.team-horizontal .team-header .box-hover .content-inner {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: 0;
  width: 100%;
  height: auto;
  padding: 0 20px;
}

.gsc-team.team-horizontal .team-header .social-list a {
  background: none;
  color: var(--clr-white) !important;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  margin-right: 20px;
  display: inline-block;
  font-size: 16px;
}

.gsc-team.team-horizontal .team-header .social-list a:last-child {
  margin-right: 0;
}

.gsc-team.team-horizontal .team-header .social-list a:hover {
  color: #ff8257 !important;
}

.gsc-team.team-horizontal .team-name {
  font-size: 14px;
  margin: 0 0 12px 0;
  text-transform: uppercase;
  padding: 0 0 12px;
  position: relative;
  font-weight: 800;
}

.gsc-team.team-horizontal .team-name:after {
  bottom: 0;
  left: 0;
  content: "";
  background: #ff8257;
  width: 45px;
  height: 1px;
  position: absolute;
}

.gsc-team.team-horizontal:hover .box-hover {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.gsc-team.team-horizontal:hover .team-header img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.gsc-team.team-vertical {
  position: relative;
  margin: 0 0 30px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

@media (max-width: 767.98px) {
  .gsc-team.team-vertical {
    max-width: 350px;
    margin: 0 auto 30px;
  }
}

.gsc-team.team-vertical:after {
  content: "";
  width: 100%;
  height: 100%;
  border: 10px solid var(--clr-white);
  top: 0;
  left: 0;
  z-index: 9;
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}

.gsc-team.team-vertical .team-block-inner {
  position: relative;
  overflow: hidden;
}

.gsc-team.team-vertical .team-image img {
  position: relative;
  text-align: center;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  top: 0;
}

.gsc-team.team-vertical .team-content .content-inner {
  z-index: 11;
  position: relative;
  padding: 15px;
  text-align: center;
}

.gsc-team.team-vertical .team-content:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #9364d4;
  opacity: 0.6;
  filter: alpha(opacity=60);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.gsc-team.team-vertical .team-email {
  display: none;
}

.gsc-team.team-vertical .team-name {
  font-weight: 700;
  color: #2e3d62;
  margin: 12px 0 2px;
  line-height: 24px;
  font-size: 18px;
}

.gsc-team.team-vertical .team-name a:hover, .gsc-team.team-vertical .team-name a:focus, .gsc-team.team-vertical .team-name a:active {
  color: var(--clr-white);
}

.gsc-team.team-vertical .team-job {
  font-weight: 300;
}

.gsc-team.team-vertical .team-socials {
  position: relative;
  padding-bottom: 10px;
}

.gsc-team.team-vertical .team-socials:after {
  content: "";
  width: 35px;
  height: 1px;
  background: #333;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -22.5px;
  z-index: 1;
}

.gsc-team.team-vertical .team-socials > a {
  font-size: 18px;
  margin: 0 5px;
}

.gsc-team.team-vertical .team-socials > a:hover, .gsc-team.team-vertical .team-socials > a:focus, .gsc-team.team-vertical .team-socials > a:active {
  color: var(--clr-white);
}

.gsc-team.team-vertical:hover {
  -webkit-box-shadow: 2px 5px 15px 0px rgba(2, 40, 78, 0.18);
  box-shadow: 2px 5px 15px 0px rgba(2, 40, 78, 0.18);
}

.gsc-team.team-vertical:hover:after {
  opacity: 1;
  filter: alpha(opacity=100);
}

.gsc-team.team-vertical:hover .team-image img {
  top: -20px;
}

.gsc-team.team-vertical:hover .team-content {
  opacity: 1;
  filter: alpha(opacity=100);
  bottom: 0;
}

.cet-team-container .container {
  max-width: 968px;
}

.cet-team-container .gsc-column {
  padding-left: 13px;
  padding-right: 13px;
}

.cet-team-container a {
  text-decoration: none;
}

.cet-team-container a:hover {
  color: var(--clr-black);
  text-decoration: underline;
}

.gsc-team.team-circle {
  margin-bottom: 8px;
  position: relative;
  background: none;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.gsc-team.team-circle .team-header {
  overflow: hidden;
  position: relative;
  max-width: 216px;
  max-height: 216px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.gsc-team.team-circle .team-header img {
  -webkit-transition: all 3s;
  transition: all 3s;
  -moz-transition: all 3s;
  -ms-transition: all 3s;
  display: inline-block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.gsc-team.team-circle .team-header .social-list {
  position: absolute;
  bottom: 45px;
  left: 0;
  width: 100%;
  z-index: 9;
  text-align: center;
}

.gsc-team.team-circle .team-header .social-list a {
  background: none;
  color: var(--clr-white) !important;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  margin-right: 20px;
  display: inline-block;
  font-size: 16px;
}

.gsc-team.team-circle .team-header .social-list a:last-child {
  margin-right: 0;
}

.gsc-team.team-circle .team-header .social-list a:hover {
  color: #ff8257 !important;
}

.gsc-team.team-circle .team-name {
  padding: 0;
  color: var(--clr-black);
}

.gsc-team.team-circle .team-position {
  color: var(--clr-black);
  margin: 5px 0 0;
}

.gsc-team.team-circle .team-content {
  color: var(--clr-black);
}
.gsc-team.team-circle .box-hover {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  width: 100%;
  height: 100%;
  background: rgba(55, 0, 110, 0.25);
  mix-blend-mode: multiply;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.gsc-team.team-circle .box-hover .team-content {
  position: absolute;
  left: 0;
  width: 100%;
  height: auto;
  padding: 0 20px;
}

.gsc-team.team-circle .team-body {
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  text-align: center;
}

.gsc-team.team-circle .team-body .content-hover {
  opacity: 0;
  filter: alpha(opacity=0);
}

.gsc-team.team-circle .team-body .info {
  padding: 20px 15px;
  position: relative;
  margin-top: 0;
  z-index: 9;
  background: var(--clr-white);
}

.gsc-team.team-circle:hover .box-hover {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.pricing-table {
  position: relative;
  text-align: center;
  background-color: var(--clr-white);
  top: 0;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  margin-bottom: 30px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  -webkit-box-shadow: 0px 10px 60px 0 rgba(46, 61, 98, 0.1);
  box-shadow: 0px 10px 60px 0 rgba(46, 61, 98, 0.1);
}

.pricing-table ul {
  padding: 45px 0;
}

@media (max-width: 991.98px) {
  .pricing-table ul {
    padding: 25px 0;
  }
}

.pricing-table ul li {
  list-style: none;
  padding: 5px 0;
  font-size: 16px;
  list-style: none;
}

.pricing-table .content-inner {
  padding: 40px 0;
  padding-top: 55px;
  border-bottom: 1px solid #e8e8e8;
}

@media (max-width: 991.98px) {
  .pricing-table .content-inner {
    padding: 30px 0 20px;
  }
}

.pricing-table .plan-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 2.86;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.pricing-table .price-value {
  display: inline-block;
  font-size: 60px;
  line-height: 50px;
  font-weight: 300;
  color: #ff8257;
}

@media (max-width: 991.98px) {
  .pricing-table .price-value {
    font-size: 40px;
    line-height: 30px;
  }
}

.pricing-table span.interval {
  display: block;
  font-size: 20px;
}

.pricing-table .plan-signup {
  padding-bottom: 30px;
}

.pricing-table .plan-note {
  margin-bottom: 50px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2.4px;
  display: inline-block;
  text-transform: uppercase;
}

@media (max-width: 991.98px) {
  .pricing-table .plan-note {
    margin-bottom: 30px;
  }
}

.pricing-table.highlight-plan {
  -webkit-box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 9;
}

.pricing-table.border-style-1 {
  border-top: 5px solid #ee539b;
}

.pricing-table.border-style-1 .price-value {
  color: #ee539b;
}

.pricing-table.border-style-1 .btn-outline {
  background: #ee539b;
  border-color: #ee539b;
  color: var(--clr-white);
}

.pricing-table.border-style-1:hover .content-inner {
  border-color: #ee539b;
}

.pricing-table.border-style-2 {
  border-top: 5px solid #fddd6e;
}

.pricing-table.border-style-2 .price-value {
  color: #fddd6e;
}

.pricing-table.border-style-2:hover .content-inner {
  border-color: #fddd6e;
}

.pricing-table.border-style-2:hover .btn-outline {
  background: #fddd6e;
  border-color: #fddd6e;
  color: var(--clr-white);
}

.pricing-table.border-style-3 {
  border-top: 5px solid #9364d4;
}

.pricing-table.border-style-3 .price-value {
  color: #9364d4;
}

.pricing-table.border-style-3:hover .content-inner {
  border-color: #9364d4;
}

.pricing-table.border-style-3:hover .btn-outline {
  background: #9364d4;
  border-color: #9364d4;
  color: var(--clr-white);
}

.gsc-quotes-rotator .cbp-qtrotator {
  position: relative;
  width: 100%;
  z-index: 1;
}

.gsc-quotes-rotator .cbp-qtrotator .cbp-qtcontent {
  position: absolute;
  min-height: 100px;
  border-top: 3px solid var(--clr-light-gray);
  padding: 70px 0 0;
  top: 0;
  z-index: 0;
  opacity: 0;
  width: 100%;
}

.gsc-quotes-rotator .cbp-qtrotator .cbp-qtcontent .content-title {
  font-size: 42px;
  line-height: 1.4;
  margin-bottom: 15px;
  font-weight: 600;
  color: var(--clr-black);
  text-transform: capitalize;
}

.gsc-quotes-rotator .cbp-qtrotator .cbp-qtcontent p {
  padding-top: 19px;
  padding-bottom: 26px;
}

@media (max-width: 767.98px) {
  .gsc-quotes-rotator .cbp-qtrotator .cbp-qtcontent .content-title {
    font-size: 28px;
    line-height: 36px;
  }
}

.gsc-quotes-rotator .cbp-qtrotator .cbp-qtcontent .content-inner {
  font-size: 14px;
  line-height: 30px;
}

.gsc-quotes-rotator .no-js .cbp-qtrotator .cbp-qtcontent {
  border-bottom: none;
}

.gsc-quotes-rotator .cbp-qtrotator .cbp-qtcontent.cbp-qtcurrent,
.gsc-quotes-rotator .no-js .cbp-qtrotator .cbp-qtcontent {
  position: relative;
  z-index: 100;
  pointer-events: auto;
  opacity: 1;
}

.gsc-quotes-rotator .cbp-qtrotator .cbp-qtcontent:before,
.gsc-quotes-rotator .cbp-qtrotator .cbp-qtcontent:after {
  content: " ";
  display: table;
}

.gsc-quotes-rotator .cbp-qtrotator .cbp-qtcontent:after {
  clear: both;
}

.gsc-quotes-rotator .cbp-qtprogress {
  position: absolute;
  background: var(--clr-yellow);
  height: 3px;
  width: 0%;
  top: 0;
  z-index: 1000;
}

.gsc-quotes-rotator .cbp-qtrotator blockquote {
  margin: 0;
  padding: 0;
}

.gsc-quotes-rotator.text-light {
  color: var(--clr-white);
}

.gsc-quotes-rotator.text-light .cbp-qtrotator .cbp-qtcontent {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.gsc-quotes-rotator.text-light .cbp-qtrotator .cbp-qtcontent .content-inner {
  color: var(--clr-white);
}

.gsc-quotes-rotator.text-light .cbp-qtrotator .cbp-qtcontent .content-title {
  color: var(--clr-white);
}

.gva-job-box {
  padding: 30px;
  border: 1px solid #eaeaea;
  background: var(--clr-white);
  margin-bottom: 25px;
  display: table;
  width: 100%;
}

.gva-job-box .logo-inner {
  width: 60px;
  text-align: center;
  display: table-cell;
}

.gva-job-box .content-inner {
  display: table-cell;
  padding-left: 20px;
}

.gva-job-box .content-inner .job-type {
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 600;
  display: inline-block;
  color: #ff8257;
}

.gva-job-box .content-inner .box-title {
  margin-bottom: 10px;
}

.gva-job-box .content-inner .box-title .title {
  font-size: 18px;
  font-weight: 600;
  color: #2e3d62;
  text-transform: capitalize;
}

.gva-job-box .content-inner .information ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.gva-job-box .content-inner .information ul li {
  position: relative;
  font-size: 14px;
}

.gva-job-box .content-inner .information ul li i {
  color: #ff8257;
  width: 20px;
  text-align: left;
  font-size: 14px;
}

.gsc-our-gallery {
  margin-bottom: 30px;
}

.gsc-our-gallery .item .box-content {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 35px 30px;
  background: #ff8257;
  z-index: 9;
  max-width: 55%;
}

.gsc-our-gallery .item .box-content .title {
  color: var(--clr-white);
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

@media (max-width: 575.98px) {
  .gsc-our-gallery .item .box-content .title {
    font-size: 18px;
    line-height: 24px;
  }
  .cet-team-container .container {
    max-width: 340px;
  }
  .cet-team-container .gsc-column {
    padding-left: 10px;
    padding-right: 10px;
  }
  .gsc-team .team-name, .gsc-team .team-position {
    font-size: 12px;
    line-height: 16px;
  }
}

.gsc-our-gallery .item .box-content .title:after {
  content: '';
  width: 50px;
  height: 2px;
  background: #9364d4;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.gsc-our-gallery .item .box-content .desc {
  color: var(--clr-white);
}

@media (max-width: 575.98px) {
  .gsc-our-gallery .item .box-content .desc {
    display: none;
  }
  #footer {
    border-image-source: linear-gradient(117.03deg, #210036 11.9%, #320148 34.01%, #22004C 83.11%, #000000 96.39%);
  }
}

.gsc-our-gallery .owl-nav .owl-prev {
  bottom: 15px;
  left: 15px;
}

.gsc-our-gallery .owl-nav .owl-next {
  bottom: 15px;
  left: 60px;
  right: auto;
}

.gsc-box-hover {
  background: var(--clr-white);
  padding: 50px;
  border: 2px solid #F3F5FA;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

@media (max-width: 1199.98px) {
  .gsc-box-hover {
    padding: 30px;
  }
}

@media (max-width: 991.98px) {
  .gsc-box-hover {
    min-height: 100px !important;
  }
}

.gsc-box-hover .overlay-link {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;
}

.gsc-box-hover .content-frontend {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  padding: 50px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

@media (max-width: 1199.98px) {
  .gsc-box-hover .content-frontend {
    padding: 30px;
  }
}

@media (max-width: 991.98px) {
  .gsc-box-hover .content-frontend {
    opacity: 0;
    top: -250px;
  }
}

.gsc-box-hover .content-frontend .icon {
  margin-bottom: 20px;
}

.gsc-box-hover .content-frontend .icon i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #ff8257;
  color: var(--clr-white);
  border-radius: 50%;
}

.gsc-box-hover .content-frontend .title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.gsc-box-hover .content-backend {
  opacity: 0;
  position: relative;
  top: 250px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

@media (max-width: 991.98px) {
  .gsc-box-hover .content-backend {
    opacity: 1;
    top: 0;
  }
}

.gsc-box-hover .content-backend .title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 20px;
}

.gsc-box-hover:hover .content-frontend, .gsc-box-hover.highlight .content-frontend {
  opacity: 0;
  top: -250px;
}

.gsc-box-hover:hover .content-backend, .gsc-box-hover.highlight .content-backend {
  opacity: 1;
  top: 0;
}

.gsc-text-rotate .rotate-text {
  max-width: 900px;
}

.gsc-text-rotate .rotate-text .primary-text {
  font-size: 38px;
  font-weight: 600;
  color: #2e3d62;
  text-transform: capitalize;
}

.gsc-text-rotate .rotate-text .second-text {
  max-width: 690px;
  margin: 6px auto 0;
}

.gsc-text-rotate .rotate-text .link {
  margin-top: 20px;
}

.gsc-text-rotate.align-center .rotate-text {
  margin: 0 auto;
  text-align: center;
}

.gsc-text-rotate.align-left .rotate-text {
  float: left;
  text-align: left;
}

.gsc-text-rotate.align-left .primary-text {
  text-align: left;
}

.gsc-text-rotate.align-left .second-text {
  text-align: left;
}

.gsc-text-rotate.align-left .link {
  text-align: left;
}

.gsc-text-rotate.text-light .rotate-text .primary-text {
  color: var(--clr-white);
}

.gsc-text-rotate.text-light .rotate-text .second-text {
  color: var(--clr-white);
}

.gsc-heading {
  padding: 0 0 65px;
  display: block;
  width: 100%;
}

.gsc-heading:before, .gsc-heading:after {
  content: " ";
  display: table;
}

.gsc-heading:after {
  clear: both;
}

@media (max-width: 991.98px) {
  .gsc-heading {
    padding: 0 0 40px;
  }
}

.gsc-heading .title {
  position: relative;
  margin: 0 auto;
  max-width: 950px;

}

@media (max-width: 991.98px) {

  h1, .h1 {
    font-size: 2.5rem;
    line-height: 50px;
  }
  h2, .h2 {
    font-size: 1.75rem;
    line-height: 38px;
  }
  h3, .h3 {
    font-size: 1.5rem;
    line-height: 34px;
  }
  .cet-purple-grd-bg h2 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .cet-purple-grd-bg .bb-inner {
    padding-top: 46px;
    padding-bottom: 33px;
  }

  .cet-purple-grd-bg .bb-inner p {
    font-size: 1rem;
    line-height: 26px;
  }

  .cet-purple-grd-bg .title-desc p {
    margin-bottom: 0;
    padding-right: 50px;
    padding-left: 50px;
  }
}

.gsc-heading .sub-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  padding-bottom: 5px;
}

.gsc-heading .title-desc {
  clear: both;
  max-width: 950px;
  margin: 0 auto 0;
  margin-top: 20px;
  font-size: 18px;
  line-height: 36px;
}

.gsc-heading .title-desc p {
  margin-bottom: 0;
}

.gsc-heading.align-center {
  text-align: center;
}

.gsc-heading.align-left {
  text-align: left;
}

.gsc-heading.align-left .heading-line {
  float: left;
  clear: both;
}

.gsc-heading.align-left .title-desc {
  float: left;
}

.gsc-heading.align-left .title-icon {
  padding-left: 50px;
}

.gsc-heading.align-right .heading-line {
  float: right;
  clear: both;
}

.gsc-heading.align-right {
  text-align: right;
}

.gsc-heading.align-right .title-desc {
  float: right;
}

.gsc-heading.style-1 {
  padding-bottom: 35px;
}

.gsc-heading.style-1 .title {
  position: relative;
  padding-bottom: 0;
  margin-top: 10px;
}

.gsc-heading.style-2 {
  padding-bottom: 0;
}

.gsc-heading.style-2 .title {
  margin-top: 0;
}

.gsc-heading.style-3, .gsc-heading.style-4 {
  padding-bottom: 0;
}

.gsc-heading.style-3 .title, .gsc-heading.style-4 .title {
  margin-top: 0;
}

.gsc-heading.style-3 .column-content p {
  margin-top: 35px;
  margin-bottom: 0;
}

.gsc-heading.style-4 .title-desc p {
  padding-bottom: 10px;
  padding-right: 60px;
}

.gsc-heading.text-light .title-sub {
  color: var(--clr-white);
}

.gsc-heading.text-light .title {
  color: var(--clr-white);
}

.gsc-heading.text-light .sub-title span {
  color: rgba(255, 255, 255, 0.9);
}

.gsc-heading.text-light.align-left-2 .heading-top:after {
  background: var(--clr-white);
}

.gsc-heading.text-light .title-desc {
  color: #F3F5FA;
}

.gsc-chart {
  text-align: center;
}

.gsc-chart .easyPieChart {
  font-size: 24px;
  font-weight: 400;
  position: relative;
  text-align: center;
  display: inline-block;
}

.gsc-chart .easyPieChart:last-child {
  margin-right: 0;
}

.gsc-chart .easyPieChart span {
  line-height: 20px;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  color: #2e3d62;
}

.gsc-chart .easyPieChart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.gsc-chart .content {
  text-align: center;
}

.gsc-chart .content > div {
  display: block;
}

.gsc-chart .content .icon {
  font-size: 25px;
  margin-top: 5px;
}

.gsc-chart .content .title {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  margin-bottom: 10px;
  margin-top: 20px;
  color: #2e3d62;
}

.gsc-map {
  position: relative;
}

.gsc-map .content-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 9;
  max-width: 960px;
  width: 100%;
  padding: 50px 30px 30px;
  background: var(--clr-white);
  left: 50%;
  margin-left: -480px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -webkit-box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
}

@media (max-width: 980px) {
  .gsc-map .content-inner {
    position: static;
    margin-left: 0;
    padding: 20px;
  }
}

.gsc-map .content-inner.close-content {
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
  top: 120px;
}

.gsc-map .content-inner.close-content .link-open-content {
  display: block;
}

.gsc-map .content-inner .content-inner-inner {
  max-width: 780px;
  margin: 0 auto;
}

.gsc-map .content-inner .content-inner-inner .title {
  font-size: 42px;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  font-weight: 600;
}

@media (max-width: 991.98px) {
  .gsc-map .content-inner .content-inner-inner .title {
    font-size: 30px;
    margin-top: 20px;
  }
}

.gsc-map .content-inner .link-close-content {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1;
  line-height: 1;
}

@media (max-width: 980px) {
  .gsc-map .content-inner .link-close-content {
    display: none;
  }
}

.gsc-map .link-open-content {
  width: 60px;
  height: auto;
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -25px;
  z-index: 1;
  text-align: center;
  border-radius: 6px 6px 0 0;
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  -ms-border-radius: 6px 6px 0 0;
  -o-border-radius: 6px 6px 0 0;
  overflow: hidden;
}

@media (max-width: 980px) {
  .gsc-map .link-open-content {
    display: none !important;
  }
}

.gsc-map .link-open-content a {
  display: block;
  color: var(--clr-white);
  line-height: 36px;
  background: #ff8257;
  font-size: 16px;
}

.gsc-map .link-open-content a:hover {
  background: #ff490b;
  color: var(--clr-white);
}

.gsc-map .info {
  margin-bottom: 10px;
  padding-left: 28px;
  position: relative;
}

.gsc-map .info span.icon {
  font-size: 18px;
  top: 5px;
  left: 0;
  position: absolute;
  z-index: 1;
  color: #2e3d62;
}

.gsc-map .phone {
  color: #ff8257;
}

.gsc-map .social-inline {
  float: left;
  width: 100%;
  text-align: left;
  margin-top: 0;
}

.gsc-map .social-inline > a {
  margin: 0 10px 0 0;
  font-size: 16px;
}

.gsc-quote {
  position: relative;
  padding-top: 125px;
}

.gsc-quote .content {
  line-height: 28px;
}

.gsc-quote .signature {
  margin-top: 60px;
}

.gsc-quote .position {
  margin-top: 25px;
}

.gsc-quote-text {
  position: relative;
  padding: 30px 30px 30px 70px;
  margin-bottom: 35px;
}

.gsc-quote-text .icon {
  position: absolute;
  left: 20px;
  top: 30px;
  z-index: 1;
  font-size: 20px;
  color: #ff8257;
}

.gsc-quote-text .content {
  line-height: 30px;
  font-size: 16px;
  color: #999999;
}

.gsc-quote-text.has-border {
  border: 2px solid #eaeaea;
}

.gsc-box-color {
  position: relative;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  margin-bottom: 35px;
  background: #ff8257;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.gsc-box-color .box-content {
  text-align: center;
}

.gsc-box-color .box-content .content-inner {
  padding: 15px 30px 15px;
}

@media (max-width: 767.98px) {
  .gsc-box-color .box-content .content-inner {
    padding: 15px;
  }
  .gsc-heading.style-4 .title-desc p {
    padding-bottom: 10px;
    padding-right: 0;
  }
}

.gsc-box-color .box-content .box-title {
  font-size: 16px;
  line-height: 30px;
  text-transform: none;
  font-weight: 400;
  position: relative;
  margin-bottom: 10px;
}

.gsc-box-color .box-content .icon {
  font-size: 24px;
  margin-left: 10px;
}

.gsc-box-color .box-content .content {
  font-size: 16px;
  line-height: 25px;
}

.gsc-box-color .box-content .action {
  margin: 15px 0 0;
}

.gsc-box-color .box-content .link {
  display: inline-block;
  font-weight: 700;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  font-size: 12px;
  z-index: 9;
  padding: 6px 20px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  color: var(--clr-white);
}

.gsc-box-color .box-content .link .text {
  position: relative;
  z-index: 9;
}

.gsc-box-color .box-content .link .background {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.gsc-box-color .box-content .link:hover .background {
  background: rgba(0, 0, 0, 0.5);
}

.gsc-box-color.text-white .box-title, .gsc-box-color.text-white .content, .gsc-box-color.text-white .link {
  color: var(--clr-white);
}

.gsc-tabs .tabs_wrapper.tabs_horizontal .nav-tabs {
  padding: 15px;
  background: var(--clr-white);
  border: 1px solid #eaeaea;
  border-bottom: 0;
}

.gsc-tabs .tabs_wrapper.tabs_horizontal .nav-tabs > li a {
  display: block;
}

.gsc-tabs .tabs_wrapper.tabs_horizontal .tab-content {
  border: 1px solid #eaeaea;
}

.gsc-tabs .tabs_wrapper.tabs_horizontal .tab-content .tab-pane {
  background: var(--clr-white);
  padding: 30px;
}

.gsc-tabs .tabs_wrapper.tabs_vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.gsc-tabs .tabs_wrapper.tabs_vertical .nav-tabs {
  width: 25%;
  float: left;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 50%;
  background: var(--clr-white);
  border: 1px solid #eaeaea;
  border-right: 0;
}

.gsc-tabs .tabs_wrapper.tabs_vertical .nav-tabs > li {
  display: block;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  padding-top: 24px;
}

.gsc-tabs .tabs_wrapper.tabs_vertical .nav-tabs > li a {
  width: 100%;
  display: block;
}

.gsc-tabs .tabs_wrapper.tabs_vertical .nav-tabs > li:last-child {
  margin-bottom: 0;
  border: none;
}

.gsc-tabs .tabs_wrapper.tabs_vertical .tab-content {
  width: 75%;
  float: left;
  width: 75%;
  float: left;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.gsc-tabs .tabs_wrapper.tabs_vertical .tab-content .tab-pane {
  background: var(--clr-white);
  padding: 30px;
  border: 1px solid #eaeaea;
}

.gsc-button {
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  border: 2px solid transparent;
  margin-bottom: 20px;
  display: inline-block;
  clear: both;
  color: var(--clr-black) !important;
  cursor: pointer;
  font-family: var(--ff-primary) !important;
}

.gsc-button.radius-2x {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.gsc-button.radius-5x {
  border-radius: 60px;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
}

.gsc-button.mini {
  font-size: 10px;
  padding: 3px 10px;
}
.gsc-button.small {
  background: var(--clr-yellow);
  font-size: 12px;
  border-radius: 12px;
  padding: 5px 20px;
  border: 2px solid var(--clr-yellow) !important;
  font-weight: 600;
  min-width: 110px;
  text-align: center;
  letter-spacing: 0px;
  text-transform: none;
  text-decoration: none;
  line-height: 16px;
  min-height: 30px;
}
.gsc-button.medium {
  background: var(--clr-yellow);
  font-size: 14px;
  border-radius: 12px;
  padding: 6px 20px;
  border: 2px solid var(--clr-yellow) !important;
  font-weight: 600;
  min-width: 128px;
  text-align: center;
  letter-spacing: 0px;
  text-transform: none;
  text-decoration: none;
  line-height: 20px;
  min-height: 36px
}
.gsc-button.large {
  background: var(--clr-yellow);
  font-size: 16px;
  border-radius: 12px;
  padding: 7px 20px;
  border: 2px solid var(--clr-yellow) !important;
  font-weight: 600;
  line-height: 22px;
  min-width: 142px;
  text-align: center;
  letter-spacing: 0px;
  text-transform: none;
  text-decoration: none;
  min-height: 40px;
}
.gsc-button.small:hover, .gsc-button.medium:hover, .gsc-button.large:hover {
  background-color: transparent;
  border-color: var(--clr-yellow) !important;
}
.cet-hero-container .gsc-button.small:hover, .cet-hero-container .gsc-button.medium:hover, .cet-hero-container .gsc-button.large:hover {
  color: var(--clr-white) !important;
}
.gsc-button.cet-inactive {
  opacity: 0.45;
  cursor: default;
  pointer-events: none;
}
.gsc-button.cet-secondary {
border-color: var(--clr-black) !important;
background: transparent;
}
.cet-hero-container .gsc-button.cet-secondary {
  color: var(--clr-white) !important;
}
.gsc-button.cet-tertiary {
  border-color: var(--clr-yellow) !important;
  background: transparent;
}
.gsc-button.cet-tertiary:hover {
  border-color: var(--clr-purple) !important;
  background: transparent;
}
.cet-hero-container .gsc-button.cet-tertiary {
  color: var(--clr-white) !important;
}
.gsc-button.cet-tertiary.cet-inactive {
  opacity: 0.45;
  border-color: var(--clr-black) !important;
  cursor: default;
  pointer-events: none;
}
.gsc-button.cet-quarternary {
  border-color: var(--clr-yellow) !important;
  background: transparent;
  color: var(--clr-white) !important;
}
.gsc-button.cet-quarternary:hover {
  border-color: var(--clr-black) !important;
  background: transparent;
}
.gsc-button.cet-quarternary.cet-inactive {
  opacity: 0.45;
  border-color: var(--clr-black) !important;
  cursor: default;
  pointer-events: none;
  background: transparent;
}
.gsc-button.cet-btn-error {
  border-color: #fc1d1d !important;
}
.gsc-button.extra-large {
  font-size: 14px;
  padding: 20px 60px;
}

.gsc-our-partners {
  margin-bottom: 45px;
}

.gsc-our-partners:before, .gsc-our-partners:after {
  content: " ";
  display: table;
}

.gsc-our-partners:after {
  clear: both;
}

.gsc-our-partners .image {
  width: 35%;
  float: left;
}

@media (max-width: 500px) {
  .gsc-our-partners .image {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}

.gsc-our-partners .content-inner {
  width: 65%;
  float: left;
  padding-left: 30px;
}

@media (max-width: 500px) {
  .gsc-our-partners .content-inner {
    width: 100%;
    padding-left: 0;
  }
}

.gsc-our-partners .content-inner .title {
  font-size: 18px;
  color: #2e3d62;
  font-weight: 600;
}

.gsc-our-partners .content-inner .info {
  font-size: 14px;
  padding: 5px 0 12px;
  font-weight: 500;
  color: #2e3d62;
}

.gsc-our-partners .content-inner .info .category {
  color: #ff8257;
}

.gsc-image {
  margin-bottom: 5px;
}

.cet-quote-purple-grd-bg .bb-inner, .cet-purple-grd-bg-container .bb-inner {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.cet-quote-purple-grd-bg .bb-inner {
  margin-bottom: 0;
}

.gsc-image-content.skin-v1, .gsc-image-content.skin-v4, .gsc-image-content.skin-v8 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.gsc-image-content.skin-v1 .box-image, .gsc-image-content.skin-v4 .box-image, .gsc-image-content.skin-v5 .box-image, .gsc-image-content.skin-v6 .box-image {
  max-width: 456px;
  width: 100%;
  position: relative;
}

.gsc-image-content.skin-v5, .gsc-image-content.skin-v6 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.gsc-image-content.skin-v1 .box-content {
  width: 100%;
  color: var(--clr-white);
  padding-left: 30px;
}

.gsc-image-content.skin-v8 .box-content {
  max-width: 915px;
  margin: 0 auto;
  width: 100%;
  color: var(--clr-black);
}

.gsc-image-content.skin-v4 .box-content, .gsc-image-content.skin-v5 .box-content, .gsc-image-content.skin-v6 .box-content {
  width: 100%;
  color: var(--clr-black);
  padding-left: 30px;
}

.gsc-image-content.skin-v5 .box-content .title {
  padding-bottom: 25px;
  font-weight: 500;
}

.gsc-image-content.skin-v6 .box-content .title {
  font-weight: 500;
  font-family: var(--ff-primary) !important;
}

.gsc-image-content.skin-v1 .box-content p, .gsc-image-content.skin-v4 .box-content p {
  font-size: 30px;
  font-weight: 400;
  line-height: 54px;
  font-family: var(--ff-primary);
  margin-bottom: 0;
}

.gsc-image-content.skin-v8 .box-content p {
  font-size: 30px;
  font-weight: 500;
  line-height: 54px;
  font-family: var(--ff-primary);
  margin-bottom: 0;
}

.gsc-image-content.skin-v1 .box-content span.author, .gsc-image-content.skin-v4 .box-content span.author, .gsc-image-content.skin-v5 .box-content span.author, .gsc-image-content.skin-v6 .box-content span.author, .gsc-image-content.skin-v8 .box-content span.author {
  font-size: 16px;
  font-weight: 600;
  line-height: 27px !important;
  display: block;
  padding-top: 35px;
  font-family: var(--ff-primary) !important;
}
.gsc-image-content.skin-v4 .box-content span.author, .gsc-image-content.skin-v5 .box-content span.author, .gsc-image-content.skin-v6 .box-content span.author {
  color: var(--clr-purple);
}
.gsc-image-content.skin-v8 .box-content span.author, .gsc-image-content.skin-v8 .box-content span.title {
  color: var(--clr-white);
}
.gsc-image-content.skin-v4 .box-content span.title, .gsc-image-content.skin-v5 .box-content span.title, .gsc-image-content.skin-v6 .box-content span.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 27px !important;
  display: block;
  font-family: var(--ff-primary) !important;
  color: var(--clr-dark-gray);
}

.gsc-image-content .box-content a.social img {
  padding-right: 20px;
  padding-top: 30px;
}

.gsc-image-content.skin-v1 .box-image img, .gsc-image-content.skin-v4 .box-image img, .gsc-image-content.skin-v5 .box-image img, .gsc-image-content.skin-v6 .box-image img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.gsc-image-content.skin-v2 {
  position: relative;
  margin-bottom: 20px;
  text-align: left;
  max-width: 420px;
  margin-right: auto;
  margin-left: auto;
}

.gsc-image-content.skin-v2 .image {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
}

.gsc-image-content.skin-v2 .box-content {
  background: var(--clr-white);
  padding: 30px 0 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.gsc-image-content.skin-v2 .box-content .title {
  margin: 0 0 15px;
  font-size: 26px;
  line-height: 38px;
}

.gsc-image-content.skin-v2 .box-content .desc {
  font-size: 16px;
  margin-top: 30px;
}

.gsc-image-content.skin-v2 .box-content .box-action {
  margin-top: 26px;
}

.gsc-image-content.skin-v2 .box-content .box-action a {
  font-size: 16px;
  font-weight: 500;
  color: #666e82;
}

.gsc-image-content.skin-v2 .box-content .box-action a:hover {
  color: #ff8257;
  text-decoration: underline;
}

.gsc-image-content.skin-v3 {
  max-width: 600px;
  margin: 0 auto 20px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}

.gsc-image-content.skin-v3 .image {
  position: relative;
  text-align: center;
}

.gsc-image-content.skin-v3 .box-content {
  padding: 30px;
  background: #ff8257;
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 11;
  border-radius: 6px;
  max-width: 250px;
  width: 100%;
}

.gsc-image-content.skin-v5 .box-content p, .gsc-image-content.skin-v6 .box-content p {
  font-size: 26px;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .gsc-image-content.skin-v1, .gsc-image-content.skin-v4, .gsc-image-content.skin-v5, .gsc-image-content.skin-v6, .gsc-image-content.skin-v8 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .gsc-image-content.skin-v3 .box-content {
    position: relative;
    max-width: 100%;
    margin-top: 10px;
  }
  .gsc-image-content.skin-v8 .box-content p {
    font-size: 26px;
    font-weight: 400;
    line-height: 43px;
  }
  .gsc-image-content.skin-v8 .box-content span.author, .gsc-image-content.skin-v8 .box-content span.title {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px !important;
    display: block;
    padding-top: 35px;
  }
}

.gsc-image-content.skin-v3 .box-content .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--clr-white);
}

.gsc-image-content.skin-v3 .box-content .title a {
  color: var(--clr-white);
}

.gsc-image-content.skin-v3 .box-content .title a:hover {
  text-decoration: underline;
}
.gsc-image-content.skin-default {
  margin: 0 auto;
  position: relative;
  display: flex;
  align-content: center;
  align-items: flex-start;
}
.gsc-image-content.skin-default a {
  margin-top: 27px;
  margin-bottom: 0;
}
.gsc-image-content.skin-default .image {
  position: relative;
  text-align: center;
}
.gsc-image-content.skin-default .box-image img {
  border-radius: 9px;
}
.gsc-image-content.skin-default .box-content {
  padding: 30px 45px 30px 50px;
  background: var(--clr-white);
  position: absolute;
  right: 0;
  z-index: 11;
  max-width: 775px;
  border: 1px solid var(--clr-dark-gray);
  margin-top: 60px;
  border-radius: 9px;
}
.gsc-image-content.skin-default .box-content .desc p {
  font-size: 26px;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 0;
}
.gsc-image-content.skin-default .box-content .desc p span.author {
  font-size: 16px;
  font-weight: 600;
  line-height: 27px !important;
  display: block;
  padding-top: 10px;
  color: var(--clr-purple);
}
.gsc-image-content.skin-default .box-content .desc p span.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px !important;
  display: block;
  color: var(--clr-dark-gray);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .gsc-image-content.skin-default .box-content {
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .gsc-image-content.skin-default .box-content {
    padding: 30px 35px 30px 40px;
    max-width: 460px;
    margin-top: 30px;
  }
  .gsc-image-content.skin-default .box-image img {
    max-width: 320px;
  }
  .gsc-image-content.skin-default .box-content .desc p {
    font-size: 19px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 0;
  }
  .gsc-image-content.skin-default .box-content .desc p span.title {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px !important;
  }
}
@media (max-width: 767.98px) {
  .gsc-image-content.skin-default {
    text-align: left;
    max-width: 445px;
    flex-direction: column;
  }
  .gsc-image-content.skin-default .box-content {
    padding: 30px 50px;
    border-radius: 9px;
    margin-right: 20px;
    margin-left: 20px;
    max-width: 405px;
    top: -60px;
    position: relative;
    margin-top: 0;
  }
  .gsc-image-content.skin-default .box-content .desc p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 0;
  }
  .gsc-image-content.skin-default .box-content .desc p span.title {
    font-size: 12px;
    line-height: 16px !important;
  }
}
@media (min-width: 399px) and (max-width: 460px) {
  .gsc-image-content.skin-default {
    min-height: 520px;
  }
  .gsc-image-content.skin-default .box-content {
    padding: 30px 30px;
  }
  .gsc-image-content.skin-default .box-content .desc p span.author {
    padding-top: 30px;
  }
}
@media (max-width: 398.98px) {
  .gsc-image-content.skin-default {
    min-height: 570px;
  }
  .gsc-image-content.skin-default .box-content {
    padding: 45px 24px;
    margin: 0 20px;
  }
  .gsc-image-content.skin-default .box-content .desc p span.author {
    padding-top: 30px;
  }
}

.gsc-service-carousel {
  text-align: center;
}

.gsc-service-carousel .item .content-inner {
  border: 1px solid #eaeaea;
  background: var(--clr-white);
  text-align: center;
  min-height: 170px;
  padding: 20px 30px 0;
  margin-bottom: 30px;
  margin-top: 10px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.gsc-service-carousel .item .content-inner .icon i {
  font-size: 38px;
  color: #ff8257;
  line-height: 60px;
}

.gsc-service-carousel .item .content-inner .title {
  margin-top: 10px;
}

.gsc-service-carousel .item .content-inner .title a {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 20px;
  display: inline-block;
}

.gsc-service-carousel .item .content-inner:hover, .gsc-service-carousel .item .content-inner:focus, .gsc-service-carousel .item .content-inner:active {
  -webkit-box-shadow: 0px 6px 21px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 21px 3px rgba(0, 0, 0, 0.1);
  background: var(--clr-white);
  border-color: transparent;
}

.gsc-service-carousel .read-more {
  margin-top: 30px;
}

.gsc-box-download {
  background: #ff8257;
  color: var(--clr-white);
  padding: 30px;
  margin: 20px 0;
}

.gsc-box-download .info .title {
  font-size: 18px;
}

.gsc-box-download .info .desc {
  font-size: 12px;
  line-height: 24px;
}

.gsc-box-download .box-files a {
  display: inline-block;
  margin-bottom: 10px;
  text-align: center;
  color: #2e3d62;
  font-weight: 700;
  font-size: 13px;
  background: var(--clr-white);
  padding: 15px;
  width: 100%;
}

.gsc-box-download.horizontal:before, .gsc-box-download.horizontal:after {
  content: " ";
  display: table;
}

.gsc-box-download.horizontal:after {
  clear: both;
}

.gsc-box-download.horizontal .info {
  width: 70%;
  float: left;
}

@media (max-width: 575.98px) {
  .gsc-box-download.horizontal .info {
    width: 100%;
    margin-bottom: 15px;
  }
}

.gsc-box-download.horizontal .box-files {
  width: 30%;
  float: left;
}

@media (max-width: 575.98px) {
  .gsc-box-download.horizontal .box-files {
    width: 100%;
  }
}

.gsc-socials.style-1:before, .gsc-socials.style-1:after {
  content: " ";
  display: table;
}

.gsc-socials.style-1:after {
  clear: both;
}

.gsc-socials.style-1 a {
  width: auto;
  color: #191b1c;
  background: var(--clr-white);
  font-size: 18px;
  line-height: 1;
  text-align: center;
  display: inline-block;
  margin: 0 10px 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.gsc-socials.style-1 a:hover, .gsc-socials.style-1 a:active, .gsc-socials.style-1 a:focus {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.gsc-socials.style-2 {
  margin-bottom: 30px;
}

.gsc-socials.style-2 a {
  color: #666e82;
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  margin-right: 12px;
  font-size: 14px;
}

.gsc-socials.style-2 a:last-child {
  margin-right: 0;
}

.gsc-socials.style-2 a:hover, .gsc-socials.style-2 a:focus, .gsc-socials.style-2 a:active {
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.gsc-socials.style-2 a i {
  color: var(--clr-white);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
}

.gsc-socials.style-2 a i.fa-facebook-square, .gsc-socials.style-2 a i.fa-facebook, .gsc-socials.style-2 a i.fa-facebook-f {
  background: #3b5998;
}

.gsc-socials.style-2 a i.fa-twitter, .gsc-socials.style-2 a i.fa-twitter-square {
  background: #4099ff;
}

.gsc-socials.style-2 a i.fa-skype {
  background: #12a5f4;
}

.gsc-socials.style-2 a i.fa-dribbble {
  background: #ea4c89;
}

.gsc-socials.style-2 a i.fa-linkedin-square, .gsc-socials.style-2 a i.fa-linkedin {
  background: #0e76a8;
}

.gsc-socials.style-2 a i.fa-apple {
  background: #f2f2f2;
}

.gsc-socials.style-2 a i[class*="fa-pinterest"] {
  background: #c92228;
}

.gsc-socials.style-2 a i.fa-google-plus-square, .gsc-socials.style-2 a i.fa-google-plus {
  background: #d34836;
}

.gsc-socials.style-2 a i.fa-youtube-square, .gsc-socials.style-2 a i.fa-youtube {
  background: #c4302b;
}

.gsc-socials.style-2 a i.fa-vimeo-square, .gsc-socials.style-2 a i.fa-vimeo {
  background: #4ebbff;
}

.gsc-socials.style-2 a i.fa-tumblr-square, .gsc-socials.style-2 a i.fa-tumblr {
  background: #35465c;
}

.gsc-socials.style-2 a i.fa-xing-square, .gsc-socials.style-2 a i.fa-xing {
  background: #126567;
}

.gsc-socials.style-2 a i.fa-instagram {
  background: #e4a342;
}

.gsc-socials.style-2 a i.fa-behance {
  background: #0075FF;
}

.gsc-instagram .title {
  color: #242527;
  font-size: 35px;
  text-align: center;
  margin-bottom: 60px;
  font-weight: 300;
}

.gsc-instagram .title a {
  color: #ff8257;
  border-bottom: 1px solid #ff8257;
}

.gsc-instagram .title a:hover, .gsc-instagram .title a:focus, .gsc-instagram .title a:active {
  color: #2e3d62;
}

.gsc-instagram.style-2 .owl-carousel {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.gsc-instagram.style-2 .owl-carousel .owl-item > .item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.gsc-progress-box {
  border: 1px solid #eaeaea;
  padding: 60px 60px 50px;
  position: relative;
  text-align: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

@media (max-width: 991.98px) {
  .gsc-progress-box {
    padding: 35px 30px 35px;
    margin-bottom: 30px;
  }
  .gsc-heading .sub-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
  }
  .gsc-image-content.skin-v8 .box-content {
    padding: 20px 0;
  }
}

.gsc-progress-box .icon-box {
  width: 40px;
  height: 40px;
  background: #F3F5FA;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  line-height: 40px;
  text-align: center;
  color: #ff8257;
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -20px;
  z-index: 11;
  font-size: 12px;
}

.gsc-progress-box .title {
  font-size: 22px;
  margin: 30px 0;
}

.gsc-progress-box .heading-box {
  line-height: 1;
}

.gsc-progress-box .step-number {
  font-size: 100px;
  color: #ff8257;
  font-weight: 300;
  display: inline-block;
  line-height: 76px;
}

@media (max-width: 991.98px) {
  .gsc-progress-box .step-number {
    font-size: 60px;
    line-height: 46px;
  }
}

.gsc-progress-box.border-style-1 {
  border-color: #9364d4;
}

.gsc-progress-box.border-style-1 .step-number, .gsc-progress-box.border-style-1 .icon-box {
  color: #9364d4;
}

.gsc-progress-box.border-style-1:hover {
  -webkit-box-shadow: 0px 10px 60px 0 rgba(46, 61, 98, 0.1);
  box-shadow: 0px 10px 60px 0 rgba(46, 61, 98, 0.1);
}

.gsc-progress-box.border-style-2 {
  border-color: #ef4059;
}

.gsc-progress-box.border-style-2 .step-number, .gsc-progress-box.border-style-2 .icon-box {
  color: #ef4059;
}

.gsc-progress-box.border-style-2:hover {
  -webkit-box-shadow: 0px 10px 60px 0 rgba(46, 61, 98, 0.1);
  box-shadow: 0px 10px 60px 0 rgba(46, 61, 98, 0.1);
}

.gsc-progress-box.border-style-3 {
  border-color: #5dd8d3;
}

.gsc-progress-box.border-style-3 .step-number, .gsc-progress-box.border-style-3 .icon-box {
  color: #5dd8d3;
}

.gsc-progress-box.border-style-3:hover {
  -webkit-box-shadow: 0px 10px 60px 0 rgba(46, 61, 98, 0.1);
  box-shadow: 0px 10px 60px 0 rgba(46, 61, 98, 0.1);
}

@media (max-width: 991.98px) {
  .gsc-images-parallax {
    margin-bottom: 30px;
  }
}

.gsc-images-parallax .shape-1 {
  width: 60px;
  height: 66px;
  border-radius: 4px;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

.gsc-images-parallax .shape-2 {
  width: 100px;
  height: 106px;
  border-radius: 4px;
  display: inline-block;
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.gsc-images-parallax .shape-3 {
  width: 100px;
  height: 106px;
  border-radius: 4px;
  display: inline-block;
  position: absolute;
  top: 30px;
  right: 30px;
}

.gsc-images-parallax .shape-4 {
  width: 60px;
  height: 66px;
  border-radius: 4px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 1200px) {
  .gsc-images-parallax .widget-content {
    padding-right: 60px;
  }
  .cet-home-logos-banner p {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .cet-home-logos-banner img {
    padding: 10px 15px;
  }
  .gsc-image-content.skin-default {
    margin-top: 40px;
    margin-bottom: 100px;
  }

  .standard-card.cet-min-height-465-mobile .widget {
    min-height: 465px !important;
  }
}

.gsc-images-parallax .box-images-top, .gsc-images-parallax .box-images-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.gsc-images-parallax .shape-right, .gsc-images-parallax .shape-left {
  width: 50%;
  position: relative;
}

.gsc-images-parallax .image-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

#gva-preloader {
  position: fixed;
  background: white;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

.cssload-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  margin: 0px auto;
  position: absolute;
  top: 50%;
  margin-top: -100px;
}

.cssload-container .wait-text {
  text-align: center;
  padding-bottom: 15px;
  color: #2e3d62;
}

.cssload-container .cssload-item {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 131px;
  height: 131px;
  background-color: var(--clr-white);
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-shadow: 0 0 21px 3px rgba(130, 130, 130, 0.26);
  -o-box-shadow: 0 0 21px 3px rgba(130, 130, 130, 0.26);
  -ms-box-shadow: 0 0 21px 3px rgba(130, 130, 130, 0.26);
  -webkit-box-shadow: 0 0 21px 3px rgba(130, 130, 130, 0.26);
  -moz-box-shadow: 0 0 21px 3px rgba(130, 130, 130, 0.26);
}

.cssload-container .cssload-moon {
  border-bottom: 26px solid #ff8257;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  animation: spin 1.45s ease infinite;
  -o-animation: spin 1.45s ease infinite;
  -ms-animation: spin 1.45s ease infinite;
  -webkit-animation: spin 1.45s ease infinite;
  -moz-animation: spin 1.45s ease infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.blur {
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
  -webkit-filter: url(#blur-filter);
  filter: url(#blur-filter);
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

.blur-svg {
  display: none;
}

.hover-in {
  -webkit-transition: .3s ease-out;
  transition: .3s ease-out;
}

.hover-out {
  -webkit-transition: .3s ease-in;
  transition: .3s ease-in;
}

@media (max-width: 991.98px) {
  .area-main-menu {
    min-height: 60px;
  }
  .gva-offcanvas-mobile {
    background: var(--clr-white);
    position: fixed !important;
    left: 0;
    top: 0;
    width: 100%;
    max-width: 350px;
    top: 0;
    bottom: 0;
    z-index: 999;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    webkit-overflow-scrolling: touch;
    -webkit-box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.2);
    padding-top: 0;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
  }
  .gva-offcanvas-mobile.show-view {
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
    -moz-transition: all 0.35s;
    -ms-transition: all 0.35s;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  .gva-offcanvas-mobile .after-offcanvas {
    display: block !important;
    visibility: visible !important;
  }
  .gva-offcanvas-mobile .after-offcanvas .block {
    float: left;
    width: 100%;
  }
  .gva-offcanvas-mobile .close-offcanvas {
    display: block !important;
    visibility: visible !important;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 15px 0;
    opacity: 1;
    filter: alpha(opacity=100);
    color: #111111;
  }
  .gva-offcanvas-mobile .close-offcanvas span {
    font-size: 24px;
  }
  .gva-offcanvas-mobile .close-offcanvas:hover {
    cursor: pointer;
    color: var(--clr-magenta);
  }
  .gva-offcanvas-mobile .block {
    border-bottom: 1px solid #eaeaea;
    padding: 20px;
  }
  .gva-offcanvas-mobile .block .block-content:before, .gva-offcanvas-mobile .block .block-content:after {
    content: " ";
    display: table;
  }
  .gva-offcanvas-mobile .block .block-content:after {
    clear: both;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu {
    padding: 10px 0 0;
    width: 100% !important;
    background: none !important;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu > li {
    list-style: none;
    display: block;
    clear: both;
    width: 100%;
    margin-bottom: 8px !important;
    padding-bottom: 8px !important;
    padding-top: 2px !important;
    border-bottom: 1px solid #eaeaea;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu > li:last-child {
    border-bottom: none;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu > li:after, .gva-offcanvas-mobile .gva-navigation .gva_menu > li:before {
    content: none;
    display: none;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu > li > a {
    display: block;
    width: 100%;
    line-height: 32px;
    font-size: 14px;
    padding: 0 !important;
    font-weight: 600;
    color: var(--clr-black) !important;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu > li > a:after, .gva-offcanvas-mobile .gva-navigation .gva_menu > li > a:before {
    content: none;
    display: none;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu > li > a:hover {
    color: #ff8257 !important;
    background: none !important;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu > li.menu-parent:hover .sub-menu {
    display: none;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu > li ul.menu.sub-menu {
    padding-left: 20px !important;
    margin-bottom: 10px;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu > li ul.menu.sub-menu .icaret {
    display: none;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu > li ul.menu.sub-menu li a {
    font-size: 14px;
    line-height: 20px;
    color: #666e82 !important;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu > li ul.menu.sub-menu li a:hover {
    color: #ff8257 !important;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu > li ul.menu.sub-menu ul.sub-menu {
    display: block !important;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu > li.megamenu .sub-menu > li {
    width: 100% !important;
    float: none !important;
    display: block;
    padding: 0;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu > li.megamenu .sub-menu > li > a {
    padding: 0;
    border: 0;
    margin: 0;
    font-size: 14px;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu > li.megamenu .sub-menu > li.block-image {
    display: none;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu li a {
    position: relative;
    display: block;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu li a:hover {
    color: #ff8257;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu li a .icaret {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    bottom: 0;
    right: -10px;
    font-size: 13px;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu li a .icaret.nav-minus:before {
    content: '\f077';
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu li a .icaret:before {
    position: absolute;
    text-transform: inherit;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu li ul {
    display: none;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu .sub-menu {
    -webkit-transition: none;
    transition: none;
    -moz-transition: none;
    -ms-transition: none;
    min-width: inherit;
    display: none;
    width: 100%;
    position: static;
    padding: 0 !important;
    visibility: visible;
    border: 0;
    background: none;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu .sub-menu:after, .gva-offcanvas-mobile .gva-navigation .gva_menu .sub-menu:before {
    content: none;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu .sub-menu li a {
    border-width: 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu .sub-menu li a:before, .gva-offcanvas-mobile .gva-navigation .gva_menu .sub-menu li a:after {
    content: none !important;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu .sub-menu li a:hover {
    text-indent: 0;
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu .sub-menu ul {
    display: none;
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .gva-offcanvas-mobile .gva-navigation .gva_menu .sub-menu .block-image {
    display: none;
  }
}

.menu-bar {
  height: 28px;
  width: 36px;
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 10px;
  margin-top: -10px;
}

.menu-bar span {
  display: block;
  width: 24px;
  height: 2px;
  background-color: #222222;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.menu-bar span.one {
  top: 6px;
}

.menu-bar span.two {
  top: 0;
  bottom: 0;
}

.menu-bar span.three {
  bottom: 6px;
}

.menu-bar.show-view span.two {
  opacity: 0;
  filter: alpha(opacity=0);
}

.menu-bar.show-view span.one, .menu-bar.show-view span.three {
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-bar.show-view span.three {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media (max-width: 480px) {
  .col-xs-6 {
    width: 100% !important;
  }
}

.team-block.team-v1 {
  max-width: 500px;
  margin: 0 auto 30px;
  text-align: center;
}

.team-block.team-v1 .team-image {
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  width: 280px;
  height: 280px;
  display: inline-block;
  overflow: hidden;
}

.team-block.team-v1 .team-image .socials-team {
  z-index: 9;
  width: auto;
  height: 30%;
  padding: 30px 0;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
  -moz-transition: all 0.15s;
  -ms-transition: all 0.15s;
  text-align: center;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  border-radius: 2px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 130, 87, 0.99)), to(rgba(255, 130, 87, 0.01)));
  background-image: linear-gradient(to top, rgba(255, 130, 87, 0.99), rgba(255, 130, 87, 0.01));
  opacity: 0;
  filter: alpha(opacity=0);
}

.team-block.team-v1 .team-image .socials-team a {
  font-size: 18px;
  color: var(--clr-white);
  display: inline-block;
}

.team-block.team-v1 .team-image .socials-team a + a {
  margin-left: 10px;
}

.team-block.team-v1 .team-image .socials-team a:hover, .team-block.team-v1 .team-image .socials-team a:focus, .team-block.team-v1 .team-image .socials-team a:active {
  color: #2e3d62;
}

.team-block.team-v1 .team-content {
  text-align: center;
  position: relative;
  top: 0;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  padding-top: 10px;
}

.team-block.team-v1 .team-content .team-name {
  font-weight: 500;
  color: #2e3d62;
  line-height: 22px;
  font-size: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.team-block.team-v1 .team-content .team-name a {
  color: #2e3d62;
}

.team-block.team-v1 .team-content .team-name a:hover {
  color: #ff8257;
}

.team-block.team-v1 .team-content .team-job {
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  text-transform: capitalize;
  margin-top: 2px;
}

.team-block.team-v1:hover .team-image {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.team-block.team-v1:hover .team-image .socials-team {
  opacity: 1;
  filter: alpha(opacity=100);
}

.team-block.team-v2 {
  padding: 30px;
  background: var(--clr-white);
  border: 1px solid #eaeaea;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  background: var(--clr-white);
  border-radius: 6px;
}

@media (max-width: 1199.98px) {
  .team-block.team-v2 {
    padding: 20px;
  }
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .cet-disclosure-container .bb-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.team-block.team-v2 .team-image {
  position: relative;
  background: transparent;
  width: 150px;
  height: 150px;
  display: inline-block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 1px solid #eaeaea;
  overflow: hidden;
}

.team-block.team-v2 .team-image .item-image {
  position: relative;
}

.team-block.team-v2 .team-image .item-image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 1;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.team-block.team-v2 .team-content {
  background: transparent;
  position: relative;
  text-align: center;
}

.team-block.team-v2 .team-content .socials-team {
  text-align: center;
  margin: 6px 0 8px;
}

.team-block.team-v2 .team-content .socials-team > a {
  font-size: 14px;
  color: #666e82;
  display: inline-block;
  margin: 0 6px;
}

.team-block.team-v2 .team-content .socials-team > a:hover {
  color: #ff8257;
}

.team-block.team-v2 .team-content .team-name {
  font-weight: 500;
  color: #2e3d62;
  line-height: 24px;
  font-size: 18px;
  position: relative;
}

.team-block.team-v2 .team-content .team-job {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
}

.team-block.team-v2 .team-skills {
  margin-top: 10px;
}

.team-block.team-v2 .team-skills .progress-label {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px !important;
}

.team-block.team-v2 .team-skills .progress {
  margin-bottom: 11px !important;
  height: 2px !important;
}

.team-block.team-v2 .team-skills .progress .progress-bar span {
  top: -4px !important;
}

.team-block.team-v2 .team-skills .progress .percentage {
  font-size: 11px !important;
  top: -22px;
}

.team-block.team-v2:hover .team-image .item-image:after {
  opacity: 1;
  filter: alpha(opacity=100);
}

.team-block.team-v2:hover .team-image .socials-team {
  opacity: 1;
  filter: alpha(opacity=100);
  top: 10px;
}

@media (max-width: 991.98px) {
  .team-block.team-v3 {
    max-width: 300px;
    margin: 0 auto;
  }
}

.team-block.team-v3 .team-block-inner {
  text-align: center;
}

.team-block.team-v3 .team-block-inner .team-image {
  position: relative;
  text-align: center;
  max-width: 220px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}

.team-block.team-v3 .team-block-inner .team-image .field--name-field-team-image a {
  display: block;
}

.team-block.team-v3 .team-block-inner .team-image .field--name-field-team-image a:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.36);
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.team-block.team-v3 .team-block-inner .team-content {
  padding: 10px 0 0;
}

.team-block.team-v3 .team-name {
  font-weight: 500;
  color: #2e3d62;
  line-height: 24px;
  font-size: 18px;
  position: relative;
}

.team-block.team-v3 .team-job {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
}

.team-block.team-v3 .team-socials {
  text-align: center;
}

.team-block.team-v3 .team-socials > a {
  font-size: 14px;
  color: #666e82;
  display: inline-block;
  margin: 0 6px;
}

.team-block.team-v3 .team-socials > a:hover {
  color: #ff8257;
}

.team-block.team-v3:hover .team-image .field--name-field-team-image a:after {
  opacity: 1;
  filter: alpha(opacity=100);
}

.team-block.team-v3:hover .team-image .socials {
  opacity: 1;
  filter: alpha(opacity=100);
}

.team-carousel .team-block {
  margin-bottom: 10px;
}

.owl-carousel .team-block {
  margin-bottom: 10px;
}

.team-list .team-block .team-image {
  width: 35%;
  float: left;
}

.team-list .team-block .team-content {
  padding-left: 30px;
  width: 65%;
  float: left;
}

.team-list .team-block .team-content .team-body {
  display: block !important;
  visibility: visible !important;
  padding: 10px 0;
}

.team-grid .team-block {
  margin-bottom: 45px;
}

.event-block .event-image {
  position: relative;
  overflow: hidden;
}

.event-block .event-image .date {
  position: absolute;
  top: 15px;
  right: 10px;
  overflow: hidden;
  padding: 5px 10px;
  background: var(--clr-white);
  -webkit-box-shadow: 0px 4px 26px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 26px 3px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.event-block .event-image .date .day {
  font-size: 30px;
  text-transform: uppercase;
  color: #ff8257;
  display: block;
  font-weight: 700;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  line-height: 1;
}

.event-block .event-image .date .month {
  font-size: 12px;
  font-weight: 700;
  color: #2e3d62;
  display: block;
  text-transform: uppercase;
}

.event-block .event-image .date .icon {
  margin-right: 5px;
}

.event-block .event-content {
  background: var(--clr-white);
  padding: 15px 20px;
  border: 1px solid #eaeaea;
  border-top: 0;
  min-height: 185px;
}

.event-block .event-content .event-info .title {
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}

.event-block .event-content .event-info .event-meta {
  font-size: 13px;
  color: #9364d4;
}

.event-block .event-content .event-info .body {
  margin-top: 10px;
  line-height: 28px;
}

.event-block-2 {
  padding-bottom: 35px;
  margin-bottom: 35px;
  border-bottom: 1px solid #eaeaea;
}

.event-block-2:before, .event-block-2:after {
  content: " ";
  display: table;
}

.event-block-2:after {
  clear: both;
}

@media (max-width: 575.98px) {
  .event-block-2 {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.event-block-2 .content-inner {
  padding-left: 320px;
  position: relative;
  z-index: 9;
}

@media (max-width: 991.98px) {
  .event-block-2 .content-inner {
    padding-left: 310px;
  }
}

@media (max-width: 767.98px) {
  .event-block-2 .content-inner {
    padding-left: 220px;
  }
}

@media (max-width: 650px) {
  .event-block-2 .content-inner {
    padding-left: 0;
  }
}

.event-block-2 .content-inner .event-content-inner {
  position: relative;
}

.event-block-2 .content-inner .event-content-inner .event-content {
  padding-left: 90px;
}

@media (max-width: 991.98px) {
  .event-block-2 .content-inner .event-content-inner .event-content {
    padding-left: 0;
  }
}

.event-block-2 .post-title {
  font-size: 20px;
}

.event-block-2 .event-image {
  width: 320px;
  float: left;
  position: relative;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .event-block-2 .event-image {
    width: 300px;
  }
}

@media (max-width: 767.98px) {
  .event-block-2 .event-image {
    width: 200px;
  }
}

@media (max-width: 650px) {
  .event-block-2 .event-image {
    width: 100%;
    margin-bottom: 15px;
  }
}

.event-block-2 .event-image .event-date {
  -webkit-box-shadow: 0px 4px 26px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 26px 3px rgba(0, 0, 0, 0.1);
  width: 120px;
  height: 120px;
  float: left;
  text-align: center;
  line-height: 1.1;
  padding-top: 15px;
  position: absolute;
  background: var(--clr-white);
  top: 50%;
  right: -60px;
  margin-top: -60px;
}

@media (max-width: 991.98px) {
  .event-block-2 .event-image .event-date {
    top: 10px;
    right: 10px;
    margin-top: 0;
    padding-top: 10px;
    width: 90px;
    height: 90px;
  }
}

.event-block-2 .event-image .event-date .date {
  font-size: 55px;
  font-weight: 700;
  color: #ff8257;
  display: block;
}

@media (max-width: 991.98px) {
  .event-block-2 .event-image .event-date .date {
    font-size: 45px;
  }
}

.event-block-2 .event-image .event-date .month {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: #2e3d62;
}

.event-block-2 .event-meta {
  font-size: 15px;
  margin-top: 5px;
  line-height: 22px;
}

.event-block-2 .event-line {
  width: 50px;
  height: 2px;
  background: #ff8257;
  margin: 15px 0 15px;
}

@media (max-width: 991.98px) {
  .event-block-2 .post-title {
    margin-top: 0;
    font-size: 18px;
  }
}

.event-list-item .event-date {
  display: inline-block;
  background: #ff8257;
  color: var(--clr-white);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 30px;
  line-height: 24px;
}

.event-list-item .event-title {
  margin-top: 5px;
}

.event-list-item .event-title a {
  font-size: 20px;
  color: #2e3d62;
  font-weight: 700;
}

.event-list-item .event-body {
  color: #232526;
}

.event-list-item .event-body p {
  margin-bottom: 0;
}

.events-list .view-content-wrap .item:last-child .event-block-2 {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

.event-block-list .event-date {
  width: 110px;
  height: auto;
  float: left;
  background: #ff8257;
  color: var(--clr-white);
  padding: 12px 10px;
}

.event-block-list .event-date span {
  display: block;
}

.event-block-list .event-date span.day {
  font-size: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  line-height: 1;
  margin-bottom: 3px;
  padding-bottom: 3px;
}

.event-block-list .event-title a {
  font-weight: 700;
  font-size: 14px;
  display: inline-block;
  line-height: 24px;
}

.event-block-list .event-address {
  font-size: 14px;
}

.event-block-list .event-address i {
  font-size: 16px;
  color: #ff8257;
}

.event-block-list .event-content {
  padding-left: 125px;
}

.event-full {
  margin-bottom: 30px;
}

.event-full .event-info {
  background: #F3F5FA;
  padding: 20px;
  font-size: 16px;
}

.event-full .event-info .date > *, .event-full .event-info .address > * {
  float: left;
}

.event-full .event-info .date i, .event-full .event-info .address i {
  margin-right: 10px;
  margin-top: 6px;
}

.portfolio-filter {
  margin-bottom: 20px;
}

.portfolio-filter ul.nav-tabs {
  padding: 5px 0;
  border-bottom: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.portfolio-filter ul.nav-tabs > li {
  display: inline-block;
}

.portfolio-filter ul.nav-tabs > li > a {
  background: none;
  font-weight: 600;
  margin-right: 10px;
  padding: 8px 30px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  text-transform: capitalize;
  letter-spacing: 0;
  position: relative;
  background: #F3F5FA;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 15px;
  border-radius: 3px;
}

@media (max-width: 1199.98px) {
  .portfolio-filter ul.nav-tabs > li > a {
    padding: 8px 20px;
  }
}

.portfolio-filter ul.nav-tabs > li > a:hover {
  border-color: #ff8257;
}

.portfolio-filter ul.nav-tabs > li > a.active {
  border-color: #ff8257;
  background: #ff8257;
  color: var(--clr-white);
}

.gva-portfolio-items {
  float: left;
  width: 100%;
}

.gva-portfolio-items .view-portfolio {
  width: 100%;
}

.portfolio-v1 {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 30px;
  background: none;
}

.portfolio-v1 .portfolio-content {
  position: relative;
  -webkit-transition: box-shadow 0.3s ease;
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  transition: -webkit-box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
  -moz-transition: box-shadow 0.3s ease;
  -ms-transition: box-shadow 0.3s ease;
}

.portfolio-v1 .portfolio-content .portfolio-overlay {
  position: absolute;
  left: 20px;
  top: 20px;
  bottom: 20px;
  right: 20px;
  background: var(--clr-white);
  border-radius: 6px;
  z-index: 11;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}

.portfolio-v1 .portfolio-images {
  position: relative;
  text-align: center;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  border-radius: 6px;
  overflow: hidden;
}

.portfolio-v1 .portfolio-images .image-popup {
  opacity: 0;
  filter: alpha(opacity=0);
  width: 40px;
  height: 40px;
  background: #F3F5FA;
  line-height: 40px;
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 99;
  text-align: center;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  margin-top: 30px;
  border-radius: 50%;
  font-size: 13px;
}

.portfolio-v1 .portfolio-images .image-popup:hover {
  color: var(--clr-white);
  background: #ff8257;
}

.portfolio-v1 .portfolio-images .link {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.45s;
  transition: all 0.45s;
  -moz-transition: all 0.45s;
  -ms-transition: all 0.45s;
  width: 40px;
  height: 40px;
  background: #F3F5FA;
  line-height: 40px;
  position: absolute;
  top: 90px;
  right: 40px;
  z-index: 99;
  text-align: center;
  margin-top: 45px;
  border-radius: 50%;
  font-size: 13px;
}

.portfolio-v1 .portfolio-images .link:hover {
  color: var(--clr-white);
  background: #ff8257;
}

.portfolio-v1 .content-inner {
  text-align: left;
  padding: 30px;
  position: absolute;
  left: 20px;
  bottom: 20px;
  right: 20px;
  z-index: 12;
}

.portfolio-v1 .content-inner .title {
  -webkit-transition: all 0.3s ease-out 0.05s;
  transition: all 0.3s ease-out 0.05s;
  margin-top: 5px;
  opacity: 0;
  top: 30px;
  position: relative;
}

.portfolio-v1 .content-inner .title a {
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
}

.portfolio-v1 .content-inner .category {
  -webkit-transition: all 0.2s ease-out 0.08s;
  transition: all 0.2s ease-out 0.08s;
  line-height: 1.1;
  margin-bottom: 5px;
  opacity: 0;
  top: 30px;
  position: relative;
}

.portfolio-v1 .content-inner .category a {
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 500;
  color: #787887;
}

.portfolio-v1 .content-inner .category a:hover, .portfolio-v1 .content-inner .category a:focus, .portfolio-v1 .content-inner .category a:active {
  color: #ff8257;
}

.portfolio-v1:hover .portfolio-overlay, .portfolio-v1:active .portfolio-overlay, .portfolio-v1:focus .portfolio-overlay {
  opacity: 1;
}

.portfolio-v1:hover .content-inner .title, .portfolio-v1:active .content-inner .title, .portfolio-v1:focus .content-inner .title {
  opacity: 1;
  top: 0;
}

.portfolio-v1:hover .content-inner .category, .portfolio-v1:active .content-inner .category, .portfolio-v1:focus .content-inner .category {
  opacity: 1;
  top: 0;
}

.portfolio-v1:hover .portfolio-images, .portfolio-v1:active .portfolio-images, .portfolio-v1:focus .portfolio-images {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.portfolio-v1:hover .portfolio-images .image-popup, .portfolio-v1:active .portfolio-images .image-popup, .portfolio-v1:focus .portfolio-images .image-popup {
  opacity: 1;
  filter: alpha(opacity=100);
  margin-top: 0;
}

.portfolio-v1:hover .portfolio-images a.link, .portfolio-v1:active .portfolio-images a.link, .portfolio-v1:focus .portfolio-images a.link {
  opacity: 1;
  filter: alpha(opacity=100);
  margin-top: 0;
}

.portfolio-v1 ul {
  list-style: none;
  padding: 0;
}

.portfolio-v2 {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 30px;
  background: none;
}

.portfolio-v2 .portfolio-content {
  position: relative;
}

.portfolio-v2 .portfolio-images {
  position: relative;
  text-align: center;
  border-radius: 6px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.portfolio-v2 .portfolio-images .portfolio-overlay {
  position: absolute;
  left: 20px;
  top: 20px;
  bottom: 20px;
  right: 20px;
  background: var(--clr-white);
  border-radius: 6px;
  z-index: 11;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}

.portfolio-v2 .portfolio-images .image-popup {
  opacity: 0;
  filter: alpha(opacity=0);
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
  z-index: 99;
  text-align: center;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  font-size: 42px;
}

.portfolio-v2 .portfolio-images .image-popup:hover {
  color: #ff8257;
}

.portfolio-v2 .content-inner {
  text-align: center;
  padding: 25px 0 10px;
}

.portfolio-v2 .content-inner .title {
  position: relative;
}

.portfolio-v2 .content-inner .title a {
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
}

.portfolio-v2 .content-inner .category {
  line-height: 1.1;
  margin-top: 6px;
  position: relative;
}

.portfolio-v2 .content-inner .category a {
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 500;
  color: #787887;
}

.portfolio-v2 .content-inner .category a:hover, .portfolio-v2 .content-inner .category a:focus, .portfolio-v2 .content-inner .category a:active {
  color: #ff8257;
}

.portfolio-v2:hover .portfolio-overlay, .portfolio-v2:active .portfolio-overlay, .portfolio-v2:focus .portfolio-overlay {
  opacity: 1;
}

.portfolio-v2:hover .portfolio-images, .portfolio-v2:active .portfolio-images, .portfolio-v2:focus .portfolio-images {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.portfolio-v2:hover .portfolio-images .image-popup, .portfolio-v2:active .portfolio-images .image-popup, .portfolio-v2:focus .portfolio-images .image-popup {
  opacity: 1;
  filter: alpha(opacity=100);
}

.portfolio-v2 ul {
  list-style: none;
  padding: 0;
}

.owl-carousel .portfolio-v1, .owl-carousel .portfolio-v2 {
  margin-bottom: 20px;
}

.portfolio-carousel-single {
  max-width: 1080px;
  margin: 0 auto;
}

.portfolio-carousel-single .portfolio-item {
  overflow: hidden;
}

.portfolio-carousel-single .portfolio-item .images {
  text-align: center;
}

.portfolio-carousel-single .portfolio-item .images img {
  max-width: 780px;
  position: relative;
  z-index: 9;
}

.portfolio-carousel-single .portfolio-item .images .image-popup {
  position: absolute;
  left: 50%;
  z-index: 99;
  width: 32px;
  height: 32px;
  margin-left: -16px;
  line-height: 32px;
  text-align: center;
  background: #2e3d62;
  color: var(--clr-white);
}

.portfolio-carousel-single .portfolio-item .images .image-popup:hover {
  background: #3e5385;
}

.portfolio-carousel-single .portfolio-item .images .read-more {
  position: absolute;
  right: 0;
  bottom: 30px;
  z-index: 1;
  width: 300px;
  text-align: right;
}

.portfolio-carousel-single .portfolio-item .images .read-more a {
  font-weight: 600;
  -webkit-transition: all 1s;
  transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  padding-right: 40px;
  opacity: 0;
  filter: alpha(opacity=0);
  color: #2e3d62;
}

.portfolio-carousel-single .portfolio-item .images .read-more a:hover {
  color: #ff8257;
}

.portfolio-carousel-single .portfolio-item .images .read-more:after {
  content: "";
  width: 0px;
  height: 1px;
  background: #ff8257;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 1s;
  transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
}

.portfolio-carousel-single .portfolio-item .portfolio-content {
  position: absolute;
  top: 100px;
  left: 50px;
  z-index: 11;
}

@media (max-width: 991.98px) {
  .portfolio-carousel-single .portfolio-item .portfolio-content {
    left: 30px;
  }
}

.portfolio-carousel-single .portfolio-item .portfolio-content:after {
  content: "";
  -webkit-transition: all 1s;
  transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  width: 1px;
  height: 0;
  background: #ff8257;
  position: absolute;
  top: -110px;
  left: 0;
}

.portfolio-carousel-single .portfolio-item .portfolio-content .category {
  margin-bottom: 5px;
}

.portfolio-carousel-single .portfolio-item .portfolio-content .category a {
  text-transform: capitalize;
  color: #2e3d62;
  font-weight: 600;
  background: var(--clr-white);
  display: inline-block;
  padding: 2px 10px;
  font-size: 14px;
  border-radius: 4px;
}

.portfolio-carousel-single .portfolio-item .portfolio-content .category a:hover {
  color: #ff8257;
}

.portfolio-carousel-single .portfolio-item .portfolio-content .title {
  background: #ff8257;
  padding: 25px 30px 0;
  max-width: 410px;
  border-radius: 6px 6px 0 0;
}

.portfolio-carousel-single .portfolio-item .portfolio-content .title a {
  font-size: 22px;
  font-weight: 600;
  color: var(--clr-white);
}

.portfolio-carousel-single .portfolio-item .portfolio-content .desc {
  background: #ff8257;
  padding: 6px 30px 30px;
  color: var(--clr-white);
  max-width: 410px;
  border-radius: 0 0 6px 6px;
}

.portfolio-carousel-single .portfolio-item .portfolio-content .category {
  -webkit-transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1) 0.5s;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1) 0.5s;
  -webkit-transform: translate(0, -70%);
  transform: translate(0, -70%);
  ms-transform: translate(0, -70%);
  opacity: 0;
  filter: alpha(opacity=0);
}

.portfolio-carousel-single .portfolio-item .portfolio-content .title {
  -webkit-transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1) 0.9s;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1) 0.9s;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  ms-transform: translate(0, -50%);
  opacity: 0;
  filter: alpha(opacity=0);
}

.portfolio-carousel-single .portfolio-item .portfolio-content .desc {
  -webkit-transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1) 1.3s;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1) 1.3s;
  -webkit-transform: translate(0, -30%);
  transform: translate(0, -30%);
  ms-transform: translate(0, -30%);
  opacity: 0;
  filter: alpha(opacity=0);
}

.portfolio-carousel-single .portfolio-item .image-popup {
  -webkit-transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1) 1.3s;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1) 1.3s;
  bottom: -30px;
  opacity: 0;
  filter: alpha(opacity=0);
}

.portfolio-carousel-single .active-effect .portfolio-item .portfolio-content:after {
  height: 100px;
}

.portfolio-carousel-single .active-effect .portfolio-item .portfolio-content .category, .portfolio-carousel-single .active-effect .portfolio-item .portfolio-content .title, .portfolio-carousel-single .active-effect .portfolio-item .portfolio-content .desc {
  transform: translate(0, 0);
  opacity: 1;
  filter: alpha(opacity=100);
}

.portfolio-carousel-single .active-effect .image-popup {
  opacity: 1;
  filter: alpha(opacity=100);
  bottom: 20px;
}

.portfolio-carousel-single .active-effect .portfolio-item .images .read-more a {
  padding-right: 10px;
  opacity: 1;
  filter: alpha(opacity=100);
}

.portfolio-carousel-single .active-effect .portfolio-item .images .read-more:after {
  width: 300px;
}

.testimonial-node-1 {
  background: var(--clr-white);
  border-radius: 4px;
  padding: 55px 30px 60px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  border: 2px solid #F3F5FA;
  border-radius: 4px;
  margin: 0 0 10px;
}

.testimonial-node-1 .testimonial-content {
  position: relative;
  max-width: 260px;
  margin: 0 auto;
}

.testimonial-node-1 .testimonial-content .quote {
  position: relative;
  font-size: 18px;
  line-height: 34px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 25px;
  margin-bottom: 30px;
}

.testimonial-node-1 .testimonial-content .quote:before, .testimonial-node-1 .testimonial-content .quote:after {
  content: " ";
  display: table;
}

.testimonial-node-1 .testimonial-content .quote:after {
  clear: both;
}

.testimonial-node-1 .testimonial-content .info {
  position: relative;
  width: 100%;
  margin-top: 20px;
}

.testimonial-node-1 .testimonial-content .info:before, .testimonial-node-1 .testimonial-content .info:after {
  content: " ";
  display: table;
}

.testimonial-node-1 .testimonial-content .info:after {
  clear: both;
}

.testimonial-node-1 .testimonial-content .info .left {
  width: 60px;
  float: left;
}

.testimonial-node-1 .testimonial-content .info .left .avatar img {
  border: 1px solid #eaeaea;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  width: 40px;
  height: auto;
}

.testimonial-node-1 .testimonial-content .info .right {
  padding-left: 60px;
}

.testimonial-node-1 .testimonial-content .info .right .title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #ff8257;
  text-transform: capitalize;
}

.testimonial-node-1 .testimonial-content .info .right .job {
  color: #2e3d62;
  font-size: 14px;
  line-height: 1.1;
}

.testimonial-node-1:hover {
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
}

.testimonial-node-1:hover .testimonial-content:after {
  background: #9364d4;
  color: var(--clr-white);
}

.testimonial-node-2 .testimonial-content {
  margin: 0 auto;
  max-width: 820px;
  text-align: center;
}

.testimonial-node-2 .testimonial-content .avatar {
  width: 250px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
}

.testimonial-node-2 .testimonial-content .quote {
  position: relative;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #2e3d62;
  padding: 40px 0 35px;
}

@media (max-width: 991.98px) {
  .testimonial-node-2 .testimonial-content .quote {
    font-size: 20px;
    line-height: 38px;
  }
}

.testimonial-node-2 .testimonial-content .info {
  position: relative;
}

.testimonial-node-2 .testimonial-content .info .title {
  font-size: 18px;
  font-weight: 600;
  color: #ff8257;
  text-transform: capitalize;
}

.testimonial-node-2 .testimonial-content .info .line {
  display: inline-block;
  margin: 0 6px;
}

.testimonial-node-2 .testimonial-content .info .job {
  text-transform: capitalize;
}

.testimonial-node-3 .item-image {
  position: relative;
  padding-top: 30px;
  padding-left: 70px;
  padding-right: 30px;
  width: 600px;
  height: 535px;
}

@media (max-width: 1199.98px) {
  .testimonial-node-3 .item-image {
    width: 100%;
    height: 100%;
  }
}

.testimonial-node-3 .item-image:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 30px;
  width: 100%;
  height: 95%;
  z-index: -1;
  background: #edfcf4;
  border-radius: 60% 100% 100% 40% / 60% 80% 80% 60%;
}

.testimonial-node-3 .item-image img {
  border-radius: 60% 100% 100% 40% / 60% 80% 80% 60%;
}

.testimonial-node-3 .icon-quote {
  position: absolute;
  color: var(--clr-white);
  background: #ff8257;
  font-size: 25px;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 99px;
  height: 99px;
  line-height: 99px;
  text-align: center;
  border-radius: 99px;
  -webkit-box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.05);
  z-index: 10;
  margin-left: 20px;
}

@media (max-width: 575.98px) {
  .testimonial-node-3 .icon-quote {
    width: 70px;
    height: 70px;
    line-height: 70px;
    -webkit-box-shadow: 0 0 0 15px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0.05);
  }
  .cet-purple-grd-bg .title-desc p {
    margin-bottom: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (min-width: 768px) {
  .testimonial-node-3 .testimonial-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .testimonial-node-3 .avatar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
  .testimonial-node-3 .info {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
  .column-banner-right-img-50-cent {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-right: 50px;
  }
  .column-banner-left-img-50-cent {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-left: 50px;
  }
  .col-lg-6 .gsc-accordion .skin-white {
    max-width: 478px;
  }
  .cet-tab-4-sections .gsc-tabs-content .nav-tabs > li > a {
    height: 90px;
  }
}

.testimonial-node-3 .avatar {
  position: relative;
}

.testimonial-node-3 .info {
  margin: 30px 0;
}

.testimonial-node-3 .quote {
  font-size: 22px;
  line-height: 2.09;
  margin-bottom: 35px;
}

@media (max-width: 991.98px) {
  .testimonial-node-3 .quote {
    font-size: 18px;
    line-height: 30px;
  }
}

.testimonial-node-3 .title {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 30px;
}

@media (max-width: 991.98px) {
  .testimonial-node-3 .title {
    font-size: 22px;
  }
}

.testimonial-node-3 .job {
  color: #ff8257;
  font-size: 20px;
  font-weight: 600;
}

.service-block-1 {
  background: var(--clr-white);
  -webkit-box-shadow: 0 10px 14px 0 rgba(37, 44, 65, 0.04);
  box-shadow: 0 10px 14px 0 rgba(37, 44, 65, 0.04);
  margin-bottom: 30px;
}

.service-block-1 .service-images {
  position: relative;
}

.service-block-1 .service-images:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
}

.service-block-1 .service-images .image-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -18px;
  margin-left: -18px;
  width: 36px;
  height: 36px;
  z-index: 11;
  background: var(--clr-white);
  text-align: center;
  line-height: 38px;
  color: #2e3d62;
  opacity: 0;
}

.service-block-1 .service-images .image-popup:hover {
  color: var(--clr-white);
  background: #ff8257;
}

.service-block-1 .service-content {
  position: relative;
  padding: 40px 30px 30px;
  border: 1px solid #eaeaea;
}

@media (max-width: 991.98px) {
  .service-block-1 .service-content {
    padding: 40px 20px 20px;
  }
}

.service-block-1 .service-content .service-icon {
  font-size: 20px;
  width: 100px;
  height: 48px;
  position: absolute;
  top: -24px;
  left: 0;
  text-align: center;
  padding-top: 6px;
  line-height: 50px;
  z-index: 11;
  color: #ff8257;
  font-size: 28px;
}

.service-block-1 .service-content .service-icon img {
  max-width: 36px;
}

.service-block-1 .service-content .service-icon:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3NiAzMSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5iZ19zb3VyY2VfYmFkZ2VfMTwvdGl0bGU+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik01Ny42NywxMS45NEM1Mi4zNiw2LjcxLDQ4LjQyLDAsMzguMywwSDM3LjdDMjcuNTgsMCwyMy42NCw2LjcxLDE4LjMzLDExLjk0LDE0LjQ3LDE2Ljc2LDYuNjEsMTcuODEsMCwxOFYzMUg3NlYxOEM2OS4zOSwxNy44MSw2MS41MywxNi43Niw1Ny42NywxMS45NFoiLz48L2c+PC9nPjwvc3ZnPg==");
}

.service-block-1 .service-content .title {
  font-size: 20px;
  margin: 5px 0 20px;
  font-weight: 600;
}

@media (max-width: 991.98px) {
  .service-block-1 .service-content .title {
    font-size: 16px;
  }
}

.service-block-1 .desc {
  margin-bottom: 15px;
}

.service-block-1:hover .service-images:after {
  opacity: 1;
}

.service-block-1:hover .service-images .image-popup {
  opacity: 1;
}

.service-block-2 {
  margin-bottom: 30px;
}

.service-block-2 .service-content {
  padding: 50px 30px 0;
  text-align: center;
  position: relative;
  background: var(--clr-white);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

@media (max-width: 1199.98px) {
  .service-block-2 .service-content {
    padding: 30px;
  }
  .cet-home-logos-banner p {
    display: grid;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;
    align-content: space-evenly;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    grid-template-columns: repeat(3,1fr);
  }
    .cet-home-logos-banner img {
    padding: 10px 15px;
    max-width: 100%;
  }
}

.service-block-2 .service-content .title {
  font-size: 18px;
  margin-bottom: 16px;
}

@media (max-width: 991.98px) {
  .service-block-2 .service-content .title {
    font-size: 16px;
  }
}

.service-block-2 .service-content .service-icon {
  margin-bottom: 65px;
  position: relative;
  display: inline-block;
}

.service-block-2 .service-content .service-icon .icon {
  color: var(--clr-white);
  font-size: 42px;
  display: inline-block;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 100px;
  height: 100px;
  background: #ff8257;
  border-radius: 50%;
  line-height: 100px;
  top: 0;
}


.service-block-2 .service-content .desc {
  margin-bottom: 45px;
}

.service-block-2 .service-content .service-readmore {
  border-top: 1px solid #eaeaea;
  padding: 15px 0;
}

.service-block-2 .service-content .service-readmore a {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  color: #ff8257;
}

.service-block-2 .service-content .service-readmore a:hover {
  text-decoration: underline;
}

.service-block-2:hover .service-content {
  background: #ff8257;
  color: var(--clr-white);
}

.service-block-2:hover .service-content a {
  color: var(--clr-white);
}

.service-block-2:hover .service-content a:hover {
  text-decoration: underline;
}

.service-block-2:hover .service-content .service-icon .icon {
  top: -10px;
  background: var(--clr-white);
  color: #ff8257;
}

.service-block-2:hover .service-content .service-readmore {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.owl-carousel .service-block-1, .owl-carousel .service-block-2 {
  margin-bottom: 0;
}

.services-grid .gva-view-grid-inner {
  margin: 0 !important;
}

.services-grid .gva-view-grid-inner > .item-columns .service-block {
  margin-bottom: 30px;
}

.services-grid .gva-view-grid-inner > .item-columns .service-block .service-block-content {
  background: var(--clr-white);
  padding: 35px 30px;
  min-height: 255px;
  position: relative;
  border: 1px solid #eaeaea;
}

.service-block-singe .service-images-inner {
  position: relative;
}

.service-block-singe .post-content {
  padding-top: 40px;
  position: relative;
}

.service-block-singe .post-content .service-icon {
  font-size: 22px;
  width: 100px;
  height: 48px;
  position: absolute;
  top: -25px;
  left: 0;
  text-align: center;
  padding-top: 6px;
  line-height: 48px;
}

.service-block-singe .post-content .service-icon > div {
  position: relative;
  z-index: 2;
}

.service-block-singe .post-content .service-icon > div img {
  max-width: 40px;
}

.service-block-singe .post-content .service-icon:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3NiAzMSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5iZ19zb3VyY2VfYmFkZ2VfMTwvdGl0bGU+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik01Ny42NywxMS45NEM1Mi4zNiw2LjcxLDQ4LjQyLDAsMzguMywwSDM3LjdDMjcuNTgsMCwyMy42NCw2LjcxLDE4LjMzLDExLjk0LDE0LjQ3LDE2Ljc2LDYuNjEsMTcuODEsMCwxOFYzMUg3NlYxOEM2OS4zOSwxNy44MSw2MS41MywxNi43Niw1Ny42NywxMS45NFoiLz48L2c+PC9nPjwvc3ZnPg==");
}

.service-block-singe .post-content .post-title {
  margin-bottom: 15px;
  font-size: 30px;
}
.cet-hero-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  padding-top: 110px;
  padding-bottom: 160px;
  min-height: 660px;
}
.cet-hero-container::before{
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.55);
}
.cet-hero-container h1, .cet-hero-container .title-desc {
  max-width: 950px;
  padding-left: 30px;
  padding-right: 30px;
}
.cet-hero-container .title-desc p {
  font-size: 26px;
  line-height: 40px;
  max-width: 600px;
}
.cet-hero-container .gsc-button {
  margin-bottom: 0;
}
.cet-hero-left-aligned {
  justify-content: flex-start;
}
.cet-hero-left-aligned h1 {
  justify-content: flex-start;
  margin-left: 0 !important;
  max-width: 825px;
}
.cet-hero-left-aligned .gsc-heading.style-1 {
  padding-left: 105px;
  text-align: left;
}
.cet-hero-left-aligned .cet-hero-multiple-btn {
  padding-left: 155px;
}
.cet-hero-multiple-btn .column-content-inner {
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.cet-hero-centered-btn .column-content-inner {
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}
.gsc-heading .heading-action {
  margin-top: 30px;
}

.cet-external-link-icon:after {
  content: url("../img/external-link-icon.svg");
  left: 10px;
  top: 2px;
  position: relative;
  padding-right: 10px;
}
.cet-article-links h5 {
  font-family: var(--ff-primary) !important;
  font-weight: 600;
  padding-bottom: 10px;
}
.cet-article-links a.social, .cet-gated-brochure a.social {
  text-decoration: none;
}
.cet-article-links a.social img, .cet-gated-brochure a.social img {
  padding-right: 16px;
}
.cet-article-disclaimer p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  font-family: var(--ff-primary);
  color: var(--clr-dark-gray);
}
.cet-article-glossary p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  font-family: var(--ff-primary);
  color: var(--clr-black);
}

.cet-article-privacy-policy p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  font-family: var(--ff-primary);
  color: var(--clr-black);
}

.cet-article-privacy-policy ul, .cet-article-privacy-policy ol {
  padding-top: 20px;
  padding-bottom: 30px;
}

.cet-article-privacy-policy li {
  line-height: 26px;
  padding: 2px 0;
}
.cet-gated-brochure .gsc-column:first-child, .cet-gated-brochure .gsc-column:first-child img {
  text-align: right;
}
.cet-gated-brochure .gsc-image img {
  width: 286px;
  border: 13px solid var(--clr-white);
  box-shadow: 0px 0px 11px 5px rgb(0 0 0 / 6%);
  border-radius: 9px;
  margin-bottom: 15px;
}
.cet-gated-brochure .gsc-button {
  width: 286px;
  padding-left: 5px;
  padding-right: 5px;
}
.cet-gated-brochure span.social-text {
  font-size: 1rem;
  font-weight: 600;
  padding-right: 12px;
  position: relative;
  top: 4px;
}
@media (max-width: 459.98px) {
  .cet-r-i-title h2.title {
    max-width: 235px;
  }
}

@media (min-width: 460px) and (max-width: 767.98px) {
  .cet-r-i-title h2.title {
    max-width: 285px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .cet-hero-left-aligned .gsc-heading.style-1 {
    padding-left: 80px;
  }
  .cet-hero-left-aligned .cet-hero-multiple-btn {
    padding-left: 130px;
  }
  .cet-article-links a.social img {
    padding-right: 5px;
  }
}

body.path-services .body-page {
  background: #F3F3F6;
}

.gavias_sliderlayer {
  background: var(--clr-white);
}

.tp-parallax-wrap {
  visibility: visible !important;
  -webkit-transform-style: unset !important;
  transform-style: unset !important;
}

.tp-parallax-wrap:before, .tp-parallax-wrap:after {
  content: " ";
  display: table;
}

.tp-parallax-wrap:after {
  clear: both;
}

.slotholder {
  -webkit-filter: unset !important;
  filter: unset !important;
}

.layer-style-image {
  display: inline-block;
  text-align: center;
}

.layer-style-image img {
  max-width: 100% !important;
  height: auto !important;
}

.gavias_sliderlayer .tp-bullets, #gavias_slider_single .tp-bullets {
  -webkit-opacity: 1 !important;
  -ms-opacity: 1 !important;
  -o-opacity: 1 !important;
  opacity: 1 !important;
  visibility: visible !important;
  margin-left: -40px;
}

.gavias_sliderlayer .tp-bullets .tp-bullet, #gavias_slider_single .tp-bullets .tp-bullet {
  width: 20px;
  height: 5px;
  background: rgba(255, 255, 255, 0.4);
}

.gavias_sliderlayer .tp-bullets .tp-bullet.selected, #gavias_slider_single .tp-bullets .tp-bullet.selected {
  background: var(--clr-white);
}

.gavias_sliderlayer .tparrows, #gavias_slider_single .tparrows {
  width: 36px;
  height: 36px;
  background: var(--clr-white) !important;
  line-height: 36px;
  color: #2e3d62;
  font-size: 18px;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 767.98px) {
  .gavias_sliderlayer .tparrows, #gavias_slider_single .tparrows {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
  .gsc-heading.style-1 {
    padding-bottom: 25px;
  }
  .gsc-heading.style-2 {
    padding-bottom: 35px;
  }
  .gsc-heading.style-3 .column-content p {
    margin-top: 25px;
  }
  .cet-hero-left-aligned .gsc-heading.style-1 {
    padding-left: 0;
    text-align: center;
  }
  .cet-hero-left-aligned .cet-hero-multiple-btn {
    padding-left: 0;
  }
  .cet-hero-left-aligned {
      justify-content: center;
  }
  .gsc-call-to-action.button-top-right .button-action, .gsc-call-to-action.button-center-right .button-action, .gsc-call-to-action.button-bottom-right .button-action {
    width: 100%;
  }
  .gsc-call-to-action.button-top-right .content, .gsc-call-to-action.button-center-right .content, .gsc-call-to-action.button-bottom-right .content {
    text-align: left;
    width: 60%;
    padding-right: 10px;
  }
  .gsc-call-to-action.button-top-right .button-action, .gsc-call-to-action.button-center-right .button-action, .gsc-call-to-action.button-bottom-right .button-action {
    width: 40%;
    margin-top: 6px !important;
  }
  .gsc-call-to-action.hidedesc .content .desc, .hidedesc .cet-image-list p {
    display: none;
  }
  .gsc-call-to-action.button-top-right .content-inner, .gsc-call-to-action.button-center-right .content-inner, .gsc-call-to-action.button-bottom-right .content-inner {
    align-items: flex-start !important;
  }
  .gsc-call-to-action.button-bottom-right .button-action .gsc-button {
    margin-bottom: 24px;
  }
  .cet-hero-multiple-btn .column-content-inner a:nth-of-type(2) {
    margin-top: 20px;
  }
  .cet-article-links {
    margin-top: 20px;
  }
  .cet-article-links a.gsc-button.large, .cet-article-links a.gsc-button.medium, .cet-article-links a.gsc-button.small {
    display: block;
  }
}

.gavias_sliderlayer .tparrows:hover, .gavias_sliderlayer .tparrows:focus, #gavias_slider_single .tparrows:hover, #gavias_slider_single .tparrows:focus {
  background: #ff8257;
  color: var(--clr-white);
}

.gavias_sliderlayer .tparrows:before, #gavias_slider_single .tparrows:before {
  color: #2e3d62 !important;
  line-height: 36px !important;
}

.gavias_sliderlayer .tp-caption, #gavias_slider_single .tp-caption {
  z-index: 99;
  line-height: 28px;
  color: #2e3d62;
}

.gavias_sliderlayer .tp-caption img, #gavias_slider_single .tp-caption img {
  max-width: 100% !important;
  height: auto !important;
}

.gavias_sliderlayer .tp-mask-wrap, #gavias_slider_single .tp-mask-wrap {
  padding-bottom: 20px;
}

.gavias_sliderlayer .slide-style-1, #gavias_slider_single .slide-style-1 {
  font-weight: 900;
}

.gavias_sliderlayer .slide-style-2, #gavias_slider_single .slide-style-2 {
  font-weight: 700;
}

.gavias_sliderlayer .slide-style-3, #gavias_slider_single .slide-style-3 {
  font-weight: 600;
}

.gavias_sliderlayer .slide-style-4, #gavias_slider_single .slide-style-4 {
  font-weight: 400;
}

.gavias_sliderlayer .slide-style-5, #gavias_slider_single .slide-style-5 {
  font-weight: 500;
  letter-spacing: 8px;
  border-bottom: 1px solid var(--clr-white);
}

.gavias_sliderlayer .slide-style-6, #gavias_slider_single .slide-style-6 {
  font-weight: 400;
}

.gavias_sliderlayer .text-line, #gavias_slider_single .text-line {
  -webkit-box-shadow: 0 -8px 0 0 rgba(255, 130, 87, 0.1) inset;
  box-shadow: 0 -8px 0 0 rgba(255, 130, 87, 0.1) inset;
}
.gavias_sliderlayer .text-black, #gavias_slider_single .text-black {
  color: var(--clr-black) !important;
}

.gavias_sliderlayer .text-theme, #gavias_slider_single .text-theme {
  color: #ff8257 !important;
}

.gavias_sliderlayer .text-blue, #gavias_slider_single .text-blue {
  color: #36266D !important;
}

.gavias_sliderlayer .text-blue, #gavias_slider_single .text-blue {
  color: #36266D !important;
}
.gavias_sliderlayer .text-purple, #gavias_slider_single .text-purple {
  color: var(--clr-purple) !important;
}
.gavias_sliderlayer .text-purple, #gavias_slider_single .text-purple {
  color: var(--clr-purple) !important;
}

.gavias_sliderlayer .text-theme-second, #gavias_slider_single .text-theme-second {
  color: #9364d4 !important;
}

.gavias_sliderlayer .text-white, #gavias_slider_single .text-white {
  color: var(--clr-white) !important;
}

.gavias_sliderlayer .text-center, #gavias_slider_single .text-center {
  text-align: center;
}

.gavias_sliderlayer .text-left, #gavias_slider_single .text-left {
  text-align: left;
}

.gavias_sliderlayer .btn-slide.inner, .gavias_sliderlayer .btn-slide a, #gavias_slider_single .btn-slide.inner, #gavias_slider_single .btn-slide a {
  background: #85216C;
  color: var(--clr-white);
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 40px;
  position: relative;
  display: inline-block;
  z-index: 1;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-box-shadow: 0px 10px 30px 0 rgba(255, 130, 87, 0.3);
  box-shadow: 0px 10px 30px 0 rgba(255, 130, 87, 0.3);
  border: none;
}

.gavias_sliderlayer .btn-slide.inner:hover, .gavias_sliderlayer .btn-slide.inner:focus, .gavias_sliderlayer .btn-slide.inner:active, .gavias_sliderlayer .btn-slide.inner.active, .gavias_sliderlayer .btn-slide a:hover, .gavias_sliderlayer .btn-slide a:focus, .gavias_sliderlayer .btn-slide a:active, .gavias_sliderlayer .btn-slide a.active, #gavias_slider_single .btn-slide.inner:hover, #gavias_slider_single .btn-slide.inner:focus, #gavias_slider_single .btn-slide.inner:active, #gavias_slider_single .btn-slide.inner.active, #gavias_slider_single .btn-slide a:hover, #gavias_slider_single .btn-slide a:focus, #gavias_slider_single .btn-slide a:active, #gavias_slider_single .btn-slide a.active {
  color: var(--clr-white);
}

.gavias_sliderlayer .btn-slide-white.inner, .gavias_sliderlayer .btn-slide-white a, #gavias_slider_single .btn-slide-white.inner, #gavias_slider_single .btn-slide-white a {
  background: var(--clr-white);
  color: #2e3d62;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 50px;
  position: relative;
  display: inline-block;
  z-index: 1;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
}

.gavias_sliderlayer .btn-slide-white.inner:hover, .gavias_sliderlayer .btn-slide-white.inner:focus, .gavias_sliderlayer .btn-slide-white.inner:active, .gavias_sliderlayer .btn-slide-white.inner.active, .gavias_sliderlayer .btn-slide-white a:hover, .gavias_sliderlayer .btn-slide-white a:focus, .gavias_sliderlayer .btn-slide-white a:active, .gavias_sliderlayer .btn-slide-white a.active, #gavias_slider_single .btn-slide-white.inner:hover, #gavias_slider_single .btn-slide-white.inner:focus, #gavias_slider_single .btn-slide-white.inner:active, #gavias_slider_single .btn-slide-white.inner.active, #gavias_slider_single .btn-slide-white a:hover, #gavias_slider_single .btn-slide-white a:focus, #gavias_slider_single .btn-slide-white a:active, #gavias_slider_single .btn-slide-white a.active {
 background: var(--clr-black);
 opacity: 0.3;
  color: var(--clr-white);
}

.colorpicker {
  z-index: 9999999 !important;
}

.permission-save-hidden #gavias_customize_save {
  display: none !important;
}

.gavias-skins-panel {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background: var(--clr-white);
  right: -403px;
  position: fixed;
  height: auto;
  top: 0px;
  bottom: 0;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  width: 400px;
  z-index: 999;
}

.gavias-skins-panel .gavias-skins-panel-inner {
  bottom: 0;
  padding: 30px 10px 10px;
  height: auto;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  width: 100%;
}

.gavias-skins-panel.active {
  right: 0 !important;
}

.gavias-skins-panel #gavias_profile_customize_name option {
  color: #2e3d62;
}

.gavias-skins-panel .control-panel {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 150px;
  left: -40px;
  width: 40px;
  height: 40px;
  background: #ff8257;
  color: var(--clr-white);
  font-size: 18px;
  line-height: 40px;
  text-align: center;
}

.gavias-skins-panel .control-panel:hover {
  cursor: pointer;
}

.gavias-skins-panel .panel-skins-content {
  padding-bottom: 20px;
  overflow: hidden;
}

.gavias-skins-panel .panel-skins-content:last-child {
  padding-bottom: 0;
}

.gavias-skins-panel .panel-skins-content .title {
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  color: #2e3d62;
  margin-bottom: 10px;
}

.gavias-skins-panel .panel-skins-content .item-color {
  width: 25px;
  height: 30px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 5px 6px 0 6px;
  border: 3px solid transparent;
}

.gavias-skins-panel .panel-skins-content .item-color:hover {
  cursor: pointer;
}

.gavias-skins-panel .panel-skins-content .item-color.default {
  background: #FF8257;
}

.gavias-skins-panel .panel-skins-content .item-color.blue {
  background: #50AF4D;
}

.gavias-skins-panel .panel-skins-content .item-color.brown {
  background: #684035;
}

.gavias-skins-panel .panel-skins-content .item-color.green {
  background: #009673;
}

.gavias-skins-panel .panel-skins-content .item-color.lilac {
  background: #8F6684;
}

.gavias-skins-panel .panel-skins-content .item-color.lime_green {
  background: #8CCA4F;
}

.gavias-skins-panel .panel-skins-content .item-color.orange {
  background: #F86923;
}

.gavias-skins-panel .panel-skins-content .item-color.pink {
  background: #D91897;
}

.gavias-skins-panel .panel-skins-content .item-color.purple {
  background: #9854B3;
}

.gavias-skins-panel .panel-skins-content .item-color.red {
  background: #E43844;
}

.gavias-skins-panel .panel-skins-content .item-color.turquoise {
  background: #1CCDCA;
}

.gavias-skins-panel .panel-skins-content .item-color.turquoise2 {
  background: #178E94;
}

.gavias-skins-panel .panel-skins-content .item-color.violet_red {
  background: #F3605D;
}

.gavias-skins-panel .panel-skins-content .item-color.violet_red2 {
  background: #973A4B;
}

.gavias-skins-panel .panel-skins-content .item-color.yellow {
  background: #ecc311;
}

.gavias-skins-panel .panel-skins-content .item-color.active {
  border-color: #2e3d62;
}

.gavias-skins-panel .panel-skins-content .layout {
  width: 80px;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
}

.gavias-skins-panel .panel-skins-content .layout:hover {
  cursor: pointer;
}

.gavias-skins-panel .panel-skins-content .layout.active {
  background-color: #ff8257;
  color: var(--clr-white);
}

.gavias-skins-panel .panel-skins-content .layout:last-child {
  margin-right: 0;
}

.gavias-skins-panel.gavias-skin-demo {
  top: 200px;
  bottom: auto !important;
  padding: 20px !important;
  width: 260px !important;
  right: -263px !important;
}

.gavias-skins-panel.gavias-skin-demo .control-panel {
  top: 75px !important;
}

.gavias-skins-panel.gavias-skin-demo.active {
  right: 0 !important;
}

#customize-gavias-preivew .panel {
  padding: 0 !important;
}

#customize-gavias-preivew .panel .panel-heading .panel-title > a {
  padding: 10px !important;
  border: 1px solid #eaeaea !important;
}

#customize-gavias-preivew .panel .panel-heading .panel-title > a:after {
  display: none !important;
}

#customize-gavias-preivew .panel .panel-body {
  padding: 15px !important;
}

#customize-gavias-preivew .form-group {
  position: relative;
  float: left;
  width: 100%;
}

#customize-gavias-preivew .form-group label {
  width: 100%;
  float: left;
  padding-top: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #2e3d62;
}

#customize-gavias-preivew .form-group input {
  width: calc(100% - 150px);
  float: left;
}

#customize-gavias-preivew .form-group select {
  width: 100%;
}

#customize-gavias-preivew .form-group .colorselector {
  width: calc(100% - 150px);
  position: relative;
}

#customize-gavias-preivew .form-group .colorselector input {
  width: 100% !important;
}

#customize-gavias-preivew .form-group .colorselector .input-group-addon {
  border: 1px solid #dee2e6;
  position: absolute;
  width: 30px;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1;
}

#customize-gavias-preivew .form-group .colorselector .input-group-addon i {
  width: 30px;
  height: 20px;
  display: inline-block;
}

#customize-gavias-preivew .form-group .colorselector .remove {
  width: 34px;
  height: 34px;
  color: #2e3d62;
  position: absolute;
  top: 3px;
  right: 25px;
  line-height: 34px;
  text-align: center;
  z-index: 99;
}

#customize-gavias-preivew .form-group .colorselector .remove:hover {
  cursor: pointer;
}

.gavias_customize_form .card {
  margin-bottom: 15px;
}

.gavias_customize_form .card .card-header a {
  font-weight: 600;
}

.gavias_customize_form .form-group.action {
  float: left;
  width: 100%;
  margin-top: 20px;
}

.gavias_customize_form .form-group.action input {
  width: 28%;
  margin-left: 10px;
  color: var(--clr-white);
  font-weight: 600;
  margin-bottom: 10px;
  padding: 0 10px !important;
}

.gavias_customize_form .form-group.action input#gavias_customize_save {
  background: #5dd8d3;
}

.gavias_customize_form .form-group.action input#gavias_customize_preview {
  background: #17a2b8;
}

.gavias_customize_form .form-group.action input#gavias_customize_reset {
  background: #fddd6e;
}
.cet-disclaimer-container {
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  text-align: center;
}

.cet-disclaimer {
  color: var(--clr-white);
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  max-width: 900px;
  margin-bottom: 50px;
}

.cet-disclaimer a {
  text-decoration: underline;
  font-weight: 600;
}
.cet-pp-logo-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cet-purple-grd-bg a {
  color: var(--clr-white);
  font-weight: 600;
}

.cet-purple-grd-bg, .cet-quote-purple-grd-bg, .cet-purple-grd-bg-container, .cet-hero-purple-grd-bg, .cet-branch-nav-purple-bg {
  background: linear-gradient(117.03deg, #210036 11.9%, #320148 34.01%, #22004C 63.11%, #000000 96.39%) !important;
}
.cet-purple-grd-bg-container .title {
  color: var(--clr-white);
  font-weight: 500;
}
.cet-purple-grd-bg-container p {
  color: var(--clr-white);
}
.cet-purple-grd-bg-container .gsc-button:hover, .cet-curved-img-bg-purple-grd .gsc-button:hover, .cet-teal-grd-bg-container .gsc-button:hover {
  color: var(--clr-white) !important;
}
.cet-purple-grd-bg h2 {
  font-weight: 500;
  padding-right: 40px;
  padding-left: 40px;
}
.cet-purple-grd-bg .bb-inner {
  padding-top: 110px;
  padding-bottom: 80px;
}
.cet-hero-purple-grd-bg .bb-inner, .cet-teal-grd-bg .bb-inner {
  padding: 20px;
}
.cet-hero-purple-grd-bg .column-inner, .cet-teal-grd-bg .column-inner {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-height: 330px;
}
.cet-hero-purple-grd-bg .heading-icon img, .cet-teal-grd-bg .heading-icon img {
  padding-bottom: 20px;
  padding-top: 14px;
}
.cet-hero-purple-grd-bg .sub-title, .cet-teal-grd-bg .sub-title {
  padding-bottom: 25px;
}
.cet-hero-purple-grd-bg .gsc-heading.style-1, .cet-teal-grd-bg .gsc-heading.style-1 {
  padding-bottom: 0;
}
.cet-hero-purple-grd-bg .gsc-heading.style-1 .title, .cet-teal-grd-bg .gsc-heading.style-1 {
  margin-top: 0;
}
.cet-home-logos-banner .bb-inner {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.cet-wide-img img {
  width: 100%;
}
.cet-wide-img {
  min-height: 550px;
}
.cet-circle-img-550px {
  max-width: 550px;
  max-height: 550px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}
.cet-flex-vertical-left-aligned {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.cet-copy h3 {
  font-weight: 600 !important;
  font-family: var(--ff-primary) !important;
  padding-top: 25px;
  padding-bottom: 30px;
}
.cet-copy li {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}
.cet-disclosure strong, .cet-disclosure b, .cet-disclosure h3[class*="cet-montserrat"], .cet-disclosure h4[class*="cet-montserrat"] {
  font-weight: 600 !important;
}
.cet-disclosure-container .bb-container, .cet-disclosure-container .gsc-heading .title {
  max-width: 1090px;
}

.cet-disclosure-container .gsc-icon-box .desc p {
  font-size: 16px;
  line-height: 22px;
}
.cet-disclosure-container a {
  color: var(--clr-purple);
}
.cet-disclosure-container a:hover {
  color: var(--clr-magenta);
}
.cet-disclosure-container .gsc-heading a {
  font-weight: 600;
}
.cet-disclosure-list-links a {
  padding-bottom: 32px;
  display: block;
}
.cet-disclosure-list-links p {
  margin-bottom: 0 !important;
}
.standard-card .widget {
  background: var(--clr-light-gray);
  border-radius: 9px;
  padding-bottom: 40px;
  min-height: 500px;
}
.standard-card-multi-rows .widget {
  margin-bottom: 40px;
}
.standard-card img {
  border-radius: 9px;
}
.standard-card .heading-content .title {
  margin-top: 23px;
  font-family: var(--ff-primary) !important;
  font-size: 22px;
  font-weight: 600;
}
.standard-card .heading-content .title-desc {
  margin-top: 16px;
}
.standard-card .heading-content {
  padding: 0 20px;
}
.standard-card .heading-action {
  bottom: 20px;
  position: absolute;
  padding-left: 20px;

}
.standard-card .btnlink {
  text-transform: none;
  font-size: 16px;
  border: none;
  margin-bottom: 0;
  line-height: 22px;
  font-weight: 600;
  color: var(--clr-black);
}
.standard-card .btnlink:hover {
  color: var(--clr-magenta);
}
.icon-box-classic .gsc-icon-box.top-center .title {
  font-size: 40px;
  font-weight: 700;
  max-width: 240px;
  margin: 0 auto;
}
.icon-box-classic .gsc-icon-box.top-center .desc p {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: var(--clr-dark-gray) !important;
}
.icon-box-classic .bb-inner {
  padding-top: 0 !important;
}
.icon-box-classic-3-icons .bb-container {
  max-width: 1000px;
}
.icon-box-classic a {
  font-size: 22px;
  color: var(--clr-dark-gray);
  font-family: var(--ff-primary);
}
.icon-box-classic a:hover {
  color: var(--clr-magenta);
}

.cet-footnote p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  font-family: var(--ff-primary);
  color: var(--clr-black);
  max-width: 750px;
  margin: 0 auto;
}
.cet-article-disclaimer p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  font-family: var(--ff-primary);
  color: var(--clr-dark-gray);
}
.centered-two-col-icon-box .row {
  max-width: 900px;
  margin: 0 auto;
}
.centered-two-col-icon-box .icon {
  margin-top: 0 !important;
}
.centered-two-col-icon-box .gsc-icon-box.text-left .highlight_content .title, .centered-two-col-icon-box .gsc-icon-box.text-left .desc {
  text-align: left !important;
}
.centered-two-col-icon-box .gsc-icon-box .desc a.btnlink {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: var(--clr-black);
}
.centered-two-col-icon-box .gsc-icon-box .desc a.btnlink:hover {
  color: var(--clr-magenta);
}
.img-border-radius-12px img {
  border-radius: 12px;
}
.img-border-radius-9px img {
  border-radius: 9px;
}
.img-border-radius-6px img {
  border-radius: 6px;
}
.mw-100 .title {
  max-width: 100%;
}

.cet-tab-component .nav-tabs {
  border-bottom: 1px solid var(--clr-light-gray);
}

.cet-tab-component ul > li {
  padding-bottom: 0;
  line-height: 0;
}

.cet-tab-component .gsc-tabs-content .nav-tabs > li > a.active {
  border-bottom: 7px solid var(--clr-yellow);
  border-radius: unset;
}

.cet-tab-component .gsc-tabs-content .nav-tabs > li > a {
  color: var(--clr-black);
  background: #fff;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--ff-primary);
  line-height: 20px;
  text-decoration: none;
  text-transform: none;
  padding: 25px 30px 20px;
  margin: 0 50px;
  border-bottom: 7px solid transparent;
  max-width: 218px;
}

.cet-tab-4-sections .gsc-tabs-content .nav-tabs > li > a {
  margin: 0 23px !important;
}

.cet-tab-component .gsc-tabs-content .nav-tabs > li > a:hover, .cet-tab-component .gsc-tabs-content .nav-tabs > li > a:focus, .cet-tab-component .gsc-tabs-content .nav-tabs > li > a:active {
  border-bottom: 7px solid var(--clr-yellow);
}

.cet-tab-component .gsc-tabs-content .gsc-tabs-content .tab-content {
  padding-top: 50px;
}

.cet-tab-component .boxes {
  padding: 35px 25px 45px;
  border: 1px solid var(--clr-light-gray);
  border-radius: 9px;
  background-color: var(--clr-white);
  min-height: 384px;
  background-repeat: no-repeat;
  background-position: right bottom;
  position: relative;
  height: 100%;
  box-shadow: 0 5px 20px rgb(0 0 0 / 7%);
}
.cet-tab-component a .boxes:hover {
  box-shadow: 0 5px 20px rgba(0,0,0,.3);
}

.cet-tab-component .tab-pane a, .cet-tab-component .card-body a {
  color: var(--clr-black);
  text-decoration: none;
}

.cet-tab-component a .boxes:hover .cet-tile-arrow:after {
  left: 10px;
}

.cet-tab-component .cet-tile-arrow {
  position: absolute;
  bottom: 20px;
}

.cet-tab-component .boxes h4 {
  font-size: 22px;
  font-weight: 600;
  line-height: 34px;
  font-family: var(--ff-primary) !important;
}

.cet-tab-component .boxes p {
  margin-bottom: 40px;
}

.cet-tab-component .gsc-column {
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 24px;
}

.cet-tab-component .tab-content .row {
  align-items: stretch;
}

.cet-tab-component .boxes .button-action {
  position: absolute;
  bottom: 30px;
}

.cet-tab-component .boxes .button-action .gsc-button {
  margin-bottom: 0;
}

.cet-tab-component .boxes .button-action .btnlink {
  font-weight: 600;
  line-height: 22px;
  color: var(--clr-black);
}

.cet-tab-component .boxes .button-action .btnlink:hover {
  color: var(--clr-magenta);
}

.cet-tab-component ul > li::before {
  content: none;
}

.field--type-text-with-summary h3[class*="cet-montserrat"] {
  font-weight: 600;
  margin-top: 0;
}
.field--type-text-with-summary strong {
  font-weight: 600;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .cet-tab-component .gsc-tabs-content .nav-tabs > li > a {
    margin: 0 40px;
  }
}


/* MOBILE */
#accordionComponentGrid {
  padding: 20px 10px 0;
}
.cet-tab-component .card {
  border: 0;
  border-radius: 0;
}
.cet-tab-component .card-header:first-of-type {
border-top-left-radius: 0;
border-top-right-radius: 0;
}
.cet-tab-component .card-header {
  padding: 15px 0;
  font-size: 15px;
  color: var(--clr-black);
  line-height: 24px;
  font-weight: 700;
  font-family: var(--ff-primary);
  text-align: left;
  background-color: #fff;
  border: 0;
  border-top: 1px solid var(--clr-dark-gray);
  overflow-anchor: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}
#accordionComponentGrid .card:last-of-type .card-header[aria-expanded="false"], #accordionComponentGrid .card .card-header[aria-expanded="true"] {
  border-bottom: 1px solid var(--clr-dark-gray);
}
.card-header .accicon {
  float: right;
  font-size: 20px;
  width: 24px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  height: 24px;
  background-repeat: no-repeat;
}
.card-header:not(.collapsed) .accicon {
  transform: rotate(-180deg);
}
.cet-tab-component .card-header:focus {
border-color: unset;
outline: none;
box-shadow: none;
}
#accordionComponentGrid .card-body {
  padding: 30px 0 20px 0;
}

.cet-tab-component .accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 5px;
}

.cet-tab-component .accordion-button:not(.collapsed) {
  color: var(--clr-black);
  background-color: var(--clr-white);
  box-shadow: none;
}

.cet-tab-component .accordion-header {
  margin: 0 10px;
}

.cet-tab-component .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}

.cet-tab-component .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.cet-tab-component .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.cet-tab-component .accordion-body {
  padding: 30px 0 20px;
}

.cet-tab-component .accordion-item {
  background-color: #fff;
  border: none;
}

.cet-tab-component .accordion-button {
  padding: 15px 0;
  font-size: 15px;
  color: var(--clr-black);
  line-height: 24px;
  font-weight: 700;
  font-family: var(--ff-primary);
  text-align: left;
  background-color: #fff;
  border: 0;
  border-top: 1px solid var(--clr-black);
  overflow-anchor: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}

.cet-tab-component .swiper-slide {
  min-height: unset;
  height: unset;
}

.accordion-item:last-of-type .accordion-button {
  border-bottom: 1px solid var(--clr-black);
}

.cet-tab-component .accordion-button[aria-expanded="true"] {
  border-bottom: 1px solid var(--clr-black);
}

.cet-tab-component .accordion-button:focus {
  border-color: unset;
  outline: none;
  box-shadow: none;
}

.cet-tab-component .swiper-horizontal>.swiper-pagination-bullets, .cet-tab-component .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .cet-tab-component .swiper-pagination-fraction {
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: 30px;
}

.cet-tab-component .swiper-pagination {
  position: relative;
}

.cet-tab-component .swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--clr-dark-gray);
}

.cet-single-grey-banner-quote {
  background: var(--clr-light-gray);
}

.gsc-cetera-quote-box {
  width: 100%;
  max-width: 360px;
  height: 100%;
  padding: 35px 30px 30px;
  border: 1px solid var(--clr-light-gray);
  border-radius: 9px;
}

.cet-single-grey-banner-quote .gsc-cetera-quote-box {
  width: 100%;
  max-width: 960px;
  height: 100%;
  padding: 20px 30px 20px;
  border: none;
  border-radius: unset;
}

.gsc-cetera-quote-box p {
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0 !important;
}

.cet-single-grey-banner-quote .gsc-cetera-quote-box p {
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 54px;
  margin-bottom: 0px !important;
}

.gsc-cetera-quote-box p:first-child {
  margin-bottom: 35px !important;
}

.gsc-cetera-quote-box__wrapper-quoter p:nth-child(1) {
  padding-bottom: 6px;
  margin-bottom: 0 !important;
  color: var(--clr-dark-gray);
}

.gsc-cetera-quote-box__wrapper-quoter p:first-child {
  color: var(--clr-purple);
}

.gsc-cetera-quote-box__wrapper-quoter p:last-child, .gsc-cetera-quote-box__wrapper-quoter p:nth-child(2) {
  padding-bottom: 0;
  color: var(--clr-dark-gray);
}

.gsc-cetera-quote-box__wrapper-bottom {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.gsc-cetera-quote-box__wrapper-image {
  min-width: 97px;
}

.gsc-cetera-quote-box__wrapper-image img{
  border-radius: 50% 50%;
  width: 97px;
  height: 97px;
}

.gsc-cetera-quote-box__wrapper-quoter p {
  margin: 0;
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}

.cet-breadcrumbs {
  font-weight: 600;
  color: var(--clr-magenta);
  line-height: 22px;
}
.cet-breadcrumbs a {
  color: var(--clr-dark-gray);
  text-decoration: none;
}
.cet-breadcrumbs a:hover {
  text-decoration: underline;
}
.cet-breadcrumbs a:after {
  content: url("../img/breadcrumbs-carrot.svg");
  padding-left: 10px;
  padding-right: 4px;
  top: 1px;
  position: relative;
}
.cet-box-quote .column-content-inner, .cet-single-grey-banner-quote .column-content-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.cet-icon-box-purple-container .column-content-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.cet-icon-box-purple-container a {
  width: 100%;
  max-width: 168px;
  margin: 0 12px;
}
.cet-icon-box-purple-container a .widget {
  border-radius: 9px !important;
}
.PRN_ImbeddedAssetReference img {
  max-width: 200px;
  margin: 20px 0;
}

@media (max-width: 767.98px) {
  .cet-tab-component .gsc-column {
    padding-right: 10px;
    padding-left: 10px;
  }
  .cet-tab-component .gsc-heading .title {
    padding: 0 10px;
  }
  .cet-tab-component .boxes {
    min-height: unset;
    margin: 0 10px;
    padding: 22px 25px 45px;
  }
  .gsc-cetera-quote-box {
    max-width: unset;
  }
  .cet-single-grey-banner-quote .gsc-cetera-quote-box {
    padding: 20px 0px 20px;
  }
  .gsc-cetera-quote-box p {
    font-size: 16px;
    line-height: 26px;
	}
  .cet-single-grey-banner-quote .gsc-cetera-quote-box p {
    font-size: 26px;
    line-height: 43px;
  }
  .cet-box-quote .gsc-column {
    padding-bottom: 20px;
  }
  .gsc-cetera-quote-box__wrapper-quoter p:nth-child(1), .gsc-cetera-quote-box__wrapper-quoter p:nth-child(2) {
    line-height: 22px;
  }

  .gsc-cetera-quote-box__wrapper-quoter p:last-child {
    line-height: 22px;
  }
  .cet-breadcrumbs, .cet-breadcrumbs a {
    font-size: 12px;
    line-height: 18px;
  }
  .cet-breadcrumbs {
    padding-top: 5px;
  }
  .cet-icon-box-purple-container a {
    max-width: 320px;
  }
}

.cet-income-stream-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  max-width: 790px;
  height: 236px;
  border-top: 6px solid var(--clr-purple);
  border-radius: 12px;
  -webkit-box-shadow: 0px 6px 15px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 6px 15px 0px rgba(0,0,0,0.1);
  margin: 0 auto;
}
.cet-income-stream-left {
  width: 185px;
  text-align: right;
  padding: 25px;
  border-right: 1px solid var(--clr-dark-gray);
  height: 179px;
  font-family: var(--ff-secondary);
  margin-top: 10px;
  margin-bottom: 10px;
}
.cet-income-stream-left h2 {
  font-size: 2.5rem;
  line-height: 50px;
  font-weight: 700;
  margin: 0;
}
.cet-income-stream-left p {
  margin: 0;
  padding-top: 15px;
  font-family: var(--ff-primary);
}
.cet-income-stream-right p.cet-is-disclaimer {
  margin: 0;
  font-size:  12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--clr-dark-gray);
  display: block;
  font-family: var(--ff-primary);
}
.cet-income-stream-right {
  width: 605px;
  text-align: left;
  padding: 25px;
  margin: 0;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 28px;
}

@media (max-width: 575.98px) {
  .cet-income-stream-container {
    flex-direction: column;
    height: unset;
  }
  .cet-income-stream-left {
    width: unset;
    text-align: center;
    border-right: none;
    border-bottom: 1px solid var(--clr-dark-gray);
    height: unset;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .cet-income-stream-right {
    width: 100%;
    text-align: center;
    padding: 15px 35px 40px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 28px;
  }
  .cet-income-stream-left p, .cet-income-stream-right p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
  .cet-icon-box-purple-container a {
    margin: 0 20px;
  }
}

@-moz-document url-prefix() {
  .stars {
    height: 33px !important;
    width: 229px !important;
  }
  .stars img {
    position: relative !important;
    top: -1px !important;
  }
}
@media (max-width: 459.98px) {
  .img-banner-illustration img {
    width: 100%;
    margin-bottom: 25px;
  }
}

@media (min-width: 460px) and (max-width: 767.98px) {
  .img-banner-illustration img {
    margin-bottom: 30px;
  }
}

@media (max-width: 767.98px) {
  h3, .h3 {
    font-size: 1.25rem;
    line-height: 30px;
  }
  .standard-card .widget {
    min-height: auto;
    padding-bottom: 3rem;
    margin-bottom: 0;
  }
  .standard-card-multi-rows .widget {
    margin-bottom: 0;
  }
  .standard-card .heading-action {
    bottom: 30px;
  }
  .standard-card .column-inner {
    max-width: 360px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .standard-card .btnlink {
    font-size: 14px;
  }
  .standard-card .heading-content .title {
    margin-top: 19px;
  }
  .standard-card .heading-content .title-desc {
    margin-top: 5px;
  }

  .gsc-heading.style-3 .title, .gsc-heading.style-4 .title {
    margin-top: 10px;
  }

  .icon-box-classic .gsc-icon-box.top-center .title {
    padding-bottom: 5px;
  }
  .icon-box-classic .gsc-icon-box.top-center .desc p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
  .centered-two-col-icon-box .gsc-icon-box.right, .centered-two-col-icon-box .gsc-icon-box.left {
    display: block;
  }
  .centered-two-col-icon-box .gsc-icon-box.right .highlight-icon, .centered-two-col-icon-box .gsc-icon-box.left .highlight-icon {
    display: block;
    padding-right: 0;
  }
  .centered-two-col-icon-box .gsc-icon-box.right .highlight_content, .centered-two-col-icon-box .gsc-icon-box.left .highlight_content {
    display: block;
  }
  .centered-two-col-icon-box .gsc-icon-box .icon-container {
    width: 100%;
    margin-bottom: 20px;
  }
  .centered-two-col-icon-box .gsc-icon-box .desc {
    text-align: center;
    padding-bottom: 30px;
  }
  .centered-two-col-icon-box .gsc-icon-box.right .highlight_content .title, .centered-two-col-icon-box .gsc-icon-box.left .highlight_content .title {
    text-align: center;
  }
  .gsc-image-content.skin-v1 .box-content, .gsc-image-content.skin-v4 .box-content, .gsc-image-content.skin-v5 .box-content, .gsc-image-content.skin-v6 .box-content {
    padding-left: 0;
  }
  .gsc-image-content.skin-v1 .box-image, .gsc-image-content.skin-v4 .box-image, .gsc-image-content.skin-v5 .box-image, .gsc-image-content.skin-v6 .box-image {
    max-width: 400px;
    margin-bottom: 40px;
  }
  .cet-hero-left-aligned .cet-hero-multiple-btn {
    padding-left: 20px;
    margin-top: 10px;
  }
  .cet-hero-multiple-btn .column-content-inner {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 991.98px) {
  .service-block-singe .post-content .post-title {
    font-size: 24px;
  }
  #block-sociallinks h2.block-title {
    font-weight: 600 !important;
  }
  #footer .socials-2 a {
    font-size: 24px;
  }
  .cet-hero-container {
    padding-top: 40px;
    padding-bottom: 60px;
    min-height: 525px;
  }
  .cet-purple-grd-bg .bb-inner {
    padding-top: 60px;
    padding-bottom: 45px;
  }
  .cet-wide-img {
    min-height: 350px;
  }
  .gsc-image-content.skin-v1 .box-content p, .gsc-image-content.skin-v4 .box-content p, .gsc-image-content.skin-v8 .box-content p {
    font-size: 28px;
    line-height: 38px;
  }
  .cet-tab-component.cet-tab-4-sections .gsc-column {
    padding-right: 20px;
    padding-left: 20px;
  }
  .icon-box-classic .gsc-icon-box.top-center .title {
    font-size: 1.75rem;
  }
  .cet-gated-brochure .gsc-column:first-child, .cet-gated-brochure .gsc-column:first-child img {
    text-align: center;
  }
  .cet-r-i-button a {
    position: relative;
    top: 59px;
    float: right;
    z-index: 1;
  }
  .cet-form-field {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .cet-hero-container h1 {
    max-width: 640px !important;
  }
  .cet-box-quote .gsc-column {
    padding-bottom: 40px;
  }
  .standard-card .widget {
    min-height: 554px;
  }
}
.cet-curved-img-banner-content .heading-action {
  margin-top: 36px;
}
.cet-curved-img-banner-content .gsc-heading.style-1 .title {
  margin-top: 0;
}
.cet-curved-img-bg-purple-grd .gsc-heading.style-1 .title {
  font-weight: 500;
}
.cet-curved-img-banner-content .column-content p {
  margin-bottom: 4px;
}
.cet-curved-img-banner-container {
  position: relative;
  width: 100%;
  border-radius: 12px;
  min-height: 450px;
  border: 1px solid #656565;
}
.cet-curved-img-bg-purple-grd {
  background: linear-gradient(117.03deg, #210036 0%, #320148 29.69%, #22004C 68.75%, #000000 100%);
  color: white;
  display: inline-block;
}
.cet-curved-img-bg-white {
  background-color: var(--clr-white);
  color: var(--clr-black);
}
.cet-curved-img-banner-content {
  font-size: 30px;
  font-weight: bold;
  position: relative;
}

.cet-image-list {
  width: 100%;
}

.cet-image-list p {
  max-width: 950px;
}

.cet-image-list .title {
  padding-bottom: 15px;
}

.cet-image-list__wrapper:first-child {
	border-top: 1px solid var(--clr-dark-gray);
  margin-top: 50px;
}

.cet-image-list__wrapper {
  display: flex;
  justify-items: center;
  width: 100%;
  border-bottom: 1px solid var(--clr-dark-gray);
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

a.cet-image-list__wrapper:link, a.cet-image-list__wrapper:visited, a.cet-image-list__wrapper:hover, a.cet-image-list__wrapper:active, a.cet-image-list__wrapper:focus, a.cet-image-list__wrapper:active p {
  text-decoration: none;
  color: #000;
}

.cet-image-list__image {
  width: 100%;
  max-width: 285px;
  margin-right: 50px;

}
.cet-image-list__image-round img {
  border-radius: 50% 50%;
  width: 92px;
  height: 92px;
  padding: 0;
  object-fit: cover;
}
.cet-image-list__image.cet-image-list__image-round {
  margin-left: 60px;
  margin-right: 100px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 94px;
  max-width: 94px;
}

.cet-image-list__text {
  margin-right: 76px;
  width: 100%;
}
.cet-image-list__text p {
  margin: 0 !important;
  font-size: 22px;
  line-height: 32px;
  display: block !important;
}
.cet-image-list__text h6 {
  font-family: var(--ff-primary) !important;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: var(--clr-dark-gray);
}

.cet-image-list__link {
  justify-self: right;
  margin-right: 30px;
}

.cet-image-list__link span {
  display: block;
  width: 56px;
  height: 56px;
  border-radius: 50% 50%;
  border: 1px solid var(--clr-dark-gray);
}

.cet-image-list__wrapper:focus .cet-image-list__link span {
  background-color: transparent !important;
}

.cet-icon-box-rounded-border, .cet-icon-box-square-border, .cet-icon-box-square-border-text {
  color: var(--clr-dark-gray);
}

.cet-icon-box-rounded-border .gsc-column {
  padding-left: 8px;
  padding-right: 8px;
}

.cet-icon-box-rounded-border .gsc-icon-box-style.style-1 {
  text-align: center;
  position: relative;
  padding: 50px 10px 0;
  border-radius: 9px;
  -webkit-transition: none;
  transition: none;
  border: 1px solid var(--clr-light-gray);
  margin-bottom: 16px;
  min-height: 230px;
}

.cet-icon-box-rounded-border .gsc-icon-box-style.style-1 .box-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  font-family: var(--ff-primary) !important;
}

.cet-icon-box-rounded-border a {
  text-decoration: none;
  color: var(--clr-dark-gray);
  transition: none;
}

.cet-icon-box-rounded-border a:hover {
  color: var(--clr-magenta) !important;
  text-decoration: underline;
}

.cet-icon-box-square-border-4-icons .bb-container {
  max-width: 770px;
}

.cet-icon-box-square-border-3-icons .bb-container {
  max-width: 578px;
}

.cet-icon-box-square-border-2-icons .bb-container {
  max-width: 384px;
}

.cet-icon-box-square-border-text-2-icons .bb-container {
  max-width: 464px;
}

.cet-icon-box-square-border-text-3-icons .bb-container {
  max-width: 696px;
}

.cet-icon-box-square-border .gsc-column, .cet-icon-box-square-border-text .gsc-column {
  padding-left: 12px;
  padding-right: 12px;
}

.cet-icon-box-square-border .gsc-heading.style-1 {
  padding-bottom: 15px;
}

.cet-icon-box-square-border h5 {
  font-family: var(--ff-primary) !important;
  font-weight: 600;
}

.cet-icon-box-square-border .gsc-icon-box-style.style-1 {
  text-align: center;
  position: relative;
  padding: 34px 10px 0;
  border-radius: 0;
  -webkit-transition: none;
  transition: none;
  border: 1px solid var(--clr-light-gray);
  margin-bottom: 24px;
  max-height: 168px;
  min-height: 168px;
}

.cet-icon-box-square-border .gsc-icon-box-style.style-1 .box-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  font-family: var(--ff-primary) !important;
  margin-top: 9px;
  color: var(--clr-black);
}

.cet-icon-box-square-border a .gsc-icon-box-style.style-1:hover, .cet-icon-box-square-border-text a .gsc-icon-box-style.style-1:hover {
  box-shadow: 0 5px 26px rgb(0 0 0 / 8%);
  border-color: rgba(0, 0, 0, 0.08);
}

.cet-icon-box-square-border .gsc-icon-box-style.style-1:hover, .cet-icon-box-square-border-text .gsc-icon-box-style.style-1:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: var(--clr-light-gray);
}

.cet-icon-box-square-border a, .cet-icon-box-square-border-text a {
  text-decoration: none;
}

.cet-icon-box-square-border a:hover .gsc-icon-box-style.style-1 .box-title, .cet-icon-box-square-border-text a:hover .gsc-icon-box-style.style-1 .box-title, .cet-icon-box-square-border-text a:hover .gsc-icon-box-style.style-1 .box-desc p {
  color: var(--clr-magenta) !important;
}

.cet-icon-box-square-border-text .gsc-icon-box-style.style-1 {
  text-align: center;
  position: relative;
  padding: 0 40px 0;
  border-radius: 0;
  -webkit-transition: none;
  transition: none;
  border: 1px solid var(--clr-light-gray);
  margin-bottom: 24px;
  min-height: 230px;
  max-width: 204px;
}

.cet-icon-box-square-border-text .gsc-icon-box-style.style-1 .box-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  font-family: var(--ff-primary) !important;
  margin-top: 34px;
  color: var(--clr-dark-gray);
}

.cet-icon-box-square-border-text .gsc-icon-box-style.style-1 .box-title span {
  font-size: 36px;
  line-height: 34px;
  font-family: var(--ff-secondary);
  font-weight: 700;
  color: var(--clr-purple);
  margin-top: 23px;
  display: block;
  margin-bottom: 25px;
}

.cet-icon-box-square-border-text .gsc-icon-box-style.style-1 .box-desc p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  font-family: var(--ff-primary);
  color: var(--clr-black);
}

.cet-image-list__date.cet-image-list__show-date {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin-right: 49px;
}

.cet-image-list__date-btn__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 25px;
}

.cet-image-list__date.cet-image-list__show-date span:first-child {
  font-size: 75px;
  font-weight: 400;
  line-height: 80px;
  padding-right: 20px;
}

.cet-image-list__date.cet-image-list__show-date span:last-child {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  color: var(--clr-dark-gray);
  width: 65px;
}

.cet-tab-component.cet-tab-4-sections .gsc-tabs-content .tab-content {
  padding-top: 60px;
  max-width: 937px;
  margin: 0 auto;
}

.cet-tab-component.cet-tab-4-sections.cet-generic-page .row {
  max-width: 937px;
  margin: 0 auto;
}

.cet-tab-component.cet-tab-4-sections .gsc-icon-box.left .highlight-icon {
  padding-right: 18px;
}

.cet-tab-component.cet-tab-4-sections .gsc-icon-box .icon-container.fa-2x .icon {
  margin-top: 12px;
}

.cet-tab-component.cet-tab-4-sections.cet-generic-page .gsc-icon-box .icon-container.fa-2x .icon {
  margin-top: 0;
}

.cet-tab-component.cet-tab-4-sections .gsc-icon-box.right .highlight_content .title, .cet-tab-component.cet-tab-4-sections .gsc-icon-box.left .highlight_content .title {
  margin-bottom: 5px;
  font-family: var(--ff-primary) !important;
  position: relative;
  font-weight: 600;
  font-size: 22px;
}

.cet-tab-component.cet-tab-4-sections .gsc-icon-box .desc p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

.cet-tab-component.cet-tab-4-sections .gsc-icon-box .desc p a {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  text-decoration: none;
  color: var(--clr-black);
}

.cet-tab-component.cet-tab-4-sections .gsc-icon-box .desc p a:hover {
  text-decoration: underline;
}
.cet-modal-bio .modal-body::-webkit-scrollbar {
  width: 7px;
  }

.cet-modal-bio .modal-body::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);
  }

.cet-modal-bio .modal-body::-webkit-scrollbar-thumb {
  background-color: var(--clr-purple);
  }

.cet-modal-bio__wrapper {
  flex-direction: row;
  padding: 40px 0;
  border-radius: 9px;
}

.cet-modal-bio img {
  width: 260px;
  height: 100%;
  border-radius: 0 6px 6px 0;
 }

.modal-content__right {
  margin-right: 2rem;
}

.modal-content__right .modal-header {
    border: none;
    padding: 0 0 15px 30px;
}

.modal-header__name-title-wrapper a {
  outline: none;
}

.modal-header__name-title-wrapper div:first-child {
    font-family: var(--ff-primary);
    font-weight: 600;
    font-size: 22px;
    color: var(--clr-purple);
    line-height: 28px;
}
.modal-header__name-title-wrapper div:last-child {
    font-family: var(--ff-primary);
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--clr-dark-gray);
}

.cet-modal-bio .modal-body {
    overflow-y: auto;
    max-height: 491px;
    padding: 10px 35px 0 30px;
}

.cet-modal-bio .modal-body p, .cet-modal-bio .modal-body ul, .cet-modal-bio .modal-body ol {
    margin-bottom: 25px;
}

.cet-modal-bio .modal-body p:last-child {
    margin-bottom: 0;
}

.cet-modal-bio .modal-body p a {
    color: var(--clr-purple);
}

.cet-modal-bio .modal-body p a:hover {
    color: var(--clr-magenta);
    text-decoration: none;
}

.cet-modal-bio .modal-body p strong {
  font-weight: 600;
}

.cet-modal-bio .modal-body li {
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 5px;
}

.modal-body__quote-wrapper {
    border: 1px solid var(--clr-light-gray);
    padding: 39px 25px 39px 20px;
    margin-bottom: 1.5rem;
    display: flex;
    -webkit-flex: 0;
    flex-shrink: 0;
}

.modal-body__quote-symbol {
    font-family: var(--ff-secondary);
    font-style: normal;
    font-weight: 500;
    font-size: 75px;
    line-height: 70px;
    color: var(--clr-yellow);
    margin-bottom: 0 !important;
    margin-right: 24px;
}

.modal-body__quote {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: var(--clr-dark-gray);
}
.cet-hero-branch-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  min-height: 470px;
}
.cet-hero-branch-container:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.55);
}
.cet-hero-branch-container .gsc-heading .sub-title {
  font-size: 16px;
  line-height: 28px;
}
.cet-hero-branch-container .bb-inner {
  padding-left: 0;
  padding-right: 0;
}
.cet-hero-branch-container .heading-content .title-desc p:first-child {
  margin-bottom: 15px;
}
.cet-hero-branch-container hr {
  width: 56px;
  margin: 8px 0 20px 0;
  border-top: 1px solid var(--clr-white);
}
.cet-hero-branch-container .gsc-heading .title-desc p {
  margin-bottom: 15px;
}
.cet-hero-branch-container .gsc-heading h2, .cet-hero-branch-container .gsc-heading h3 {
  font-weight: 500;
  margin-bottom: 2px;
}
.cet-branch-card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.cet-branch-card {
  max-width: 283px;
  width: 100%;
  min-height: 419px;
  border: 1px solid var(--clr-light-gray);
  border-radius: 9px;
  text-align: center;
  padding: 30px;
  float: right;
  position: relative;
  top: -223px;
  background-color: var(--clr-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
}
.cet-branch-advisor-photo img {
  border-radius: 50%;
  margin-bottom: 20px;
}
.cet-branch-card h3 {
  font-family: var(--ff-primary) !important;
  font-size: 22px;
  font-weight: 600;
}
.cet-branch-card p {
  margin-bottom: 20px;
}
.cet-branch-card .gsc-button {
  width: 100%;
  margin-bottom: 12px;
}
.cet-branch-card .gsc-button:last-child {
  margin-bottom: 0;
}
.cet-purple-grd-bg-container.cet-branch .bb-inner {
margin-bottom: 0;
}
.cet-purple-grd-bg-container.cet-branch .gsc-heading.style-5 {
  padding: 15px 0;
}
.cet-purple-grd-bg-container.cet-branch .gsc-heading .title-desc {
  clear: both;
  max-width: 950px;
  margin: 0 auto 0;
  margin-top: 12px;
}
.cet-purple-grd-bg-container.cet-branch .gsc-heading .title {
  max-width: unset;
  margin: inherit;
}
.cet-purple-grd-bg-container.cet-branch .gsc-button {
  margin-bottom: 0;
}
.cet-branch-purple-banner-btn .column-content-inner {
  text-align: right;
}
.cet-branch-left-content p {
  margin-bottom: 50px;
}
.cet-branch-left-content h3 {
  font-family: var(--ff-primary) !important;
  font-weight: 600;
  font-size: 22px;
}
#footer.cet-branch-footer {
  margin-top: 0px !important;
}
#block-footerfirstblock {
  margin-bottom: 0;
}
#block-footerfirstblock p {
  line-height: 24px;
  margin-bottom: 24px;
}
#block-footerfirstblock .footer-second p {
  font-size: 16px;
  line-height: 22px;
}
#block-footerfirstblock a {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  text-decoration: none;
  padding-bottom: 17px;
  display: inline-block;
  top: -2px;
  position: relative;
}
#block-footerfirstblock .hidden-xl a {
  padding-bottom: 32px;
}
#block-footerfirstblock .row {
  padding-bottom: 30px;
  margin-left: 0;
  margin-right: 0;
}

.cet-RIA-animated-gif .widget-content, .cet-marketingcentral-animated-gif .widget-content  {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cet-404 hr {
  margin: 30px 0;
  border-top: 1px solid var(--clr-light-gray);
}

.cet-sitemap .title {
  font-family: var(--ff-primary) !important;
  font-weight: 600;
  border-bottom: 1px solid var(--clr-black);
  padding-bottom: 3px !important;
  margin-top: 30px !important;
}
.cet-sitemap p a {
  color: var(--clr-black);
  font-weight: 600;
  display: block;
  line-height: 25px;
  margin-bottom: 15px;
}
.cet-sitemap p a:hover, .cet-sitemap-submenu a:hover {
  color: var(--clr-magenta);
}
.cet-sitemap-submenu a {
  color: var(--clr-black);
  margin-left: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  display: block;
  margin-bottom: 15px;
}
.cet-sitemap-submenu-2 a {
  margin-left: 40px;
}

.cet-dropdown-white .cet-recruiter-container {
  color: var(--clr-black);
}

.cet-dropdown-white .cet-contact-dropdown-data .gsc-column {
  background-color: var(--clr-white);
}

.cet-dropdown-white .cet-recruiter-contact {
  color: var(--clr-dark-gray);
}
.cet-dropdown-white .cet-recruiter-btn button {
  border: 2px solid var(--clr-black) !important;
  color: var(--clr-black);
  font-weight: 600;
}
.cet-dropdown-white .cet-recruiter-btn button:hover {
  background-color: var(--clr-white);
  color: var(--clr-black);
  border: 2px solid var(--clr-yellow) !important;
}

@media (max-width: 767px) {
  .cet-curved-desktop {
    display: none;
  }
  .cet-curved-img-banner-content {
    padding: 255px 20px 30px;
  }
  .cet-curved-img-clip {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-position: center top;
    border-radius: 12px;
  }
  .cet-magenta-circle img {
    display: none;
  }
  .gsc-image-content.skin-v5 .box-image::after {
    right: 0;
    bottom: 45px;
  }
  .gsc-image-content.skin-v5 .box-content p, .gsc-image-content.skin-v6 .box-content p {
    font-size: 21px;
    line-height: 32px;
  }
  .cet-image-list__wrapper {
    padding: 25px 0;
  }
  .cet-image-list__text {
    padding: 15px 0;
    margin-right: 0.5rem;
    margin-left: 0 !important;
	}
  .cet-image-list__link {
    margin-right: 0;
	}
  .cet-image-list__show-image, .cet-image-list__show-text {
    display: block;
  }
  .cet-image-list__hide-image, .cet-image-list__hide-text {
    display: none;
  }
  .cet-icon-box-rounded-border .gsc-icon-box-style.style-1 {
    min-height: 206px;
    margin-bottom: 15px;
  }
  .cet-icon-box-rounded-border .gsc-column {
    padding-left: 15px;
    padding-right: 15px;
  }
  .cet-icon-box-square-border-text .gsc-icon-box-style.style-1 {
    min-height: 222px;
    max-width: unset;
  }
  .gsc-quotes-rotator .cbp-qtrotator .cbp-qtcontent p {
    padding-top: 7px;
    padding-bottom: 10px;
  }
  .gsc-quotes-rotator .cbp-qtrotator {
    border-bottom: 1px solid var(--clr-light-gray);
    margin-bottom: 20px;
  }
  .gsc-quotes-rotator .cbp-qtrotator .cbp-qtcontent {
    padding: 24px 0 0;
  }
  .cet-image-list__date.cet-image-list__show-date span:first-child {
    display: none;
  }
  .cet-image-list__date.cet-image-list__show-date {
    margin-right: 0;
  }
  .cet-image-list__date.cet-image-list__show-date span:last-child {
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 10px;
    max-width: 50px;
  }
  .cet-image-list__date-btn__container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 10px;
  }
  .cet-tab-component.cet-tab-4-sections .select ul li.option {
    z-index: 1;
    padding: 0;
    display: none;
    margin-bottom: 0;
  }
  .cet-tab-component.cet-tab-4-sections .select ul li:first-child {
      display: block;
  }
  .cet-tab-component.cet-tab-4-sections .select ul li {
      cursor: pointer;
  }
  .cet-tab-component .nav-tabs.cet-tab-dropdown {
    border-bottom: 1px solid var(--clr-light-gray);
    margin: 0;
    border: 1px solid var(--clr-dark-gray) !important;
    max-width: unset;
    border-radius: 9px !important;
  }
  .cet-tab-component .gsc-tabs-content .nav-tabs.cet-tab-dropdown > li > a.btnlink:hover {
    border-bottom: 0;
    background: var(--clr-light-gray);
  }
  .cet-tab-component .gsc-tabs-content .nav-tabs.cet-tab-dropdown > li > a:focus, .cet-tab-component .gsc-tabs-content .nav-tabs.cet-tab-dropdown > li > a:active {
    border-bottom: 0;
    background: transparent;
  }
  .cet-tab-component .gsc-tabs-content .nav-tabs.cet-tab-dropdown > li > a {
    color: var(--clr-dark-gray);
    background: transparent;
    font-size: 16px;
    font-weight: 400;
    font-family: var(--ff-primary);
    line-height: 22px;
    text-decoration: none;
    text-transform: none;
    padding: 11px 10px;
    margin: 0 !important;
    border-bottom: none;
    max-width: unset;
    text-align: left;
  }

  .cet-tab-component .gsc-tabs-content .nav-tabs.cet-tab-dropdown > li > a:first-child:hover {
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
  }
  .cet-tab-component .gsc-tabs-content .nav-tabs.cet-tab-dropdown > li > a:last-child:hover {
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }
  .modal-header .close, .modal-header svg {
    outline: none;
  }
  .cet-modal-bio__wrapper {
    flex-direction: column;
  	padding: 1.5rem 0;
    border: none;
  }
  .cet-modal-bio__wrapper > .modal-header {
    padding: 0 1rem 1rem;
    border: none;
  }
  .cet-modal-bio img {
    width: 300px;
    height: 100%;
    align-self: center;
    margin-bottom: 1.5rem;
    border-radius: 6px;
  }
  .modal-content__right {
  	margin-right: 1rem
  }
  .modal-content__right .modal-body {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1.5rem 1rem;
  }
  .cet-modal-bio .modal-body li {
    font-size: 16px;
    line-height: 26px;
  }
  .cet-breadcrumbs a:after {
    top: 2px;
  }
  .cet-branch-left-content h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .cet-sitemap-submenu a {
    font-size: 16px;
  }
}

@media (max-width: 420px) {
  .cet-icon-box-square-border-text .gsc-icon-box-style.style-1 {
    min-height: 245px;
    max-width: unset;
  }
}

@media (min-width: 768px) {
  .cet-flip-img-horizontal {
    transform: scaleX(-1);
  }
  .cet-curved-mobile{
    display: none;
  }
  .cet-curved-img-banner-content {
    width: 100%;
    max-width: 540px;
    float: right;
    margin-right: 35px;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: flex-start;
    flex-direction: column;
    align-content: flex-start;
    height: 100%;
    min-height: 450px;
  }
  .cet-curved-img-banner-content .heading-action .gsc-button {
    margin-bottom: 8px;
  }
  .cet-curved-img-clip {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-position: left center;
    border-radius: 12px;
    clip-path: circle(38% at 18% 50%);
  }
  .cet-magenta-circle {
    position: absolute;
    bottom: 44px;
    left: 456px;
  }
  .cet-hero-multiple-btn .column-content-inner a:nth-of-type(2) {
    margin-left: 30px;
  }
  .cet-hero-left-aligned .heading-action {
    margin-left: 30px;
  }
  .cet-image-list__wrapper {
    min-height: 176px;
  }
  .cet-image-list__image {
    margin-left: 30px;
  }
  .cet-image-list__date-btn__container .cet-image-list__link {
    margin-right: 0;
  }
  .cet-modal-bio {
    max-width: 75vw;
 }
 .cet-article-links {
  padding: 5px 0 5px 25px;
  border-left: 1px solid var(--clr-dark-gray);
  }
  .cet-box-quote .column-inner {
    height: 100%;
  }
  .cet-box-quote .column-content-inner {
    height: 100%;
  }
  .gsc-cetera-quote-box {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
  }
  .standard-card.cet-min-height-465 .widget {
    min-height: 465px !important;
  }
}
@media (max-width: 419.98px) {
  .cet-curved-img-banner-content {
    padding: 250px 30px 30px;
  }
  .cet-curved-img-clip {
    clip-path: circle(440px at 50% -221px);
    background-position: center top;
  }
}
@media (min-width: 420px) and (max-width: 575.98px) {
  .cet-curved-img-banner-content {
    padding: 285px 30px 30px;
  }
  .cet-curved-img-clip {
    clip-path: circle(470px at 50% -220px);
    background-position: center top;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .cet-curved-img-banner-content {
    padding: 285px 30px 30px;
  }
  .cet-curved-img-clip {
    clip-path: circle(410px at 50% -160px);
    background-position: center top;
  }
  .cet-icon-box-purple-container a {
    margin: 0 30px;
  }
}

@media (max-width: 575.98px) {
  .cet-icon-box-square-border-text .gsc-icon-box-style.style-1 {
    padding: 0 15px 0;
    max-width: unset;
  }
  .cet-modal-bio img {
    width: 100%;
    max-width: 300px;
  }
  .modal-header__name-title-wrapper div:first-child {
    font-size: 20px;
    line-height: 26px;
  }
  .modal-header__name-title-wrapper div:last-child {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .cet-curved-img-banner-content .gsc-heading.style-1 {
    padding-bottom: 25px;
  }
  .cet-curved-img-banner-content {
    max-width: 300px;
  }
  .cet-curved-img-clip {
    clip-path: circle(50% at 8% 50%);
    background-position: -110px center;
  }
  .cet-magenta-circle {
    left: 272px;
  }
  .cet-icon-box-rounded-border .gsc-icon-box-style.style-1 {
    min-height: 228px;
  }
  .cet-icon-box-rounded-border .gsc-icon-box-style.style-1 .box-title {
    font-size: 14px;
    line-height: 24px;
  }
  .cet-icon-box-square-border-text .gsc-icon-box-style.style-1 {
    min-height: 250px;
    max-width: unset;
  }
  .gsc-quotes-rotator .cbp-qtrotator .cbp-qtcontent {
    padding: 40px 0 0;
  }
  .cet-image-list__date.cet-image-list__show-date {
    margin-right: 30px;
  }
  .cet-image-list__date.cet-image-list__show-date span:first-child {
    font-size: 55px;
    line-height: 65px;
  }
  .cet-image-list__date.cet-image-list__show-date span:last-child {
    width: unset;
  }
  .cet-image-list__date.cet-image-list__show-date .cet-image-list__link {
    margin-right: 10px;
  }
  .cet-tab-4-sections .gsc-tabs-content .nav-tabs > li > a {
    max-width: 185px !important;
    margin: 0 !important;
    padding: 25px 20px 20px !important;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .gsc-quotes-rotator .cbp-qtrotator .cbp-qtcontent {
    padding: 40px 0 0;
  }
  .gsc-quotes-rotator .cbp-qtrotator .cbp-qtcontent p {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .gsc-image-content.skin-default {
    margin-top: 40px;
    margin-bottom: 120px;
  }
}

@media (min-width: 930px){
  .cet-modal-bio img {
   width: 360px;
   height: 577px;
  }
 }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .cet-curved-img-banner-content {
    max-width: 440px;
  }
  .cet-curved-img-clip {
    clip-path: circle(50% at 8% 50%);
    background-position: -40px center;
  }
  .cet-magenta-circle {
    left: 360px;
  }
  .gsc-quotes-rotator .cbp-qtrotator .cbp-qtcontent {
    padding: 40px 0 0;
  }
  .cet-image-list__image.cet-image-list__image-round {
    margin-left: 50px;
    margin-right: 50px;
  }
  .cet-image-list__text {
    margin-right: 50px;
  }
  .cet-tab-4-sections .gsc-tabs-content .nav-tabs > li > a {
    max-width: 185px !important;
    margin: 0 23px !important;
    padding: 25px 20px 20px !important;
  }
  .cet-hero-branch-container .heading-content {
    max-width: 650px;
  }
}

@media (max-width: 991.98px) {
  .cet-hero-container .title-desc p {
    font-size: 22px;
    line-height: 30px;
    max-width: 600px;
  }
  #footer .footer-second, #footer .footer-third, #footer .footer-four {
    padding-left: 5px;
  }
  .cet-image-list__text p {
    font-size: 18px;
    line-height: 28px;
  }
  .cet-image-list__image.cet-image-list__image-round {
    margin-left: 30px;
    margin-right: 30px;
  }
  .cet-image-list__text {
    margin-right: 30px;
    margin-left: 15px;
  }
  .cet-image-list__link span {
    width: 44px;
    height: 44px;
  }

  .cet-icon-box-square-border-4-icons .bb-container, .cet-icon-box-square-border-text-3-icons .bb-container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .cet-hero-branch-container {
    min-height: 426px;
  }
  .cet-branch-card-container {
    align-items: center;
  }
  .cet-branch-card {
    max-width: 400px;
    top: 0;
    margin-top: 50px;
  }
  .cet-branch-purple-banner-btn .column-content-inner {
    text-align: left;
  }
  .cet-purple-grd-bg-container.cet-branch .gsc-button {
    margin-top: 11px;
  }
  #block-footerfirstblock .footer-second p:first-child {
    margin-bottom: 0px;
  }
  #block-footerfirstblock .footer-second {
    margin-top: 0px !important;
    border-bottom: 1px solid var(--clr-dark-gray);
  }
  #block-footerfirstblock .row {
    margin-left: 10px;
    margin-right: 10px;
  }
  #block-footerfirstblock .footer-first, #block-footerfirstblock .footer-second, #block-footerfirstblock .footer-third {
    padding-left: 10px;
    padding-right: 10px;
  }
  #block-footerfirstblock .footer-second p {
    font-size: 16px;
    line-height: 22px;
 }
}
@media (min-width: 992px) {
  .cet-image-list__date-btn__container .cet-image-list__link span {
    display: block;
    font-size: 14px;
    padding: 5px 22px;
    width: 136px;
    border-radius: 25px;
    border: 1px solid var(--clr-dark-gray);
    color: var(--clr-dark-gray);
    height: unset;
  }
  #footer.cet-branch-footer .footer-first {
    padding-left: 20px;
  }
  #block-footerfirstblock .footer-third {
    padding-left: 100px;
  }
  .cet-hero-branch-container .heading-content {
    max-width: 820px;
  }
  .cet-article-links a.gsc-button.large {
    padding: 7px 13px;
  }
  .cet-r-i-button a {
    position: relative;
    top: 66px;
    float: right;
    z-index: 1;
 }
}

a.cet-image-list__wrapper:hover {
  background-color: var(--clr-light-gray);
}

.cet-image-list__wrapper:hover .cet-image-list__link span {
  background-color: var(--clr-yellow);
}

@media (hover: none) {
  a.cet-image-list__wrapper, a.cet-image-list__wrapper:focus {
    background-color: var(--clr-white) !important;
  }

  .cet-image-list__wrapper .cet-image-list__link span, .cet-image-list__wrapper:focus .cet-image-list__link span {
    background-color: var(--clr-white) !important;
  }
}
/* Apple iPhone issues */
@supports (-webkit-overflow-scrolling: touch) {
  h2.title, .gsc-quotes-rotator h2, .gsc-icon-box.top-center .desc h2 {
    letter-spacing: -2px !important;
 }
 .gsc-accordion .panel-group.skin-white-border .panel .panel-heading .panel-title > a {
    letter-spacing: -1px !important;
 }
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  .stars {
   height: 33px !important;
   width: 229px !important;
 }
 .stars img {
   position: relative !important;
   top: -1px !important;
 }
}

/* ########################################################################
#                                                                         #
#                               CUSTOM CSS                                #
#                                                                         #
######################################################################### */

@media only screen and (min-device-width: 991px) {
  header .navigation .gva_menu .sub-menu {
    display: block !important;
  }
}
.m-components-tab-grid, .m-components-tab-width-image {
  display:none;
}

.icon-images {
  float:right;
}

.card-header .accicon {
  float: right;
  font-size: 20px;
  width: 1.2em;
}

.card-header{
  cursor: pointer;
  border-bottom: none;
}

.card-header:not(.collapsed) .rotate-icon {
  transform: rotate(180deg);
}

@media (max-width: 767.98px) {
  .m-components-tab-grid, .m-components-tab-width-image {
    display:block;
  }
  .d-components-tab-grid, .d-components-tab-width-image {
    display:none;
  }
}

@media only screen and (max-width: 1039.98px) {
  header .navigation .gva_menu>li:hover>a>span:first-child, header .navigation .gva_menu>li.menu-item--active-trail>a:first-child span {
    border-bottom: none;
  }
}

.contact-search-results-purple, .contact-search-results-white {
  display: none;
}

.purple-width {
  padding: 0px !important;
}

.contact-drop-modal{
  max-width: 60% !important;
}

.contact-modal-title {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 15px;
  padding-left: 15px;
}

.contact-drop-modal .mktoOffset{
  height: 0px !important;
}
.contact-drop-modal .modal-body{
  padding: 0rem 3rem 2rem 3rem !important;
}

.contact-modal-image{
  text-align: left;
  padding-left: 35px !important;
}

.contact-modal-image img{
  max-width: 100%;
  margin-top: 47px;
  border-radius: 6px;
}

.contact-modal-image .contact-modal-fname{
  font-size: 26px;
  width: 100%;
  text-align: left;
  margin: 10px 0 7px;
  line-height: 32px;
}

.contact-modal-image .contact-modal-design{
  font-size: 18px;
  width: 100%;
  text-align: left;
  margin: auto;
  line-height: 24px;
}

.contact-drop-modal .modal-header {
  padding-bottom: 0px;
}

#cet-form {
  margin: 0 auto;
  max-width: 800px;
}
.cet-form-field label {
  margin-bottom: 0px;
  margin-top: 15px;
  line-height: 22px;
}
.cet-form-field select, .cet-form-field textarea, .cet-form-field input {
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100% !important;
  padding: 5px 15px !important;
  height: 48px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  color: var(--clr-dark-gray);
  text-align: left;
  border-radius: 6px;
  border: solid 1px var(--clr-dark-gray);
  font-family: 'Montserrat' !important;
  -webkit-transition: all 0.8s cubic-bezier(0.165,0.84,0.44,1);
  -o-transition: all 0.8s cubic-bezier(0.165,0.84,0.44,1);
  transition: all 0.8s cubic-bezier(0.165,0.84,0.44,1);
  width: 100%;
  outline: none;
  appearance: none;
}
.cet-form-field select:focus, .cet-form-field textarea:focus, .cet-form-field input:focus {
  border-color: var(--clr-black);
}
.cet-form-field textarea {
  height: 100px !important;
}
.cet-form-field select {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background: url(https://pages.cetera.com/rs/211-FLX-077/images/form-arrow.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) calc(1em + 6px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
}

@media only screen and (min-width: 768px) {
  .contact-drop-modal {
    max-width: 758px !important;
  }
}
@media only screen and (max-width: 767.98px) {
  .contact-drop-modal {
    max-width: 550px !important;
  }
}

.cetera-contacts-row .modal-content {
  border-radius: 9px;
}

@media only screen and (max-width: 549px) {
  .modal-dialog.contact-drop-modal {
    margin: 0.5rem;
  }
}
@media only screen and (min-width: 550px) {
  .modal-dialog.contact-drop-modal {
    margin: 0.5rem auto;
  }
}

.cetera-contacts-components-white .row.row-wrapper {
  margin-right: -20px;
  margin-left: -20px;
}
.cetera-contacts-components-white .gsc-column {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
@media (max-width: 991.98px) {
  .cet-contact-dropdown-banner .cet-curved-img-banner-container .contact-states, .cet-dropdown-white .contact-states {
    min-width: 230px;
    width: 100%;
  }
}
@media (min-width: 576px) {
  .contacts-search-results-purple {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (min-width: 992px) {
  .cet-contact-dropdown-banner .cet-curved-img-banner-container .contact-states, .cet-dropdown-white .contact-states {
    min-width: 320px;
  }
}

.no-state-wrapper {
  min-height: 0px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .no-state-wrapper {
    min-height: 0px;
  }
}

.cet-contact-dropdown-banner .gsc-column {
  padding-left: 35px;
  padding-right: 35px;
  z-index: 1;
}

.gsc-button.large {
  max-width: 100%;
}

select.gsc-button.large.contact-states option {
  background: white;
}

.cet-contact-dropdown-data .gsc-column {
  padding-left: 35px;
  padding-right: 35px;
  background-color: var(--clr-purple);
}

.cet-recruiter-container {
  padding: 35px 0;
  color: var(--clr-white);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.cet-recruiter-photo {
  max-width: 170px;
  width: 100%;
}

.cet-recruiter-data-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.cet-recruiter-data {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 0 20px;
}

.cet-recruiter-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.cet-recruiter-name {
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  font-family: var(--ff-primary);
}
.cet-recruiter-coverage, .cet-recruiter-contact {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 5px;
}

.cet-recruiter-btn button {
  background: transparent;
  font-size: 16px;
  border-radius: 12px;
  padding: 4px 0;
  margin-top: 15px;
  border: 2px solid var(--clr-white) !important;
  font-weight: 500;
  min-width: 150px;
  text-align: center;
  letter-spacing: 0px;
  text-transform: none;
  text-decoration: none;
  transition: all 0.35s;
  color: var(--clr-white);
  cursor: pointer;
  outline: none;
}

.cet-recruiter-btn button:hover {
  background-color: var(--clr-white);
  color: var(--clr-purple);
}

.cet-recruiter-btn button:first-child {
  margin-right: 15px;
}

.node--type-branch-page .secondary-branch-menu {
  display: inline-flex;
}

.node--type-branch-page .secondary-branch-menu .gva_menu_main.login_branch_color {
  padding: 0;
  margin: 0;
}

.node--type-branch-page .secondary-branch-menu .login_branch_color a span {
  line-height: 22px;
  position: relative;
  padding: 11px 11px;
  margin: 0px 10px;
  border-bottom: 5px solid #00000000;
  transition: all 0.2s;
  padding-top: 35px;
  margin-bottom: 22px;
  display: inline-block;
  margin-right: 0 !important;
}

.node--type-branch-page .secondary-branch-menu .login_branch_color a {
    display: flex;
    align-items: center;
}

.node--type-branch-page .secondary-branch-menu .icaret {
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
}

.node--type-branch-page .secondary-branch-menu .icaret img {
  height: auto;
  width: 14px;
}

.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color:hover .icaret {
  transform: rotate(180deg);
  transition: all 0.2s;
}


.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color {
  padding: 0;
}

.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color .sub-menu {
  border-radius: 12px;
  font-family: Montserrat !important;
  min-width: max-content !important;
  padding: 23px 33px;
  position: absolute;
  background-color: #fff;
  z-index: 999;
  right: 0;
  left: auto;
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 4px 1px rgb(0 0 0 / 20%);
  transform: rotateX(-90deg);
  transform-origin: 0 0;
  border-top: 1px solid #eaeaea;
  top: 100%;
  opacity: 0;
  display: block;
}

.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color .sub-menu li {
  padding: 0;
  margin: 0;
  border: none;
}

.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color .sub-menu li a {
  text-decoration: none;
  border-bottom: none;
  border-top: none !important;
  font-weight: 600 !important;
  font-size: 15px;
  text-transform: none;
  margin-bottom: 0;
  letter-spacing: 0.5px !important;
  display: block;
  font-family: Montserrat !important;
  line-height: 24px;
  border: none;
  text-align: left;
  color: #000;
  transition: all 0.35s;
}

.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color:hover .sub-menu {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
  top: 78%;
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
}

.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color .sub-menu li:hover {
  border-left: none;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  background-color: transparent;
}

.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color .sub-menu li:hover a {
  border-left: none;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  background-color: transparent;
  color: #37006e !important;
  text-decoration: underline;
}

.close:hover, .close:focus {
  border: none;
  outline: none;
}

header .header-main-inner {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

header .gva-offcanvas-mobile .gva-navigation .gva_menu>li {
  border-bottom: none;
}

.menu--secondary-main-menu {
  margin-top: 0px;
}

header .navigation .gva_menu>li>a>span {
  padding-bottom: 0px;
}

header .navigation .gva_menu li a .icaret {
  display: block;
}

header .navigation .gva_menu .gva-mega-menu>.sub-menu>li,
header .navigation .gva_menu .sub-menu>li {
  border-left: 5px solid #00000000;
  padding-left: 15px;
  padding-right: 0px;
}

header .navigation .gva_menu>li:hover>ul.sub-menu,
header .navigation .gva_menu>li:hover div.sub-menu {
  top: 71px;
}

header .navigation .gva_menu>li {
  padding: 28px 7px 23px 7px;
}

header .header-inner nav .gva-navigation .menu-item--expanded a {
  font-size: 16px;
  font-weight: 600 !important;
  font-family: Montserrat !important;
  line-height: 16px;
}

header .header-inner nav .gva-navigation .menu-item--expanded a :first-child {
  padding: 13px 8px 12px 8px !important;
  display: flex;
  border-bottom: 5px solid #00000000;
  border-top: 5px solid #00000000;
}

header .navigation .gva_menu .sub-menu>li {
  padding: 0px 14px;
  margin-bottom: 0px;
}

header .header-inner nav .gva-navigation .sub-menu .menu-item>a {
  padding: 12.5px 0px 12.5px 0px !important
}

header .header-inner nav .gva-navigation .sub-menu .menu-item>a>span {
  padding: 0px !important;
  border: none !important;
}

header .header-inner nav .gva-navigation .sub-menu a {
  font-size: 15px;
  padding: 0px;
  border: none;
  font-family: Montserrat !important;
  line-height: 24px;
}

header .navigation .gva_menu .sub-menu {
  border-radius: 12px;
  font-family: Montserrat !important;
  min-width: 350px;
  padding: 15px 13px;
}

header .secondary-main-menu--cblock .gva_menu .sub-menu {
  min-width: max-content !important;
  padding: 23px 33px !important;
}

header .navigation .gva_menu>li a,
.navigation .gva_menu .megamenu>.sub-menu>li>a {
  font-size: 15px;
  font-weight: 600 !important;
  font-family: Montserrat !important;
  line-height: 24px;
  border: none;
}

.navigation .gva_menu .megamenu>.sub-menu>li>a {
  border-bottom: none !important
}

header .navigation .gva_menu .gva-mega-menu>.sub-menu>li>a h6,
header .navigation .gva_menu .sub-menu li a h6 {
  font-size: 13px;
  font-weight: 400 !important;
  font-family: Montserrat !important;
  margin-bottom: 0;
  text-transform: none;
  line-height: 21px;
}

header .navigation .gva_menu .megamenu>.sub-menu>li,
.navigation .gva_menu .gva-mega-menu>.sub-menu>li {
  padding: 0 15px;
}

header .navigation .gva_menu .megamenu>.sub-menu,
.navigation .gva_menu .gva-mega-menu>.sub-menu {
  padding: 12.5px 13px 12.5px 13px;
}

.branding {
  z-index: 2000;
}

/* Header Menus */
header.header-default .header-main-inner .header-inner {
  padding-left: 0px;
  padding-right: 0px;
}

header.header-default .header-main-inner .gva-offcanvas-mobile {
  float: left;
}

header .area-main-menu .navigation ul li.login-butt {
  position: absolute !important;
  right: 21.5%;
}

header .area-main-menu .navigation ul li.mnu-ctus {
  position: absolute !important;
  right: 5.5%;
}

.header-inner .gva-search-region {
  right: 30%;
  position: absolute;
}

header .site-branding-logo {
  padding-top: 30px !important;
  padding-bottom: 30px;
  padding-left: 0px;
  display: inline-block;
}

header .main-menu ul.gva_menu>li.icn-hdn>a {
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

header .main-menu ul.gva_menu>li.mnu-ctus>a {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 24px;
  gap: 10px;
  background: #ffc864;
  border-radius: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-decoration: none;
}

header.header-default .header-main-inner .gva-search-region {
  margin-top: -5px;
}

header .header-main {
  padding: 0 30px;
}

header.header-default .header-main-inner .branding {
  width: auto;
  float: left;
  max-width: fit-content;
}

header.header-default .header-main-inner .branding div:first-child {
  display: flex;
}

.navigation .gva_menu>li a:first-child,
.navigation .gva_menu .megamenu>.sub-menu>li>a:first-child {
  text-decoration: none;
  border-bottom: none;
  border-top: none !important;
  font-weight: 600 !important;
  font-size: 15px;
  text-transform: none;
  margin-bottom: 0;
  letter-spacing: 0.5px !important;
}

header .navigation .gva_menu .gva-mega-menu>.sub-menu>li:hover,
header .navigation .gva_menu .sub-menu li:hover {
  border-left: 5px solid #37006e;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.09);
  background-color: #f8f9f9;
}

header .navigation .gva_menu .gva-mega-menu>.sub-menu>li:hover a,
header .navigation .gva_menu .sub-menu li:hover a {
  color: #37006e !important;
}

header .navigation .gva_menu .gva-mega-menu>.sub-menu>li:hover a h6,
header .navigation .gva_menu .sub-menu li:hover a h6 {
  color: #000 !important;
}

.block-local-tasks-block {
  z-index: 9;
}

header .menu-bar {
  display: none;
}

header .area-main-menu .secondary-main-menu--cblock ul li .login-butt {
  position: unset;
}

.header-inner .gva-search-region {
  right: 31%;
}

header .area-main-menu .secondary-main-menu--cblock ul li .login-butt svg {
  display: block;
}

header .navigation .gva_menu>li.menu-item--expanded {
  position: relative !important;
  padding: 17px 7px 17px 7px;
}

header .navigation .gva_menu>li.gva-mega-menu>.sub-menu {
  min-width: 650px;
}

header .area-main-menu .secondary-main-menu--cblock ul li .login-butt {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #656565 !important;
  padding: 4px 0px;
}

header .area-main-menu .secondary-main-menu--cblock ul li svg {
  transform: rotate(90deg);
  color: #656565 !important;
  font-size: 16px;
}

header .navigation .gva_menu>li:hover>a>span,
header .navigation .gva_menu>li:focus>a>span,
header .navigation .gva_menu>li:active>a>span,
header .navigation .gva_menu>li.menu-item--active-trail>a>span {
  border-bottom: 5px solid #37006e;
}

header .navigation .gva_menu>li:hover>a>span:nth-child(2),
header .navigation .gva_menu>li:focus>a>span:nth-child(2),
header .navigation .gva_menu>li:active>a>span:nth-child(2),
header .navigation .gva_menu>li.menu-item--active-trail>a span:nth-child(2),
header .navigation .gva_menu>li:hover>a>span:nth-child(2) {
  border-bottom: none !important;
}

header .navigation .gva_menu>li>a .icaret {
  padding: 0px;
  border-bottom: none !important;
  transform: rotate(00deg);
  transition: all 600ms;
}

header .navigation .gva_menu>li:hover>a .icaret {
  padding: 0px;
  border-bottom: none !important;
  transform: rotate(180deg);
  transition: all 600ms;
}

header.navigation .gva_menu .megamenu>.sub-menu>li>a,
header.navigation .gva_menu .gva-mega-menu>.sub-menu>li>a {
  padding: 6px 0px 6px 0px;
  margin: 0;
  text-transform: unset;
}


header .navigation .gva_menu>li:hover>a>span:first-child,
header .navigation .gva_menu>li.isexpanded>a>span:first-child,
header .navigation .gva_menu>li.isexpanded>a>span:first-child,
header .navigation .gva_menu>li.menu-item--active-trail>a:first-child span {
  border-bottom: 5px solid #37006e;
}

header .navigation .gva_menu>li>a .icaret {
  border-bottom: none !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:nth-child(2) a {
  font-size: 16px;
  line-height: 27px;
  border: 2px solid #ffc864 !important;
  padding: 4px 25px !important;
  border-radius: 12px;
  text-align: center;
  display: block;
  width: 100%;
  font-weight: 600 !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:nth-child(2) a:hover {
  background: #ffc864 !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:hover {
  border-left: none;
  border-radius: 6px;
}

header .secondary-main-menu--cblock ul.gva_menu li:nth-child(3) a {
  font-size: 16px;
  line-height: 27px;
  border: 2px solid #ffc864 !important;
  padding: 4px 25px !important;
  border-radius: 12px;
  background: #ffc864 !important;
  color: #000;
  text-align: center;
  display: block;
  width: 100%;
  font-weight: 600 !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:nth-child(3) a:hover {
  background: #fff !important;
}

header .navigation .gva_menu>.login-butt>ul.sub-menu li {
  padding: 0;
  color: #37006e !important;
}

header.navigation .gva_menu>.login-butt>ul.sub-menu li a {
  padding: 2px 0;
  color: #37006e !important;
  text-decoration: underline;
}

header .area-main-menu .secondary-main-menu--cblock ul .login-butt:hover svg {
  transform: rotate(270deg);
  transition: all 600ms;
}

.gva-offcanvas-mobile .gva-navigation .gva_menu>li>a {
  text-transform: none;
}

header .secondary-main-menu--cblock {
  position: absolute !important;
  top: 0;
  right: 0;
}

header .secondary-main-menu--cblock .gva_menu {
  display: flex;
  align-items: center;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child {
  align-items: center;
  gap: 8px;
  color: #656565 !important;
  padding: 5px 0px 3px 0px;
}

header .secondary-main-menu--cblock ul.gva_menu li:nth-child(2) {
  padding-right: 13px;
  padding-left: 30px;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child:hover a:first-child,
header .secondary-main-menu--cblock ul.gva_menu li:first-child a:first-child {
  border-bottom: none !important;
  border-top: none !important;
  margin-top: 3px;
  margin-bottom: 0px;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child a {
  position: unset;
  display: flex;
  align-items: center;
  color: #656565 !important;
  gap: 4px;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child a svg {
  transform: none !important;
  color: #656565 !important;
  fill: #656565 !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:hover a svg {
  transform: rotate(180deg) !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child .sub-menu li {
  padding: 0;
  box-shadow: none;
  margin-bottom: 0;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child .sub-menu li:hover {
  background: #00000000;
  text-decoration: underline;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child .sub-menu li:hover a {
  color: #37006E !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child .sub-menu li a {
  border: none !important;
  background-color: #00000000 !important;
  padding: 0 !important;
  padding-right: 0 !important;
  font-weight: 600 !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child .sub-menu li:hover {
  box-shadow: none !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child .icaret {
  padding: 0 !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child .sub-menu li:hover {
  border: none !important;
  box-shadow: none;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child svg {
  display: block;
}

header .secondary-main-menu--cblock .gva_menu>li:hover>ul.sub-menu,
header .secondary-main-menu--cblock .gva_menu>li:hover div.sub-menu {
  top: 100%;
}

header .secondary-main-menu--cblock .gva_menu>ul.sub-menu {
  min-width: max-content;
}

header .secondary-main-menu--cblock ul.gva_menu li {
  padding-left: 0;
  padding-right: 0;
  border: none !important;
}

header .header-main-inner.p-relative .row {
  align-items: center;
}

@media (max-width: 991.98px) {
  .gva-offcanvas-mobile {
      max-width: 100%;
  }
}

header .navigation .secondary-main-menu--cblock>li:hover>a:first-child,
header .navigation .gva_menu>li:focus>a:first-child,
header .secondary-main-menu--cblock>li:active>a:first-child,
header .secondary-main-menu--cblockli.menu-item--active-trail>a:first-child {
  border-bottom: 4px solid #00000000 !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child .sub-menu li a {
  color: #000 !important;
  font-size: 15px;
  line-height: 24px;
  margin: 0px 0px;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child .sub-menu li:first-child a {
  margin-bottom: 5px;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child .sub-menu li a:hover {
  font-weight: 600 !important;
  text-decoration: underline;
  color: #37006e !important;
}

.contact-modal-mobile-title {
  display: none;
}

/* mobile gs header*/
@media only screen and (min-device-width: 320px) and (max-device-width: 1040px) {
.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color .sub-menu li:hover a {
  color: #fff !important;
  text-decoration: none;
}
.node--type-branch-page header #block-menublock a.menu-branch-active span {
  border-bottom: none !important;
}
.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color:hover .icaret {
  transform: none;
}
  .node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color .icaret {
  transform: none;
}
.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color.isexpanded .icaret {
transform: rotate(180deg);
}

.node--type-branch-page .secondary-branch-menu .icaret::before {
  content: "" !important
}

.node--type-branch-page .secondary-branch-menu {
  display: block;
}

.node--type-branch-page .secondary-branch-menu .login_branch_color a {
  display: flex !important;
  align-items: baseline;
  justify-content: space-between;
}

.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color:hover .sub-menu {
  opacity: 1;
  transform: none;
  visibility: visible;
  top: 0;
  margin-top: 20px;
}

.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color .sub-menu {
  display:none;
  position: relative;
  padding: 0;
  background-color: transparent !important;
  padding-left: 20px;
  border: none;
  box-shadow: none;
  opacity: 1;
  transform: none;
  margin-top:20px;
  padding-bottom:8px;
}

.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color .sub-menu li a {
  color: #fff !important;
}

.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color .sub-menu li:first-child a {
  margin-bottom: 24px;
}

.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color.isexpanded .sub-menu {
  margin-top: 20px !important;
  opacity: 1;
  top: 78%;
  transform: rotateX(0);
  visibility: visible;
}

.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color.isexpanded span:not(.icaret) {
  border-bottom: 5px solid #fff !important;
}

.node--type-branch-page header #block-menublock a span:hover {
  border-bottom: none !important;
}

.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color.isexpanded .icaret,
.node--type-branch-page .secondary-branch-menu .menu-item.login_branch_color.isexpanded .icaret {
  transform: rotate(180deg) !important;
}


.node--type-branch-page .secondary-branch-menu .login_branch_color a span:not(.icaret) {
  border-bottom: 5px solid transparent;
  margin: 0 !important;
}

.node--type-branch-page .secondary-branch-menu .login_branch_color.isexpanded a span:not(.icaret) {
  border-bottom:none !important;
}

header .navigation .gva_menu>li:hover>a .icaret {
  transform: none !important;
}

header .navigation .gva_menu>li.isexpanded>a .icaret {
  transform: rotate(180deg) !important;
}

header .navigation .gva_menu>li:hover>a>span:first-child {
  border-bottom: 5px solid #00000000;
}

header .secondary-main-menu--cblock .gva_menu>li.isexpanded a span:first-child {
  border-bottom: none !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child a .icaret {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  position: unset !IMPORTANT;
  height: auto;
  width: auto;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child a .icaret:before {
  position: unset !important;
  padding: 0 !important;
  transform: none;
  top: auto;
  margin: 0;
}

header .navigation .gva_menu>li.isexpanded a span:first-child {
  border-bottom: 5px solid #37006e;
}

header .navigation .gva_menu>li.isexpanded .sub-menu a span:first-child {
  border-bottom: none !important;
}

.node--type-branch-page header .menu-scroll-content {
  height: auto;
  display: block;
}

.contact-drop-modal .modal-body {
  padding: 0rem 1rem 2rem 1rem !important;
}

.cetera-contacts-row .contact-modal-image br {
  display: none;
}

header .menu-scroll-content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 70px);
  justify-content: space-between;
}

header .secondary-main-menu--cblock .gva_menu {
  flex-direction: column;
  padding: 12px 20px !important;
}

header .main-menu .area-main-menu .area-inner #block-gavias-nonid-main-menu {
  height: auto;
  position: unset;
  z-index: 1;
}

header .secondary-main-menu--cblock {
  z-index: 1 !important;
  position: unset !important;
  padding: 0 !important;
}

header .menu--secondary-main-menu .gva-navigation .gva_menu {
  padding-bottom: 100px !important;
}

header .secondary-main-menu--cblock {
  z-index: 1 !important
}

.menu--secondary-main-menu .gva-navigation .gva_menu {
  background: white !important
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child {
  border-bottom: 1px solid #E0E1E2 !important;
  padding-bottom: 31px !important;
  margin-bottom: 31px !important;
  padding-top: 8px !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child a {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #656565 !important;
  line-height: 22px !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:nth-child(2) a,
header .secondary-main-menu--cblock ul.gva_menu li:last-child a {
  line-height: 28px !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:nth-child(2) {
  padding-bottom: 24px !important;
  padding-left: 0;
  padding-right: 0;
}

header .secondary-main-menu--cblock ul.gva_menu li:last-child {
  padding-bottom: 19px !important;
}

header .navigation .gva_menu>li a,
.navigation .gva_menu .megamenu>.sub-menu>li>a {
  font-size: 15px !important;
  line-height: 15px !important;
}

.gva-offcanvas-mobile .gva-navigation .gva_menu>li ul.menu.sub-menu li a:hover {
  color: #000 !important;
}

.branding div:first-child {
  display: flex;
}

header .header-inner nav .gva-navigation .sub-menu .menu-item>a {
  padding: 12px 0 !important;
}

header .navigation .gva_menu .gva-mega-menu>.sub-menu li {
  margin: 0 !important;
}

header .gva-offcanvas-mobile .gva-navigation .gva_menu>li ul.menu.sub-menu li a:hover {
  color: #000 !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child .sub-menu li a {
  margin: 10px 0px 0px 0px;
  font-weight: 500 !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child .sub-menu li a :hover {
  font-weight: 600 !important;
}

header .navigation .gva_menu>li.menu-item--expanded {
  padding: 0px 7px !important;
}

header .header-inner nav .gva-navigation .menu-item--expanded a :first-child {
  padding: 0px 8px 10px 8px !important;
}

.main-menu .area-main-menu .area-inner .block {
  z-index: -1;
}

header .header-main {
  padding: 0px 0px;
}

header .navigation .gva_menu .gva-mega-menu>.sub-menu li {
  margin: 0px 0px 0px 0px !important;
}

header .navigation .gva_menu>li a,
.navigation .gva_menu .megamenu>.sub-menu>li>a {
  font-size: 15px !important;
  font-weight: 600 !important;
  font-family: Montserrat !important;
  line-height: 21px !important;
  border: none;
}

header .navigation .gva_menu .gva-mega-menu>.sub-menu>li>a h6,
header .navigation .gva_menu .sub-menu li a h6 {
  font-size: 13px !important;
  font-weight: 400 !important;
  font-family: Montserrat !important;
  margin-bottom: 0;
  text-transform: none;
  line-height: 21px !important;
}

header .menu--secondary-main-menu .gva-offcanvas-mobile .gva-navigation .gva_menu li ul.menu.sub-menu li a {
  padding-bottom: 6px !important;
}

header .navigation .gva_menu>li.menu-item--expanded>a>span {
  padding: 0 10px 10px 10px !important;
  margin-bottom: 8px !important;
}

.gva-offcanvas-mobile .gva-navigation .gva_menu {
  padding-top: 17px;
}

.menu--secondary-main-menu .gva-navigation .gva_menu {
  padding-top: 17px !important;
  box-shadow: 0px -1px 4px rgb(0 0 0 / 25%);
  gap: 0;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child {
  padding: 0 0 20px 0 !important;
}

.gva-offcanvas-mobile .gva-navigation .gva_menu>li ul.menu.sub-menu li a:hover,
.gva-offcanvas-mobile .gva-navigation .gva_menu>li ul.menu.sub-menu li a {
  color: #000 !important;
}

.gva-offcanvas-mobile .gva-navigation .gva_menu>li:first-child ul.menu.sub-menu li a {
  line-height: 22px !important;
  margin-top: 4px !important;
  margin-bottom: 0 !important;
}

header .navigation .gva_menu li a .icaret {
  display: block;
}

header.header-default {
  padding: 0px;
}

.gva-offcanvas-mobile .gva-navigation .gva_menu > li > a {
  text-transform: none;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

header .area-main-menu {
  min-height: auto;
  display: flex;
  justify-content: flex-end;
}

header .navigation .gva_menu .menu-item {
  background-color: white;
}

header .navigation .gva_menu .sub-menu li {
  border-left: none !important;
  padding-left: 0;
  background-color: white;
}

header .navigation .gva_menu .gva-mega-menu>.sub-menu>li:hover,
header .navigation .gva_menu .sub-menu li:hover {
  border-left: none;
  box-shadow: none;
  background: #00000000;
}

header .main-menu .area-main-menu .area-inner #block-gavias-nonid-main-menu {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 0;
  height: 70vh;
  padding: 20px;
}

header.header-default .header-main-inner .gva-offcanvas-mobile {
  height: 100vh;
  overflow: hidden;
}

header .header-main-inner .row:first-child {
  justify-content: space-between;
}

.menu-bar {
  display: block !important;
  height: 28px;
  width: 36px;
  position: relative;
  cursor: pointer;
  top: 0;
  right: 0;
  margin-top: 0;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child svg,
header .secondary-main-menu--cblock ul.gva_menu li:first-child a svg,
header .secondary-main-menu--cblock ul.gva_menu li:hover svg,
header .secondary-main-menu--cblock ul.gva_menu li:hover a svg {
  transform: none !important;
}

header .secondary-main-menu--cblock ul.gva_menu li,
header .secondary-main-menu--cblock ul.gva_menu li a {
  width: 100%;
  display: block;
  text-align: center !important;
}

header menu--secondary-main-menu {
  background-color: white;
}

header .secondary-main-menu--cblock ul.gva_menu li .sub-menu li {
  border-bottom: none !important;
  padding: 0 !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child a {
  border-bottom: 1px solid #e0e1e2 !important;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child,
header .secondary-main-menu--cblock ul.gva_menu li:first-child a {
  text-align: left !important;
  color: #656565 !important;
  margin-top: 0;
}

header .secondary-main-menu--cblock ul.gva_menu li:first-child .sub-menu li,
header .secondary-main-menu--cblock ul.gva_menu li:first-child .sub-menu li a {
  color: #37006e !important;
  margin-bottom: 4px !important;
}


header .site-branding-logo {
  padding-left: 0;
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.header-inner .gva-search-region {
  right: 18%;
  right: 18%;
  margin-top: 0 !important;
  top: 0;
  position: relative;
  display: flex;
  align-items: center;
}

.navigation .gva_menu li a .icaret {
  display: block;
}

.gva-offcanvas-mobile .gva-navigation .gva_menu li a .icaret {
  width: 12px;
  height: 12px;
  right: 0;
  position: relative;
}

.gva-offcanvas-mobile .close-offcanvas {
  text-align: right;
  padding: 15px;
  border-bottom: none;
  z-index: 4;
}

.gva-offcanvas-mobile .close-offcanvas i {
  font-weight: 900;
  font-size: 12px;
  margin: 16px 9px 0px 0px;
  z-index: 2;
}

.gva-offcanvas-mobile .gva-navigation .gva_menu li a:hover {
  color: #000 !important;
}

.gva-offcanvas-mobile .block {
  border-bottom: none;
}

.gva-offcanvas-mobile .gva-navigation .gva_menu>li {
  border-bottom: none !important;
  margin-bottom: 0 !important;
}

.gva-offcanvas-mobile .gva-navigation .gva_menu>li:last-child {
  border-bottom: 1px solid #eaeaea;
}

header .gva-offcanvas-mobile .gva-navigation .gva_menu>li:last-child {
  border-bottom: none;
}

header .secondary-main-menu--cblock ul.gva_menu li .menu-item--expanded:first-child {
  border-bottom: 1px solid #e0e1e2 !important;
}
}

/* tab gs header*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1040px) {

  .contact-modal-mobile-title {
    display: none !important;
  }

  .contact-modal-title {
    display: block;
    padding-left: 15px;
    padding-bottom: 0;
  }

  .secondary-main-menu--cblock ul.gva_menu li:first-child a {
    position: relative;
  }

  .gva-offcanvas-mobile .gva-navigation .gva_menu>li ul.menu.sub-menu li a:hover {
    color: #54117b !important;
  }

  header .area-inner {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  header menu--secondary-main-menu {
    background-color: white;
  }

  header .secondary-main-menu--cblock .gva_menu {
    position: fixed !important;
    bottom: 0;
    z-index: 999999;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 20px !important;
    background-color: #fff;
    width: 100%;
    gap: 16px;
  }

  .menu-bar {
    display: block !important;
    height: 28px;
    width: 36px;
    position: relative;
    cursor: pointer;
    top: 0;
    right: 0;
    margin-top: 0;
  }

  header .secondary-main-menu--cblock ul.gva_menu li:first-child {
    border-bottom: 1px solid #e0e1e2 !important;
    padding: 0 0 20px 0 !important;
  }

  .gva-offcanvas-mobile .gva-navigation .gva_menu li a .icaret {
    width: 20px;
    height: 20px;
  }


  .gva-offcanvas-mobile .close-offcanvas {
    border-bottom: none;
  }
}

.node--type-branch-page .header-default {
  background: linear-gradient(117.03deg, #210036 11.9%, #320148 34.01%, #22004c 63.11%, #000000 86.39%);
}

.node--type-branch-page .header-main-inner .row:first-child {
  justify-content: space-between;
  align-items: center;
}

.node--type-branch-page .header-main-inner .row .col-md-9.p-static {
  display: flex;
  justify-content: flex-end;
}

.node--type-branch-page header .site-branding-logo {
  padding-top: 20px;
  padding-bottom: 20px;
}

.node--type-branch-page header #block-menublock a>span {
  font-size: 16px;
  font-family: Montserrat;
  color: white;
  font-weight: 600;
  padding: 0 24px;
  text-decoration: none;
}

/* mobile gs  about*/
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

  .node--type-branch-page header .menu-scroll-content {
    height: auto;
    display: block;
  }

  .contact-modal-mobile-title {
    display: block !important;
  }

  .contact-modal-image {
    padding-left: 30px !important;
  }

  .contact-modal-title {
    display: none;
    padding-left: 0;
    padding-bottom: 0;
  }

  .node--type-branch-page header .menu-scroll-content {
    height: auto;
    display: block;
  }

  .cetera-contacts-row .contact-modal-image img {
    display: none;
  }

  header .main-menu .area-main-menu .area-inner {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .node--type-branch-page .menu-bar span {
    background-color: #fff;
  }

  .node--type-branch-page header #block-menublock a>span {
    padding-left: 0;
    color: #000;
  }

  .node--type-branch-page header #block-menublock a {
    display: block;
  }
}

/* tab gs  about*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1040px) {

  .node--type-branch-page header .menu-scroll-content {
    height: auto;
    display: block;
  }

  .branding div:first-child {
    display: flex;
  }

  .node--type-branch-page .menu-bar span {
    background-color: #fff !important;
  }

  .node--type-branch-page .menu-bar.hidden-md {
    display: block !important;
    z-index: 999;
  }

  .node--type-branch-page header #block-menublock a {
    padding-left: 0;
    color: #000;
    display: block;
  }
}

.icaret {
  border-bottom: none !important;
}

.block-branch-logo-bock img {
  max-width: 210px;
}

.block-branch-logo-bock {
  margin-bottom: 0px;
  padding: 13px 0px;
}

.press-room-banner-row {
  margin: 0px !important;
}

.press-room-banner-row .press-room-banner {
  padding: 0px !important;
}

.branch-modal .mktoOffset {
  height: 0px;
}

.contact-branch-heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  padding-left: 15px;
  padding-bottom: 16px;
}

.branch-modal .mktoButtonWrap {
  display: flex;
  padding: 0 15px;
}

.branch-modal .mktoButtonWrap .mktoButton {
  width: 100%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
.branding div:first-child {
  display: flex;
}

.menu--secondary-main-menu .gva-navigation .gva_menu {
  background: white !important;
}

header .secondary-main-menu--cblock {
  z-index: 1 !important;
  background: white !important;
  padding: 0 !important;
}

.gva-offcanvas-mobile {
  transform: translateX(100%);
}
}

@media only screen and (min-device-width: 480px) and (max-device-width: 1040px) {
  header .header-inner nav .gva-navigation .sub-menu .menu-item>a>span {
    margin-top: 0 !important;
  }

  header .navigation .gva_menu .megamenu>.sub-menu>li>a {
    margin-top: 0 !important;
  }

  .branding #block-branchlogoblock-2 {
    padding-top: 4px;
  }

  header .header-inner nav .gva-navigation .menu-item--expanded a :first-child {
    display: inline-flex;
  }

  .gva-offcanvas-mobile {
    transform: translateX(100%);
  }

  header .secondary-main-menu--cblock ul.gva_menu li,
  header .secondary-main-menu--cblock ul.gva_menu li a {
    text-align: center !important;
  }

  .gva-offcanvas-mobile .gva-navigation .gva_menu>li ul.menu.sub-menu li a:hover,
  .gva-offcanvas-mobile .gva-navigation .gva_menu>li ul.menu.sub-menu li a {
    color: #000 !important;
  }
  }

  @media only screen and (min-device-width: 1040px) and (max-device-width: 1224px) {
  .node--type-branch-page header .menu-scroll-content {
    height: auto;
    display: block;
  }

  .contact-modal-mobile-title {
      display: none !important;
  }

  .contact-modal-title {
    display: block;
    padding-left: 15px;
    padding-bottom: 0;
  }

  header .navigation .gva_menu>li.menu-item--expanded {
    padding: 17px 1px 17px 1px;
  }

  header .secondary-main-menu--cblock ul.gva_menu li:first-child {
    padding: 0px;
  }

  .gva-offcanvas-mobile .gva-navigation .gva_menu>li ul.menu.sub-menu li a:hover,
  .gva-offcanvas-mobile .gva-navigation .gva_menu>li ul.menu.sub-menu li a {
    color: #000 !important;
  }

  header .header-main-inner .row>.branding,
  header .header-main-inner .row>.p-static {
    padding-left: 10px;
    padding-right: 10px;
  }

  header .header-main {
    padding: 0 10px;
  }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1040px) {
  .gva-offcanvas-mobile.show-view {
    transform: translateX(0);
    transition: all 0.35s;
  }

  .gva-offcanvas-mobile .gva-navigation .gva_menu>li ul.menu.sub-menu li a {
    font-size: 15px !important;
  }

  .gva-offcanvas-mobile {
    position: fixed !important;
    bottom: 0;
    z-index: 9;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    width: 100%;
  }

  .gva-offcanvas-mobile .gva-navigation .gva_menu>li {
    list-style: none;
    display: block;
    clear: both;
    width: 100%;
    padding-bottom: 8px !important;
    padding-top: 2px !important;
    border-bottom: 1px solid #eaeaea;
  }

  .gva-offcanvas-mobile .gva-navigation .gva_menu li a .icaret:before {
    position: absolute;
    text-transform: inherit;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .gva-offcanvas-mobile .gva-navigation .gva_menu li a .icaret {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    bottom: 0;
    right: 0px;
    font-size: 13px;
  }

  .gva-offcanvas-mobile .close-offcanvas {
    display: block !important;
    visibility: visible !important;
    width: 100%;
    padding: 15px;
    opacity: 1;
    filter: alpha(opacity=100);
    color: #111111;
  }

  .gva-offcanvas-mobile .gva-navigation .gva_menu .sub-menu {
    -webkit-transition: none;
    transition: none;
    -moz-transition: none;
    -ms-transition: none;
    min-width: inherit;
    display: none;
    width: 100%;
    position: static;
    padding: 0 !important;
    visibility: visible;
    border: 0;
    background: none;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 20px !important;
  }

  .gva-offcanvas-mobile .gva-navigation .gva_menu>li.megamenu .sub-menu>li {
    width: 100% !important;
    float: none !important;
    display: block;
    padding: 0;
  }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 768px) {
  .contact-modal-image {
    padding-left: 20px !important;
  }

  .contact-modal-mobile-title {
    display: block !important;
  }

  .contact-modal-title {
    display: none;
    padding-left: 0;
    padding-bottom: 0
  }

  .cetera-contacts-row .contact-modal-image img {
    display: none;
  }

  header .menu-bar {
    display: block !important;
  }

  header .header-main {
    padding: 0px 0px;
  }
}

.node--type-branch-page .block-branch-logo-bock {
  padding-top: 11px;
  padding-bottom: 14px
}

.block-branch-logo-bock {
  padding: 12.5px 0px;
}

.node--type-branch-page header #block-menublock a>span {
  line-height: 22px;
  position: relative;
  padding: 11px 11px;
  margin: 0px 10px;
  border-bottom: 5px solid #00000000;
  transition: all 0.2s;
  padding-top: 35px;
  margin-bottom: 22px;
  display: inline-block;
}

.node--type-branch-page header #block-menublock a span:hover,
.node--type-branch-page header #block-menublock a.menu-branch-active span {
  border-bottom: 5px solid #fff;
}

.node--type-branch-page .menu-bar span {
  background-color: #fff;
}

.node--type-branch-page .cet-branch-nav-purple-bg .header-main-inner .p-static {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .branding #block-branchlogoblock-2 {
    padding-top: 4px;
  }

  .node--type-branch-page header.header-default .header-main-inner .branding {
    width: auto;
  }

  .node--type-branch-page header.header-default .header-main-inner .branding img {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}

@media only screen and (min-device-width: 386px) and (max-device-width: 480px) {
  .node--type-branch-page header.header-default .header-main-inner .branding img {
    height: 70px;
    width: auto;
    min-width: auto;
    max-width: none;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1040px) {
  .node--type-branch-page header.header-default .header-main-inner .branding {
    padding-left: 18px;
    height: 78px;
  }

  .node--type-branch-page header #block-menublock a::after {
    display: none;
    padding: 0;
  }

  .node--type-branch-page header #block-menublock a>span {
    color: #fff;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-bottom: 10px;
    padding-left: 8px;
  }

  .node--type-branch-page header #block-menublock a {
    display: block;
  }

  .node--type-branch-page header #block-menublock a:last-child span {
    margin-bottom: 0;
    border-bottom: 0;
  }

  .node--type-branch-page .cet-branch-nav-purple-bg .header-main-inner .p-static {
    display: flex;
    justify-content: end;
    width: 50%;
    max-width: 50%;
  }

  .node--type-branch-page header.header-default .header-main-inner .gva-offcanvas-mobile {
    background: linear-gradient(117.03deg, #210036 11.9%, #320148 34.01%, #22004c 63.11%, #000000 86.39%);
  }

  .node--type-branch-page header .menu-bar {
    display: block !important;
    z-index: 999;
  }

  .node--type-branch-page .gva-offcanvas-mobile .close-offcanvas {
    display: none !important;
  }

  .node--type-branch-page .gva-offcanvas-mobile.show-view {
    background: linear-gradient(117.03deg, #210036 11.9%, #320148 34.01%, #22004c 63.11%, #000000 86.39%);
  }

  .node--type-branch-page header.header-default .header-main-inner .gva-offcanvas-mobile {
    bottom: unset;
    height: auto;
    top: 0;
    padding: 0 !important;
  }

  .node--type-branch-page .main-menu .area-main-menu .area-inner .block {
    margin-top: 99px;
    padding: 0px 28px 60px 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1040px) {
  .cetera-contacts-row .contact-modal-image img {
    display: block;
  }

  .node--type-branch-page .main-menu .area-main-menu .area-inner .block {
    margin-top: 98px;
    padding-top: 0;
  }
}

.fap-wrapper-main {
 z-index: 9;
}

.cet-hero-container::before {
    background: linear-gradient(180deg, #707070 0%, rgba(18, 28, 34, 0.95) 82.81%);
    opacity: 0.9;
    mix-blend-mode: multiply;
}
.cet-teal-grd-bg-header {
  background: linear-gradient(123.79deg, #060E1E 5.01%, #101E3D 22.31%, #051C28 37.16%, #000000 88.9%);
}
.cet-teal-grd-bg-header .container {
  max-width: 100%;
}
.cet-teal-grd-bg-container, .cet-teal-grd-bg {
  background: radial-gradient(35.35% 84.06% at 98.43% 95.08%, rgba(41, 14, 68, 0.54) 22.52%, rgba(0, 33, 52, 0) 73.07%, rgba(0, 0, 0, 0) 100%), radial-gradient(89.49% 178.99% at 0% 19.88%, #1A1D59 0%, rgba(0, 33, 52, 0) 64.06%, rgba(0, 0, 0, 0) 100%), radial-gradient(38.91% 92.52% at 24.34% 14.96%, #05273A 21.14%, rgba(0, 33, 52, 0) 64.06%, rgba(0, 0, 0, 0) 100%), linear-gradient(126.91deg, #060E1E 5.2%, #101E3D 25.09%, #0C2636 38.47%, #000000 92.02%);
}
.cet-teal-grd-bg-container .bb-inner {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 50px;
}
.cet-teal-grd-bg-container .title {
  color: var(--clr-white);
  font-weight: 500;
}
.cet-teal-grd-bg-container p {
  color: var(--clr-white);
}
.cet-teal-grd-bg-header .gsc-image {
    margin-bottom: 0;
}
.cet-teal-grd-bg-header img {
    vertical-align: middle;
    padding-left: 100px;
}
@media (min-width: 576px) and (max-width: 767.98px) {
.cet-teal-grd-bg-header img {
    padding-left: 70px;
}
}
@media (max-width: 575.98px) {
.cet-teal-grd-bg-header img {
    padding-left: 0;
}
}
.cethold-quote {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: #FFF;
    padding: 45px 15px;
}
.cethold-quote h1 {
    padding-right: 20px;
    font-size: 70px !important;
    line-height: 70px !important;
}
.cethold-quote .quote {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
}
.cethold-quote .quote .author {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    padding-top: 25px;
}

@media (max-width: 767px) {
  .cet-teal-grd-bg-header.gbb-row .bb-inner.padding-small {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
  }
  .cethold-quote .quote .author {
      font-size: 12px;
      line-height: 16px;
  }
  .CH-img-padding-left, .CH-img-padding-right {
    padding: 20px 0;
  }
}
.cethold-bd-container {
    box-shadow: 0px 4px 18.84px rgba(0, 0, 0, 0.25);
    padding: 35px 20px;
    margin-bottom: 40px;
}
.cethold-bd {
    font-family: 'Rufina';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #1A1D59;
    min-height: 70px;
    max-width: 300px;
}
.cethold-bdsub {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    padding: 20px 0 0 0;
    min-height: 75px;
}
.cethold-bdbtn a {
   margin-bottom: 0;
}
.gsc-icon-box.top-center .desc {
    max-width: 240px;
}

@media (min-width: 768px) {
  .padding-20-left {
    padding-left: 20px;
  }
  .CH-img-padding-left {
    padding: 25px 0 25px 5px;
  }
  .CH-img-padding-right {
    padding: 25px 5px 25px 0;
  }
}
@media (max-width: 1199.98px) {
  .CH-content-padding {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

/*Modal bio content*/

.cet-modal-bio__wrapper {
  flex-direction: row;
  padding: 40px 0;
  border-radius: 9px;
}

.modal-header__name-title-wrapper div:first-child {
  font-family: var(--ff-primary);
  font-weight: 600;
  font-size: 22px;
  color: var(--clr-purple);
  line-height: 28px;
}

.modal-header__name-title-wrapper div:last-child {
  font-family: var(--ff-primary);
  color: var(--clr-dark-gray);
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

@media (min-width: 768px) {
  .cet-modal-bio {
    max-width: 75vw;
  }
}

.cet-modal-bio .modal-body {
  overflow-y: auto;
  max-height: 491px;
  padding: 10px 35px 0 30px;
}

.cet-modal-bio .modal-body li {
  list-style: disc!important;
}

@media (max-width: 767px) {
  .cet-modal-bio__wrapper {
    flex-direction: column;
    padding: 1.5rem 0;
    border: none;
  }
}


